import React, { memo } from 'react'
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer'
import { PDFViewer } from '@react-pdf/renderer'
import DCLogo from '../../../assets/images/logo/dc-logo.jpg'
import InterFont from '../../../assets/fonts/Helvetica-Narrow-Bold.ttf'
import { formatNumber } from '../../../helper/return.number'

Font.register({
  family: 'DejaVu-Bold',
  src: '../../../assets/fonts/DejaVuSerif-Bold.ttf',
})

Font.register({
  family: 'InterFont',
  fonts: [
    {
      src: InterFont,
      fontWeight: 600,
    },
  ],
})

const styles = StyleSheet.create({
  body: {
    paddingBottom: 65,
    // paddingHorizontal: 35,
  },
  mainView: {
    paddingLeft: 40,
    paddingRight: 40,
  },
  container: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container_two: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  header_row: {
    marginTop: 20,
    flexDirection: 'row',
    // justifyContent: 'start',
    fontFamily: 'Helvetica-Bold',
  },
  spec_row: {
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'start',
  },
  info_row: {
    marginTop: 7,
    padding: 10,
    paddingBottom: 5,
    // flexDirection: 'row',
    // justifyContent: 'center',
    // border : "1px solid black"
  },
  info_col: {
    flex: 6,
    flexDirection: 'col',
    justifyContent: 'start',
  },
  header: {
    fontSize: 12.87,
    // justifyContent: 'start',
    textTransform: 'uppercase',
  },
  subheader: {
    fontSize: 12.87,
    justifyContent: 'center',
  },
  subheader_info: {
    fontSize: 11,
    justifyContent: 'justify',
  },
  info_first_label: {
    fontSize: 11,
    justifyContent: 'center',
    textTransform: 'capitalize',
  },
  info_first_content: {
    fontSize: 11,
    justifyContent: 'center',
    textTransform: 'uppercase',
  },
  info_second_label: {
    marginTop: 10,
    marginBottom: 5,
    fontSize: 10,
    justifyContent: 'center',
    textTransform: 'capitalize',
    fontFamily: 'Helvetica',
    fontWeight: 500,
    color: '#636363',
    textTransform: 'uppercase',
  },
  info_second_content: {
    marginTop: 10,
    marginBottom: 5,
    fontSize: 10,
    justifyContent: 'start',
    textAlign: 'start',
    // fontFamily : 'InterFont',
    fontFamily: 'Helvetica',
  },
  spec_content: {
    fontSize: 9,
    color: '#333333',
    // marginLeft: 30,
    paddingLeft: 30,
    paddingRight: 30,
    justifyContent: 'start',
    textTransform: 'uppercase',
    lineHeight: 2,
    // fontFamily: 'Times-Roman',
  },
  main_image_div: {
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    // flexDirection: 'col',
  },
  image_size: {
    width: 500,
    height: 250,
    objectFit: 'cover',
  },
  image_size_report: {
    width: 500,
    height: 600,
    objectFit: 'contain',
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 40,
    right: 40,
    textAlign: 'center',
  },
  footerText: {
    fontSize: 12,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 20,
    fontFamily: 'Helvetica-Bold',
  },
  price: {
    marginTop: 5,
    flexDirection: 'row',
    justifyContent: 'center',
    fontSize: 17,
    // fontFamily : 'Inter-Bold'
    fontFamily: 'Helvetica-Bold',
  },
  headerLogo: {
    width: 150,
    marginTop: 30,
    marginBottom: 10,
  },
  text_info_row: {
    // marginTop: 5,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderBottom: '0.5px solid #bfbfbf',
  },
  specification_container: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 20,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const Header = () => (
  <View style={styles.header}>{/* <Image style={styles.headerLogo} src={DCLogo} /> */}</View>
)

const Footer = () => (
  <View style={styles.footer}>
    <Text style={styles.footerText}>Offered subject to prior sale</Text>
  </View>
)

const OpenCarPDf = ({ data, displayImg, exteriorImg, interiorImg, reportImg }) => {
  const imagesPerPage = 2
  const totalPageCount = Math.ceil(
    exteriorImg?.length / imagesPerPage + interiorImg?.length / imagesPerPage,
  )

  const carInfoLeft = [
    {
      name: 'Maker',
      value: data?.brand_name,
    },
    {
      name: 'Type',
      value: data?.ready_stock_type,
    },
    {
      name: 'Chassis No',
      value: data?.chassis_number,
    },
    {
      name: 'Code',
      value: data?.code,
    },
    {
      name: 'Mfg Year',
      value: data?.car_infos?.manufactory_year ? data.car_infos.manufactory_year : '-',
    },
    {
      name: 'Reg Date',
      value: data?.car_infos?.register_at ? data.car_infos.register_at : '-',
    },
  ]

  const carInfoRigth = [
    {
      name: 'Mileage',
      value: data?.car_infos?.mileage ? data.car_infos.mileage + ' KM' : '-',
    },
    {
      name: 'Fuel Type',
      value: data?.car_infos?.fuel_type ? data.car_infos.fuel_type : '-',
    },
    {
      name: 'Color',
      value: data?.car_infos?.color ? data.car_infos.color : '-',
    },
    {
      name: 'Capacity',
      value: data?.car_infos?.engine_capacity ? data.car_infos.engine_capacity : '-',
    },
    {
      name: 'Description',
      value: '-',
    },
    {
      name: 'Condition',
      value: data?.car_infos?.condition ? data?.car_infos?.condition : '-',
    },
  ]
  return (
    // <Suspense>
    // <PDFViewer style={{ width: "100%", height: "100vh" }}>
    <Document>
      <Page style={styles.body} wrap>
        <Header fixed />
        <View style={styles.mainView}>
          <View style={styles.container_two}>
            <View style={styles.header_row}>
              <Text style={styles.header}>
                {data?.car_infos?.manufactory_year ? data.car_infos.manufactory_year : '-'} /{' '}
                {data?.car_infos?.register_at ? data.car_infos.register_at : '-'} {data.brand_name}{' '}
                {data.car_model_name}
              </Text>
            </View>
          </View>
          {displayImg && displayImg.length > 0 && (
            <View style={styles.main_image_div}>
              <Image style={styles.image_size} src={displayImg[0]?.source} />
            </View>
          )}
          <View style={styles.container}>
            <View style={styles.header_row}>
              <Text style={styles.price}>RM {formatNumber(data?.asking_price)}</Text>
            </View>
          </View>
          <View style={styles.info_row}>
            <View>
              {carInfoLeft.map((item, index) => {
                return (
                  <View style={styles.text_info_row} key={index}>
                    <View style={{ flex: 6 }}>
                      <Text style={styles.info_second_label}>{item.name} :</Text>
                    </View>
                    <View style={{ flex: 6 }}>
                      <Text style={styles.info_second_content}>{item.value}</Text>
                    </View>
                  </View>
                )
              })}
            </View>
            <View>
              {carInfoRigth.map((item, index) => {
                return (
                  <View style={styles.text_info_row} key={index}>
                    <View style={{ flex: 6 }}>
                      <Text style={styles.info_second_label}>{item.name} :</Text>
                    </View>
                    <View style={{ flex: 6 }}>
                      <Text style={styles.info_second_content}>{item.value}</Text>
                    </View>
                  </View>
                )
              })}
            </View>
          </View>
        </View>
        <Footer fixed />
      </Page>
      <Page style={styles.body} wrap>
        <View style={styles.specification_container}>
          <View style={styles.header_row}>
            <Text style={styles.subheader}>Vehicle Specification</Text>
          </View>
        </View>
        <View style={styles.container_two}>
          <View style={styles.spec_row}>
            <Text style={styles.spec_content}>{data?.converted_specification}</Text>
          </View>
        </View>
        <Footer fixed />
      </Page>
      {(exteriorImg?.length > 0 || interiorImg?.length > 0 || reportImg?.length > 0) &&
        Array.from({ length: totalPageCount }, (_, index) => (
          <Page style={styles.body} key={index} wrap>
            <Header fixed />
            <View style={styles.mainView}>
              {index === 0 && (
                <View style={styles.container}>
                  <View style={styles.header_row}>
                    <Text style={styles.subheader}>Image Gallery</Text>
                  </View>
                </View>
              )}
              {exteriorImg &&
                exteriorImg.length > 0 &&
                index < Math.ceil(exteriorImg.length / imagesPerPage) && // Check if there are more exterior image pages
                exteriorImg
                  .slice(index * imagesPerPage, (index + 1) * imagesPerPage)
                  .map((image, imageIndex) => (
                    <View style={styles.main_image_div} key={imageIndex}>
                      <Image
                        style={{
                          height:
                            image.height > image.width && image.height > 600
                              ? 600
                              : image.height > image.width
                              ? image.height
                              : 300,
                          margin: 'auto',
                          objectFit: 'cover',
                        }}
                        src={image.source}
                      />
                    </View>
                  ))}

              {interiorImg &&
                interiorImg.length > 0 &&
                index >= Math.ceil(exteriorImg.length / imagesPerPage) && // Check if it's an interior image page
                interiorImg
                  .slice(
                    (index - Math.ceil(exteriorImg.length / imagesPerPage)) * imagesPerPage,
                    (index - Math.ceil(exteriorImg.length / imagesPerPage) + 1) * imagesPerPage,
                  )
                  .map((image, imageIndex) => (
                    <View style={styles.main_image_div} key={imageIndex}>
                      <Image
                        // style={interiorImg.length > 0 ? styles.image_size : ''}
                        style={{
                          height:
                            image.height > image.width && image.height > 600
                              ? 600
                              : image.height > image.width
                              ? image.height
                              : 300,
                          margin: 'auto',
                          objectFit: 'cover',
                        }}
                        src={image.source}
                      />
                    </View>
                  ))}
            </View>
            <Footer fixed />
          </Page>
        ))}
      {reportImg &&
        reportImg.length > 0 &&
        reportImg.map((image, imageIndex) => (
          <Page style={styles.body} key={imageIndex} wrap>
            <Header fixed />
            <View style={styles.mainView}>
              <View style={styles.container}>
                <View style={styles.header_row}>
                  <Text style={styles.subheader}>Report Image</Text>
                </View>
              </View>
              <View style={styles.main_image_div}>
                <Image
                  style={reportImg.length > 0 ? styles.image_size_report : ''}
                  src={image.source}
                />
              </View>
            </View>
            <Footer fixed />
          </Page>
        ))}
    </Document>
    //  </PDFViewer>
    // </Suspense>
  )
}

export default memo(OpenCarPDf)
