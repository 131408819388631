import React from 'react'
import { useState } from 'react'
import { Icon } from "@iconify/react";

//lib
import {
  MDBCard,
  MDBCardBody,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
  MDBCol,
  MDBContainer,
  MDBRow,
} from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//assets
import about1 from '../../../assets/images/about-us-img/about1.webp'
import about2 from '../../../assets/images/about-us-img/about2.webp'
import about3 from '../../../assets/images/about-us-img/about3.webp'
import about4 from '../../../assets/images/about-us-img/about4.webp'
import about5 from '../../../assets/images/about-us-img/about5.webp'
import Vision from '../../../assets/json/another_dart.gif'
import Mission from '../../../assets/json/mission.gif'
import Value from '../../../assets/json/value.gif'
import useWindowDimensions from '../../../components/common/window-dimension';

export default function AboutTableV2() {
  const width = useWindowDimensions()
  const [isFlipped1, setIsFlipped1] = useState(false);
  const [isFlipped2, setIsFlipped2] = useState(false);

  const [modalOpen, setModalOpen] = useState(false)
  const [modalImageSrc, setModalImageSrc] = useState('')

  function handleImageClick(src) {
    setModalImageSrc(src)
    setModalOpen(true)
  }

  const handleFlip1 = () => {
    setIsFlipped1(!isFlipped1);
  };

  const handleFlip2 = () => {
    setIsFlipped2(!isFlipped2);
  };

  return (
    <MDBContainer className="about-us-container">
      <MDBRow>
        <label className="_label">
          <span className="_text --gold ">About Us</span>
        </label>
      </MDBRow>

      <MDBRow className="grid-container mt-4">
        <MDBCol className="grid-item grid-item-1">
        <div 
          className={`image-card ${isFlipped1 ? 'flipped' : ''}`}
          onClick={handleFlip1}
          onMouseEnter={handleFlip1}
          onMouseLeave={handleFlip1}
        >
          <div className="front">
            <div className="about-us-image-container-v2">
                <LazyLoadImage
                src={about5}
                className="w-100 about-img"
                onClick={() => handleImageClick(about5)}
                alt="..."
                />
            </div>
          </div>
          <div className="back">
            <div className="about-us-image-container-v2 --back">
                <LazyLoadImage
                src={about5}
                className="w-100 about-img"
                onClick={() => handleImageClick(about5)}
                alt="..."
                />
            {/* <div className="text">test</div> */}
            </div>
          </div>
        </div>
        </MDBCol>
        <MDBCol className="grid-item grid-item-2">
          <div className="about-us-image-container-v2">
            <LazyLoadImage
              src={about3}
              className="w-100"
              onClick={() => handleImageClick(about3)}
              alt="..."
            />
          </div>
        </MDBCol>
        <MDBCol className="grid-item grid-item-3">
          <div className="about-us-image-container-v2">
            <LazyLoadImage
              src={about2}
              className="w-100"
              onClick={() => handleImageClick(about2)}
              alt="..."
            />
          </div>
        </MDBCol>
        <MDBCol className="grid-item grid-item-4 mt-lg-5">
          <div className="about-us-image-container-v2">
            <LazyLoadImage
              src={about1}
              className="w-100"
              onClick={() => handleImageClick(about1)}
              alt="..."
            />
          </div>
        </MDBCol>
        <MDBCol className="grid-item grid-item-5 mt-lg-5">
          <div className="about-us-image-container-v2">
            <LazyLoadImage
              src={about4}
              className="w-100"
              onClick={() => handleImageClick(about4)}
              alt="..."
            />
          </div>
        </MDBCol>
        <MDBCol className="grid-item grid-item-6 mt-lg-5">
            <div 
              className={`image-card ${isFlipped2 ? 'flipped' : ''}`}
              onClick={handleFlip2}
              onMouseEnter={handleFlip2}
              onMouseLeave={handleFlip2}
            >
            <div className="front">
              <div className="about-us-image-container-v2">
                <LazyLoadImage
                  src={about2}
                  className="w-100 about-img"
                  onClick={() => handleImageClick(about2)}
                  alt="..."
                />
              </div>
            </div>
            <div className="back">
              <div className="about-us-image-container-v2 --back">
                <LazyLoadImage
                  src={about2}
                  className="w-100 about-img"
                  onClick={() => handleImageClick(about2)}
                  alt="..."
                />
                {/* <div className="text">test</div> */}
              </div>
            </div>
            </div>
        </MDBCol>
      </MDBRow>

      <MDBModal show={modalOpen} tabIndex="-1" onClick={() => setModalOpen(false)}>
        <MDBModalDialog size="xl" centered>
          <MDBModalContent>
            <MDBModalBody>
              <LazyLoadImage src={modalImageSrc} alt="..." className="w-100" />
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>

      <MDBRow className="d-flex justify-content-center">
        {/* <label className="_label">
          <span className="_text --white2">What is <span style={{ color: '#EC3237' }}>D'</span>Concept?</span>
        </label> */}
        <label className="_label mt-5">
          <span className="_text --subtitle">We believe service the best, values the customer and honestly always the best policy at all time</span>
        </label>
      </MDBRow>
      <MDBRow className='mt-5'>
        <MDBCol className='col-12 col-md-12 col-lg-4'>
          <MDBCard className='_about-card --vision'>
            <MDBCardBody>
              <div className='grid-div'>
                <h3>Vision</h3>
                <div className='gif-div'>
                  <LazyLoadImage src={Vision} width="100%"/>
                </div>
              </div>
              <div className='d-flex'>
                <div className='mt-2 quote'>
                    <Icon icon="bi:quote" width="15"/>
                    &nbsp;&nbsp;We aspire to provide best service for everyone to own an affordable and ideal imported car&nbsp;&nbsp;
                    <Icon className="mb-2" icon="bi:quote" width="15" hFlip={true} vFlip={true} />
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol className='col-12 col-md-12 col-lg-4'>
          <MDBCard className='_about-card --mission'>
            <MDBCardBody>
              <div className='grid-div'>
                <h3>Mission</h3>
                <div className='gif-div'>
                <LazyLoadImage src={Mission} width="100%"/>
                </div>
              </div>
              <div className='d-flex'>
                <div className='mt-2 quote'>
                    <Icon icon="bi:quote" width="15"/>
                    &nbsp;&nbsp;We aspire to provide best service for everyone to own an affordable and ideal imported car&nbsp;&nbsp;
                    <Icon className="mb-2" icon="bi:quote" width="15" hFlip={true} vFlip={true} />
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        <MDBCol className='col-12 col-md-12 col-lg-4'>
          <MDBCard className='_about-card --value'>
            <MDBCardBody>
              <div className='grid-div'>
                <h3>Value</h3>
                <div className='gif-div'>
                  <LazyLoadImage src={Value} width="100%"/>
                </div>
              </div>
              <div className='d-flex'>
                <div className='mt-2 quote'>
                    <Icon icon="bi:quote" width="15"/>
                    &nbsp;&nbsp;We aim to provide a transparent platform for end users to import their preferred vehicles from Japan and UK.&nbsp;&nbsp;
                    <Icon className="mb-2" icon="bi:quote" width="15" hFlip={true} vFlip={true} />
                </div>
              </div>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      {/* <MDBRow center className="_padding">
        <MDBCol className="col-4">
          <h1 className="_text --gold">VISION</h1>
        </MDBCol>
        <MDBCol className="col-8">
          <p className="_text --white">
            We aspire to provide best service for everyone to own an affordable and ideal imported
            car
          </p>
        </MDBCol>
      </MDBRow>

      <MDBRow center className="_padding">
        <MDBCol className="col-8">
          <p className="_text --white">
            We aim to provide a transparent platform for end users to import their preferred
            vehicles from Japan and UK
          </p>
        </MDBCol>
        <MDBCol className="col-4">
          <h1 className="_text --gold">MISSION</h1>
        </MDBCol>
      </MDBRow>

      <MDBRow center className="_padding">
        <MDBCol className="col-4">
          <h1 className="_text --gold">Value</h1>
        </MDBCol>
        <MDBCol className="col-8">
          <p className="_text --white">
            Quality <br />
            Transparency <br />
            Integrity
          </p>
        </MDBCol>
      </MDBRow> */}

      {/* <MDBRow className='_flex'>
        <MDBCol cmd='4' className='mb-3'>
          <div>
            <MDBRipple className='bg-image' rippleTag='div' rippleColor='light'>
              <div className='position-relative'>
                <img src={about5} className='w-100' />
                <div className='mask' style={{ backgroundColor: 'rgba(224, 224, 224, 0.95)'}}></div>
                <div className='position-absolute top-50 start-50 translate-middle text-center'>
                <p className='_text --black'>VISION</p>
                </div>
              </div>
              <div className='hover-overlay position-absolute top-0 start-0 w-100 h-100'>
                <div className='mask' style={{ background: 'rgba(0, 0, 0, 0.5)' }}>
                  <div className='position-relative'>
                    <div className='image-container'>
                    <img src={about5} className='w-100' />
                    </div>
                    <div className='position-absolute top-50 start-50 translate-middle text-center'>
                  <p className='_text --white'>We aspire to provide best service for everyone to own an affordable and ideal imported car</p>
                    </div>
                  </div>
                </div>
              </div>
            </MDBRipple>
          </div>
        </MDBCol>

        <MDBCol md='4' className='mb-3'>
          <div>
            <MDBRipple className='bg-image' rippleTag='div' rippleColor='light'>
              <div className='position-relative'>
                <img src={about4} className='w-100' />
                <div className='mask' style={{ backgroundColor: 'rgba(224, 224, 224, 0.95)' }}></div>
                <div className='position-absolute top-50 start-50 translate-middle text-center'>
                <p className='_text --black'>MISSION</p>
                </div>
              </div>
              <div className='hover-overlay'>
                <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                  <div className='position-relative'>
                    <div className='image-container'>
                      <img src={about4} className='w-100' />
                    </div>
                    <div className='position-absolute top-50 start-50 translate-middle text-center'>
                  <p className='_text --white'>We aim to provide a transparent platform for end users to import their preferred vehicles from Japan and UK</p>
                    </div>
                  </div>
                </div>
              </div>
            </MDBRipple>
          </div>
        </MDBCol>

        <MDBCol md='4' className='mb-3'>
          <div>
            <MDBRipple className='bg-image' rippleTag='div' rippleColor='light'>
              <div className='position-relative'>
                <img src={about3} className='w-100' />
                <div className='mask' style={{ backgroundColor: 'rgba(224, 224, 224, 0.95)' }}></div>
                <div className='position-absolute top-50 start-50 translate-middle text-center'>
                <p className='_text --black'>VALUE</p>
                </div>
              </div>
              <div className='hover-overlay'>
                <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}>
                  <div className='position-relative'>
                    <div className='image-container'>
                      <img src={about3} className='w-100' />
                    </div>
                    <div className='position-absolute top-50 start-50 translate-middle text-center'>
                  <p className='_text --white'>We aim to provide a transparent platform for end users to import their preferred vehicles from Japan and UK</p>
                    </div>
                  </div>
                </div>
              </div>
            </MDBRipple>
          </div>
        </MDBCol>
      </MDBRow> */}
    </MDBContainer>
  )
}
