//lib
import { MDBContainer } from 'mdb-react-ui-kit'

const LoanResult = ({ payload }) => {
  return (
    <MDBContainer className="loan-table-container">
      <table className="table __loan">
        <thead>
          <tr>
            <th scope="col">Year</th>
            <th scope="col">Principle</th>
            <th scope="col">Interest</th>
            <th scope="col">Balance</th>
          </tr>
        </thead>

        <tbody>
          {payload && payload.length > 0
            ? payload.map((obj, index) => (
                <tr>
                  <td>{obj.year}</td>
                  <td>{parseFloat(obj.principal).toFixed(2)}</td>
                  <td>{parseFloat(obj.interest).toFixed(2)}</td>
                  <td>{parseFloat(obj.balance).toFixed(2)}</td>
                </tr>
              ))
            : ''}
          <tr></tr>
        </tbody>
      </table>
    </MDBContainer>
  )
}

export default LoanResult
