import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//hook
import useWindowDimensions from '../../components/common/window-dimension'

//component
import SearchBar from '../../components/element/searchbar'
import ToggleSwitch from '../../components/element/toggleSwitch'
import { BrandList } from '../../components/element/brandList'
import { BodyTypeList } from '../../components/element/bodyTypeList'
import StockListing from './component/stock.listing'

//lib
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-datepicker/dist/react-datepicker.css'
import { Overlay } from 'react-bootstrap'
import { Helmet } from 'react-helmet'

//assets
import '../../assets/css/stock.css'

//helper
import LazyImage from '../../components/common/common.image'

//redux
import {
  setShowBrandList,
  setShowBodyTypeList,
  setStockType,
  setCarBrandFilter,
  setCarBodyTypeFilter,
  setStart,
  setShowCarYearList,
  setCarYearFilter,
  setSearchData,
  setShowCarPriceList,
  setCarPriceFilter,
  setSearchPage,
  setShowSortByList,
  setCarModelFilter,
  setShowMobileFilterList,
  setModelFilterData,
  setDisplayBrandModelData,
  setDisplayBrandModelFilter,
  setBrandFilterData,
  setPriceFilterData,
  setYearFilterData,
  setBodyTypeFilterData,
  setTotalRecord,
  setSortByFilter,
  updateDisplayText,
  setSearchStock,
} from '../../redux/reducer/stockReducer'
import { useSelector, useDispatch } from 'react-redux'
import YearPickerList from '../../components/element/yearPicker'
import { useLocation, useSearchParams } from 'react-router-dom'
import queryString from 'query-string'
import { Icon } from '@iconify/react'
import { PriceList } from '../../components/element/priceList'
import { SortByList } from '../../components/element/sortByList'
import { MobileFilterList } from '../../components/element/mobileFilterList'

import 'bootstrap/dist/css/bootstrap.min.css'
import { PaginationControl } from 'react-bootstrap-pagination-control'
import ReactPaginate from 'react-paginate'
import { motion, AnimatePresence } from "framer-motion";

export default function Stock() {
  const dispatch = useDispatch()
  const location = useLocation()
  const parsedQuery = queryString.parse(location.search)
  const { isDarkMode } = useSelector((state) => state.home)
  const { width } = useWindowDimensions()
  const [search, setSearch] = useState(parsedQuery.search !== undefined ? parsedQuery.search : '')
  const [displayMessage, setDisplayMessage] = useState('')
  const [fixOptionHeader, setFixOptionHeader] = useState(false)
  const [selectYear, setSelectYear] = useState(new Date())
  const [isYearPickerOpen, setIsYearPickerOpen] = useState(false)

  const {
    ownerLoading,
    stockLoading,
    searchStock,
    stockData,
    ownerData,
    stockType,
    carBodyTypeFilter,
    carBrandFilter,
    brandFilterData,
    carYearFilter,
    carPriceFilter,
    showCarYearList,
    showBodyTypeList,
    showBrandList,
    showCarPriceList,
    ownerTotalRecord,
    stockTotalRecord,
    showSortByList,
    showMobileFilterList,
    displayBrandModelFilter,
    sortByFilter,
    sortBySequence,
    displayText,
    carModelFilter,
  } = useSelector((state) => state.stock)

  const [startData, setStartData] = useState(0)
  const [length, setLength] = useState(9)
  const [currentPage, setCurrentPage] = useState(1)
  const [searchParams, setSearchParams] = useSearchParams()
  const navigateTo = useNavigate()

  const totalPages = Math.ceil((stockType === 'D-Stock' ? stockTotalRecord : ownerTotalRecord) / length)

  const handlePageClick = (pageNumber) => {
    setStartData(length * (pageNumber - 1))
    setCurrentPage(pageNumber)
    setSearchParams('')
  }

  const getPageNumbersArray = () => {
    const pageNumbers = []
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i)
    }
    return pageNumbers
  }

  const currentYear = new Date().getFullYear()

  const fixedHeaderRef = useRef(null)
  const [styleTop, setStyleTop] = useState('')
  const [showBodyTypeDiv, setShowBodyTypeDiv] = useState(false)
  const [showBrandDiv, setShowBrandDiv] = useState(false)
  const [showCarYearDiv, setShowCarYearDiv] = useState(false)
  const [showCarPriceDiv, setShowCarPriceDiv] = useState(false)
  const [showSortByDiv, setShowSortByDiv] = useState(false)
  const [showLeftEllipsis, setShowLeftEllipsis] = useState(false)
  const [showRightEllipsis, setShowRightEllipsis] = useState(false)
  const [tempModelFilterData, setTempModelFilterData] = useState(carModelFilter)
  const [tempBrandFilterData, setTempBrandFilterData] = useState(carBrandFilter)

  function dispatchSetStockFunction(type) {
    dispatch(setStockType(type))
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const FixOptionHeader = () => {
    const carIcon = document.getElementById('car-scroll-top')
    const scrolled = document.documentElement.scrollTop

    if (scrolled > 287 && carIcon) {
      carIcon.style.display = 'block'
      setFixOptionHeader(true)
    } else if (scrolled < 800 && carIcon) {
      carIcon.style.display = 'none'
      setFixOptionHeader(false)
    }
  }

  const clearFilterData = () => {
    setSearch('')
    setSearchParams('')
    setCurrentPage(1)
    dispatch(updateDisplayText([]))
    //setStartData(0)
    dispatch(setStart(0))
    dispatch(setSearchStock(''))
    //dispatch(setTotalRecord(0))
    dispatch(setCarBrandFilter([]))
    dispatch(setCarModelFilter([]))
    dispatch(setCarPriceFilter([50000, 5000000]))
    dispatch(setCarYearFilter([1990, currentYear]))
    dispatch(setCarBodyTypeFilter([]))

    dispatch(setModelFilterData([]))
    dispatch(setDisplayBrandModelData([]))
    dispatch(setDisplayBrandModelFilter([]))
    dispatch(setBrandFilterData([]))
    dispatch(setBodyTypeFilterData([]))
    dispatch(setSortByFilter([]))
  }

  const yearCustomInput = {
    padding: '10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  }

  window.addEventListener('scroll', FixOptionHeader)

  // useEffect(() => {
  //   if (parsedQuery.search) {
  //     setCurrentPage(parsedQuery.search)
  //   }
  // }, [parsedQuery.search])
  
  useEffect(() => {
    if (parsedQuery.page) {
      setCurrentPage(parsedQuery.page)
    }
  }, [parsedQuery.page])

  useEffect(() => {
    dispatch(setSearchData(search))
    dispatch(setSearchPage(currentPage))

    const timeOutId = setTimeout(() => {
      setDisplayMessage(search)
      dispatch(setStart(0))
    }, 1000)
    return () => clearTimeout(timeOutId)
  }, [search, currentPage])

  useEffect(() => {
    scrollToTop()
  }, [currentPage])

  return (
    <MDBContainer className="common-main-container" breakpoint="none">
      <Helmet>
        <title>Dconcept Car Stock</title>
        <meta name="Dconcept" content="Dconcept Car Stock" />
      </Helmet>
      <div className="stock-body">
        <div className="stockHeader">
          <ToggleSwitch
            optionOne="D-Stock"
            optionTwo="Direct Owner"
            setStockType={dispatchSetStockFunction}
            stockType={stockType}
            clearFilter={clearFilterData}
          />

          {/* filter body */}
          <AnimatePresence>
          {/* fixOptionHeader ? 'option-div fix' :  */}
          <div style={{ position: 'relative' }}>
            <div 
              ref={fixedHeaderRef}
              className={'option-div'}
            >
              <SearchBar className={'--stock'} setValue={setSearch} value={search} setCurrentPage={setCurrentPage} />

              <MDBContainer className="btnList-div">
                {width > 991 ? (
                  <div style={{ width: '100%' }}>
                    <div className="d-flex justify-content-start">
                      <div style={{ position: 'relative' }}>
                        <MDBBtn
                          className={
                            carBrandFilter.length > 0
                              ? 'darkTheme-btn --filter brand-btn --selected'
                              : 'darkTheme-btn filter-btn'
                          }
                          style={{
                            fontSize: width >= 991 ? '1.5rem' : '1.2rem',
                            marginLeft: 0,
                          }}
                          onClick={() => {
                            if (fixOptionHeader) {
                              setShowBrandDiv(!showBrandDiv)
                              dispatch(setShowBrandList(false))
                            } else {
                              dispatch(setShowBrandList(!showBrandList))
                              setShowBrandDiv(false)
                            }
                          }}>
                          <div>Brand & Model</div>
                          <div></div>
                          <div className="d-flex align-items-center drop-icon">
                            <Icon icon="icon-park-solid:down-one" />
                          </div>
                        </MDBBtn>
                        <div id="brand-modal">
                          {showBrandList && !fixOptionHeader && (
                            <BrandList
                              type="non-fixed"
                              backFirstPage={setCurrentPage}
                              startZero={setStartData}
                              tempModelFilterData={tempModelFilterData}
                              setTempModelFilterData={setTempModelFilterData}
                              tempBrandFilterData={tempBrandFilterData}
                              setTempBrandFilterData={setTempBrandFilterData}
                            />
                          )}
                          {fixOptionHeader && showBrandDiv && (
                            <BrandList
                              show={showBrandDiv}
                              setShow={setShowBrandDiv}
                              type="fixed"
                              backFirstPage={setCurrentPage}
                              startZero={setStartData}
                              tempModelFilterData={tempModelFilterData}
                              setTempModelFilterData={setTempModelFilterData}
                              tempBrandFilterData={tempBrandFilterData}
                              setTempBrandFilterData={setTempBrandFilterData}
                            />
                          )}
                        </div>
                      </div>
                      <div style={{ position: 'relative' }}>
                        <MDBBtn
                          className={
                            carPriceFilter.length > 0 &&
                            carPriceFilter[0] !== undefined &&
                            carPriceFilter[0] !== '' &&
                            (carPriceFilter[0] !== 50000 || carPriceFilter[1] !== 5000000)
                              ? 'darkTheme-btn --filter brand-btn --selected'
                              : 'darkTheme-btn filter-btn'
                          }
                          style={{
                            fontSize: width >= 991 ? '1.5rem' : '1.2rem',
                            marginLeft: 0,
                          }}
                          onClick={(e) => {
                            if (fixOptionHeader) {
                              setShowCarPriceDiv(!showCarPriceDiv)
                              dispatch(setShowCarPriceList(false))
                            } else {
                              dispatch(setShowCarPriceList(!showCarPriceList))
                              setShowCarPriceDiv(false)
                            }
                          }}>
                          <div style={{ width: 'auto' }}>Price</div>
                          <div></div>
                          <div className={`d-flex align-items-center drop-icon`}>
                            <Icon
                              icon="icon-park-solid:down-one"
                              style={{ color: carBrandFilter ? '#d1d5db' : '#5d6375' }}
                            />
                          </div>
                        </MDBBtn>
                        <div id="year-modal">
                          {showCarPriceList && !fixOptionHeader && (
                            <PriceList
                              type="non-fixed"
                              backFirstPage={setCurrentPage}
                              startZero={setStartData}
                            />
                          )}
                          {fixOptionHeader && showCarPriceDiv && (
                            <PriceList
                              show={showCarPriceDiv}
                              setShow={setShowCarPriceDiv}
                              type="fixed"
                              backFirstPage={setCurrentPage}
                              startZero={setStartData}
                            />
                          )}
                        </div>
                      </div>

                      <div style={{ position: 'relative' }}>
                        <MDBBtn
                          className={
                            carYearFilter.length > 0 &&
                            carYearFilter[0] != undefined &&
                            carYearFilter[0] !== '' &&
                            (carYearFilter[0] !== 1990 || carYearFilter[1] !== currentYear)
                              ? 'darkTheme-btn --filter brand-btn --selected'
                              : 'darkTheme-btn filter-btn'
                          }
                          style={{
                            fontSize: width >= 991 ? '1.5rem' : '1.2rem',
                            marginLeft: 0,
                          }}
                          onClick={(e) => {
                            if (fixOptionHeader) {
                              setShowCarYearDiv(!showBrandDiv)
                              dispatch(setShowCarYearList(false))
                            } else {
                              dispatch(setShowCarYearList(!showCarYearList))
                              setShowCarYearDiv(false)
                            }
                          }}>
                          <div>Mfg. Year</div>
                          <div></div>
                          <div className="d-flex align-items-center drop-icon">
                            <Icon icon="icon-park-solid:down-one" />
                          </div>
                        </MDBBtn>
                        <div id="year-modal">
                          {showCarYearList && !fixOptionHeader && (
                            <YearPickerList
                              type="non-fixed"
                              backFirstPage={setCurrentPage}
                              startZero={setStartData}
                            />
                          )}
                          {fixOptionHeader && showCarYearDiv && (
                            <YearPickerList
                              show={showCarYearDiv}
                              setShow={setShowCarYearDiv}
                              type="fixed"
                              backFirstPage={setCurrentPage}
                              startZero={setStartData}
                            />
                          )}
                        </div>
                      </div>

                      <div style={{ position: 'relative' }}>
                        <MDBBtn
                          className={
                            carBodyTypeFilter.length !== 0
                              ? 'darkTheme-btn --filter filter-btn --selected'
                              : 'darkTheme-btn filter-btn'
                          }
                          style={{
                            fontSize: width >= 991 ? '1.5rem' : '1.2rem',
                          }}
                          onClick={() => {
                            if (fixOptionHeader) {
                              setShowBodyTypeDiv(!showBodyTypeDiv)
                              dispatch(setShowBodyTypeList(false))
                            } else {
                              dispatch(setShowBodyTypeList(!showBodyTypeList))
                              setShowBodyTypeDiv(false)
                            }
                          }}>
                          <div>Body Type</div>
                          <div></div>
                          <div className="d-flex align-items-center drop-icon">
                            <Icon icon="icon-park-solid:down-one" />
                          </div>
                        </MDBBtn>
                        <div id="body-type-modal">
                          {showBodyTypeList && !fixOptionHeader && (
                            <BodyTypeList
                              type="non-fixed"
                              backFirstPage={setCurrentPage}
                              startZero={setStartData}
                            />
                          )}
                          {fixOptionHeader && showBodyTypeDiv && (
                            <BodyTypeList
                              show={showBodyTypeDiv}
                              setShow={setShowBodyTypeDiv}
                              type="fixed"
                              backFirstPage={setCurrentPage}
                              startZero={setStartData}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    <div>
                      {carBrandFilter.length !== 0 ||
                      carBodyTypeFilter.length !== 0 ||
                      carPriceFilter[0] !== 50000 ||
                      carPriceFilter[1] !== 5000000 ||
                      carYearFilter[0] !== 1990 ||
                      carYearFilter[1] !== currentYear ||
                      displayBrandModelFilter.length !== 0 ? (
                        <div className="d-flex" style={{ justifyContent: 'space-between' }}>
                          <div className="d-flex tag-row">
                            <label
                              onClick={() => {
                                setTempModelFilterData([])
                                setTempBrandFilterData([])
                                dispatch(setCarBrandFilter([]))
                                dispatch(setCarModelFilter([]))
                                dispatch(setCarPriceFilter([50000, 5000000]))
                                dispatch(setPriceFilterData([50000, 5000000]))
                                dispatch(setCarYearFilter([1990, currentYear]))
                                dispatch(setYearFilterData([1990, currentYear]))
                                dispatch(setCarBodyTypeFilter([]))
                                dispatch(setBodyTypeFilterData([]))

                                dispatch(setModelFilterData([]))
                                dispatch(setDisplayBrandModelData([]))
                                dispatch(setDisplayBrandModelFilter([]))
                                dispatch(setBrandFilterData([]))

                                dispatch(updateDisplayText([]))
                              }}>
                              Clear Filter
                            </label>
                            <div className="d-flex tag-row-scroll">
                              {displayText &&
                                displayText.map((brand, brandIndex) => (
                                  <div className="ms-2 tag-div" key={brandIndex}>
                                    {brand}
                                  </div>
                                ))}

                              {carPriceFilter &&
                                (carPriceFilter[0] !== 50000 || carPriceFilter[1] !== 5000000) && (
                                  <div className="ms-2 tag-div">
                                    {'Budget Pricing RM' + carPriceFilter[0]?.toLocaleString()} -{' '}
                                    {'RM' + carPriceFilter[1]?.toLocaleString()}
                                  </div>
                                )}

                              {carYearFilter &&
                                (carYearFilter[0] !== 1990 || carYearFilter[1] !== currentYear) && (
                                  <div className="ms-2 tag-div">
                                    {carYearFilter[0]} - {carYearFilter[1]}
                                  </div>
                                )}

                              {carBodyTypeFilter &&
                                carBodyTypeFilter.map((bodyType, bodyTypeIndex) => (
                                  <div className="ms-2 tag-div" key={bodyTypeIndex}>
                                    {bodyType}
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div>
                            <MDBBtn
                              className={
                                // carBodyTypeFilter ? 'darkTheme-btn --filter filter-btn --selected' :
                                'darkTheme-btn filter-btn --sort'
                              }
                              style={{
                                fontSize: width >= 991 ? '1.5rem' : '1.2rem',
                              }}
                              onClick={() => {
                                if (fixOptionHeader) {
                                  setShowSortByDiv(!showSortByDiv)
                                  dispatch(setShowSortByList(false))
                                } else {
                                  dispatch(setShowSortByList(!showSortByList))
                                  setShowSortByDiv(false)
                                }
                              }}>
                              <div>Sort By</div>
                              <div></div>
                              <div className="d-flex align-items-center drop-icon --sort">
                                <Icon icon="icon-park-solid:down-one" />
                              </div>
                            </MDBBtn>
                            <div id="body-type-modal">
                              {showSortByList && !fixOptionHeader && (
                                <SortByList
                                  type="non-fixed"
                                  backFirstPage={setCurrentPage}
                                  startZero={setStartData}
                                />
                              )}
                              {fixOptionHeader && showSortByDiv && (
                                <SortByList
                                  show={showSortByDiv}
                                  setShow={setShowSortByDiv}
                                  type="fixed"
                                  backFirstPage={setCurrentPage}
                                  startZero={setStartData}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ) : (
                        width > 991 && (
                          <>
                            <div className="d-grid justify-content-end">
                              <MDBBtn
                                className={
                                  // carBodyTypeFilter ? 'darkTheme-btn --filter filter-btn --selected' :
                                  'darkTheme-btn filter-btn --sort'
                                }
                                style={{
                                  fontSize: width >= 991 ? '1.5rem' : '1.2rem',
                                }}
                                onClick={() => {
                                  if (fixOptionHeader) {
                                    setShowSortByDiv(!showSortByDiv)
                                    dispatch(setShowSortByList(false))
                                  } else {
                                    dispatch(setShowSortByList(!showSortByList))
                                    setShowSortByDiv(false)
                                  }
                                }}>
                                <div>Sort By</div>
                                <div></div>
                                <div className="d-flex align-items-center drop-icon --sort">
                                  <Icon icon="icon-park-solid:down-one" />
                                </div>
                              </MDBBtn>
                              <div id="body-type-modal">
                                {showSortByList && !fixOptionHeader && (
                                  <SortByList
                                    type="non-fixed"
                                    backFirstPage={setCurrentPage}
                                    startZero={setStartData}
                                  />
                                )}
                                {fixOptionHeader && showSortByDiv && (
                                  <SortByList
                                    show={showSortByDiv}
                                    setShow={setShowSortByDiv}
                                    type="fixed"
                                    backFirstPage={setCurrentPage}
                                    startZero={setStartData}
                                  />
                                )}
                              </div>
                            </div>
                          </>
                        )
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <MDBRow style={{ width: '100%' }}>
                      <div style={{ position: 'relative', width: '50%' }} className="mt-3">
                        <MDBBtn
                          className={
                            carBrandFilter.length !== 0 ||
                            carBodyTypeFilter.length !== 0 ||
                            carPriceFilter[0] !== 50000 ||
                            carPriceFilter[1] !== 5000000 ||
                            carYearFilter[0] !== 1990 ||
                            carYearFilter[1] !== currentYear ||
                            displayText.length !== 0
                              ? 'darkTheme-btn --filter filter-btn --selected'
                              : 'darkTheme-btn filter-btn'
                          }
                          style={{
                            fontSize: width >= 991 ? '1.5rem' : '1.2rem',
                          }}
                          onClick={() => {
                            // if (fixOptionHeader) {
                            //   setShowBodyTypeDiv(!showBodyTypeDiv)
                            //   dispatch(setShowBodyTypeList(false))
                            // } else {
                            dispatch(setShowMobileFilterList(!showMobileFilterList))
                            //   setShowBodyTypeDiv(false)
                            // }
                          }}>
                          <div className="col-10 d-flex">
                            <label className="ellipsis-word">Filter</label>
                          </div>
                          <div></div>
                          <div className="col-2 d-flex align-items-center justify-content-end drop-icon">
                            <Icon icon="icon-park-solid:down-one" />
                          </div>
                        </MDBBtn>
                      </div>
                      <div style={{ position: 'relative', width: '50%' }} className="mt-3">
                        <MDBBtn
                          className={
                            sortByFilter?.length > 0
                              ? 'darkTheme-btn --filter filter-btn --selected'
                              : 'darkTheme-btn filter-btn'
                          }
                          style={{
                            fontSize: width >= 991 ? '1.5rem' : '1.2rem',
                          }}
                          onClick={() => {
                            // if (fixOptionHeader) {
                            //   setShowBodyTypeDiv(!showBodyTypeDiv)
                            //   dispatch(setShowBodyTypeList(false))
                            // } else {
                            dispatch(setShowSortByList(!showSortByList))
                            //   setShowBodyTypeDiv(false)
                            // }
                          }}>
                          <div className="col-10 d-flex">
                            <label className="ellipsis-word">Sort By</label>
                          </div>
                          <div></div>
                          <div className="col-2 d-flex align-items-center justify-content-end drop-icon">
                            <Icon icon="icon-park-solid:down-one" />
                          </div>
                        </MDBBtn>
                      </div>
                    </MDBRow>
                  </>
                )}
              </MDBContainer>
              {totalPages > 1 && !(stockType === 'D-Stock' ? stockLoading : ownerLoading) && (stockType === 'D-Stock' ? stockData.length > 0 : ownerData.length > 0) && (
                <div className={`pagination-div ${width < 991 ? 'pt-4' : 'p-0'}`}>
                  <div
                    className={currentPage > 1 ? 'previous-div' : 'previous-div --disable'}
                    onClick={() => {
                      if (currentPage > 1) {
                        // handlePageClick(parseInt(currentPage) - 1)
                        navigateTo(`/stock?${searchStock ? `search=${searchStock}&` : ''}page=${parseInt(currentPage) - 1}`)
                      }
                    }}>
                    <Icon
                      icon="icon-park-outline:left"
                      color={isDarkMode ? 'black' : currentPage > 1 ? 'white' : 'black'}
                      width="20"
                      height="20"
                    />
                  </div>
                  <div className="d-flex">
                    <ReactPaginate
                      pageCount={Math.ceil((stockType === 'D-Stock' ? stockTotalRecord : ownerTotalRecord) / 9)}
                      pageRangeDisplayed={width < 991 ? 3 : 4}
                      marginPagesDisplayed={2}
                      onPageChange={({ selected }) => {
                        navigateTo(`/stock?${searchStock ? `search=${searchStock}&` : ''}page=${selected + 1}`)
                      }}
                      disableInitialCallback={true}
                      containerClassName={'pagination'}
                      activeClassName={'active'}
                      pageClassName={'page-item'}
                      pageLinkClassName={'page-link'}
                      previousLabel={''}
                      nextLabel={''}
                      forcePage={currentPage - 1}
                    />
                  </div>
                  <div
                    className={currentPage < totalPages ? 'next-div' : 'next-div --disable'}
                    onClick={() => {
                      if (currentPage < totalPages) {
                        // handlePageClick(parseInt(currentPage) + 1)
                        navigateTo(`/stock?${searchStock ? `search=${searchStock}&` : ''}page=${parseInt(currentPage) + 1}`)
                      }
                    }}>
                    <Icon
                      icon="icon-park-outline:right"
                      color={isDarkMode ? 'black' : currentPage < totalPages ? 'white' : 'black'}
                      width="20"
                      height="20"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          </AnimatePresence>
          {/* filter body */}
          
        </div>
        <StockListing
          search={displayMessage}
          searchLoading={search}
          startData={startData}
          selectedPage={currentPage}
        />
        {width < 991 && showMobileFilterList && (
          <MobileFilterList
            backFirstPage={setCurrentPage}
            startZero={setStartData}
            tempModelFilterData={tempModelFilterData}
            setTempModelFilterData={setTempModelFilterData}
            tempBrandFilterData={tempBrandFilterData}
            setTempBrandFilterData={setTempBrandFilterData}
          />
        )}

        {width < 991 && showSortByList && (
          <SortByList backFirstPage={setCurrentPage} startZero={setStartData} />
        )}
      </div>
      {totalPages > 1 && !(stockType === 'D-Stock' ? stockLoading : ownerLoading) && (stockType === 'D-Stock' ? stockData.length > 0 : ownerData.length > 0) && (
        <div className={`pagination-div ${width < 991 ? 'pt-4' : 'p-0'}`}>
          <div
            className={currentPage > 1 ? 'previous-div' : 'previous-div --disable'}
            onClick={() => {
              if (currentPage > 1) {
                // handlePageClick(parseInt(currentPage) - 1)
                navigateTo(`/stock?${searchStock ? `search=${searchStock}&` : ''}page=${parseInt(currentPage) - 1}`)
              }
            }}>
            <Icon
              icon="icon-park-outline:left"
              color={isDarkMode ? 'black' : currentPage > 1 ? 'white' : 'black'}
              width="20"
              height="20"
            />
          </div>
          <div className="d-flex">
            <ReactPaginate
              pageCount={Math.ceil((stockType === 'D-Stock' ? stockTotalRecord : ownerTotalRecord) / 9)}
              pageRangeDisplayed={width < 991 ? 3 : 4}
              marginPagesDisplayed={2}
              onPageChange={({ selected }) => {
                navigateTo(`/stock?${searchStock ? `search=${searchStock}&` : ''}page=${selected + 1}`)
              }}
              disableInitialCallback={true}
              containerClassName={'pagination'}
              activeClassName={'active'}
              pageClassName={'page-item'}
              pageLinkClassName={'page-link'}
              previousLabel={''}
              nextLabel={''}
              forcePage={currentPage - 1}
            />
          </div>
          <div
            className={currentPage < totalPages ? 'next-div' : 'next-div --disable'}
            onClick={() => {
              if (currentPage < totalPages) {
                // handlePageClick(parseInt(currentPage) + 1)
                navigateTo(`/stock?${searchStock ? `search=${searchStock}&` : ''}page=${parseInt(currentPage) + 1}`)
              }
            }}>
            <Icon
              icon="icon-park-outline:right"
              color={isDarkMode ? 'black' : currentPage < totalPages ? 'white' : 'black'}
              width="20"
              height="20"
            />
          </div>
        </div>
      )}
      <div
        onClick={() => {
          window.scrollTo(0, 0)
        }}
        id="car-scroll-top"
        className="car-scroll-top"
        style={{
          position: 'fixed',
          bottom: '2.5em',
          right: '2.5em',
          display: 'none',
          zIndex: 999,
        }}>
        <div className="car-container">
          <LazyImage
            width={width >= 991 ? 'auto' : 50}
            src={require('../../assets/images/icon/car.webp')}
            alt="d-concept"
          />
          <LazyImage
            width={width >= 991 ? 'auto' : 50}
            className="car-focus"
            src={require('../../assets/images/icon/car-focus.webp')}
            alt="d-concept"
          />
        </div>
      </div>
    </MDBContainer>
  )
}

{/* <PaginationControl
  page={currentPage}
  between={7}
  total={totalRecord}
  limit={9}
  changePage={(page) => {
    // handlePageClick(page);
    navigateTo(`/stock?page=${page}`)
  }}
  next={false}
  ellipsis={1}
/> */}

// export const filterBody = (
//   fixOptionHeader,
//   dispatch,
//   width,
//   setShowBodyTypeList,
//   setCarBrandFilter,
//   setSearch,
//   search,
//   carBodyTypeFilter,
//   carBrandFilter,
//   setCarYearFilter,
//   carYearFilter,
//   selectYear,
//   setSelectYear,
//   isYearPickerOpen,
//   setIsYearPickerOpen,
//   setShowCarYearList,
//   showCarYearList,
//   showCarPriceList,
//   showBodyTypeList,
//   showBrandList,
//   yearCustomInput,
//   fixedHeaderRef,
//   styleTop,
//   showBodyTypeDiv,
//   setShowBodyTypeDiv,
//   showBrandDiv,
//   setShowBrandDiv,
//   showCarYearDiv,
//   setShowCarYearDiv,
//   setStart,
//   setStartData,
//   setCurrentPage,
//   setShowCarPriceDiv,
//   showCarPriceDiv,
//   setShowCarPriceList,
//   carPriceFilter,
//   setSearchParams,
//   brandFilterData,
//   setShowSortByList,
//   showSortByList,
//   setShowSortByDiv,
//   showSortByDiv,
//   setShowMobileFilterList,
//   showMobileFilterList,
//   displayBrandModelFilter,
//   showLeftEllipsis,
//   setShowLeftEllipsis,
//   showRightEllipsis,
//   setShowRightEllipsis,
//   setPriceFilterData,
//   setYearFilterData,
//   setBodyTypeFilterData,
//   sortByFilter,
//   sortBySequence,
//   displayText,
//   tempModelFilterData,
//   setTempModelFilterData,
//   tempBrandFilterData,
//   setTempBrandFilterData,
//   updateDisplayText,
//   currentYear,
// ) => {

// }

{/* {filterBody(
  false,
  dispatch,
  width,
  setShowBodyTypeList,
  setCarBrandFilter,
  setSearch,
  search,
  carBodyTypeFilter,
  carBrandFilter,
  setCarYearFilter,
  carYearFilter,
  selectYear,
  setSelectYear,
  isYearPickerOpen,
  setIsYearPickerOpen,
  setShowCarYearList,
  showCarYearList,
  showCarPriceList,
  showBodyTypeList,
  showBrandList,
  yearCustomInput,
  fixedHeaderRef,
  styleTop,
  showBodyTypeDiv,
  setShowBodyTypeDiv,
  showBrandDiv,
  setShowBrandDiv,
  showCarYearDiv,
  setShowCarYearDiv,
  setStart,
  setStartData,
  setCurrentPage,
  setShowCarPriceDiv,
  showCarPriceDiv,
  setShowCarPriceList,
  carPriceFilter,
  setSearchParams,
  brandFilterData,
  setShowSortByList,
  showSortByList,
  setShowSortByDiv,
  showSortByDiv,
  setShowMobileFilterList,
  showMobileFilterList,
  displayBrandModelFilter,
  showLeftEllipsis,
  setShowLeftEllipsis,
  showRightEllipsis,
  setShowRightEllipsis,
  setPriceFilterData,
  setYearFilterData,
  setBodyTypeFilterData,
  sortByFilter,
  sortBySequence,
  displayText,
  tempModelFilterData,
  setTempModelFilterData,
  tempBrandFilterData,
  setTempBrandFilterData,
  updateDisplayText,
  currentYear,
)}
{fixOptionHeader &&
  filterBody(
    true,
    dispatch,
    width,
    setShowBodyTypeList,
    setCarBrandFilter,
    setSearch,
    search,
    carBodyTypeFilter,
    carBrandFilter,
    setCarYearFilter,
    carYearFilter,
    selectYear,
    setSelectYear,
    isYearPickerOpen,
    setIsYearPickerOpen,
    setShowCarYearList,
    showCarYearList,
    showCarPriceList,
    showBodyTypeList,
    showBrandList,
    yearCustomInput,
    fixedHeaderRef,
    styleTop,
    showBodyTypeDiv,
    setShowBodyTypeDiv,
    showBrandDiv,
    setShowBrandDiv,
    showCarYearDiv,
    setShowCarYearDiv,
    setStart,
    setStartData,
    setCurrentPage,
    setShowCarPriceDiv,
    showCarPriceDiv,
    setShowCarPriceList,
    carPriceFilter,
    setSearchParams,
    brandFilterData,
    setShowSortByList,
    showSortByList,
    setShowSortByDiv,
    showSortByDiv,
    setShowMobileFilterList,
    showMobileFilterList,
    displayBrandModelFilter,
    showLeftEllipsis,
    setShowLeftEllipsis,
    showRightEllipsis,
    setShowRightEllipsis,
    setPriceFilterData,
    setYearFilterData,
    setBodyTypeFilterData,
    sortByFilter,
    sortBySequence,
    displayText,
    tempModelFilterData,
    setTempModelFilterData,
    tempBrandFilterData,
    setTempBrandFilterData,
    updateDisplayText,
    currentYear,
  )} */}

  {/* {width > 600 && width < 991 ?    
    carBrandFilter.length == 0 && !carBodyTypeFilter && (carPriceFilter[0] == 0 || carPriceFilter[1] == 5000000) && (carYearFilter[0] == 1990 || carYearFilter[1] == currentYear) ?
    <div>
      <MDBBtn
        className={
          'darkTheme-btn filter-btn --sort-icon'
        }
        style={{
          fontSize: width >= 991 ? '1.5rem' : '1.2rem',
        }}
        onClick={() => {
          if (fixOptionHeader) {
            setShowSortByDiv(!showSortByDiv)
            dispatch(setShowSortByList(false))
          } else {
            dispatch(setShowSortByList(!showSortByList))
            setShowSortByDiv(false)
          }
        }}>
        <div>
          <Icon icon="bx:sort" width={16} />
        </div>
      </MDBBtn>
      <div id="body-type-modal">
        {showSortByList && !fixOptionHeader && (
          <SortByList
            type="non-fixed"
            backFirstPage={setCurrentPage}
            startZero={setStartData}
          />
        )}
        {fixOptionHeader && showSortByDiv && (
          <SortByList
            show={showSortByDiv}
            setShow={setShowSortByDiv}
            type="fixed"
            backFirstPage={setCurrentPage}
            startZero={setStartData}
          />
        )}
      </div>
    </div>
    : ''
  : ''} */}