import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setOpenVersionModal } from '../../redux/reducer/homeReducer'
import {
  MDBRow,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit' // Added MDBModalDialog, MDBModalContent, and MDBModalBody

const VersionUpdatePage = () => {
  const dispatch = useDispatch()
  const { openVersionModal } = useSelector((state) => state.home)

  return (
    <MDBModal show={openVersionModal} tabIndex="-1" staticBackdrop>
      {' '}
      {/* Changed 'isOpen' to 'show' */}
      <MDBModalDialog centered size="lg">
        {' '}
        {/* Added MDBModalDialog wrapper */}
        <MDBModalContent>
          {' '}
          {/* Added MDBModalContent wrapper */}
          <MDBModalBody>
            {' '}
            {/* Added MDBModalBody wrapper */}
            <div
              className="vh"
              style={{
                padding: '2rem',
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                justifyContent: 'center',
                // height: '100%',
                minHeight: '100px',
                backgroundColor: '#f7f7f7',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                // zIndex: 9999,
              }}>
              <div className="wrap">
                <h1
                  style={{
                    fontSize: '30px',
                    fontWeight: 700,
                  }}>
                  Version Outdated
                </h1>

                <p
                  style={{
                    fontSize: '16px',
                    marginTop: '1rem',
                    marginBottom: '1.3rem',
                  }}>
                  Your current version is outdated. Please click the button below to refresh and
                  update your version.
                </p>

                <MDBBtn
                  class="btn btn-primary btn-lg"
                  type="button"
                  onClick={() => {
                    dispatch(setOpenVersionModal(false))
                    window.location.reload()
                  }}>
                  Update Now
                </MDBBtn>
              </div>
            </div>
          </MDBModalBody>
        </MDBModalContent>
      </MDBModalDialog>
    </MDBModal>
  )
}

export default VersionUpdatePage
