import React, { useEffect, useRef, useState } from 'react'

//lib
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Skeleton } from 'primereact/skeleton'

export default function CarSpecification({ data, loading }) {
  const [show, setShow] = useState(true)
  const [showMore, setShowMore] = useState(false)
  const divRef = useRef(null)

  useEffect(() => {
    const divHeight = divRef?.current?.offsetHeight
    const maxHeight = 58 // 5em = 5 * 16px (assuming 1em = 16px)
    setShowMore(divHeight > maxHeight)
    if (divHeight > maxHeight) {
    }
  }, [data, divRef?.current])

  return (
    <MDBContainer className="carInfo-details-information">
      {data ? (
        !loading ? (
          <div>
            <MDBRow className="carInfo-details-information-title p-0">
              <label className="_label --sub-title-specification p-0">Specification :</label>
            </MDBRow>
            <MDBContainer>
              {/* <ul
                style={{
                  height: showMore ? '4em' : '10em',
                }}
                className={
                  show
                    ? 'carInfo-details-information-specs-hide'
                    : 'carInfo-details-information-specs'
                }>
                {data && Array.isArray(data) ? (
                  data.map((item, index) => <li key={index}>{item}</li>)
                ) : ( */}
              <p
                ref={divRef}
                className={
                  show
                    ? 'carInfo-details-information-specs-hide'
                    : 'carInfo-details-information-specs'
                }
                style={{
                  height: showMore ? (show ? '5em' : 'auto') : 'auto',
                  width: '100%',
                  whiteSpace: 'pre-line',
                  fontSize: '12px',
                  padding: '0',
                }}>
                {data}
              </p>
              {/* )}
              </ul> */}
            </MDBContainer>
            {showMore && (
              <MDBRow>
                <p className="_label --see-more" onClick={() => setShow(!show)}>
                  {show ? 'See More' : 'Show Less'}
                </p>
              </MDBRow>
            )}
          </div>
        ) : (
          <>
            <Skeleton width="12em" height="15px" className="mt-5" />
            <Skeleton width="100%" height="15px" className="mt-3 mb-3" />
          </>
        )
      ) : (
        ''
      )}
    </MDBContainer>
  )
}
