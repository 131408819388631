import { useEffect, useRef, useState } from 'react'

//lib
import ReactPlayer from 'react-player'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

export default function VideCard({ videoActive, video, index, poster }) {
  const playerRef = useRef(null)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const [canvas, setCanvas] = useState(null)
  const [start, setStart] = useState(false)
  const { width } = useWindowDimensions()

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!(document.fullscreenElement || document.webkitFullscreenElement))
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange)
    }
  }, [])

  useEffect(() => {
    if (!isFullscreen && canvas) {
      const player = playerRef.current && playerRef.current.getInternalPlayer()
      player.pause()
    } else if (isFullscreen) {
      // const player = playerRef.current.getInternalPlayer()
      // player.play()
    }
  }, [isFullscreen, videoActive, canvas])

  const handleOnReady = () => {
    const video = document.querySelector(`#video-${index} video`)

    const canvas = document.createElement('canvas')
    canvas.width = video.videoWidth
    canvas.height = video.videoHeight

    const ctx = canvas.getContext('2d')
    ctx.drawImage(video, 0, 0)
    const base64 = canvas.toDataURL('image/png')
    setCanvas(base64)
  }

  const handlePlayerEnterFullscreen = () => {
    const player = playerRef.current && playerRef.current.getInternalPlayer()
    if (player.requestFullscreen) {
      player.requestFullscreen()
    } else if (player.webkitRequestFullscreen) {
      player.webkitRequestFullscreen()
    } else if (player.msRequestFullscreen) {
      player.msRequestFullscreen()
    }
    // player.play()
  }

  return (
    <div style={{ height: width >= 991 ? '24em' : '14em' }} onClick={() => {}}>
      {!start && (
        <div
          className="play-button-round"
          onClick={() => {
            const player = playerRef.current.getInternalPlayer()
            player.play()
          }}>
          <button className="play-button"></button>
        </div>
      )}
      {!start && poster && (
        <img
          onClick={() => {
            const player = playerRef.current.getInternalPlayer()
            player.play()
          }}
          alt="home-video-cover"
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 3,
            objectFit: 'cover',
          }}
          src={poster}
        />
      )}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: '#242424fc',
          backgroundImage: `url(${canvas})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          zIndex: 1,
          filter: 'blur(1em)',
          boxShadow: 'inset 0 0 99999px rgb(255 255 255 / 48%)',
        }}></div>
      <ReactPlayer
        config={{
          file: {
            attributes: { poster: poster ? poster : null },
            tracks: [{ src: 'captions_en.vtt', kind: 'captions', label: 'english_captions' }],
          },
        }}
        onClick={() => {}}
        ref={playerRef}
        // onStart={() => onStart()}
        style={{
          height: width >= 991 ? '' : '13em',
          width: width < 991 ? '100% !important' : '',
          zIndex: 2,
          position: 'relative',
        }}
        loop={false}
        controls={true}
        id={`video-${index}`}
        url={video}
        width="100%"
        height="100%"
        onStart={() => {
          setStart(true)
          document.getElementById(`video-${index}`).style.objectFit = 'cover'
          handlePlayerEnterFullscreen()
        }}
        onPlay={() => {
          handlePlayerEnterFullscreen()
        }}
        onReady={handleOnReady}
        className="react-player"
        playing={false}
        playsinline
      />
    </div>
  )
}
