import React from 'react'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import { Icon } from '@iconify/react'
import { MDBCol, MDBContainer, MDBRow, MDBBtn } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Skeleton } from 'primereact/skeleton'

//assets
import Profile from '../../../assets/images/logo/profile-icon.webp'

//helper
import LazyImage from '../../../components/common/common.image'

export default function CarBasicInfo2({ data, loading }) {
  const { width } = useWindowDimensions()

  const openWhatsapp = () => {
    const whatsappNumber = data.user_num ? data.user_num : ''
    const message = 'Hello, I am interested for your car.'
    const url = `https://api.whatsapp.com/send?phone=6${whatsappNumber}&text=${encodeURIComponent(
      message,
    )}`
    window.open(url, '_blank')
  }

  const openPhone = () => {
    const phoneNumber = data.user_num ? data.user_num : ''
    window.location.href = `tel:${phoneNumber}`
  }

  const formatCash = (num, digits = 2) => {
    var units = ['k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'],
      decimal

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1)

      if (num <= -decimal || num >= decimal) {
        const number = +(num / decimal).toFixed(digits)
        if (units[i] === 'Y') {
          return number.toLocaleString() + units[i]
        }
        return number.toLocaleString() + units[i]
      }
    }

    return num
  }

  const checkIsPreorder = data?.ready_stock_type === 'preorder'

  const formatPrice = (price) => {
    if (!price && price !== 0) {
      return 'N/A'
    }

    price = parseFloat(price.replace(/,/g, ''))

    if (isNaN(price)) {
      return 'N/A'
    }

    return Math.floor(price).toLocaleString()
  }

  return (
    <MDBContainer className="carBasic-info2">
      <MDBRow className="m-0">
        <MDBCol className="col-6">
          <MDBRow>{!loading ? <label className="askPrice">Price</label> : ''}</MDBRow>
          <MDBRow>
            {/* {!loading ? (
              <label className="_label --car-price">
                {`RM ${parseInt(data.asking_price).toLocaleString()}`}
              </label>
            ) : (
              <Skeleton width="20em" height="15px" />
            )} */}
            {!loading ? (
              checkIsPreorder ? (
                <label className="_label --car-price">{`RM ${formatPrice(
                  data.asking_price,
                )}`}</label>
              ) : (
                <label className="_label --car-price">{`RM ${parseInt(
                  data.asking_price,
                ).toLocaleString()}`}</label>
              )
            ) : (
              <Skeleton width="20em" height="15px" />
            )}
            {!loading && data.chassis_number && !checkIsPreorder && (
              <label className="newCarPrice">
                Chassis Number:{' '}
                {data.chassis_number &&
                  data.chassis_number.substring(data.chassis_number.length - 5)}
              </label>
            )}
            {!loading && data.stock_status && !checkIsPreorder && (
              <label className="newCarPrice">Status: {data.stock_status}</label>
            )}
          </MDBRow>
        </MDBCol>
        <MDBCol className="col-6">
          <MDBRow className="text-end">
            <MDBCol className="col-9">
              {!loading && <label className="_label --agent_name">{data.user_name}</label>}
            </MDBCol>
            <MDBCol className="text-end col-2">
              <div className="avatar-div">
                {!loading ? (
                  <LazyImage
                    src={data.user_photo?.length > 0 ? data.user_photo[0].url : Profile}
                    width={45}
                    alt="contact-avatar"
                    className="image-agent"
                  />
                ) : (
                  <Skeleton width="3em" height="30px" borderRadius="100%" />
                )}
              </div>
            </MDBCol>
          </MDBRow>
          {!loading && (
            <MDBRow className="contact-row">
              <MDBCol className="contact-col col-6"></MDBCol>
              <MDBCol className="contact-col">
                <div className="contact_btn" onClick={openPhone}>
                  <label className="_label">
                    <Icon icon="fluent:call-16-regular" height={14} />
                    &nbsp;Contact
                  </label>
                </div>{' '}
                &nbsp; &nbsp; &nbsp;
                <div className="contact_btn agent">
                  <label className="_label agent" onClick={openWhatsapp}>
                    <Icon icon="logos:whatsapp-icon" height={14} />
                    &nbsp;Whatsapp
                  </label>
                </div>
              </MDBCol>
            </MDBRow>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
