import { useNavigate } from 'react-router-dom'

//lib
import { Icon } from '@iconify/react'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//asset
import { LogoMain } from '../../assets/images'

//reducer
import { useDispatch, useSelector } from 'react-redux'
import { setFooterItemActive } from '../../redux/reducer/commonReducer'
import { setOpenJapanModal } from '../../redux/reducer/homeReducer'
import JapanLinkModal from '../modal/popup.japan.link'
import { setOpenEventModal } from '../../redux/reducer/homeReducer'
import EventModal from '../modal/popup.event'
import { useEffect } from 'react'
import ThemeSwitcher from '../element/themeSwitch'

export default function FooterOne() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { footerListing } = useSelector((state) => state.common)

  const { openJapanModal } = useSelector((state) => state.home)
  // const { openEventModal } = useSelector((state) => state.home)
  const { isDarkMode } = useSelector((state) => state.home)

  const openPhone = (phoneNumber) => {
    window.location.href = `tel:${phoneNumber}`
  }

  const currentYear = new Date().getFullYear()

  const openWhatsapp = () => {
    const url = 'https://wa.me/60125733527'
    window.open(url)
  }

  return (
    <>
      <MDBContainer className="footer-container footer-one" breakpoint="none">
        <MDBRow>
          <MDBCol>
            <LazyLoadImage src={LogoMain} width="150" alt="footer-dc-logo" />
          </MDBCol>
          {/* <MDBCol className="theme-switch-container">
            <ThemeSwitcher toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
          </MDBCol> */}
        </MDBRow>
        <MDBRow className="pt-5">
          <label className="_label --desc-text">
            D-Concept is Malaysia largest imported car platform, with presence in Malaysia, Japan,
            Australia and England.
          </label>
        </MDBRow>
        <MDBContainer className="line-container --grey-thin" breakpoint="none"></MDBContainer>
        <MDBContainer className="p-0" breakpoint="none">
          <MDBRow
            className="pt-3 pb-3 m-0"
            onClick={() => {
              window.open('https://sub.dconcept.my', '_blank')
            }}
            style={{ cursor: 'pointer' }}>
            <MDBCol className="p-0">
              <label className="_label --item-title">Switch to Old Version</label>
            </MDBCol>
            <MDBCol className="text-end p-0">
              {/* <Icon color="#D1D5DB" icon={'ic:outline-keyboard-arrow-down'} /> */}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        {footerListing.map((listing, index) => (
          <MDBContainer className="p-0" breakpoint="none" key={index}>
            <MDBRow
              className="pt-3 pb-3 m-0"
              onClick={() => dispatch(setFooterItemActive(index))}
              style={{ cursor: 'pointer' }}>
              <MDBCol className="p-0">
                <label className="_label --item-title">{listing.title}</label>
              </MDBCol>
              <MDBCol className="text-end p-0">
                {/* {isDarkMode ? ( */}
                <Icon
                  color={isDarkMode ? '#D1D5DB' : 'Black'} // dark_mode
                  icon={
                    listing.active
                      ? 'ic:outline-keyboard-arrow-up'
                      : 'ic:outline-keyboard-arrow-down'
                  }
                />
                {/* ) : (
                  <Icon
                    color="black" //light_mode
                    icon={
                      listing.active
                        ? 'ic:outline-keyboard-arrow-up'
                        : 'ic:outline-keyboard-arrow-down'
                    }
                  />
                )} */}
              </MDBCol>
            </MDBRow>
            <div
              style={{ height: listing.active ? listing.item.length * 22 : 0 }}
              className={
                listing.active ? `listing-item-container active` : `listing-item-container`
              }>
              {listing.item.map((listing_item, index) => (
                <MDBContainer className="p-0" breakpoint="none" key={index}>
                  <label
                    onClick={() => {
                      if (listing_item.label === 'Import UK Car') {
                        window.open(listing_item.to, '_blank')
                      } else if (listing_item.label === 'Import Japan Car') {
                        dispatch(setOpenJapanModal(true))
                      } else {
                        navigate(listing_item.to)
                      }
                    }}
                    className="_label --item-text">
                    {listing_item.label}
                  </label>
                </MDBContainer>
              ))}
            </div>
          </MDBContainer>
        ))}
        <MDBContainer className="line-container --grey-thin" breakpoint="none"></MDBContainer>
        <MDBRow>
          <label className="_label --desc-text">
            Reach Us Directly :{' '}
            <span className="_label --highlight-text">Dconcept.danny@gmail.com</span> Or call :{' '}
            <span
              className="_label --highlight-text"
              onClick={() => openWhatsapp()}
              style={{ cursor: 'pointer' }}>
              {/* 603 33599147 */}
              60125733527
            </span>
          </label>
          <label className="_label --desc-text --special">
            2018-{currentYear} DIRECT IMPORT CONCEPT Sdn Bhd (1344320-U) All rights reserved.
            Powered by © D'concept Malaysia (Version {process.env.REACT_APP_VERSION})
          </label>
        </MDBRow>
      </MDBContainer>
      {openJapanModal && <JapanLinkModal show={openJapanModal} setShow={setOpenJapanModal} />}
      {/* {openEventModal && <EventModal show={openEventModal} setShow={setOpenEventModal} />} */}
    </>
  )
}
