import { useNavigate, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
//lib
import Lottie from 'lottie-react'
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import ReactPixel from 'react-facebook-pixel'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getCarPostInfo, setContactAgent, setSelectedTab } from '../../redux/reducer/carPostReducer'
import { setLastView } from '../../redux/reducer/homeReducer'

//assets
import CarLoader2 from '../../assets/json/car-loader.json'
import DiscoveryNoData from '../../assets/json/discovery-no-data.json'

//hook
import useWindowDimensions from '../../components/common/window-dimension'

//component
import Header from '../../components/header/header-main'
import HeaderSecondary from '../../components/header/header-secondary'
import ImageHeader from './component/image.header'
import QuickLookView from './view/quick.look.view'
import TabBar from './component/tabbar'
import Toolbar from './component/toolbar'
import VideoView from './view/video.view'
import AskBotView from './view/ask.bot.view.'
import DirectOwnerView from './view/direct.owner.view'
import StockView from './view/stock.view'
import ContactAgent from '../../components/modal/popup.contact.agent'
import ShareCardBox from '../../components/modal/share.card.box'
import FooterOne from '../../components/footer/footer-one'
import { Skeleton } from 'primereact/skeleton'

export default function CarPost() {
  const { id } = useParams()
  const divRef = useRef(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const animationStyle = {
    width: width >= 991 ? '20%' : '60%',
  }
  const brand = localStorage.getItem('brand')
  const selectedPosts = JSON.parse(localStorage.getItem('selectedPosts')) || []

  const store = useSelector((state) => state.carPost)
  const [modalBox, setModalBox] = useState(false)
  const { quick_look_main, loading, owner, stock } = useSelector((state) => state.carPost)
  const { lastView } = useSelector((state) => state.home)

  // const maxImageSize = 640 // max size for WhatsApp sharing

  // const createBlobFromElement = async (element) => {
  //   // if (divRef.current.clientWidth > maxImageSize || divRef.current.clientHeight > maxImageSize) {
  //   //   scaleFactor = Math.min(
  //   //     maxImageSize / divRef.current.clientWidth,
  //   //     maxImageSize / divRef.current.clientHeight,
  //   //   )
  //   // }
  //   const newWidth = divRef.current.clientWidth
  //   const newHeight = divRef.current.clientHeight

  //   const options = {
  //     width: newWidth,
  //     height: newHeight,
  //   }
  //   const canvas = await html2canvas(element, options)
  //   return new Promise((resolve) => canvas.toBlob(resolve, 'image/jpg'))
  // }

  const generateImage = async () => {
    // const blob = await createBlobFromElement(divRef.current)
    // const shareData = {
    //   files: [new File([blob], `${quick_look_main.brand_name}.jpg`, { type: blob.type })],
    // }
    await navigator
      .share({
        // files: shareData.files,
        text: `Hey friends, check out this amazing car I found! It's the ${quick_look_main.car_model_name}, an ${quick_look_main.car_bodies_name} that I think you'll love. You can find more information about it and see some stunning pictures by visiting this link: ${window.location.href}`,
      })
      .then((res) => {})
      .catch((ex) => {})
  }

  const openWhatsapp = () => {
    const whatsappNumber = `+6${quick_look_main.company_mobile_number}`
    const message = `Hello, I${"'"}m interested with this ${
      quick_look_main.car_model_name
    }, how D-Concept can help to import?`
    const url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
      message,
    )}`
    window.open(url, '_blank')
  }

  useEffect(() => {
    if (quick_look_main.status === 'inactive') {
      const updatedPosts = selectedPosts.filter((post) => post !== quick_look_main.id)
      localStorage.setItem('selectedPosts', JSON.stringify(updatedPosts))
    }
  }, [selectedPosts, quick_look_main])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [window.location.pathname])

  useEffect(() => {
    dispatch(getCarPostInfo({ id: id }))
  }, [])

  useEffect(() => {
    if (quick_look_main.car_model_name)
      ReactPixel.trackCustom('IntPost_' + quick_look_main?.car_model_name, {
        car_model_name: quick_look_main?.car_model_name,
        car_model_year: quick_look_main?.car_model_year,
        car_bodies_name: quick_look_main?.car_bodies_name,
        direct_import: quick_look_main?.direct_import,
      })
  }, [quick_look_main])

  if (loading) {
    return (
      <MDBContainer className="common-main-container" breakpoint="none">
        {width >= 991 && <Header />}
        <div className="content-container carpost-page-container">
          <HeaderSecondary
            className="header-secondary-container"
            handleClick={() => {
              if (lastView) {
                navigate(`/`)
                dispatch(setLastView(false))
              } else {
                navigate(`/brand/${brand}`, { replace: true })
                dispatch(setSelectedTab('quick_look'))
              }
            }}
            title={
              <label className="_label --modal-title --number">
                <span>No</span>
                {quick_look_main.id}
              </label>
            }
          />
          <MDBContainer className="line-container --banner-line"></MDBContainer>
          <MDBContainer className="image-header-container mt-2" ref={divRef}>
            <div className="overlay-img --post"></div>
            <div className="overlay-img-div --post d-flex justify-content-center">
              <Lottie animationData={CarLoader2} style={{ width: '100%' }} />
            </div>
          </MDBContainer>
          <MDBContainer className="toolbar-container">
            <MDBRow className="toolbar-box">
              <MDBCol className="col-6 toolbar-box-columns p-0">
                <label className="_label --grey-label">
                  <Skeleton width="15em" height="15px" />
                </label>
                <label className="_label --pricing">
                  <Skeleton width="20em" height="15px" className="mt-3" />
                </label>
              </MDBCol>
              <MDBCol className="col-6 d-flex align-items-end justify-content-end p-0">
                <MDBRow className="m-0">
                  <Skeleton width="4em" height="40px" borderRadius="100%" />
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
          <MDBContainer className="tabbar-container">
            <div className="tabbar-section-row mt-3">
              {Array.from({ length: 5 }, (_, index) => (
                <Skeleton width="8em" height="15px" key={index} />
              ))}
            </div>
          </MDBContainer>
          <MDBContainer>
            <MDBRow className="d-flex">
              <MDBCol className="options-box-container mt-5 col-6">
                <MDBRow className="">
                  <Skeleton width="25em" height="15px" className="mb-3" />
                </MDBRow>
                <MDBRow>
                  <Skeleton width="15em" height="15px" className="mb-3" />
                </MDBRow>
                <MDBRow>
                  <Skeleton width="40em" height="15px" className="mb-3" />
                </MDBRow>
              </MDBCol>
              <MDBCol className="col-1" />
              <MDBCol className="car-panel-container mt-5 col `-5">
                <MDBRow className="">
                  {width >= 911 && (
                    <MDBRow className="contact-section">
                      <MDBCol className="col-6 text-center d-flex align-item-center justify-content-end">
                        <Skeleton width="18em" height="35px" className="mt-1 mb-2" />
                      </MDBCol>
                      <MDBCol className="col-6 text-center d-flex align-item-center justify-content-start">
                        <Skeleton width="18em" height="35px" className="ms-4 mt-1 mb-2" />
                      </MDBCol>
                    </MDBRow>
                  )}
                </MDBRow>
                <MDBRow>
                  {Array.from({ length: 6 }, (_, index) => (
                    <MDBCol className="col-4 d-flex align-items-center p-0" key={index}>
                      <Skeleton width="12em" height="15px" className="ms-3 mt-5" />
                    </MDBCol>
                  ))}
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </MDBContainer>
    )
  }

  return (
    <MDBContainer className="common-main-container" breakpoint="none">
      {quick_look_main && quick_look_main.status === 'active' ? (
        <>
          {width >= 991 && <Header />}
          <div className="content-container carpost-page-container">
            <HeaderSecondary
              className="header-secondary-container"
              handleClick={() => {
                if (lastView) {
                  navigate(`/`)
                  dispatch(setLastView(false))
                } else {
                  navigate(`/brand/${brand}`, { replace: true })
                  dispatch(setSelectedTab('quick_look'))
                }
              }}
              title={
                <label className="_label --modal-title --number">
                  <span>No</span>
                  {quick_look_main.id}
                </label>
              }
            />
            <MDBContainer className="line-container --banner-line"></MDBContainer>
            <ImageHeader divRef={divRef} loading={loading} />
            <Toolbar setModalBox={setModalBox} loading={loading} />
            <TabBar loading={loading} />
            {store.selected_tab === 'quick_look' ? (
              <QuickLookView loading={loading} />
            ) : store.selected_tab === 'video' ? (
              <VideoView />
            ) : store.selected_tab === 'd-stock' ? (
              <StockView stock={stock} loading={loading} />
            ) : store.selected_tab === 'direct_owner' ? (
              <DirectOwnerView owner={owner} loading={loading} />
            ) : store.selected_tab === 'ask_bot' ? (
              <AskBotView />
            ) : (
              ''
            )}
          </div>

          {width < 991 && navBar(dispatch, setContactAgent, openWhatsapp)}
          {store.contact_agent_modal && (
            <ContactAgent show={store.contact_agent_modal} data={quick_look_main} />
          )}
        </>
      ) : (
        <MDBContainer
          className="common-main-container"
          breakpoint="none"
          style={{ background: '#1E1F21' }}>
          <Header />
          <MDBContainer
            style={{
              paddingTop: width >= 991 ? '10em' : '0rem',
              paddingLeft: '0em',
              paddingRight: '0em',
            }}>
            <MDBRow
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                marginTop: '6em',
                marginLeft: '0',
                marginRight: '0',
                color: '#D1D5DB',
                fontFamily: 'cabin-regular',
              }}>
              <label
                style={{
                  fontFamily: 'inter-regular',
                  fontSize: '22px',
                  fontWeight: '600',
                  color: '#33363b',
                  fontStyle: 'italic',
                  textTransform: 'capitalize',
                }}>
                Invalid Car Post
              </label>
              <Lottie animationData={DiscoveryNoData} style={animationStyle} />
            </MDBRow>
          </MDBContainer>
          <FooterOne />
        </MDBContainer>
      )}
      {modalBox && (
        <ShareCardBox show={modalBox} setShow={setModalBox} generateImage={() => generateImage()} />
      )}
    </MDBContainer>
  )
}

export const navBar = (dispatch, setContactAgent, openWhatsapp) => {
  return (
    <div
      className="car-post-bottom fixed-bottom"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.3)', backdropFilter: 'blur(5px)' }}>
      <MDBRow className="contact-section">
        <MDBBtn
          className="button-group --contact-agent"
          onClick={() => dispatch(setContactAgent(true))}>
          CONTACT D-AGENT
        </MDBBtn>
        {/* <MDBBtn className="button-group --whatsapp" onClick={openWhatsapp}>
          <Icon icon="logos:whatsapp-icon" width="20" color="#10141A" />
          <span className="ps-2 pt-2">WhatsApp</span>
        </MDBBtn> */}
      </MDBRow>
    </div>
  )
}
