import { useRef, useState, useEffect } from 'react'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'

//hook
import { useDispatch, useSelector } from 'react-redux'
import {
  setCarBodyTypeFilter,
  setCarBrandFilter,
  setCarYearFilter,
  setEmptyData,
  setHasMore,
  setLoading,
  setOwnerData,
  setOwnerLoading,
  setOwnerTotalRecord,
  setSearch,
  setSearchPage,
  setSearchStock,
  setStockData,
  setStockLoading,
  setStockTotalRecord,
  setTotalRecord,
  stockDetailListing,
} from '../../redux/reducer/stockReducer'
import { useLocation, useSearchParams } from 'react-router-dom'
import useWindowDimensions from '../common/window-dimension'
import queryString from 'query-string'

export default function SearchBar({ setShow, className, type, setValue, value, setCurrentPage }) {
  const ref = useRef(null)
  const dispatch = useDispatch()
  const location = useLocation()
  const { width } = useWindowDimensions()
  const { isDarkMode } = useSelector((state) => state.home)
  const [searchParams, setSearchParams] = useSearchParams()

  const [placeholder, setPlaceholder] = useState('')
  const placeholderText = 'Search your dream car info'
  let index = 0

  const currentYear = new Date().getFullYear()

  const {
    ownerLoading,
    stockLoading,
    stockType,
    carBodyTypeFilter,
    carBrandFilter,
    carYearFilter,
    carPriceFilter,
    carModelFilter,
    sortByFilter,
    sortBySequence,
  } = useSelector((state) => state.stock)

  const parsedQuery = queryString.parse(location.search)

  const handleSearchStock = (search) => {
    dispatch(setOwnerData([]))
    dispatch(setStockData([]))

    if (stockType === 'D-Stock') {
      dispatch(setStockLoading(true))
    } else {
      dispatch(setOwnerLoading(true))
    }

    dispatch(setHasMore(true))
    dispatch(setEmptyData(false))

    dispatch(
      stockDetailListing({
        search: search,
        start: 0,
        length: 9,
        brand_name: carBrandFilter,
        car_post: carModelFilter,
        start_price:
          carPriceFilter[0] !== 50000 || carPriceFilter[1] !== 5000000 ? carPriceFilter[0] : '',
        end_price:
          carPriceFilter[0] !== 50000 || carPriceFilter[1] !== 5000000 ? carPriceFilter[1] : '',
        start_year:
          carYearFilter[0] !== 1990 || carYearFilter[1] !== currentYear ? carYearFilter[0] : '',
        end_year:
          carYearFilter[0] !== 1990 || carYearFilter[1] !== currentYear ? carYearFilter[1] : '',
        sort_by: sortByFilter,
        sort_order: sortBySequence,
        body_type: carBodyTypeFilter,
        ready_stock_type: stockType === 'D-Stock' ? 'd-stock' : 'direct owner',
      }),
    )
      .unwrap()
      .then((res) => {
        if (res.data.iFilterTotalRecords === 0) {
          dispatch(setHasMore(false))
          dispatch(setEmptyData(true))
        }
        dispatch(setHasMore(false))
        if (stockType === 'D-Stock') {
          dispatch(setStockLoading(false))
          dispatch(setStockData(res.data.aaData))
          dispatch(setStockTotalRecord(res.data.iFilterTotalRecords))
        } else {
          dispatch(setOwnerLoading(false))
          dispatch(setOwnerData(res.data.aaData))
          dispatch(setOwnerTotalRecord(res.data.iFilterTotalRecords))
        }
      })
      .catch((ex) => {})
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      // if (value) {
      //   dispatch(setSearchPage('1'))
      //   setSearchParams({ search: value })
      //   handleSearchStock(value)
      //   setCurrentPage(1)
      // }

      if (value && !(ownerLoading || stockLoading)) {
        dispatch(setSearchStock(value))
        dispatch(setSearchPage('1'))
        // dispatch(setLoading(true))
        setCurrentPage(1)
        // handleSearchStock(value)
        setSearchParams({ search: value })

        if (parsedQuery.search === value && !parsedQuery.page) {
          handleSearchStock(value)
        }
      }
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (index <= placeholderText.length) {
        setPlaceholder(placeholderText.slice(0, index))
        index++
      } else {
        clearInterval(interval)
      }
    }, 70)

    return () => clearInterval(interval)
  }, [])

  return (
    <MDBContainer className={`search-bar-container ${className}`}>
      <MDBRow
        // ref={wrapperRef}
        className={'search-bar-row'}
        onClick={() => {
          ref.current.focus()
        }}>
        <MDBContainer className="p-0">
          <MDBRow className="m-0">
            <MDBCol className="col-10 text-start d-flex align-items-center">
              <input
                ref={ref}
                value={value}
                onChange={(e) => {
                  if (e.target.value == '') {
                    setSearchParams('')
                    setCurrentPage(1)
                    dispatch(setSearchStock(''))
                    // if(!parsedQuery.search) {
                    // handleSearchStock('')
                    // }
                  }
                  // dispatch(setSearchStock(e.target.value))
                  setValue(e.target.value)
                }}
                onKeyDown={handleKeyDown}
                style={{
                  color: '#000',
                }}
                placeholder={placeholderText}></input>
            </MDBCol>
            <MDBCol className="col-2 d-flex justify-content-end">
              {value && (
                <div
                  className="pointer"
                  onClick={() => {
                    dispatch(setSearchStock(''))
                    setValue('')
                    setSearchParams('')
                    setCurrentPage(1)

                    // if(!parsedQuery.search) {
                    //   handleSearchStock('')
                    // }
                  }}>
                  <Icon
                    icon="material-symbols:close"
                    color="#9091A0"
                    width={25}
                    height={30}
                    style={{
                      marginTop: width < 991 ? '0.1em' : '',
                      marginRight: width < 991 ? '0.4em' : '0.5em',
                    }}
                  />
                </div>
              )}
              <div
                className="pointer"
                onClick={(e) => {
                  e.stopPropagation()
                  if (value && !(ownerLoading || stockLoading)) {
                    dispatch(setSearchStock(value))
                    dispatch(setSearchPage('1'))
                    // dispatch(setLoading(true))
                    setCurrentPage(1)
                    // handleSearchStock(value)
                    setSearchParams({ search: value })

                    if (parsedQuery.search === value && !parsedQuery.page) {
                      handleSearchStock(value)
                    }
                  }
                }}>
                <Icon
                  icon="eva:search-outline"
                  color={isDarkMode ? '#D6A055' : 'AC3434'}
                  width="25"
                  height="30"
                  className="mb-1"
                />
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </MDBRow>
    </MDBContainer>
  )
}
