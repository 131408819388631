//lib
import { MDBContainer, MDBTable, MDBTableHead, MDBTableBody } from 'mdb-react-ui-kit'

export default function AuctionThirdTable() {
  return (
    <MDBContainer className="auction-guide-table-container">
      <MDBTable striped bordered borderColor="dark">
        <MDBTableHead className="table-dark _text --head ">
          <tr>
            <th scope="col">BIDDING OUTCOMES</th>
            <th scope="col">STATUS</th>
          </tr>
        </MDBTableHead>
        <MDBTableBody className="_text --body ">
          <tr>
            <th scope="row" className="table-warning">
              You purchase a car matching your budget or close to it.
            </th>
            <td className="table-warning">BOUGHT</td>
          </tr>
          <tr>
            <th scope="row" className="table-warning">
              Car purchased by someone before you in the auction.
            </th>
            <td className="table-warning">SOLD</td>
          </tr>
          <tr>
            <th scope="row" className="table-warning">
              The bids do not reach the reserve price set by sellers.
            </th>
            <td className="table-warning">PASSED</td>
          </tr>
          <tr>
            <th scope="row" className="table-warning">
              A passed vehicle sold after negotiation on price.
            </th>
            <td className="table-warning">SOLD IN NEGOTIATION</td>
          </tr>
          <tr>
            <th scope="row" className="table-warning">
              A vehicle removed from auction by the seller during the process.
            </th>
            <td className="table-warning">REMOVED</td>
          </tr>
        </MDBTableBody>
      </MDBTable>
    </MDBContainer>
  )
}
