import React from 'react';

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component';

//assets
import location from '../../../assets/images/contact-us-img/marker.gif'
import mail from '../../../assets/images/contact-us-img/mail.gif'
import message from '../../../assets/images/contact-us-img/message.gif'
import time from '../../../assets/images/contact-us-img/time.gif'

export default function ContactTable() {

  return (
    <MDBContainer className='contact-us-container'>
      <MDBRow className="main-title-row">
        <label className="_title-special-text">
          
          <span className="_title--big ">Contact Us</span>
        </label>
      </MDBRow> 

      <div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3984.023189261877!2d101.4502457051763!3d3.0884834826987437!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc53b446ac1553%3A0xacbbb923fb84a538!2sD&#39;%20Concept!5e0!3m2!1sen!2smy!4v1680579407829!5m2!1sen!2smy"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          title="Google Maps"
        ></iframe>
      </div> 
          <div className='_bg-color'>
          <MDBRow className='_row-padding'>
            <MDBCol size='4' className='_img-align'>
              <LazyLoadImage src={time} alt="time-gif" />

            </MDBCol>
              <MDBCol size='6' className='_text'>
                <h1 className='_yellow'>Operation Hour</h1>
                <MDBRow className='_white'>
                <p>Monday - Friday</p>
                <p>09:00 AM - 07:00PM </p>
                <p>Saturday</p>
                <p>09:00 AM - 05:00PM </p>
                <p>Sunday</p>
                <p>09:00 AM - 05:00PM </p>
                </MDBRow>
            </MDBCol>
          </MDBRow>

          <MDBRow className='_row-padding'>
            <MDBCol size='4' className='_img-align'>
              <LazyLoadImage src={location} alt="location-gif" />

            </MDBCol>
            <MDBCol size='6' className='_text'>
              <h1 className='_yellow'>Address</h1>
              <p className='_white'>No.1, Jln Astaka 4/KU2, Bandar Bukit Raja, 41050 Klang, Selangor</p>
            </MDBCol>
          </MDBRow>

          <MDBRow className='_row-padding'>
            <MDBCol size='4' className='_img-align'>
              <LazyLoadImage src={message} alt="message-gif" />

            </MDBCol>
            <MDBCol size='6' className='_text'>
              <h1 className='_yellow'>Call Us</h1>
              <p className='_white'>+60 12-288 8687 (Mr Chia)</p>
              <p className='_white'>+60 12-220 3330 (Danny Tan)</p>
            </MDBCol>
          </MDBRow>

          <MDBRow className='_row-padding'>
            <MDBCol size='4' className='_img-align'>
              <LazyLoadImage src={mail} alt="mail-gif" />

            </MDBCol>
            <MDBCol size='6' className='_text'>
              <h1 className='_yellow'>Email Us</h1>
              <p className='_white'>dconcept.danny@gmail.com</p>
            </MDBCol>
          </MDBRow>
          </div>
    </MDBContainer>
  )
}
