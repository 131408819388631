import { useEffect, useState } from 'react'

//lib
import { MDBBtn, MDBRow } from 'mdb-react-ui-kit'
import * as Yup from 'yup'
import { Form, Formik, Field } from 'formik'
import { toast } from 'react-toastify'

//redux
import { useDispatch } from 'react-redux'
import { setLoginModal } from '../../../redux/reducer/orderReducer'

//component
import Text from '../../../components/element/text'
import Code from '../../../components/element/code'

//service
import authServices from '../../../services/auth.services'

export default function VerificationLogin() {
  const dispatch = useDispatch()
  const [countdown, setCountdown] = useState(null)
  const [timerId, setTimerId] = useState(null)
  const initialValues = {
    u_m_n: '',
    u_o_t_p: '',
  }

  const useSchema = Yup.object({
    u_m_n: Yup.string()
      .required('Phone number is required')
      .matches(/^(01)[02-9]-*[0-9]{7}$|^(01)[1]-*[0-9]{8}$/g, 'Invalid phone number format'),
    u_o_t_p: Yup.string().min(6).required('Code is required'),
  })

  const handleSubmit = async ({ values, setFieldError }) => {
    try {
      const response = await authServices.verificationLogin({
        mobile_number: values.u_m_n,
        otp: values.u_o_t_p,
      })
      if (response) {
        localStorage.setItem('username', response.data.user.username)
        localStorage.setItem('token', response.data.token)
        dispatch(setLoginModal(false))
        toast.success('Login successful.', {
          autoClose: 3000,
          theme: 'dark',
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        })
      }
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => setFieldError(item, errors[item]))
        }
      }
    }
  }

  useEffect(() => {
    if (countdown === 0) {
      // 清除计时器
      clearInterval(timerId)
      setCountdown(null)
    }
  }, [countdown, timerId])

  const handleClick = async (values, setFieldError) => {
    try {
      const response = await authServices.verificationCode({
        mobile_number: values.u_m_n,
      })
      if (response) {
        if (countdown === null) {
          // 开始倒计时
          setCountdown(120)
          const timer = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1)
          }, 1000)
          setTimerId(timer)
        }
      }
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => setFieldError(item, errors[item]))
        }
      }
    }
  }

  const formatTimer = (time) => {
    if (time === 120) {
      return `( ${time}s )`
    } else {
      return `( ${time}s )`
    }
  }
  // const [inputvalue, setInputvalue] = useState('')
  // useEffect(() => {}, [])

  // const handleOnCustomValueChange = (value) => {
  //   setInputvalue(value)
  // }

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={useSchema}
        enableReinitialize={true}
        validateOnChange={true}
        onSubmit={(values, { setFieldError }) => {
          handleSubmit({ values, setFieldError })
          //resetForm({ values: '' })
        }}>
        {({ setFieldError, isSubmitting, isValid, values, setFieldValue }) => (
          <Form>
            <MDBRow>
              <label className="_label --modal-title">Welcome Back</label>
            </MDBRow>
            <Text
              name="u_m_n"
              label="Phone Number"
              placeholder="eg. 0123456789"
              className="phone-input"
              // autoComplete="nope"
              // customValue={inputvalue}
              // handleOnCustomValueChange={handleOnCustomValueChange}
              // setFieldValue={setFieldValue}
              // autocomplete="off"
              // autoComplete="off"
            />
            <Code className="form-input" name="u_o_t_p" label="Enter OTP" />
            <MDBRow className="d-flex justify-content-center m-0">
              <MDBBtn
                className="button-group --send-code mt-1"
                disabled={countdown !== null}
                type="button"
                onClick={() => handleClick(values, setFieldError)}>
                {countdown === null ? 'SEND OTP' : `RESEND OTP ${formatTimer(countdown)}`}
              </MDBBtn>
            </MDBRow>
            <MDBRow className="d-flex justify-content-center m-0">
              <MDBBtn
                disabled={isValid && values.u_o_t_p.length === 6 ? false : true}
                className="button-group --login-button"
                type="submit">
                LOG IN / SIGN UP
              </MDBBtn>
            </MDBRow>
          </Form>
        )}
      </Formik>
    </div>
  )
}
