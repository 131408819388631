import React from 'react'

import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Skeleton } from 'primereact/skeleton'

export default function CustomTablePreorder({ data, price, loading }) {
  const formatPrice = (price) => {
    if (!price && price !== 0) {
      return 'N/A' // Or any default value you prefer for null or undefined prices
    }

    price = parseFloat(price.replace(/,/g, ''))

    if (isNaN(price)) {
      return 'N/A'
    }

    return Math.floor(price).toLocaleString()

    // if (price >= 1000000) {
    //   return (price / 1000000).toFixed(1) + 'M'
    // } else if (price >= 1000) {
    //   return (price / 1000).toFixed(0) + 'k'
    // } else {
    //   return price.toFixed(0)
    // }
  }

  const findDollarSign = (country) => {
    switch (country.toLowerCase()) {
      case 'japan':
        return '¥'
      case 'england':
        return '£'
      case 'australia':
        return 'A$'
      default:
        return '$'
    }
  }

  return (
    <MDBContainer
      className="carInfo-details-information"
      style={{ paddingBottom: '2rem', paddingTop: '1.5rem' }}>
      <MDBRow className="carInfo-details-information-title p-0">
        {!loading ? (
          <label className="_label --sub-title p-0">Estimated Cost</label>
        ) : (
          <div>
            <Skeleton width="20em" height="15px" borderRadius="5px" className="mb-3" />
          </div>
        )}
      </MDBRow>

      <MDBRow className="carInfo-table">
        <MDBCol className="carInfo-table-container-calculation boder-top-left col-4 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding">Country </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation boder-top-right col-8 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding">{data?.preorder_country}</label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-4 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding _style --wrap-1 ">
                Currency Rate{' '}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-8 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding">
                1 : {data.preorder_currency_rate ? data.preorder_currency_rate : '-'}
                {' ( MYR : '}
                {data?.preorder_currency_name}
                {' )'}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-4 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding _style --wrap-1">Transit Time </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-8 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding">
                ETA {data?.preorder_transit_time} days
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-4 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding _style --wrap-1">Cost Price </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-8 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding">
                {findDollarSign(data?.preorder_country)}{' '}
                {formatPrice(data?.preorder_input_car_price)}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-4 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding _style --wrap-1">
                Malaysia Price{' '}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-8 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding">
                {/* RM{data.preorder_car_price ? data.preorder_car_price : '-'} */}
                RM {formatPrice(data?.preorder_car_price)}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>

        <MDBCol className="carInfo-table-container-calculation col-4 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding _style --wrap-1">Duty Tax </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-8 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding">
                {/* RM{data.preorder_duty_tax ? data.preorder_duty_tax : '-'} */}
                RM {formatPrice(data?.preorder_duty_tax)}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-4 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding _style --wrap-1">Global MISC </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-8 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding">
                {/* RM{data.preorder_misc ? data.preorder_misc : '-'} */}
                RM {formatPrice(data?.preorder_misc)}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-4 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding _style --wrap-1">
                Service Charge{' '}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-4 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding _style --wrap-1">
                <span style={{ fontSize: '9px' }}>(8%) &nbsp;</span>
                <span style={{ fontWeight: '600' }}>
                  RM {formatPrice(data?.preorder_service_fee)}
                </span>
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container-calculation col-4 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding _style --wrap-1">
                <span style={{ fontSize: '9px' }}>(3%) &nbsp;</span>
                <span style={{ fontWeight: '600' }}>
                  RM {formatPrice(data?.preorder_service_fee_3)}
                </span>
              </label>
            </>
          ) : (
            <>
              <div
                className="d-flex justify-content-center"
                // style={{ paddingLeft: '3rem' }}
              >
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        {/* <div className="d-flex align-items-center"> */}
        <MDBCol className="carInfo-table-container-calculation boder-btm-left col-4 text-start">
          {!loading ? (
            <>
              <label className="_label --white-text --padding _style --wrap-1">
                <strong>Sub Total </strong>
                <div>
                  <p style={{ fontSize: '9px', marginBottom: '-1px', marginTop: '-4px' }}>
                    {' '}
                    ( Based on 3% SC )
                  </p>
                </div>
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol
          className="carInfo-table-container-calculation boder-btm-right col-8 text-end"
          style={{ display: 'flex', alignItems: 'center' }}>
          {!loading ? (
            <>
              <label className="_label --white-text --padding">
                {/* color: '#AC4343', fontWeight: 'bold' */}
                <span style={{ fontSize: '11px', letterSpacing: '1px' }}> est price &nbsp;</span>
                <span style={{ color: '#AC4343', fontWeight: 'bold', fontSize: '1.6rem' }}>
                  RM {formatPrice(price)}
                </span>
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        {/* </div> */}
      </MDBRow>
    </MDBContainer>
  )
}
