import React from 'react'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Skeleton } from 'primereact/skeleton'

export default function CarInfoTable({ data, price, loading }) {
  return (
    <MDBContainer className="carInfo-details-information">
      <MDBRow className="carInfo-details-information-title p-0">
        {!loading ? (
          <label className="_label --sub-title p-0">Car Information</label>
        ) : (
          <div>
            <Skeleton width="20em" height="15px" borderRadius="5px" className="mb-3" />
          </div>
        )}
      </MDBRow>
      <MDBRow className="carInfo-table">
        <MDBCol className="carInfo-table-container col-4">
          {!loading ? (
            <>
              <label className="_label --gray-text">Selling Price</label>
              <label className="_label --white-text">
                RM{price ? parseInt(price).toLocaleString() : '-'}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container col-4">
          {!loading ? (
            <>
              <label className="_label --gray-text">Year Manufacture</label>
              <label className="_label --white-text">
                {data.manufactory_year ? data.manufactory_year : '-'}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container col-4">
          {!loading ? (
            <>
              <label className="_label --gray-text">Register At</label>
              <label className="_label --white-text">
                {data.register_at ? data.register_at : '-'}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container col-4">
          {!loading ? (
            <>
              <label className="_label --gray-text">Color</label>
              <label className="_label --white-text">{data.color ? data.color : '-'}</label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container col-4">
          {!loading ? (
            <>
              <label className="_label --gray-text">Fuel Type</label>
              <label className="_label --white-text">{data.fuel_type ? data.fuel_type : '-'}</label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container col-4">
          {!loading ? (
            <>
              <label className="_label --gray-text">Transmission</label>
              <label className="_label --white-text">
                {data.transmission ? data.transmission : '-'}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container col-4">
          {!loading ? (
            <>
              <label className="_label --gray-text">Condition</label>
              <label className="_label --white-text">{data.condition ? data.condition : '-'}</label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3 mb-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container col-4">
          {!loading ? (
            <>
              <label className="_label --gray-text">Mileage</label>
              <label className="_label --white-text">{data.mileage ? data.mileage : '-'}</label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3 mb-3" />
              </div>
            </>
          )}
        </MDBCol>
        <MDBCol className="carInfo-table-container col-4">
          {!loading ? (
            <>
              <label className="_label --gray-text">Engine Capacity</label>
              <label className="_label --white-text">
                {data.engine_capacity ? data.engine_capacity : '-'}
              </label>
            </>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <Skeleton width="12em" height="15px" className="mt-3 mb-3" />
              </div>
            </>
          )}
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
