//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { RemoveScroll } from 'react-remove-scroll'
import { Image } from 'primereact/image'
import { Galleria } from 'primereact/galleria'

import React, { useState } from 'react'
import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css' // Import the CSS file for styling

//redux
import useWindowDimensions from '../../../components/common/window-dimension'

import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef } from 'react'
import { setOpenGalleria } from '../../../redux/reducer/carInfoReducer'

//helper
import LazyImage from '../../../components/common/common.image'

export default function CarImage({ title, data }) {
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const { car_Image_Modal, openGalleria } = useSelector((state) => state.carInfo)

  let newData = []

  for (var key in data) {
    newData.push(data[key]?.source)
  }

  // const galleria = useRef(null)

  // const itemTemplate = (item) => {
  //   return <LazyImage src={item} alt={item.alt} style={{ width: '100%', display: 'block' }} />
  // }

  // const thumbnailTemplate = (item) => {
  //   return <LazyImage src={item} alt={item.alt} style={{ display: 'block' }} />
  // }

  // useEffect(() => {
  //   if (openGalleria && title === 'Exterior Look') {
  //     galleria.current.show()
  //   }

  //   return () => dispatch(setOpenGalleria(null))
  // }, [openGalleria])

  const [lightboxIsOpen, setLightboxIsOpen] = useState(false)
  const [currentImageIndex, setCurrentImageIndex] = useState(0)

  const toggleLightbox = (index) => {
    setCurrentImageIndex(index)
    setLightboxIsOpen(!lightboxIsOpen)
  }

  return (
    <RemoveScroll enabled={car_Image_Modal.show ? true : false}>
      <MDBContainer className="carInfo-image-container p-0">
        <div className="carInfo-title">{title}</div>
        <MDBContainer className="carInfo-look-image">
          <MDBRow>
            <MDBCol onClick={() => toggleLightbox(0)}>
              <LazyImage
                src={newData[0]}
                alt="car-img"
                width="250"
                height={width > 991 ? '200' : '100'}
              />
            </MDBCol>
            <MDBCol onClick={() => toggleLightbox(1)}>
              <LazyImage
                src={newData[1]}
                alt="car-img"
                width="250"
                height={width > 991 ? '200' : '100'}
              />
            </MDBCol>
          </MDBRow>
          <MDBRow>
            <MDBCol onClick={() => toggleLightbox(2)}>
              <LazyImage
                src={newData[2]}
                alt="car-img"
                width="250"
                height={width > 991 ? '200' : '100'}
              />
            </MDBCol>
            <MDBCol className="last-image-column" onClick={() => toggleLightbox(0)}>
              <div className="image">
                <LazyImage
                  src={newData[3]}
                  alt="car-img"
                  width="250"
                  height={width > 991 ? '200' : '100'}
                />
                {newData.length > 4 && (
                  <div className="overlay">
                    <label>+{newData.length - 3}</label>
                  </div>
                )}
              </div>
            </MDBCol>
          </MDBRow>
          {/* <Galleria
            ref={galleria}
            value={newData}
            numVisible={9}
            style={width > 850 ? { maxWidth: '50%' } : { maxWidth: '100%' }}
            // style={
            //   width > 850
            //     ? { maxWidth: '50%', maxHeight: '95%', overflowY: 'auto' }
            //     : { maxWidth: '100%' }
            // }
            circular
            fullScreen
            showItemNavigators
            showThumbnails={false}
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
          /> */}

          {lightboxIsOpen && (
            <Lightbox
              mainSrc={newData[currentImageIndex]}
              nextSrc={newData[(currentImageIndex + 1) % newData.length]}
              prevSrc={newData[(currentImageIndex + newData.length - 1) % newData.length]}
              onCloseRequest={() => setLightboxIsOpen(false)}
              onMovePrevRequest={() =>
                setCurrentImageIndex((currentImageIndex + newData.length - 1) % newData.length)
              }
              onMoveNextRequest={() =>
                setCurrentImageIndex((currentImageIndex + 1) % newData.length)
              }
            />
          )}
        </MDBContainer>
      </MDBContainer>
    </RemoveScroll>
  )
}
