import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from '../../services/api.services'

const initialState = {
  login_modal: false,
  selectedLoginTab: 'account',
  login_data: {
    email: "",
    password: ""
  },
  order_history_listing: [{title: "-",status: "-"}],
  announcement: [],
  orderDetails: [],
  recommendDetails: [],
  taskCompleted: [],
};

export const getAnnouncement = createAsyncThunk('get/announcement', async (payload) => {
  const response = await apiServices.getAnnouncement(payload)
  return response.data
})
export const getOrderHistory = createAsyncThunk('get/order_history', async (payload) => {
  const response = await apiServices.getOrderHistory(payload)
  return response.data
})
export const getRecommended = createAsyncThunk('get/recommended', async (payload) => {
  const response = await apiServices.getRecommended(payload)
  return response.data
})

const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setLoginModal: (state,action) => {
      state.login_modal = action.payload
    },
    setSelectedLoginTab: (state,action) => {
      state.selectedLoginTab = action.payload
    }
  },
  extraReducers: {
    [getAnnouncement.pending]: (state, action) => {
      state.announcement_loading = true
    },
    [getAnnouncement.fulfilled]: (state, action) => {
      state.announcement_loading = false
      state.announcement = action.payload
    },

    [getOrderHistory.pending]: (state, action) => {
      state.history_loading = true
    },
    [getOrderHistory.fulfilled]: (state, action) => {
      state.history_loading = false
      state.orderDetails = action.payload
    },

    [getRecommended.pending]: (state, action) => {
      state.recommend_loading = true
    },
    [getRecommended.fulfilled]: (state, action) => {
      state.recommend_loading = false
      state.recommendDetails = action.payload.aaData
    },
  },
});

export const { setLoginModal, setSelectedLoginTab } = orderSlice.actions;
const { reducer } = orderSlice;
export default reducer;
