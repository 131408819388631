import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { MDBContainer } from 'mdb-react-ui-kit'

export default function HeaderSecondary({ handleClick, title, className }) {
  return (
    <MDBContainer className={className}>
      <div className="back-btn" onClick={() => handleClick()}>
        <FontAwesomeIcon icon={faChevronLeft} />
        <FontAwesomeIcon icon={faChevronLeft} />
        BACK
      </div>
      {title}
    </MDBContainer>
  )
}
