import { useEffect } from 'react'

//lib
import { MDBContainer } from 'mdb-react-ui-kit'

//component
import Header from '../../components/header/header-main'
import AboutTableV2 from './component/about.table.v2'

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <MDBContainer className="common-main-container" breakpoint="none">
      <Header />
      <AboutTableV2 />
    </MDBContainer>
  )
}
