import { useNavigate } from 'react-router-dom'

//lib
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCreative } from 'swiper'
import 'swiper/css'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setLastView } from '../../../redux/reducer/homeReducer'

//helper
import LazyImage from '../../../components/common/common.image'

export default function LastHistory() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const { lastViewData } = useSelector((state) => state.home)

  //last view
  function handlePostClick(id) {
    let updatedPosts

    const storedPosts = JSON.parse(localStorage.getItem('selectedPosts')) || []
    if (storedPosts.includes(id)) {
      updatedPosts = storedPosts.filter((n) => n !== id)
    } else {
      updatedPosts = storedPosts
    }

    updatedPosts.unshift(id)

    if (updatedPosts.length > 5) {
      updatedPosts.pop()
    }

    localStorage.setItem('selectedPosts', JSON.stringify(updatedPosts))
  }

  return (
    <>
      {lastViewData?.length > 0 ? (
        <MDBContainer className="last-history-container mt-5">
          <MDBRow className="main-title-row">
            <label className="_title-special-text">
              D - <span className="_title">LAST VIEW</span>
            </label>
          </MDBRow>
          <div className="car-carousel-detail">
            <Swiper
              slidesPerView={width > 991 ? 5 : 2.5}
              spaceBetween={15}
              grabCursor={true}
              creativeEffect={{
                prev: {
                  shadow: false,
                  translate: ['-120%', 0, -500],
                },
                next: {
                  shadow: false,
                  translate: ['120%', 0, -500],
                },
              }}
              modules={[EffectCreative]}>
              {lastViewData.map((post, index) => (
                <SwiperSlide key={index}>
                  <div
                    className="car-card-container"
                    onClick={() => {
                      handlePostClick(post.id)
                      dispatch(setLastView(true))
                      navigate(`/post/${post.id}`)
                    }}>
                    <label className="_car-name">{post.brand_name}</label>
                    <label className="_car-series">{post.car_model_name}</label>
                    <div className="_car-image">
                      <LazyImage item={post.image && post.image[0] ? post.image[0] : null} src={post.image[0].url} width="70%" alt={post.car_model_name} />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </MDBContainer>
      ) : (
        ''
      )}
    </>
  )
}
