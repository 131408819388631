import React from 'react'

//lib
import { MDBContainer } from 'mdb-react-ui-kit'

import VideCard from './video.card'

export default function TiktokVideo({ data, loading }) {
  return (
    <MDBContainer className="carInfo-carVideo p-0">
      {data && (
        <div>
          {data.length > 0 &&
            (!loading ? (
              <MDBContainer className="carInfo-title">Video Intro</MDBContainer>
            ) : (
              // <MDBContainer className="carInfo-title">
              //   <Skeleton
              //     width="25em"
              //     height="15px"
              //     className="mt-4"
              //   />
              // </MDBContainer>
              ''
            ))}
          <MDBContainer className="carInfo-carVideo-listContainer">
            {/* <Swiper spaceBetween={5} slidesPerView={2.5}> */}
            {data.length > 0 &&
              data.map((item, index) =>
                // <SwiperSlide>
                !loading ? (
                  <div className="carInfo-carVideo-container" key={index}>
                    {/* <Player playsInline>
                        <source src={item.video} />
                      </Player> */}
                    <VideCard video={item.source} index={index} />
                  </div>
                ) : (
                  // <Skeleton
                  //   width="100%"
                  //   height='300px'
                  //   className="mt-3 mb-3"
                  // />
                  ''
                ),
              )}
          </MDBContainer>
        </div>
      )}
    </MDBContainer>
  )
}
{
  /* </SwiperSlide> */
}
{
  /* </Swiper> */
}
