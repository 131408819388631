import React, { useEffect, useState } from 'react';
import { Dropdown } from 'primereact/dropdown'

export default function TandCDropdown({ containerRef, section1, section2 }) {
  const [activeSection, setActiveSection] = useState(false);
  const [selectedValue , setSelectedValue] = useState({name : "Terms and Conditions"})
  const option = [{name : "Terms and Conditions"} , {name : "Privacy Policy"}]

  const handleChange = (e) => {
    setSelectedValue(e.target.value)
    setActiveSection(!activeSection)
  }

  useEffect(() => {
    function handleScroll() {
      const section1Top = section1.current.offsetTop;
      const section2Top = section2.current.offsetTop;
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;
      const scrollHeight = windowHeight * 0.1;

      if (scrollPosition >= section1Top - windowHeight * 0.15 && scrollPosition < section2Top - windowHeight * 0.15) {
        setSelectedValue({name : "Terms and Conditions"})
      } else if (scrollPosition >= section2Top - windowHeight * 0.15) {
        setSelectedValue({name : "Privacy Policy"})
      } else {
        // setSelectedValue("")
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [section1, section2]);

  useEffect(() => {
    if(selectedValue.name && selectedValue.name === "Terms and Conditions"){
        section1.current.scrollIntoView({
            behavior: 'smooth', // Optional: Add smooth scrolling animation
            block: 'start', // Scroll to the top of the element
            inline: 'nearest', // Keep the element nearest to the top
          });
    
    } else if(selectedValue.name && selectedValue.name === "Privacy Policy"){
        section2.current.scrollIntoView({
            behavior: 'smooth', // Optional: Add smooth scrolling animation
            block: 'start', // Scroll to the top of the element
            inline: 'nearest', // Keep the element nearest to the top
          });
    
    }
   
  }, [activeSection]);

  return (
    <div className='t_and_c-dropdown-div'>
    <Dropdown value={selectedValue} onChange={(e) => handleChange(e)} options={option} optionLabel="name" 
    placeholder="Select a City" className="w-full md:w-14rem" />
    </div>
  );
}
