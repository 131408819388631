import { useEffect, useState ,useRef} from 'react'
import { LogoMain, Video1, discoverImage , LogoD } from '../../../assets/images';
import { Icon } from '@iconify/react'
//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import { MDBContainer, MDBRow , MDBCol } from 'mdb-react-ui-kit'
import { motion } from 'framer-motion'
import ReactPlayer from "react-player";

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedVideo } from '../../../redux/reducer/discoverReducer';

import LazyImage from '../../../components/common/common.image';

export default function VideoCard({item , index}) {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const [show, setShow] = useState(true)
  const [showMore, setShowMore] = useState(false)
  const { selectedVideo} = useSelector((state) => state.discover)

  const divRef = useRef(null)

  const itemCss = {
    hidden: { opacity: 0  , y:200,},
    show: { opacity: 1 , y:0} , 
  }

  const openWhatsapp = () => {
    const message = "Hello, I want to know what the car is about the video."
    const url = `https://api.whatsapp.com/send?phone=6${item.phone_number}&text=${encodeURIComponent(message,)}`
    window.open(url, '_blank')
  }

  useEffect(() => {
    const divHeight = divRef?.current?.offsetHeight
    const maxHeight = 70 // 5em = 5 * 16px (assuming 1em = 16px)
    setShowMore(divHeight > maxHeight)
    if (divHeight > maxHeight) {
    }
  }, [])

  return (
   <MDBCol className={width > 1200 ? "col-12 card-col" : width > 991 ? "col-12 card-col" : "col-12 card-col"}>
    <motion.div variants={itemCss}  className={`video-card ${width < 991 && `mobile`}`} >
    <motion.div className={width > 991 ? 'video-div' : "video-div mobile"} layoutId={"card-"+item.id}>
      <motion.div layoutId={"div-"+item.id} className='block-div' onClick={() => {
       dispatch(setSelectedVideo(item))
      }}>
   </motion.div>
      <div style={{position : "absolute" , right : "0" , marginTop : "0.3em" , marginRight : "0.3em"}}><Icon icon="line-md:play-filled" color='FFF' height={25}/></div>
       {item.medias?.[1]?.url ? <LazyImage src={item.medias?.[1]?.url} alt='Dconcept discover' style={{height : "100%" , width : "100%" , objectFit : "cover"}}/>   :
         <ReactPlayer
         className={width > 1200 ?  "discover-cover desktop" : "discover-cover"}
          style={{maxHeight : width > 991 ? "463.63px desktop" : "236px" , maxWidth : "255px" ,height : "100%" , width : "100%"  , objectFit : "cover"}}
          url={item.medias?.[0]?.url}
          width={"100%"}
          height={"100%"}
          loop={false}
          />}
    </motion.div>
   
    <MDBContainer className='label-container'>
      <MDBRow className={width < 991 && "label-row"} >
        <label className={width < 991 ? "_label --title mobile" : show ?  '_label --title hide' : "_label --title " }
        ref={divRef}
        style={{
          height : width > 991 && showMore ? (show ? '48px' : "auto") : "auto",
          whiteSpace: 'pre-line'
        }}
        >
        {item.description}
        </label>
      </MDBRow>
      {/* { width > 991 && showMore && (
              <MDBRow>
                <p className="_label --see-more" onClick={() => setShow(!show)}>
                  {show ? 'Read More' : 'Hide'}
                </p>
              </MDBRow>
            )} */}
        <MDBRow className='mb-2 mt-1' style={{alignItems : "center"}}>
          <MDBCol className='col-2 text-center p-0' style={{justifyContent : "center" , display : "flex"}}>
            <div style={{height :width > 991 ? "25px" : "" , maxHeight :width > 991 ? "25px" : "26.23px",width :width > 991 ? "25px" : "100%" , borderRadius : "50%"}}> 
              <img src={item?.upload_by_img?.[0]?.url ? item?.upload_by_img?.[0]?.url : LogoD} alt={"avatar"} style={{objectFit : "cover" , borderRadius : "50%" , height : "100%" , width : "100%"}} onClick={() => {
                openWhatsapp()
              }}/>
            </div>
          </MDBCol>
          <MDBCol className='col-10'>
            <motion.div
            variants={{
              show : {
                opacity : 1 ,
                transition : {
                  duration : 0.5,
                  delay : 0.1
                }
              },
              hidden : {
                opacity : 0 ,
                transition : {
                  duration : 0.1 
                }
              }
            }}
            initial="show"
            // animate={selectedVideo?.id === item.id ? "hidden" : "show"}
            >
          <label className='_label --username'>
            {item.upload_by ? item.upload_by : "D-concept"}
        </label>
            </motion.div>
          </MDBCol>
        </MDBRow>
    </MDBContainer>
    </motion.div>
   </MDBCol>
  )
}
