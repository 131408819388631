import { useState } from 'react'

//lib
import {
  MDBCol,
  MDBContainer,
  MDBRow,
} from 'mdb-react-ui-kit'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { motion ,AnimatePresence} from 'framer-motion'

//component
import SwiperItem from './swiper.item'
import VideCard from './video.card'

//redux
import { useSelector } from 'react-redux'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'
import { toast } from 'react-toastify'

//image
import ch from '../../../assets/images/homepage/china.png'
import uk from '../../../assets/images/homepage/uk.png'
import mal from '../../../assets/images/homepage/malaysia.png'

export default function WhatWeDo() {
  const { what_we_do_listing , isDarkMode } = useSelector((state) => state.home)
  const [videoActive, setVideoActive] = useState(0)
  const { width , height } = useWindowDimensions()

  const [swiperInstance, setSwiperInstance] = useState(null)
  const [selectedLanguage, setSelectedLanguage] = useState('en')
  // const [unavailableLanguages, setUnavailableLanguages] = useState([])

  const handleSwiperInstance = (item) => {
    setSwiperInstance(item)
  }

  const handleLanguageChange = (language, index) => {
    // setSelectedLanguage(language)
    const currentItem = what_we_do_listing[index]

    if (currentItem.video[language] === 'comming soon') {
      toast.warning('Language currently unavailable. Coming soon...')
      setSelectedLanguage('en')
    } else {
      setSelectedLanguage(language)
    }
  }

  const getVideoForSelectedLanguage = (item) => {
    return item.video[selectedLanguage] || item.video['en']
  }

  const getPosterForSelectedLanguage = (item) => {
    return item.poster[selectedLanguage] || item.poster['en']
  }

  return (
    <motion.div
    // initial={{opacity : 0 , transform : "translateY(100px)"}}
    // animate={{opacity : 1 , transform : "translateY(0)" ,  transition: {
    //   duration: 0.45,
    //   delay: 1,
    //   type: "spring", 
    // },}}
    >
    <MDBContainer className="what-we-do-container">
      <MDBRow className="main-title-row">
        <label className="_title-special-text">
          D - <span className="_titlebig" style={{color : isDarkMode === false &&height > 270  && width >= 653 && width < 992 ?  "#d1d5db" :  isDarkMode === false &&height < 270  && width >= 653  ?  "#24262d" : ""}}> WHAT WE DO</span>
        </label>
      </MDBRow>

      <Swiper
        className="mySwiper"
        noSwiping={true}
        allowTouchMove={width >= 991 ? false : true}
        onSlideChange={(e) => setVideoActive(e.realIndex)}>
        <SwiperItem handleSwiperInstance={handleSwiperInstance} />
        {what_we_do_listing.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              {/* <div className="language-dropdown-container">
                <MDBDropdown className="language-dropdown">
                  <MDBDropdownToggle color="dark">Language</MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem onClick={() => handleLanguageChange('en', index)}>
                      English
                    </MDBDropdownItem>
                    <MDBDropdownItem onClick={() => handleLanguageChange('zh', index)}>
                      Chinese
                    </MDBDropdownItem>
                    <MDBDropdownItem onClick={() => handleLanguageChange('my', index)}>
                      Malay
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </div> */}
              <div className="language-buttons-container">
                <button
                  className={`language-button ${selectedLanguage === 'en' ? 'selected' : ''}`}
                  onClick={() => handleLanguageChange('en', index)}
                  style={{ top: '1em', right: '1em' }}>
                  {/* <img src={uk} alt="English Flag" /> */}
                  EN
                </button>
                <button
                  className={`language-button ${selectedLanguage === 'zh' ? 'selected' : ''}`}
                  onClick={() => handleLanguageChange('zh', index)}
                  style={{ top: '3.5em', right: '1em' }}>
                  {/* <img src={ch} alt="Chinese Flag" /> */}
                  CN
                </button>
                <button
                  className={`language-button ${selectedLanguage === 'my' ? 'selected' : ''}`}
                  onClick={() => handleLanguageChange('my', index)}
                  style={{ top: '6em', right: '1em' }}>
                  {/* <img src={mal} alt="Malay Flag" /> */}
                  BM
                </button>
              </div>
              <VideCard
                videoActive={videoActive}
                // poster={item.poster}
                // video={item.video}
                poster={getPosterForSelectedLanguage(item) ?? item.poster}
                video={getVideoForSelectedLanguage(item) ?? item.video}
                index={index}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>

      <MDBRow className="tab-row">
        {what_we_do_listing.map((item, index) => (
          <MDBCol
            key={index}
            className="text-center"
            onClick={() => {
              swiperInstance.slideTo(index, 500)
              setVideoActive(index)
            }}
            style={{ cursor: 'pointer' }}>
            <label className="tab-label" style={{color : isDarkMode === false && width >= 653 && width < 992 && height > 544 || isDarkMode === false && width >= 991 && height > 752 ? "white" : ""}}>
              {item.label}
              {/* {width >= 991 && ( */}
              <div
                className={
                  videoActive === index ? 'line-container --yellow' : 'line-container'
                }></div>
              {/* )} */}
            </label>
            {/* {width < 991 && ( */}
            {/* <label className="radio-button">
              <MDBRadio checked={videoActive === index} disabled={true} />
            </label> */}
            {/* )} */}
          </MDBCol>
        ))}
      </MDBRow>
    </MDBContainer>
    </motion.div>
  )
}
