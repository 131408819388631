import React from 'react'
import { ThreeDots } from 'react-loader-spinner'

const Loader = ({ style }) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...style,
      }}>
      <ThreeDots color="#c90000" height="55" width="55" />
    </div>
  )
}
export default Loader
