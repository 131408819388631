//hook
import useWindowDimensions from "../../../components/common/window-dimension";

//lib
import { MDBContainer, MDBRow } from "mdb-react-ui-kit";
import Lottie from 'lottie-react'

//assets
import DiscoveryNoData from '../../../assets/json/discovery-no-data.json'
import DiscoveryNoDataLight from '../../../assets/json/discovery-nodata-white.json'


//redux 
import { useSelector } from "react-redux";

export default function VideoView() {
  const { width } = useWindowDimensions();
  const { isDarkMode } = useSelector((state) => state.home)
  const animationStyle = {
    width: width >= 991 ? '20%' : '60%',
  }

  return (
    <MDBContainer>
      <MDBRow
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          marginTop: '6em',
          marginLeft: '0',
          marginRight: '0',
          color: '#D1D5DB',
          fontFamily: 'cabin-regular',
        }}>
        <label
          style={{
            fontFamily: 'inter-regular',
            fontSize: '22px',
            fontWeight: '600',
            color: '#33363b',
            fontStyle: 'italic',
            textTransform: 'capitalize',
          }}>
            No data available
        </label>
        {isDarkMode ?  
        <Lottie animationData={DiscoveryNoData} style={animationStyle} />
        : 
        <Lottie animationData={DiscoveryNoDataLight} style={animationStyle} />
        }
      </MDBRow>
    </MDBContainer>
  );
}
