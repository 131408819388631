import { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import Lottie from 'lottie-react'
import { Skeleton } from 'primereact/skeleton'
import ReactPixel from 'react-facebook-pixel'

//hook
import useWindowDimensions from '../../components/common/window-dimension'

//component
import FooterOne from '../../components/footer/footer-one'
import Header from '../../components/header/header-main'
import HeaderSecondary from '../../components/header/header-secondary'
import Banner from './component/banner'
import CarListing from './component/car.listing'

//assets
import DiscoveryNoData from '../../assets/json/discovery-no-data.json'

//redux
import { getCarPost, setLoading } from '../../redux/reducer/brandReducer'
import { useDispatch, useSelector } from 'react-redux'
export default function BrandInfo() {
  const { id } = useParams()
  const { state } = useLocation()
  const { width } = useWindowDimensions()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const animationStyle = {
    width: width >= 991 ? '20%' : '60%',
  }
  const [current, setCurrent] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const start = 0
  const length = 10
  const [totalRecord, setTotalRecord] = useState(0)
  const [bodyType, setBodyType] = useState({
    Convertible: 0,
    Coupe: 0,
    Hatchback: 0,
    MPV: 0,
    Sedan: 0,
    SUV: 0,
    Wagon: 0,
  })
  const [nextBodyType, setNextBodyType] = useState({})
  const { chooseBrand } = useSelector((state) => state.car_compare)
  const { loading } = useSelector((state) => state.brand)
  const [data, setData] = useState([])
  const [brandData, setBrandData] = useState({})
  const selectedItems = JSON.parse(localStorage.getItem('selectedItems')) || []

  let currentLength = 0

  const getListing = async ({ total_record = 0, body_type = '', start = '', length = '' }) => {
    dispatch(
      getCarPost({
        search: id,
        start: start,
        length: length,
        body_type: body_type,
      }),
    ).then((res) => {
      setData((oldState) => [...oldState, ...res.payload.data.aaData])

      setCurrent((prevState) => {
        const newState = prevState + res.payload.data.aaData.length
        if (newState >= total_record) {
          setHasMore(false)
        }
        return newState
      })
      currentLength = currentLength + res.payload.data.aaData.length
      if (currentLength >= 10) {
        dispatch(setLoading(false))
      } else if (currentLength >= total_record) {
        dispatch(setLoading(false))
      }
    })
  }

  const calculateObjectBodyType = (obj) => {
    const targetSum = 10
    let currentSum = 0

    const selectedObject = {}
    const remainingObject = {}

    for (const [key, value] of Object.entries(obj)) {
      if (currentSum < targetSum) {
        const { count, skip } = value
        const remaining = targetSum - currentSum
        const selectedCount = Math.min(count, remaining)
        currentSum += selectedCount
        selectedObject[key] = { ...value, count: selectedCount }
        if (selectedCount < count) {
          remainingObject[key] = {
            count: count - remaining,
            skip: skip === undefined ? selectedCount : skip + selectedCount,
            separate: true,
          }
        }
      } else {
        remainingObject[key] = value
      }
    }
    setNextBodyType(remainingObject)
    return { selectedObject, remainingObject }
  }

  const getFirstEntries = () => {
    dispatch(
      getCarPost({
        search: id,
        start: start,
        length: length,
      }),
    ).then((res) => {
      if (res.payload.data.iFilterRecords === 0) {
        dispatch(setLoading(false))
      }
      setBrandData(res.payload.data.brandData[0])
      setTotalRecord(res.payload.data.iFilterRecords)
      const updatedObject = Object.keys({
        Convertible: res.payload.data.brandCount?.Convertible,
        Coupe: res.payload.data.brandCount?.Coupe,
        Hatchback: res.payload.data.brandCount?.Hatchback,
        MPV: res.payload.data.brandCount?.MPV,
        Sedan: res.payload.data.brandCount?.Sedan,
        SUV: res.payload.data.brandCount?.SUV,
        Wagon: res.payload.data.brandCount?.Wagon,
      }).reduce((result, key) => {
        result[key] = {
          count: res.payload.data.brandCount[key] ? res.payload.data.brandCount[key] : 0,
          separate: false,
        }
        return result
      }, {})
      setBodyType({
        Convertible: res.payload.data.brandCount?.Convertible
          ? res.payload.data.brandCount.Convertible
          : 0,
        Coupe: res.payload.data.brandCount.Coupe ? res.payload.data.brandCount?.Coupe : 0,
        Hatchback: res.payload.data.brandCount?.Hatchback
          ? res.payload.data.brandCount.Hatchback
          : 0,
        MPV: res.payload.data.brandCount?.MPV ? res.payload.data.brandCount.MPV : 0,
        Sedan: res.payload.data.brandCount?.Sedan ? res.payload.data.brandCount.Sedan : 0,
        SUV: res.payload.data.brandCount?.SUV ? res.payload.data.brandCount.SUV : 0,
        Wagon: res.payload.data.brandCount?.Wagon ? res.payload.data.brandCount.Wagon : 0,
      })

      const { selectedObject } = calculateObjectBodyType(updatedObject)
      for (var key in selectedObject) {
        if (key && selectedObject[key].count > 0) {
          getListing({
            start: start,
            length: selectedObject[key].count,
            body_type: key,
            total_record: res.payload.data.iFilterRecords,
          })
        }
      }
    })
  }

  const fetchMoreData = () => {
    const { selectedObject } = calculateObjectBodyType(nextBodyType)
    for (var key in selectedObject) {
      if (key && selectedObject[key].count > 0) {
        getListing({
          start: selectedObject[key].separate ? selectedObject[key].skip : start,
          length: selectedObject[key].separate ? length : selectedObject[key].count,
          body_type: key,
          total_record: totalRecord,
        })
      }
    }
  }

  useEffect(() => {
    if (brandData?.brand_status === 'inactive' && selectedItems) {
      const updatedItems = selectedItems?.filter((item) => item !== brandData.brand_id)
      localStorage.setItem('selectedItems', JSON.stringify(updatedItems))
    }
  }, [selectedItems, brandData])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [window.location.pathname])

  const FixOptionHeader = () => {
    const carIcon = document.getElementById('car-scroll-top')
    const scrolled = document.documentElement.scrollTop

    if (scrolled > 287 && carIcon) {
      carIcon.style.display = 'block'
    } else if (scrolled < 800 && carIcon) {
      carIcon.style.display = 'none'
    }
  }
  window.addEventListener('scroll', FixOptionHeader)

  useEffect(() => {
    // getPostListing()
    getFirstEntries()
  }, [])

  useEffect(() => {
    if (brandData != {})
      ReactPixel.trackCustom('InterestedBrandEvent', {
        brand_name: brandData?.brand_name,
        id: brandData?.id,
        direct_import: brandData?.direct_import,
      })
  }, [brandData])

  if (loading) {
    return (
      <MDBContainer className="common-main-container" breakpoint="none">
        {width >= 991 && <Header />}
        <div className="content-container brand-page-container">
          <HeaderSecondary
            className="header-secondary-container"
            handleClick={() => {
              if (chooseBrand) {
                navigate(`/calculator?tab=compare`)
              } else {
                navigate('/')
                localStorage.removeItem('brand')
              }
            }}
            title={<label className="_label --modal-title">Select Model</label>}
          />
          <MDBContainer className="line-container --banner-line"></MDBContainer>
          <MDBContainer className="video-banner-container mb-3 p-3">
            <div className="video-responsive" style={{ borderRadius: 0 }}>
              <Skeleton
                className="mt-3"
                width="100%"
                height="360px"
                style={{
                  borderRadius: 0,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
              />
            </div>
          </MDBContainer>
        </div>
        <MDBContainer className="car-listing-container" breakpoint="sm">
          <MDBRow className="m-0">
            {Array.from({ length: 3 }, (_, index) => (
              <MDBCol
                className="car-item-container col-12 col-md-6 col-lg-4"
                breakpoint="none"
                key={index}>
                <MDBRow className="car-item-box">
                  <MDBCol className="col-5 p-1">
                    <Skeleton width="100%" height="100px" borderRadius="5px" />
                  </MDBCol>
                  <MDBCol className="col-7 p-3">
                    <Skeleton width="100%" height="15px" borderRadius="5px" />
                    <Skeleton width="100%" height="15px" borderRadius="5px" className="mt-3" />
                    <Skeleton width="50%" height="15px" borderRadius="5px" className="mt-3" />
                    <div className="mt-3 d-flex">
                      <Skeleton
                        width="40%"
                        height="15px"
                        borderRadius="5px"
                        //className="mt-3"
                      />
                      <Skeleton width="40%" height="15px" borderRadius="5px" className="ms-3" />
                    </div>
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            ))}
          </MDBRow>
        </MDBContainer>
      </MDBContainer>
    )
  }

  return (
    <MDBContainer className="common-main-container" breakpoint="none">
      {brandData?.brand_status === 'inactive' ? (
        <MDBContainer
          className="common-main-container"
          breakpoint="none"
          style={{ background: '#1E1F21' }}>
          <Header />
          <MDBContainer
            style={{
              paddingTop: width >= 991 ? '10em' : '0rem',
              paddingLeft: '0em',
              paddingRight: '0em',
            }}>
            <MDBRow
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                marginTop: '6em',
                marginLeft: '0',
                marginRight: '0',
                marginBottom: '10em',
                color: '#D1D5DB',
                fontFamily: 'cabin-regular',
              }}>
              <label
                style={{
                  fontFamily: 'inter-regular',
                  fontSize: '22px',
                  fontWeight: '600',
                  color: '#33363b',
                  fontStyle: 'italic',
                  textTransform: 'capitalize',
                }}>
                Invalid Car Brand
              </label>
              <Lottie animationData={DiscoveryNoData} style={animationStyle} />
            </MDBRow>
          </MDBContainer>
          <FooterOne />
        </MDBContainer>
      ) : brandData?.brand_status === 'active' ? (
        <>
          {width >= 991 && <Header />}
          <div className="content-container brand-page-container">
            <HeaderSecondary
              className="header-secondary-container"
              handleClick={() => {
                if (chooseBrand) {
                  navigate(`/calculator?tab=compare`)
                } else {
                  navigate('/')
                  localStorage.removeItem('brand')
                }
              }}
              title={<label className="_label --modal-title">Select Model</label>}
            />
            <MDBContainer className="line-container --banner-line"></MDBContainer>
            <Banner embedId={brandData?.brand_link?.split('/').pop()} />
            <CarListing
              data={data}
              bodyType={bodyType}
              hasMore={hasMore}
              state={state}
              brandData={brandData}
              loading={loading}
              fetchMoreData={fetchMoreData}
            />
          </div>
        </>
      ) : (
        ''
      )}
      <div
        onClick={() => {
          window.scrollTo(0, 0)
        }}
        id="car-scroll-top"
        className="car-scroll-top"
        style={{
          position: 'fixed',
          bottom: '2.5em',
          right: '2.5em',
          display: 'none',
          zIndex: 999,
        }}>
        <div className="car-container">
          <LazyLoadImage
            width={width >= 991 ? 'auto' : 50}
            src={require('../../assets/images/icon/car.webp')}
            alt="..."
          />
          <LazyLoadImage
            width={width >= 991 ? 'auto' : 50}
            className="car-focus"
            src={require('../../assets/images/icon/car-focus.webp')}
            alt="..."
          />
        </div>
      </div>
    </MDBContainer>
  )
}
