import React, { useEffect, useState } from 'react';
import { MDBScrollspy, MDBScrollspyLink } from 'mdb-react-ui-kit';

export default function TandCScroll({ containerRef, section1, section2 }) {
  const [activeSection, setActiveSection] = useState(null);

  useEffect(() => {
    function handleScroll() {
      const section1Top = section1.current.offsetTop;
      const section2Top = section2.current.offsetTop;
      const scrollPosition = window.scrollY;
      const windowHeight = window.innerHeight;

      if (scrollPosition >= section1Top - windowHeight * 0.15 && scrollPosition < section2Top - windowHeight * 0.15) {
        setActiveSection(1);
      } else if (scrollPosition >= section2Top - windowHeight * 0.15) {
        setActiveSection(2);
      } else {
        setActiveSection(null);
      }
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [section1, section2]);

  return (
    <MDBScrollspy container={containerRef} className="scrollspy-container mt-2">
      <MDBScrollspyLink targetRef={section1} className={activeSection !== 2 ? 'navLink-active' : 'navLink'}>
        1. Terms and Conditions
      </MDBScrollspyLink>
      <MDBScrollspyLink targetRef={section2} className={activeSection === 2 ? 'navLink-active' : 'navLink'}>
        2. Privacy Policy
      </MDBScrollspyLink>
    </MDBScrollspy>
  );
}
