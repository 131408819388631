import { useState } from 'react'

//lib
import {
  MDBCardImage,
} from 'mdb-react-ui-kit'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Pagination, Navigation } from "swiper";
import LazyImage from '../../../components/common/common.image';
//redux
import { useSelector } from 'react-redux'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'



export default function StockImage({data}) {
  const { width } = useWindowDimensions()


  return (
   <Swiper
   slidesPerView={1}
   spaceBetween={0}
   grabCursor={true}
   loop={true}
  //  pagination={{
  //   type: 'fraction',
  // }}
  pagination
   navigation={width > 991 ? true : false}
   modules={[Navigation , Pagination]}
  >
    {data.length > 0 && data.map((item , index) => {
         if (item.collection_type !== "ready_stock_image" && item.collection_type !== "exterior_image") {
          return null;
        }
        return (
            <SwiperSlide className="section-top" key={index}>
            <LazyImage 
               item={item}
               src={item.url}
               alt="ready-stock-car-img"
               style={{height : "100%" , width : "100%" , objectFit : "cover"} }
            />
            </SwiperSlide>
        )
    })}
   </Swiper>
  )
}
