import { createSlice ,createAsyncThunk} from "@reduxjs/toolkit";
import apiServices from '../../services/api.services'


const initialState = {
  videoList : [
    // {
    //     video : "https://youtu.be/2T7bev7x38Q?si=OMu-JrQFM1YxJrfp",
    //     title : "今天带你来看看我平时买进口车的 Showroom ！！"
    // },
    // {
    //     video : "https://youtu.be/HSIAyWixh8o?si=J3pknlj_46KFZEyS",
    //     title : "今天带你来参观神车 Showroom !!!! ( Pagani UK )"
    // },
    // {
    //     video : "https://youtu.be/nE0XKXa8LbY?si=H2zGaATvGyeAY7si",
    //     title : "地表最强 BMW M4 ，竟然在我的眼前出现 ！！"
    // },
  ],

  data : [] ,
  hasMore : false,
  inIntialLoading : false , 
  loading : false,
  selectedVideo : {},

  start : 0,
  search : "" , 
  length : 8 , 
  videoNumber : null ,
}

export const getVideos = createAsyncThunk("get/videos/api", async (payload) => {
    const response = await apiServices.getVideos2(payload);
    return response;
});

const discoverSlice = createSlice({
    name: "discover",
    initialState,
    reducers: {
          setData: (state,action) => {
            state.data = action.payload
          },
          setHasMore: (state,action) => {
            state.hasMore = action.payload
          },
          setInitialLoading: (state,action) => {
            state.inIntialLoading = action.payload
          },
           setStart: (state,action) => {
            state.start = action.payload
          },
          setSearch: (state,action) => {
            state.search = action.payload
          },
            setLength: (state,action) => {
            state.length = action.payload
          },
          setVideoNumber: (state,action) => {
            state.videoNumber = action.payload
          },
          setSelectedVideo: (state,action) => {
            state.selectedVideo = action.payload
          },
          
    },
    extraReducers : {
        [getVideos.pending]: (state, action) => {
            state.loading = true
          },
          [getVideos.fulfilled]: (state, action) => {
            state.loading = false
            state.videoList = action.payload
          },
    }
})

export const {
    setData , setHasMore , setInitialLoading , setSearch , setStart , setLength , setVideoNumber , setSelectedVideo
} = discoverSlice.actions
const { reducer } = discoverSlice
export default reducer;