//lib
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { debounce } from 'lodash'

//redux
import { useSelector, useDispatch } from 'react-redux'
import {
  setShowBodyTypeList,
  setCarBodyTypeFilter,
  setShowMobileFilterList,
  setCarPriceFilter,
  setCarYearFilter,
  setDisplayBrandModelFilter,
  setDisplayBrandModelData,
  setCarBrandFilter,
  setBrandFilterData,
  setCarModelFilter,
  setModelFilterData,
  setPriceFilterData,
  setYearFilterData,
  updateDisplayText,
  setBodyTypeFilterData,
} from '../../redux/reducer/stockReducer'
import { Icon } from '@iconify/react'
import { MDBContainer } from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react'
import useWindowDimensions from '../common/window-dimension'
import { BodyTypeList } from './bodyTypeList'
import { BrandList } from './brandList'
import { PriceList } from './priceList'
import YearPickerList from './yearPicker'
import { useSearchParams } from 'react-router-dom'

export const MobileFilterList = ({
  show,
  setShow,
  type,
  display,
  top,
  backFirstPage,
  startZero,
  tempModelFilterData,
  setTempModelFilterData,
  tempBrandFilterData,
  setTempBrandFilterData,
}) => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const { isDarkMode } = useSelector((state) => state.home)

  const {
    carBrandFilter,
    carModelFilter,
    carPriceFilter,
    priceFilterData,
    carYearFilter,
    yearFilterData,
    carBodyTypeFilter,
    bodyTypeFilterData,
    availableBodyTypes,
    carBodyTypeListing,
    showBodyTypeList,
    showMobileFilterList,
    displayBrandModelFilter,
    displayBrandModelData,
    brandFilterData,
    modelFilterData,
    displayText,
    originalCarBrands,
  } = useSelector((state) => state.stock)
  const [chooseMenu, setChooseMenu] = useState('brand_model')
  const [ searchParams, setSearchParams ] = useSearchParams()

  const availableCarBodyTypes = carBodyTypeListing.filter((bodyType) =>
    availableBodyTypes.includes(bodyType.title),
  )

  const filterMenuList = [
    { label: 'Brand & Model', value: 'brand_model' },
    { label: 'Body Type', value: 'body_type' },
    { label: 'Price', value: 'price' },
    { label: 'Mfg. Year', value: 'year' },
  ]

  const handleToggleOverlay = () => {
    dispatch(setShowBodyTypeList(!showBodyTypeList))
    document.body.style.overflow = 'auto'
  }

  const handleShowToggleOverlay = () => {
    setShow(!show)
    document.body.style.overflow = 'auto'
  }

  const handleOverflowChange = debounce((newShowValue) => {
    document.body.style.overflow = newShowValue ? 'hidden' : 'auto'
  }, 100)

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (type === 'non-fixed') {
      handleOverflowChange(showBodyTypeList)
    } else if (type === 'fixed') {
      handleOverflowChange(show)
    } else {
      handleOverflowChange(showBodyTypeList)
    }
  }, [showBodyTypeList, show])

  return (
    <>
      <Modal
        show={showMobileFilterList}
        onHide={() => {
          dispatch(setShowMobileFilterList(false))
        }}
        keyboard={false}
        centered
        fullscreen
        className={isDarkMode ? "brandlist-modal-container-dark" :  "brandlist-modal-container-light"}>
        <Modal.Header className="brandlist-modal-header --filter">
          <div className="d-flex" style={{ justifyContent: 'space-between' }}>
            <div
              onClick={() => {
                setTempModelFilterData([])
                setTempBrandFilterData([])
                dispatch(setCarBrandFilter([]))
                dispatch(setCarModelFilter([]))
                dispatch(setCarPriceFilter([50000, 5000000]))
                dispatch(setPriceFilterData([50000, 5000000]))
                dispatch(setCarYearFilter([1990, currentYear]))
                dispatch(setYearFilterData([1990, currentYear]))
                dispatch(setCarBodyTypeFilter([]))
                dispatch(setBodyTypeFilterData([]))

                dispatch(setModelFilterData([]))
                dispatch(setDisplayBrandModelData([]))
                dispatch(setDisplayBrandModelFilter([]))
                dispatch(setBrandFilterData([]))

                dispatch(updateDisplayText([]))

                dispatch(setShowMobileFilterList(false))
              }}
              className="_label --clear"
              style={{ cursor: 'pointer' }}>
              Clear Filter
            </div>
            <div className="_label --filter">Filter</div>
            <div
              className="brandlist-header-closeBtn --filter"
              onClick={() => {
                dispatch(setShowMobileFilterList(false))
              }}>
              <FontAwesomeIcon icon={solid('times')}></FontAwesomeIcon>
            </div>
          </div>

          {carBrandFilter.length !== 0 ||
          carBodyTypeFilter.length !== 0 ||
          carPriceFilter[0] !== 50000 ||
          carPriceFilter[1] !== 5000000 ||
          carYearFilter[0] !== 1990 ||
          carYearFilter[1] !== currentYear ||
          displayText.length !== 0 ? (
            <div className="d-flex mt-2">
              <div className="d-flex tag-row">
                <div className="d-flex tag-row-scroll">
                  {displayText &&
                    displayText.map((brand, brandIndex) => (
                      <div className="ms-2 tag-div" key={brandIndex}>
                        {brand}
                      </div>
                    ))}
                  {carPriceFilter && (carPriceFilter[0] !== 50000 || carPriceFilter[1] !== 5000000) && (
                    <div className="ms-2 tag-div">
                      {'Budget Pricing RM' + carPriceFilter[0]?.toLocaleString()} -{' '}
                      {'RM' + carPriceFilter[1]?.toLocaleString()}
                    </div>
                  )}
                  {carYearFilter && (carYearFilter[0] !== 1990 || carYearFilter[1] !== currentYear) && (
                    <div className="ms-2 tag-div">
                      {carYearFilter[0]} - {carYearFilter[1]}
                    </div>
                  )}
                  {carBodyTypeFilter &&
                    carBodyTypeFilter.map((bodyType, bodyTypeIndex) => (
                      <div className="ms-2 tag-div" key={bodyTypeIndex}>
                        {bodyType}
                      </div>
                  ))}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </Modal.Header>
        <Modal.Body className="brandlist-modal-body --footer">
          <div className="two-column-grid">
            <div className="filter-menu">
              {filterMenuList.map((menu, menuIndex) => (
                <div
                  key={menuIndex}
                  className={chooseMenu == menu.value ? 'filter-block --active' : 'filter-block'}
                  onClick={() => {
                    setChooseMenu(menu.value)
                  }}>
                  {menu.label}
                </div>
              ))}
            </div>
            <div className="filter-content">
              {chooseMenu == 'body_type' ? (
                <BodyTypeList />
              ) : chooseMenu == 'brand_model' ? (
                <BrandList
                  backFirstPage={backFirstPage}
                  startZero={startZero}
                  tempModelFilterData={tempModelFilterData}
                  setTempModelFilterData={setTempModelFilterData}
                  tempBrandFilterData={tempBrandFilterData}
                  setTempBrandFilterData={setTempBrandFilterData}
                  optionStep={"checkbox"}
                />
              ) : chooseMenu == 'price' ? (
                <PriceList />
              ) : chooseMenu == 'year' ? (
                <YearPickerList />
              ) : (
                ''
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="brandlist-modal-footer">
          {/* <div
            className="d-flex align-items-center"
            onClick={() => {
              setBodyTypeData('')
              dispatch(setCarBodyTypeFilter(''))
            }}>
            Clear
          </div> */}
          <div
            className="darkTheme-btn reset-btn --all"
            onClick={() => {
              backFirstPage(1)
              startZero(0)
              setSearchParams({ page: '1' })
              
              dispatch(setCarBrandFilter(tempBrandFilterData))
              dispatch(setCarModelFilter(tempModelFilterData))
              if((priceFilterData[0] < 50000 || priceFilterData[0] > 5000000) && (priceFilterData[1] < 50000 || priceFilterData[1] > 5000000)) {
                dispatch(setCarPriceFilter([50000, 5000000]))
              } else if (priceFilterData[0] < 50000 || priceFilterData[0] > 5000000) {
                dispatch(setCarPriceFilter([50000, priceFilterData[1]]))
              } else if (priceFilterData[1] < 50000 || priceFilterData[1] > 5000000) {
                dispatch(setCarPriceFilter([priceFilterData[0], 5000000]))
              } else {
                dispatch(setCarPriceFilter(priceFilterData))
              }

              if((yearFilterData[0] < 1990 || yearFilterData[0] > currentYear) && (yearFilterData[1] < 1990 || yearFilterData[1] > currentYear)) {
                dispatch(setCarYearFilter([1990, currentYear]))
              } else if (yearFilterData[0] < 1990 || yearFilterData[0] > currentYear) {
                dispatch(setCarYearFilter([1990, yearFilterData[1]]))
              } else if (yearFilterData[1] < 1990 || yearFilterData[1] > currentYear) {
                dispatch(setCarYearFilter([yearFilterData[0], currentYear]))
              } else {
                dispatch(setCarYearFilter(yearFilterData))
              }
              //dispatch(setCarYearFilter(yearFilterData))
              dispatch(setCarBodyTypeFilter(bodyTypeFilterData))
              dispatch(setShowMobileFilterList(false))

              dispatch(setModelFilterData(tempModelFilterData))
              dispatch(setBrandFilterData(tempBrandFilterData))

              const updatedDisplayText = getUpdatedDisplayText(
                tempModelFilterData,
                tempBrandFilterData,
                originalCarBrands,
              )
              dispatch(updateDisplayText(updatedDisplayText))
              function getUpdatedDisplayText(updatedModelFilter, updatedBrandFilter, brands) {
                const updatedDisplayText = []
                for (const brandName of updatedBrandFilter) {
                  const brand = brands.find((brand) => brand.brand_name === brandName)
                  if (brand) {
                    const brandPosts = brand.car_posts.filter((post) =>
                      updatedModelFilter.includes(post),
                    )
                    if (brandPosts.length === brand.car_posts.length) {
                      updatedDisplayText.push(brandName)
                    } else if (brandPosts.length > 0) {
                      updatedDisplayText.push(...brandPosts.map((post) => `${brandName} ${post}`))
                    }
                  }
                }
                return updatedDisplayText
              }
              dispatch(setDisplayBrandModelFilter(displayBrandModelData))
            }}>
            Apply
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}