import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiServices from "../../services/api.services";

export const getCarPostInfo = createAsyncThunk("get/car_post_info", async (payload) => {
    const response = await apiServices.getCarPostInfo(payload);
    return response.data;
});

const initialState = {
    contact_agent_modal: false,
    selected_tab: "quick_look",
    id: "BMW",
    brand: "Mercedes",
    model: "SUV",
    type: "GLA300",
    icon: "",
    price: 230000,
    like: 1500,
    tag: ["D-Import","D-Stock","Direct Owner"],
    asset:[{
        icon: "",
        label: "Body",
        detail: "SUV"
    },{
        icon: "",
        label: "Seat",
        detail: "5"
    },{
        icon: "",
        label: "Fuel Type",
        detail: "Petrol"
    },{
        icon: "",
        label: "Brand",
        detail: "Merchedes"
    },{
        icon: "",
        label: "Model",
        detail: "GLA 200"
    },{
        icon: "",
        label: "Model Year",
        detail: "2018"
    }],
    
    owner: [],
    quick_look_main: {},
    quick_look: {},
    selectedPost: [],
    openModal: false
};

const carPostReducer = createSlice({
  name: "carPost",
  initialState,
  reducers: {
    openShareModal: (state,action) => {
        state.openModal = action.payload
    },
    setSelectedPost: (state, action) => {
        state.selectedPost = action.payload
    },
    setContactAgent: (state,action) => {
        state.contact_agent_modal = action.payload
    },
    setSelectedTab: (state,action) => {
        state.selected_tab = action.payload
    }
  },
  extraReducers: {
    [getCarPostInfo.pending]: (state, action) => {
        state.loading = true;
    },
    [getCarPostInfo.fulfilled]: (state, action) => {
        state.loading = false;
        state.quick_look_main = action.payload;

        state.quick_look = {
            spec_option: action.payload.spec_option,
            variarion: action.payload.variations,
            dimension: action.payload.dimension,
            engine_specifications: action.payload.engine_specification,
            transmission: action.payload.transmission,
            fuel_efficiency: action.payload.fuel_efficiency,
            safety_features: action.payload.safety_features, 
            interior_features: action.payload.interior_features,
            exterior_features: action.payload.exterior_features,
            medias: action.payload.medias,
        };

        state.stock = action.payload.stocks;
        state.owner = action.payload.directs;
    },
  }
});

export const {
    openShareModal,
    setContactAgent,
    setSelectedTab,
    setSelectedPost,
} = carPostReducer.actions
const { reducer } = carPostReducer;
export default reducer;
