import { useNavigate } from 'react-router-dom'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import { Icon } from '@iconify/react'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { setAllCarData, setSelectedId } from '../../../redux/reducer/carCompareReducer'
import { setSection } from '../../../redux/reducer/calculatorReducer'

//assets
import { IconToolbarCar } from '../../../assets/images'

export default function Toolbar({ setModalBox, loading }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const { isDarkMode } = useSelector((state) => state.home)
  const { quick_look_main } = useSelector((state) => state.carPost)

  return (
    <MDBContainer className="toolbar-container">
      <MDBRow className="toolbar-box" style={{ alignItems: 'center' }}>
        <MDBCol className="col-8 toolbar-box-columns p-0">
          {/* <label className="_label --grey-label">Approximately Starting Price</label> */}
          <label className="_label --grey-label">
            Car Type : {quick_look_main.car_bodies_name}
          </label>
          <label className="_label --pricing">{quick_look_main.car_model_name}</label>
          {/* <label className="_label --pricing">
            {width > 991
              ? `RM ${parseInt(quick_look_main.starting_price).toLocaleString()}`
              : quick_look_main.starting_price >= 1000000
              ? `RM ${(quick_look_main.starting_price / 1000000).toFixed(2)}M`
              : quick_look_main.starting_price >= 100000
              ? `RM ${quick_look_main.starting_price / 1000}K`
              : quick_look_main.starting_price}
          </label>
          <label className="_label --note-text">Note: this just an estimated start prices</label> */}
          <label className="_label --note-text">
            Note: model comes with a variety of specs, this is just one of the many.
          </label>
        </MDBCol>
        <MDBCol className="col-4 d-flex align-items-end justify-content-end p-0">
          <MDBRow className="m-0">
            {/* <div className="toolbar-right-section">
              <Icon icon="mdi:heart" color="#9091a0" width={30} height={30} />
              <label className="_label --tool-text">
                {formatNumber(quick_look_main.total_like)} Likes
              </label>
            </div> */}
            {/* <div
              className="toolbar-right-section"
              onClick={() => {
                navigate('/calculator?tab=compare')
                dispatch(setSection('compare'))
                dispatch(setSelectedId('1'))
                dispatch(setAllCarData(quick_look_main))
              }}>
              <LazyLoadImage src={IconToolbarCar} width={35} height={24} alt="compare-icon" />
              <label className="_label --tool-text pt-1">Compare</label>
            </div> */}
            <div
              className="toolbar-right-section"
              onClick={() => {
                //dispatch(openShareModal(true))
                setModalBox(true)
              }}>
              <Icon
                icon="fa-solid:share-alt"
                color={isDarkMode ? '#9091a0' : '#616469'}
                width={30}
                height={24}
                alt="share-icon"
              />
              <label className="_label --tool-text pt-1">Shares</label>
            </div>
          </MDBRow>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
