import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import apiServices from '../../services/api.services'

export const getCarPost = createAsyncThunk('getCarPost', async (payload, thunkAPI) => {
  try {
    const response = await apiServices.getCarPostApi(payload)
    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(error)
  }
})

const initialState = {
  postData: [],
  groupedData: [],
  carPostInfo: [],
  loading: true,
}

const brandReducer = createSlice({
  name: 'brandInfo',
  initialState,
  reducers: {
    setLoading: (state,action) => {
      state.loading = action.payload
    }
  },
  extraReducers: {
    [getCarPost.fulfilled]: (state, action) => {
      state.carPostInfo = action.payload

      const sortedData = action.payload.data.aaData.sort((a, b) => {
        if (a.car_bodies_name < b.car_bodies_name) return -1
        if (a.car_bodies_name > b.car_bodies_name) return 1
        return 0
      })

      const groupedData = sortedData.reduce((acc, item) => {
        const key = item.car_bodies_name.toLowerCase()
        if (!acc[key]) acc[key] = []
         acc[key].push(item)
        return acc
      }, {})

      state.groupedData = groupedData
    },
    [getCarPost.pending]: (state) => {
      state.groupedData = {}
    },
    [getCarPost.rejected]: (state, action) => {
      state.loading = false
    },
  },
})

export const { setLoading } = brandReducer.actions
const { reducer } = brandReducer
export default reducer
