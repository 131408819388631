//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//redux
import { useDispatch, useSelector } from 'react-redux'

//component
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { getRecommended } from '../../../redux/reducer/orderReducer'
import { useEffect, useState } from 'react'
import apiServices from '../../../services/api.services'
import { toast } from 'react-toastify'
import { Skeleton } from 'primereact/skeleton'
import LazyImage from '../../../components/common/common.image'

export default function Recommend() {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [recommendId, setRecommendId] = useState()
  const { recommendDetails, recommend_loading } = useSelector((state) => state.order)
  const { width } = useWindowDimensions()

  const formatCash = (num, digits = 2) => {
    var units = ['K', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'],
      decimal

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1)

      if (num <= -decimal || num >= decimal) {
        const number = +(num / decimal).toFixed(digits)
        if (units[i] === 'Y') {
          return number.toLocaleString() + units[i]
        }
        return number.toLocaleString() + units[i]
      }
    }

    return num
  }

  const handleSubmit = async ({ values, setFieldError, setFieldValue }) => {
    setLoading(true)
    try {
      const response = await apiServices.postRecommendContact({
        recommend_car_id: recommendId,
        mobile_number: values.mobile_number,
      })
      if (response) {
        setLoading(false)
        setFieldValue(values.mobile_number, '')
        toast.success('Request quote successful.', {
          autoClose: 3000,
          theme: 'dark',
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        })
      }
    } catch (ex) {
      setLoading(false)
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => setFieldError(item, errors[item]))
        }
      }
    }
  }

  useEffect(() => {
    dispatch(
      getRecommended({
        search: '',
        start: '',
        length: '',
      }),
    )
  }, [])

  const initialValues = {
    recommend_car_id: '',
    mobile_number: '',
  }

  const useSchema = Yup.object({
    mobile_number: Yup.number()
      .required('Phone number is required field')
      .typeError('Phone number must be a number'),
  })

  return (
    <MDBContainer className="recommend-container">
      <MDBRow className="main-title-row">
        <label className="_title-special-text">
          D - <span className="_title">RECOMMEND</span>
        </label>
      </MDBRow>
      {!recommend_loading ? (
        <div className="recommend-content">
          {recommendDetails.map((item, index) => (
            <MDBContainer className="recommend-box" key={index}>
              <MDBRow className="recommend-car">
                <MDBCol className="car-columns">
                  <div className="car-images">
                    <LazyImage src={item.image[0]?.url} item={item.image && item.image[0] ? item.image[0] : null} alt="Dconcept recommend car" width="150" />
                  </div>
                </MDBCol>
                <MDBCol>
                  <div className="car-details">
                    <label className="_label --title">{item.car_model_name}</label>
                    <label className="_label --grey-label">Estimate price range as below</label>
                  </div>
                </MDBCol>
              </MDBRow>
              <MDBRow className="recommend-price-section">
                <MDBCol className="price-details">
                  <label className="_label --gold-label">
                    RM{' '}
                    {width > 991
                      ? parseInt(item.lowers_quote).toLocaleString()
                      : formatCash(item.lowers_quote)}
                  </label>
                  <label className="_label --grey-label">Lowest Quote</label>
                </MDBCol>
                <MDBCol className="col-1">
                  <div className="_label --grey-label">+</div>
                </MDBCol>
                <MDBCol className="tag-details">
                  <label className="_label --grey-label">AP Fees</label>
                  <label className="_label --grey-label">Duty Tax</label>
                  <label className="_label --grey-label">MISC</label>
                </MDBCol>
                <MDBCol className="col-1">
                  <div className="_label --grey-label">=</div>
                </MDBCol>
                <MDBCol className="price-details">
                  <label className="_label --gold-label">
                    RM{' '}
                    {width > 991
                      ? parseInt(item.estimated_price).toLocaleString()
                      : formatCash(item.estimated_price)}
                  </label>
                  <label className="_label --grey-label">Estimated Price</label>
                </MDBCol>
              </MDBRow>
              <Formik
                initialValues={initialValues}
                validationSchema={useSchema}
                enableReinitialize={true}
                validateOnChange={true}
                // onSubmit={(values, { setFieldError, setFieldValue }) => {
                //   handleSubmit({ values, setFieldError, setFieldValue })
                // }}
              >
                {({ setFieldValue, values, errors, isSubmitting, isValid, setFieldError }) => (
                  <Form>
                    <MDBRow className="recommend-contact-input">
                      <MDBCol className="left-input-container">
                        <input
                          placeholder="Please fill in your contact"
                          maxLength={15}
                          name="mobile_number"
                          onChange={(e) => {
                            setFieldValue('mobile_number', e.target.value)
                          }}
                        />
                      </MDBCol>
                      <MDBCol className="right-button-container">
                        {/* <button
                          type="submit"
                          onClick={() => {
                            setRecommendId(item.id)
                          }}> */}
                        <label
                          onClick={() => {
                            if (!loading) {
                              handleSubmit({ values, setFieldError, setFieldValue })
                              setRecommendId(item.id)
                            }
                          }}>
                          Request Quote
                        </label>
                        {/* </button> */}
                      </MDBCol>
                    </MDBRow>
                    <MDBRow className="remark-container">
                      {errors && errors.mobile_number ? (
                        <div className="text-danger no-padding" style={{ fontSize: '0.8em' }}>
                          {errors.mobile_number}
                        </div>
                      ) : (
                        ''
                      )}
                      <label className="_label --remark">
                        *after click the request quote our d-concept agent will arrange an call with
                        you{' '}
                      </label>
                    </MDBRow>
                  </Form>
                )}
              </Formik>
            </MDBContainer>
          ))}
        </div>
      ) : (
        <>
          {width >= 500 ? (
            <>
              <div className="d-flex">
                <Skeleton width="10em" height="10em" className="mt-2 ms-4" borderRadius="100%" />
                <div>
                  <Skeleton width="20em" height="15px" className="mt-5 mb-2 ms-5" />
                  <Skeleton width="30em" height="15px" className="mt-4 mb-2 ms-5" />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <Skeleton width="80%" height="40px" className="mt-5 mb-2 p-3" borderRadius="1em" />
              </div>
            </>
          ) : (
            <>
              <div style={{ display: 'grid', gridTemplateColumns: '30% 70%' }}>
                <Skeleton width="100%" height="10em" className="mt-2 ms-4" borderRadius="100%" />
                <div>
                  <Skeleton width="15em" height="15px" className="mt-5 mb-2 ms-5" />
                  <Skeleton width="20em" height="15px" className="mt-4 mb-2 ms-5" />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <Skeleton width="80%" height="40px" className="mt-5 mb-2 p-3" borderRadius="1em" />
              </div>
            </>
          )}
        </>
      )}
    </MDBContainer>
  )
}
