import { useEffect } from 'react'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { getRoadtax, postRoadtax } from '../../../redux/reducer/calculatorReducer'
//component
import FormikForm from '../../../components/element/formik.form'
import Loader from '../../../components/element/loader'
import Input from '../../../components/element/input'

//helper
import { sweetAlertHelper } from '../../../helper/sweetalert.helper'

async function onSubmit(data, setFieldError, dispatch) {
  const value = {
    engine_cc: data.engine_cc.replace(/,/g, ''),
    car_type: data.car_type,
    region: data.region,
  }
  try {
    dispatch(postRoadtax(value))
    // sweetAlertHelper({
    //   title: 'Roadtax has calculated',
    //   text: 'Please refer to the result',
    //   icon: 'success',
    // })
  } catch (ex) {
    if (ex && ex.response.status === 422) {
      const errors = ex.response.data.errors

      if (errors && Object.keys(errors).length > 0) {
        Object.keys(errors).map((item, i) => {
          setFieldError(item, errors[item])
        })
      }
    }
  }
}

const RoadtaxForm = () => {
  const dispatch = useDispatch()
  const { roadtaxData, loading, roadtaxParam, roadtaxResult } = useSelector(
    (state) => state.calculator,
  )

  const date = new Date()
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const monthsOfYear = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const formattedDate = `${daysOfWeek[date.getDay()]}, ${date.getFullYear()} ${
    monthsOfYear[date.getMonth()]
  } ${date.getDate()}`

  useEffect(() => {
    dispatch(getRoadtax())
  }, [])

  const roadtaxSchema = Yup.object({
    engine_cc: Yup.string().required('Please enter engine cc'),
    region: Yup.string().required('Please select region'),
    car_type: Yup.string().required('Please select car type'),
  })

  return (
    <MDBContainer className="calculator-loan-form">
      <Formik
        initialValues={roadtaxData}
        enableReinitialize={true}
        validationSchema={roadtaxSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          onSubmit(values, setFieldError, dispatch)
          setSubmitting(false)
        }}>
        {({ setFieldValue, errors, isSubmitting, isValid }) => (
          <>
            {!loading ? (
              <Form className="formik-form">
                <MDBRow className="roadtax-remark">
                  <MDBRow className="roadtax-remark-content">
                    <label className="white">
                      Latest JPJ formula - calculate how much your vehicles' road tax will cost
                    </label>
                    <span className="font-highlight no-width">{formattedDate}</span>
                  </MDBRow>
                </MDBRow>
                <Input
                  name="engine_cc"
                  label="Engine CC Capacity"
                  type="text"
                  autoComplete="off"
                  readOnly={false}
                  className="className"
                  priceValue={true}
                />
                {/* {FormikForm.renderInput(
                  'engine_cc',
                  'Engine CC Capacity',
                  'number',
                  'off',
                  'className',
                  false,
                  errors,
                )} */}
                {FormikForm.renderSelect('region', '', roadtaxParam.region, errors)}
                {FormikForm.renderSelect('car_type', '', roadtaxParam.car_type, errors)}
                <MDBRow className="roadtax-remark">
                  <MDBRow className="roadtax-remark-content">
                    <MDBCol className="col-4 p-0 text-end">
                      <span className="font-highlight">Saloon :</span>
                    </MDBCol>
                    <MDBCol>
                      <span className="font-white">
                        Sedan / Hatchback / Coupe / Wagon / Convertible
                      </span>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="roadtax-remark-content">
                    <MDBCol className="col-4 p-0 text-end">
                      <span className="font-highlight">Non Saloon:</span>
                    </MDBCol>
                    <MDBCol>
                      <span className="font-white">MPV / SUV / Pick-up / Commercial</span>
                    </MDBCol>
                  </MDBRow>
                </MDBRow>

                <MDBRow className="roadtax-result">
                  <label className="roadtax-result year">Yearly Repayment</label>
                  <label className="roadtax-result amount">
                    {' '}
                    RM{' '}
                    {roadtaxResult && roadtaxResult.base_rate
                      ? Number(roadtaxResult.base_rate).toFixed(2).toLocaleString()
                      : '0'}
                  </label>
                </MDBRow>
                {FormikForm.renderButton('Calculate', isSubmitting, isValid, 'btn-submit')}
              </Form>
            ) : (
              <Loader
                style={{
                  position: 'fixed',
                  left: '44%',
                  top: '44%',
                }}
              />
            )}
          </>
        )}
      </Formik>
    </MDBContainer>
  )
}

export default RoadtaxForm
