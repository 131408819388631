import React from 'react'
import { useNavigate } from 'react-router-dom'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//redux
import { useSelector, useDispatch } from 'react-redux'
import {
  setCar1Data,
  setCar2Data,
  setCar3Data,
  setChooseBrand,
} from '../../../redux/reducer/carCompareReducer'
import { setSection } from '../../../redux/reducer/calculatorReducer'

export default function CompareTable() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { width } = useWindowDimensions()

  const { direct_import_action } = useSelector((state) => state.home)

  return (
    <MDBContainer className="compare-model-container">
      <MDBRow>
        <MDBCol>
          <label
            onClick={() => {
              navigate('/')
              dispatch(setChooseBrand(false))
              dispatch(setCar1Data({}))
              dispatch(setCar2Data({}))
              dispatch(setCar3Data({}))
            }}
            className="_text --gold">
            &lt;&lt; Back
          </label>
        </MDBCol>

        <MDBCol>
          <span className="_text --yellow ">Select Car</span>
        </MDBCol>
      </MDBRow>
      <MDBRow className="_padding --top">
        <div className="line-container --gold-long-compare"></div>
      </MDBRow>

      <div className="_padding --btm">
        <MDBRow className="action-row mt-4">
          {direct_import_action.map((item, index) => (
            <MDBCol
              className={
                width < 991
                  ? 'col-3 text-center'
                  : `${item.colums} d-grid align-items-center justify-content-center`
              }
              key={index}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (item.action) {
                  window.open(item.action, '_blank')
                }
                if (item.navigate) {
                  dispatch(setSection(item.value))
                  dispatch(setCar1Data({}))
                  dispatch(setCar2Data({}))
                  dispatch(setCar3Data({}))
                  navigate(item.navigate)
                }
              }}>
              <LazyLoadImage
                alt="..."
                src={item.image}
                width={width < 991 ? '35' : '45'}
                height={width < 991 ? '35' : '45'}
              />
              <label className={width < 991 ? '_label' : '_label --bold'}>{item.label}</label>
            </MDBCol>
          ))}
        </MDBRow>
      </div>
    </MDBContainer>
  )
}
