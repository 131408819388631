import { useState } from 'react'

//lib
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalBody,
} from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//assets
import auctionSheet from '../../../assets/images/auction-guide-img/auctionSheet.webp'
import auctionSheet2 from '../../../assets/images/auction-guide-img/auctionSheet2.webp'
import damage from '../../../assets/images/auction-guide-img/damage.webp'
import genuineMile from '../../../assets/images/auction-guide-img/genuineMile.webp'
import sale from '../../../assets/images/auction-guide-img/sale.webp'
import radiation from '../../../assets/images/auction-guide-img/radiation.webp'
import poor from '../../../assets/images/auction-guide-img/poor.webp'
import picture from '../../../assets/images/auction-guide-img/picture.webp'

export default function AuctionGuideImage() {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalImageSrc, setModalImageSrc] = useState('')

  function handleImageClick(src) {
    setModalImageSrc(src)
    setModalOpen(true)
  }
  return (
    <MDBContainer className="auction-guide-container">
      <MDBRow className="main-title-row">
        <label className="_title-special-text">
          D - <span className="_title">What you can find out?</span>
        </label>
      </MDBRow>

      <MDBRow className="">
        <MDBCol>
          <LazyLoadImage className="_image" src={picture} alt="..." width="40%" />
          <p className="_text">Auction Pictures References</p>
        </MDBCol>
        <MDBCol>
          <LazyLoadImage className="_image" src={damage} alt="..." width="38%" />
          <p className="_text">Accident Damage and Repair</p>
        </MDBCol>
        <MDBCol>
          <LazyLoadImage className="_image" src={poor} alt="..." width="38%" />
          <p className="_text">Poor Condition</p>
        </MDBCol>
      </MDBRow>

      <MDBRow className="">
        <MDBCol>
          <LazyLoadImage className="_image" src={genuineMile} alt="..." width="38%" />
          <p className="_text">Differentiate Genuine Mile</p>
        </MDBCol>
        <MDBCol>
          <LazyLoadImage className="_image" src={sale} alt="..." width="38%" />
          <p className="_text">Feel Overcharged and Tired Seeking</p>
        </MDBCol>
        <MDBCol>
          <LazyLoadImage className="_image" src={radiation} alt="..." width="48.5%" />
          <p className="_text">Differentiate Radiation Free</p>
        </MDBCol>
      </MDBRow>

      <MDBRow className="main-title-row">
        <label className="_title-special-text">
          <span className="_title--big ">Auction Guide</span>
        </label>
      </MDBRow>
      <MDBRow className="auction-guide-image-row">
        <MDBCol>
          <div className="image-container-small">
            <LazyLoadImage
              src={auctionSheet}
              alt="..."
              onClick={() => handleImageClick(auctionSheet)}
            />
          </div>
        </MDBCol>
        <MDBCol>
          <div className="image-container-small">
            <LazyLoadImage
              src={auctionSheet2}
              alt="..."
              onClick={() => handleImageClick(auctionSheet2)}
            />
          </div>
        </MDBCol>
      </MDBRow>

      <MDBModal show={modalOpen} tabIndex="-1" onClick={() => setModalOpen(false)}>
        <MDBModalDialog size="xl">
          <MDBModalContent>
            <MDBModalBody>
              <LazyLoadImage src={modalImageSrc} alt="..." className="w-100" />
            </MDBModalBody>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </MDBContainer>
  )
}
