import { useState } from 'react'

//lib
import Modal from 'react-bootstrap/Modal'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import Lottie from 'lottie-react'
import NoSearchData from '../../assets/json/no-search-data-found.json'
import { debounce } from 'lodash'

//redux
import { useSelector, useDispatch } from 'react-redux'
import {
  setShowBodyTypeList,
  setCarBodyTypeFilter,
  setShowCarPriceList,
  setCarPriceFilter,
  setPriceFilterData,
} from '../../redux/reducer/stockReducer'
import { Icon } from '@iconify/react'
import { MDBContainer } from 'mdb-react-ui-kit'
import { useEffect } from 'react'
import useWindowDimensions from '../common/window-dimension'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

// range slider
import Slider from '@mui/material/Slider';
import { useSearchParams } from 'react-router-dom'

export const PriceList = ({ show, setShow, type, display, top, backFirstPage, startZero }) => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const [ searchParams, setSearchParams ] = useSearchParams()

  const { carPriceFilter, priceFilterData, availableCarModels, showCarPriceList } = useSelector(
    (state) => state.stock,
  )

  const handleToggleOverlay = () => {
    dispatch(setShowCarPriceList(!showCarPriceList))
    document.body.style.overflow = 'auto'
  }

  const handleShowToggleOverlay = () => {
    setShow(!show)
    document.body.style.overflow = 'auto'
  }

  const handleOverflowChange = debounce((newShowValue) => {
    document.body.style.overflow = newShowValue ? 'hidden' : 'auto'
  }, 100)

  // range slider
  const [fromValue, setFromValue] = useState(carPriceFilter[0])
  const [toValue, setToValue] = useState(carPriceFilter[1])
  const [value, setValue] = useState([carPriceFilter[0], carPriceFilter[1]])

  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  const handleRange = (event, newValue) => {
    setFromValue(newValue[0])
    setToValue(newValue[1])
    dispatch(setPriceFilterData([fromValue, toValue]))
  }

  function valueText(value) {
    return `RM ${value.toLocaleString("en-MY")}`;
  }

  useEffect(() => {
    if (type === 'non-fixed') {
      handleOverflowChange(showCarPriceList)
    } else if (type === 'fixed') {
      handleOverflowChange(show)
    } else {
      handleOverflowChange(showCarPriceList)
    }
  }, [showCarPriceList, show])

  return (
    <>
      {type ? (
        <>
          <div
            className="brandlist-div-overlay"
            onClick={() => {
              type == 'fixed' ? handleShowToggleOverlay() : handleToggleOverlay()
            }}
          />
          <MDBContainer
            className={
              type == 'non-fixed' ? 'brandlist-div-container' : 'brandlist-div-container --fixed'
            }
            style={{ top: top }}>
            <div className="brandlist-div-body --slider" style={{ width: '100%' }}>
              <div className="mt-5">
                <div className='mui-slider-div'               
                  // onMouseEnter={handleMouseEnter}
                  // onMouseLeave={handleMouseLeave}
                >
                  <Slider
                    min={50000}
                    max={5000000}
                    getAriaLabel={() => 'Price List'}
                    defaultValue={value}
                    value={[fromValue, toValue]}
                    onChange={handleRange}
                    valueLabelDisplay={showTooltip ? "on" : "auto"} // Show tooltip only on hover
                    getAriaValueText={valueText}
                    valueLabelFormat={valueText}
                  />
                </div>
                {/* <RangeSlider
                  min={0}
                  max={5000000}
                  defaultValue={value}
                  value={[fromValue, toValue]}
                  step={1}
                  id="range-slider-gradient"
                  onInput={(e) => handleRange(e)} // Make sure this function receives correct data
                /> */}
                <div className="d-flex justify-content-center mt-4 range-slider-input">
                  <input
                    defaultValue={`RM ${fromValue?.toLocaleString("en-MY")}`}
                    value={
                      Number(fromValue) >= 0
                        ? `RM ${fromValue?.toLocaleString("en-MY")}`
                        : carPriceFilter[0] != undefined
                        ? `RM ${carPriceFilter[0]?.toLocaleString("en-MY")}`
                        : "RM 0"
                    }
                    onChange={(e) => {
                      const number = parseFloat(e.target.value.replace(/[^0-9]/g, ''))
                      if (isNaN(number)) {
                        setFromValue(0)
                      } else {
                        setFromValue(number)
                      }
                    }}
                  />
                  <label className="ms-3 me-3"> - </label>
                  <input
                    defaultValue={`RM ${toValue?.toLocaleString("en-MY")}`}
                    value={
                      Number(toValue) >= 0
                        ? `RM ${toValue?.toLocaleString("en-MY")}`
                        : carPriceFilter[1] != undefined
                        ? `RM ${carPriceFilter[1]?.toLocaleString("en-MY")}`
                        : "RM 0"
                    }
                    onChange={(e) => {
                      const number = parseFloat(e.target.value.replace(/[^0-9]/g, ''))
                      if (isNaN(number)) {
                        setToValue(0)
                      } else {
                        setToValue(number)
                      }
                    }}
                  />
                </div>
              </div>
              <div className="brandlist-div-footer">
                <div
                  className="d-flex align-items-center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setCarPriceFilter([50000, 5000000]))
                    if (type == 'fixed') {
                      handleShowToggleOverlay()
                    } else {
                      handleToggleOverlay()
                    }
                  }}>
                  Clear
                </div>
                <div
                  className="darkTheme-btn reset-btn --all"
                  onClick={() => {
                    startZero(0)
                    backFirstPage(1)
                    setSearchParams({ page: '1' })

                    if((fromValue < 50000 || fromValue > 5000000) && (toValue < 50000 || toValue > 5000000)) {
                      dispatch(setCarPriceFilter([50000, 5000000]))
                    } else if (fromValue < 50000 || fromValue > 5000000) {
                      dispatch(setCarPriceFilter([50000, toValue]))
                    } else if (toValue < 50000 || toValue > 5000000) {
                      dispatch(setCarPriceFilter([fromValue, 5000000]))
                    } else {
                      dispatch(setCarPriceFilter([fromValue, toValue]))
                    }

                    if (type == 'fixed') {
                      handleShowToggleOverlay()
                    } else {
                      handleToggleOverlay()
                    }
                  }}>
                  Apply
                </div>
              </div>
            </div>
          </MDBContainer>
        </>
      ) : (
        <div className="brandlist-div-body" style={{ width: '100%' }}>
          <div className="mt-5">
            <div className='mui-slider-div'>
              <Slider
                min={50000}
                max={5000000}
                getAriaLabel={() => 'Price List'}
                defaultValue={value}
                value={[fromValue, toValue]}
                onChange={handleRange}
                valueLabelDisplay="auto"
                getAriaValueText={valueText}
                valueLabelFormat={valueText}
              />
            </div>
            <div className="d-flex justify-content-center mt-4 range-slider-input">
              <input
                defaultValue={`RM ${fromValue?.toLocaleString("en-MY")}`}
                value={
                  Number(fromValue) >= 0
                    ? `RM ${fromValue?.toLocaleString("en-MY")}`
                    : carPriceFilter[0] != undefined
                    ? `RM ${carPriceFilter[0]?.toLocaleString("en-MY")}`
                    : "RM 0"
                }
                onChange={(e) => {
                  const number = parseFloat(e.target.value.replace(/[^0-9]/g, ''))
                  if (isNaN(number)) {
                    setFromValue(0)
                  } else {
                    setFromValue(number)
                    dispatch(setPriceFilterData([number, toValue]))
                  }
                }}
              />
              <label className="ms-3 me-3"> - </label>
              <input
                defaultValue={`RM ${toValue?.toLocaleString("en-MY")}`}
                value={
                  Number(toValue) >= 0
                    ? `RM ${toValue?.toLocaleString("en-MY")}`
                    : carPriceFilter[1] != undefined
                    ? `RM ${carPriceFilter[1]?.toLocaleString("en-MY")}`
                    : "RM 0"
                }
                onChange={(e) => {
                  const number = parseFloat(e.target.value.replace(/[^0-9]/g, ''))
                  if (isNaN(number)) {
                    setToValue(0)
                  } else {
                    setToValue(number)
                    dispatch(setPriceFilterData([fromValue, number]))
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
