import { useEffect } from 'react'
import { useSwiper } from 'swiper/react'

export default function SwiperItem({ handleSwiperInstance }) {
  const swiper = useSwiper()

  useEffect(() => {
    handleSwiperInstance(swiper)
  }, [])

  return <></>
}
