//lib
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import Lottie from 'lottie-react'
import NoSearchData from './../../assets/json/no-search-data-found.json'
import { debounce } from 'lodash'

//redux
import { useSelector, useDispatch } from 'react-redux'
import { setShowBodyTypeList, setCarBodyTypeFilter, setBodyTypeFilterData } from '../../redux/reducer/stockReducer'
import { Icon } from '@iconify/react'
import { MDBContainer } from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react'
import useWindowDimensions from '../common/window-dimension'
import { useSearchParams } from 'react-router-dom'

export const BodyTypeList = ({ show, setShow, type, display, top, backFirstPage, startZero }) => {

  const dispatch = useDispatch()
  const { isDarkMode } = useSelector((state) => state.home)
  const { width , height} = useWindowDimensions()
  const [ searchParams, setSearchParams ] = useSearchParams()
  const { carBodyTypeFilter, bodyTypeFilterData, availableBodyTypes, carBodyTypeListing, showBodyTypeList } = useSelector((state) => state.stock)

  const availableCarBodyTypes = carBodyTypeListing.filter((bodyType) =>
    availableBodyTypes.includes(bodyType.title)
  );

  const handleToggleOverlay = () => {
    dispatch(setShowBodyTypeList(!showBodyTypeList));
    document.body.style.overflow = 'auto'
  };

  const handleShowToggleOverlay = () => {
    setShow(!show);
    document.body.style.overflow = 'auto'
  };

  const handleOverflowChange = debounce((newShowValue) => {
    document.body.style.overflow = newShowValue ? 'hidden' : 'auto';
  }, 100);
  
  useEffect(() => {
    if ( type === 'non-fixed') {
      handleOverflowChange(showBodyTypeList);
    } else if (type === 'fixed') {
      handleOverflowChange(show);
    } else {
      handleOverflowChange(showBodyTypeList);
    }
  }, [showBodyTypeList, show]);

  return (
    <>
      {type ?
        <>
          <div className='brandlist-div-overlay' onClick={() => {type == 'fixed' ? handleShowToggleOverlay() : handleToggleOverlay()}}/>
          <MDBContainer 
            className={type == 'non-fixed' ? "brandlist-div-container" : "brandlist-div-container --fixed"} 
            style={{ top: top }}
          >
            <div className="brandlist-div-body" style={{height : height > 600 ? "25em" : "250px"}}>
              <div className='brandlist-div-content'>
              {availableCarBodyTypes && availableCarBodyTypes.length > 0 ?
                availableCarBodyTypes.map((label, index) => {
                  return (
                      <ul key={`carbody-${index}`} className='brandlist-ul'>
                        <li
                          className="brandlist-listing --grid"
                          onClick={() => {
                            let updatedBodyTypeFilter = [...bodyTypeFilterData]; // Create a copy of the original filter

                            if (updatedBodyTypeFilter.includes(label.title)) {
                              updatedBodyTypeFilter = updatedBodyTypeFilter.filter(
                                (selectedBodyType) => selectedBodyType !== label.title
                              );
                            } else {
                              updatedBodyTypeFilter.push(label.title);
                            }
                    
                            dispatch(setBodyTypeFilterData(updatedBodyTypeFilter));
                          }}>
                          <div style={{ height: width > 600 ? '4em' : '3em'}}>
                            <LazyLoadImage src={label.icon} width="100%" height="100%" alt="body-type-img"/>
                          </div>
                          <p>{label.title}</p>
                          <div>
                            <Icon
                              icon="mdi:tick"
                              width={width < 991 ? '16px' : '22px'}
                              style={{ color: bodyTypeFilterData.includes(label.title) && isDarkMode ? '#ffd439' : bodyTypeFilterData.includes(label.title)  ? "#DC6969"   :'transparent' }}
                            />
                          </div>
                        </li>
                      </ul>
                  )
                })
              :
              ''
              }
              </div>
            <div className="brandlist-div-footer">
              <div
                  className="d-flex align-items-center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setBodyTypeFilterData([]))
                    dispatch(setCarBodyTypeFilter([]))
                    if (type == 'fixed') {
                      handleShowToggleOverlay()
                    } else {
                      handleToggleOverlay()
                    }
                  }}>
                  Clear
                </div>
                <div 
                  className="darkTheme-btn reset-btn --all"
                  onClick={() => {
                    backFirstPage(1)
                    startZero(0)
                    setSearchParams({ page: '1' })

                    dispatch(setCarBodyTypeFilter(bodyTypeFilterData))
                    if (type == 'fixed') {
                      handleShowToggleOverlay()
                    } else {
                      handleToggleOverlay()
                    }
                  }}>
                  Apply
                </div>
            </div>
            </div>
          </MDBContainer>
        </>
      :
        <>
          {availableCarBodyTypes && availableCarBodyTypes.length > 0 ?
            availableCarBodyTypes.map((label, index) => {
              return (
                <ul key={`carbody-${index}`} className='brandlist-ul'>
                  <li
                    className="brandlist-listing --grid"
                    onClick={() => {
                      let updatedBodyTypeFilter = [...bodyTypeFilterData]; // Create a copy of the original filter

                      if (updatedBodyTypeFilter.includes(label.title)) {
                        updatedBodyTypeFilter = updatedBodyTypeFilter.filter(
                          (selectedBodyType) => selectedBodyType !== label.title
                        );
                      } else {
                        updatedBodyTypeFilter.push(label.title);
                      }
              
                      dispatch(setBodyTypeFilterData(updatedBodyTypeFilter));
                    }}>
                    <div style={{ height: '5em' }}>
                      <LazyLoadImage src={label.icon} width="100%" height="100%" alt="body-type-img"/>
                    </div>
                    <p>{label.title}</p>
                    <div>
                      <Icon
                        icon="mdi:tick"
                        width={width < 991 ? '20px' : '22px'}
                        style={{ color: bodyTypeFilterData.includes(label.title) && isDarkMode ? '#ffd439' : bodyTypeFilterData.includes(label.title)  ? "#DC6969"   :'transparent' }}
                      />
                    </div>
                  </li>
                </ul>
              )
            })
            :
            ''
          }
        </>
      }
    </>
  )
}
