import { useNavigate } from 'react-router-dom'
import { useRef } from 'react'
import { UseCar1 } from '../../../assets/images'

//image
import { LogoMain } from '../../../assets/images'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import { MDBBtn, MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'
import { Skeleton } from 'primereact/skeleton'
import { Galleria } from 'primereact/galleria'
import { Image } from 'primereact/image'

export default function OwnerDetailCard({ data, index, type, loading }) {
  const { width } = useWindowDimensions()
  const navigate = useNavigate()

  const openWhatsapp = () => {
    const whatsappNumber = `${data?.user_contact ? data.user_contact : ''}`
    const message = `Hello, I${"'"}m interested with this ${
      data.car_model_name
    }, how D-Concept can help to import?`
    const url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
      message,
    )}`
    window.open(url, '_blank')
  }

  const openPhone = () => {
    const phoneNumber = data?.user_contact ? data.user_contact : ''
    window.location.href = `tel:${phoneNumber}`
  }

  let newData = []

  for (let i = 0; i < data.medias.length; i++) {
    newData.push(data.medias[i]?.api_url)
  }

  const galleria = useRef(null)

  const itemTemplate = (item) => {
    return <img src={item} alt={item.alt} style={{ width: '100%', display: 'block' }} />
  }

  const thumbnailTemplate = (item) => {
    return <img src={item} alt={item.alt} style={{ display: 'block' }} />
  }

  return (
    <MDBCol
      className={width > 550 ? 'detail-card-container mt-4 desktop' : 'detail-card-container mt-4'}
      key={index}>
      <MDBRow className="toolbar-box">
        {!loading ? (
          <MDBCol className="col-12 col-lg-7">
            <label className="_label --main-title">{data.car_model_name}</label>
            <MDBRow className="mt-4 mb-4">
              <MDBCol className="col-1 p-0">
                <div className="post-image-div">
                  <img
                    alt="dconcept-img"
                    src={data?.user_image?.[0]?.url ? data?.user_image?.[0]?.url : LogoMain}
                  />
                </div>
              </MDBCol>
              <MDBCol className="col-5">
                <div className="name-section">
                  <label className="_label --name text-capitalize">
                    {data.user_name || 'Alex Ko Kim Chi'}
                  </label>
                  <label className="_label --agent-type">{data.user_role || 'Sales Agent'}</label>
                </div>
              </MDBCol>
              <MDBCol
                className="d-flex align-item-center justify-content-center col-6"
                style={{ alignItems: 'center' }}>
                <div
                  className="button-group --contact-agent"
                  style={{ margin: '0em 0.3em' }}
                  onClick={openWhatsapp}>
                  <label>
                    <Icon
                      icon="logos:whatsapp-icon"
                      width={width > 991 ? '14' : '10'}
                      color="#10141A"
                    />
                    WhatsApp
                  </label>
                </div>
                <div
                  className="button-group --contact-agent"
                  style={{ margin: '0em 0.3em' }}
                  onClick={openPhone}>
                  <label>
                    <Icon
                      icon="fluent:call-16-regular"
                      width={width > 991 ? '14' : '10'}
                      color="#10141A"
                    />
                    Contact
                  </label>
                </div>
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-4 p-0">
              <label className="_label --tag">
                <Icon icon="mdi:comment-processing-outline" color="#9091a0" />
                ask best price for this car
              </label>
              <label className="_label --tag">
                <Icon icon="mdi:comment-processing-outline" color="#9091a0" />
                ask car report
              </label>
              <label className="_label --tag">
                <Icon icon="mdi:comment-processing-outline" color="#9091a0" />
                ask for visit
              </label>
            </MDBRow>
            <label className="_label --sub-title">Car Information</label>
            <MDBRow
              className="car-info-container"
              onClick={() => {
                navigate(`/carInfo/${data.path_name}/${data.id}`)
              }}>
              <MDBCol className="col-4 d-grid justify-content-center text-center p-0">
                <label className="_label --options">Selling Price</label>
                <label className="_label --options-label">
                  RM{parseInt(data.asking_price).toLocaleString()}
                </label>
              </MDBCol>
              <MDBCol className="col-4 d-grid justify-content-center text-center p-0">
                <label className="_label --options">Year Manufacture</label>
                <label className="_label --options-label">{data.car_infos.manufactory_year}</label>
              </MDBCol>
              <MDBCol className="col-4 d-grid justify-content-center text-center p-0">
                <label className="_label --options">Register At</label>
                <label className="_label --options-label">{data.car_infos.register_at}</label>
              </MDBCol>
              <MDBCol className="col-4 d-grid justify-content-center text-center p-0 mt-3">
                <label className="_label --options">Color</label>
                <label className="_label --options-label">{data.car_infos.color}</label>
              </MDBCol>
              <MDBCol className="col-4 d-grid justify-content-center text-center p-0 mt-3">
                <label className="_label --options">Fuel Type</label>
                <label className="_label --options-label">{data.car_infos.fuel_type}</label>
              </MDBCol>
              <MDBCol className="col-4 d-grid justify-content-center text-center p-0 mt-3">
                <label className="_label --options">Transmission</label>
                <label className="_label --options-label">{data.car_infos.transmission}</label>
              </MDBCol>
              <MDBCol className="col-4 d-grid justify-content-center text-center p-0 mt-3">
                <label className="_label --options">Condition</label>
                <label className="_label --options-label">{data.car_infos.condition}</label>
              </MDBCol>
              <MDBCol className="col-4 d-grid justify-content-center text-center p-0 mt-3">
                <label className="_label --options">Mileage</label>
                <label className="_label --options-label">{data.car_infos.mileage}</label>
              </MDBCol>
              <MDBCol className="col-4 d-grid justify-content-center text-center p-0 mt-3">
                <label className="_label --options">Engine Capacity</label>
                <label className="_label --options-label">{data.car_infos.engine_capacity}</label>
              </MDBCol>
            </MDBRow>
          </MDBCol>
        ) : (
          <MDBCol className="col-12 col-lg-8">
            <Skeleton width="20em" height="15px" className="mt-1" />
            <MDBRow className="mt-4 mb-4">
              <MDBCol>
                <Skeleton width="15em" height="15px" className="mt-2" />
                <Skeleton width="10em" height="15px" className="mt-3" />
              </MDBCol>
              <MDBCol className="d-flex align-item-center justify-content-center gap-3">
                <Skeleton width="18em" height="35px" className="mt-1" />
                <Skeleton width="18em" height="35px" className="mt-1" />
              </MDBCol>
            </MDBRow>
            <MDBRow className="mb-4">
              <div className="d-flex mt-3">
                <Skeleton width="15em" height="15px" />
                <Skeleton width="15em" height="15px" className="ms-3" />
                <Skeleton width="15em" height="15px" className="ms-3" />
              </div>
            </MDBRow>
            <Skeleton width="20em" height="15px" className="mt-2" />
            <MDBRow className="car-info-container">
              {Array.from({ length: 9 }, (_, index) => (
                <MDBCol className="col-4 d-grid justify-content-center text-center p-0">
                  <Skeleton width="15em" height="15px" className="ms-2 mt-3 mb-3" />
                </MDBCol>
              ))}
            </MDBRow>
          </MDBCol>
        )}
        {!loading ? (
          <MDBCol className="col-12 col-lg-5">
            {width >= 991 && newData.length > 0 ? (
              <>
                <MDBRow>
                  <MDBCol onClick={() => galleria.current.show()}>
                    <Image src={newData[0]} alt="car-img" width="100%" height="166.84px" />
                  </MDBCol>
                  {newData[1] && (
                    <MDBCol onClick={() => galleria.current.show()}>
                      <Image src={newData[1]} alt="car-img" width="100%" height="166.84px" />
                    </MDBCol>
                  )}
                </MDBRow>
                <MDBRow style={{ marginTop: '1em' }}>
                  <MDBCol onClick={() => galleria.current.show()}>
                    <Image src={newData[2]} alt="car-img" width="100%" height="166.84px" />
                  </MDBCol>
                  <MDBCol className="last-image-column" onClick={() => galleria.current.show()}>
                    <div className="image">
                      <Image src={newData[3]} alt="car-img" width="100%" height="166.84px" />
                      {newData.length > 4 && (
                        <div className="overlay">
                          <label>+{newData.length - 3}</label>
                        </div>
                      )}
                    </div>
                  </MDBCol>
                </MDBRow>
                <Galleria
                  ref={galleria}
                  value={newData}
                  numVisible={9}
                  style={width > 850 ? { maxWidth: '50%' } : { maxWidth: '100%' }}
                  circular
                  fullScreen
                  showItemNavigators
                  showThumbnails={false}
                  item={itemTemplate}
                  thumbnail={thumbnailTemplate}
                />
              </>
            ) : (
              ''
            )}
            {width < 991 && newData.length > 0 ? (
              <>
                <MDBRow>
                  <MDBCol
                    onClick={() => galleria.current.show()}
                    style={{
                      padding: '0px 3px',
                    }}>
                    <Image
                      src={newData[0]}
                      alt="car-img"
                      width="100%"
                      height="78.95px"
                      style={{ objectFit: 'cover' }}
                    />
                  </MDBCol>
                  {newData[1] && (
                    <MDBCol
                      onClick={() => galleria.current.show()}
                      style={{
                        padding: '0px 3px',
                      }}>
                      <Image
                        src={newData[1]}
                        alt="car-img"
                        width="100%"
                        height="78.95px"
                        style={{ objectFit: 'cover' }}
                      />
                    </MDBCol>
                  )}
                  <MDBCol
                    onClick={() => galleria.current.show()}
                    style={{
                      padding: '0px 3px',
                    }}>
                    <Image
                      src={newData[2]}
                      alt="car-img"
                      width="100%"
                      height="78.95px"
                      style={{ objectFit: 'cover' }}
                    />
                  </MDBCol>
                  <MDBCol
                    className="last-image-column"
                    onClick={() => galleria.current.show()}
                    style={{
                      padding: '0px 3px',
                    }}>
                    <div className="image">
                      <Image
                        src={newData[3]}
                        alt="car-img"
                        width="100%"
                        height="78.95px"
                        style={{ objectFit: 'cover' }}
                      />
                      {newData.length > 4 && (
                        <div className="overlay">
                          <label>+{newData.length - 3}</label>
                        </div>
                      )}
                    </div>
                  </MDBCol>
                </MDBRow>
                <Galleria
                  ref={galleria}
                  value={newData}
                  numVisible={9}
                  style={width > 850 ? { maxWidth: '50%' } : { maxWidth: '100%' }}
                  circular
                  fullScreen
                  showItemNavigators
                  showThumbnails={false}
                  item={itemTemplate}
                  thumbnail={thumbnailTemplate}
                />
              </>
            ) : (
              ''
            )}
          </MDBCol>
        ) : (
          <MDBCol className="col-12 col-lg-4">
            <div className="gap-3 d-flex">
              <Skeleton width="100%" height="120px" className="mt-4 ms-2" />
              <Skeleton width="100%" height="120px" className="mt-4 ms-2" />
            </div>
            <div className="gap-3 d-flex">
              <Skeleton width="100%" height="120px" className="mt-3 ms-2" />
              <Skeleton width="100%" height="120px" className="mt-3 ms-2" />
            </div>
          </MDBCol>
        )}
      </MDBRow>
    </MDBCol>
  )
}
