import React, { useState, useEffect } from 'react';
import '../../assets/css/toggleSwitch.css';

export default function ToggleSwitch({ optionOne = '', optionTwo = '', setStockType, stockType, clearFilter }) {
  const [toggle, setToggle] = useState(stockType === 'D-Stock' ? false : true);

  return (
    <div className="toggleSwitchContainer">
      <div
        className="toggleSwitch"
      >
        <div className={!toggle ? 'toggle' : 'toggle-optionTwo'}>
          <div className="switch" id={stockType === 'D-Stock' ? 'active-left-toggled' : 'active-toggled'}></div>
          <div
            className={stockType === 'D-Stock' ? 'optionOne' : 'optionOne-white'}
            onClick={() => {
              if(stockType !== 'D-Stock') {
                setToggle(!toggle);
                setStockType('D-Stock');
                clearFilter();
              }
            }}
          >
            {optionOne}
          </div>
          <div
            className={stockType === 'Direct Owner' ? 'optionTwo' : 'optionTwo-white'}
            onClick={() => {
              if(stockType !== 'Direct Owner') {
                setToggle(!toggle);
                setStockType('Direct Owner');
                clearFilter();
              }
            }}
          >
            {optionTwo}
          </div>
        </div>
      </div>
    </div>
  );
}
