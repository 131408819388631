import './scss/index.scss'
import './scss/light_mode.scss'

import { useEffect, useState } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

//toast
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

//pages
import Home from './pages/Home'
import Discover from './pages/Discover'
import Stock from './pages/Stock'
import Preorder from './pages/Preorder'
import Order from './pages/Order'
import CarInfo from './pages/CarInfo'
import BrandInfo from './pages/BrandInfo'
import CarPost from './pages/CarPost'
import Calculator from './pages/Calculator'
import ViewPanaroma from './pages/360View'
import AuctionGuide from './pages/AuctionGuide'
import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
import CompareModel from './pages/CompareModel'
import FooterOne from './components/footer/footer-one'
import FirstLoadScreen from './components/common/first.load.screen'
import Layout from './components/layout/layout'
import TermsAndConditions from './pages/T&C'
import EventModal from '../src/components/modal/popup.event'
import { setOpenEventModal, setIsDarkMode } from '../src/redux/reducer/homeReducer'
import { useDispatch, useSelector } from 'react-redux'
import httpService from './services/http.services'

function App() {
  const { openEventModal, isDarkMode } = useSelector((state) => state.home)
  const storedPreference = localStorage.getItem('dontShowEventModal')
  const dispatch = useDispatch()
  const storedTheme = localStorage.getItem('isDark')

  function updateMetaThemeColor(color) {
    document
      .querySelector("meta[name='theme-color']")
      // .setAttribute("content", "#12B6DF");
      .setAttribute('content', color)
  }

  useEffect(() => {
    // Set cache headers on initial page load
    setCacheHeaders()
    if (storedTheme === 'true') {
      dispatch(setIsDarkMode(true))
    } else if (storedTheme === 'false') {
      dispatch(setIsDarkMode(false))
    } else {
      dispatch(setIsDarkMode(true))
    }

    if (storedPreference === 'true') {
      dispatch(setOpenEventModal(false))
    } else {
      dispatch(setOpenEventModal(true))
    }
  }, [])

  useEffect(() => {
    if (isDarkMode) {
      updateMetaThemeColor('#14161E')
    } else {
      updateMetaThemeColor('#FFFFFF')
    }
  }, [isDarkMode])

  useEffect(() => {
    httpService.initializeInterceptors(dispatch)
  }, [])

  const setCacheHeaders = () => {
    const cacheControl = 'public, max-age=86400' // Set cache expiration time to 1 day
    const expires = new Date(Date.now() + 86400000).toUTCString() // Set expires header to 1 day in the future

    // Set cache headers in the browser
    document.documentElement.setAttribute('cache-control', cacheControl)
    document.documentElement.setAttribute('expires', expires)
  }

  const routeData = [
    {
      path: '/',
      element: (
        <Layout>
          <Home />
        </Layout>
      ),
    },
    {
      path: '/discover',
      element: (
        <Layout>
          <Discover />
        </Layout>
      ),
    },
    { path: '/calculator', element: <Calculator /> },
    {
      path: '/stock',
      element: (
        <Layout>
          <Stock />
        </Layout>
      ),
    },
    {
      path: '/preorder',
      element: (
        <Layout>
          <Preorder />
        </Layout>
      ),
    },
    {
      path: '/order',
      element: (
        <Layout>
          <Order />
        </Layout>
      ),
    },
    { path: '/carInfo/:car_name/:id', element: <CarInfo /> },
    { path: '/brand/:id', element: <BrandInfo /> },
    { path: '/view/:id', element: <ViewPanaroma /> },
    { path: '/post/:id', element: <CarPost /> },
    { path: '/auction_guide', element: <AuctionGuide /> },
    { path: '/about_us', element: <AboutUs /> },
    { path: '/contact_us', element: <ContactUs /> },
    { path: '/compare_model', element: <CompareModel /> },
    { path: '/terms-of-use', element: <TermsAndConditions /> },
    {
      path: '*',
      element: (
        <Layout>
          <Navigate to="/" />
        </Layout>
      ),
    },
  ]
  return (
    <BrowserRouter>
      <div className={`app-container ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
        <FirstLoadScreen />
        <Routes>
          {/* <Route
            path="*"
            element={
              <Layout>
                <Navigate to="/" />
              </Layout>
            }
          /> */}
          {routeData.map((item, index) => {
            return <Route key={index} path={item.path} element={item.element} />
          })}
        </Routes>
        {/* {openEventModal && <EventModal show={openEventModal} setShow={setOpenEventModal} />} */}
        <FooterOne />
        <ToastContainer />
      </div>
    </BrowserRouter>
  )
}

export default App
