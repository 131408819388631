import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

//lib
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { debounce } from 'lodash'
import { Icon } from '@iconify/react'

//redux
import { useSelector, useDispatch } from 'react-redux'
import {
  setShowBrandList,
  setCarBrandFilter,
  setBrandFilterData,
  setCarPriceFilter,
  setCarModelFilter,
  setDisplayBrandModelFilter,
  setDisplayBrandModelData,
  setModelFilterData,
  updateDisplayText,
} from '../../redux/reducer/stockReducer'
import { getBrand } from '../../redux/reducer/homeReducer'
import { MDBContainer } from 'mdb-react-ui-kit'
import useWindowDimensions from '../common/window-dimension'

export const BrandList = ({
  show,
  setShow,
  type,
  top,
  startZero,
  backFirstPage,
  tempModelFilterData,
  setTempModelFilterData,
  tempBrandFilterData,
  setTempBrandFilterData,
  optionStep
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width ,height } = useWindowDimensions()
  const { isDarkMode } = useSelector((state) => state.home)
  const { showBrandList } = useSelector((state) => state.stock)
  const {
    carBrandFilter,
    carModelFilter,
    availableCarBrands,
    brandFilterData,
    displayBrandModelFilter,
    displayBrandModelData,
    modelFilterData,
    displayText,
    originalCarBrands,
  } = useSelector((state) => state.stock)

  const { chooseBrand } = useSelector((state) => state.car_compare)

  const [brandData, setBrandData] = useState({})
  const [modelDropdownState, setModelDropdownState] = useState({})
  const [clearChooseBrand, setClearChooseBrand] = useState(false)

  const [ searchParams, setSearchParams ] = useSearchParams()

  const handleToggleOverlay = () => {
    dispatch(setShowBrandList(!showBrandList))
    document.body.style.overflow = 'auto'
  }

  const handleShowToggleOverlay = () => {
    setShow(!show)
    document.body.style.overflow = 'auto'
  }

  const handleOverflowChange = debounce((newShowValue) => {
    document.body.style.overflow = newShowValue ? 'hidden' : 'auto'
  }, 100)

  useEffect(() => {
    if (type === 'non-fixed') {
      handleOverflowChange(showBrandList)
    } else if (type === 'fixed') {
      handleOverflowChange(show)
    } else {
      handleOverflowChange(showBrandList)
    }
  }, [showBrandList, show])

  // useEffect(() => {
  //   dispatch(getBrand()).then((action) => {
  //     const data = action.payload.data
  //     const brandData = {}

  //     Object.keys(data).forEach((key) => {
  //       if (data[key].length > 0) {
  //         brandData[key] = data[key]
  //       }
  //     })

  //     setBrandData(brandData)
  //   })
  // }, [])

  useEffect(() => {
    if(window.location.pathname !== "/calculator"){
      setTempBrandFilterData(carBrandFilter)
      setTempModelFilterData(carModelFilter)
    }
  }, [])

  const demo = ['1']

  return (
    <>
      {type ? (
        <>
          <div
            className="brandlist-div-overlay"
            onClick={() => {
              type == 'fixed' ? handleShowToggleOverlay() : handleToggleOverlay()
            }}
          />
          <MDBContainer
            className={
              type == 'non-fixed' ? 'brandlist-div-container' : 'brandlist-div-container --fixed'
            }
            style={{ top: top }}>
            <div className="brandlist-div-body" style={{height : height > 600 ? "25em" : "250px"}}>
              <div className="brandlist-div-content" >
                {/* here means api return have data */}
                {Object.keys(availableCarBrands).length !== 0 ? (
                  Object.keys(availableCarBrands).map((key, index) => {
                    return (
                      <div key={index}>
                        {availableCarBrands[key].map((brand, brandIndex) => {
                          const isLastItem = brandIndex === availableCarBrands[key].length - 1
                          return (
                            <li
                              className="brandlist-listing --brand-grid "
                              key={'brand-' + brandIndex}>
                              <div
                                className="mt-1"
                                key={'brand-div-' + brandIndex}
                                onClick={() => {
                                  setModelDropdownState((prevState) => ({
                                    ...prevState,
                                    [brand.brand_name]: !prevState[brand.brand_name],
                                  }))
                                }}
                          >
                                <p>{brand.brand_name}</p>
                                {/* <Icon
                                rotate={2}
                                  key={'brand-icon-' + brandIndex}
                                  style={{ cursor: brand.car_posts == 0 ? 'default' : 'pointer' }}
                                  icon={
                                    tempBrandFilterData.includes(brand.brand_name)
                                      ? 'charm:square-tick'
                                      : brand.car_posts == 0
                                      ? 'bi:dash'
                                      : 'charm:square'
                                  }
                                  color={
                                    tempBrandFilterData.includes(brand.brand_name)  && isDarkMode 
                                      ? '#ffd439' :  tempBrandFilterData.includes(brand.brand_name)   ? '#AC3434'
                                      : '#d1d5db'
                                  }
                                  width={width < 991 ? '12px' : '18px'}
                                /> */}
                                    
                              </div>
                              <div
                              style={{width : "100%" , height : "100%"}}
                                onClick={() => {
                                  setModelDropdownState((prevState) => ({
                                    ...prevState,
                                    [brand.brand_name]: !prevState[brand.brand_name],
                                  }))
                                }}
                               >
                                {/* <p>{brand.brand_name}</p> */}
                              </div>
                              <div
                                className="selectedIcon"
                                onClick={() => {
                                  setModelDropdownState((prevState) => ({
                                    ...prevState,
                                    [brand.brand_name]: !prevState[brand.brand_name],
                                  }))
                                }}>
                                <Icon
                                rotate={2}
                                  icon={
                                    brand.car_posts.length > 0
                                      ? modelDropdownState[brand.brand_name]
                                        ? 'mingcute:down-line'
                                        : 'mingcute:up-line'
                                      : ''
                                  }
                                  color="#d1d5db"
                                  width={width < 991 ? '12px' : '18px'}
                                />
                              </div>
                              {modelDropdownState[brand.brand_name] &&
                                brand.car_posts.length > 0 && (
                                  <div className="model-row">
                                     {demo.map((post, postIndex) => {
                                      const allCarPostsIncluded = brand.car_posts.every(post => tempModelFilterData.includes(post));
                                      return (
                                        <div key={postIndex}>
                                          <div
                                            key={postIndex}
                                            className={
                                              allCarPostsIncluded
                                                ? 'model-div --active'
                                                : 'model-div'
                                            }
                                            data-tooltip-id={`tooltip-${post}`}
                                            onClick={() => {
                                              backFirstPage(1)
                                              startZero(0)
                                              if (brand.car_posts.length > 0) {
                                                const brandName = brand.brand_name
            
                                                const updatedBrandFilter = tempBrandFilterData.includes(
                                                  brandName,
                                                )
                                                  ? tempBrandFilterData.filter(
                                                      (selectedBrand) => selectedBrand !== brandName,
                                                    )
                                                  : [...tempBrandFilterData, brandName]
            
                                                const anyPostsSelected = brand.car_posts.some((post) =>
                                                  tempModelFilterData.includes(post),
                                                )
            
                                                let updatedModelFilterData = [...tempModelFilterData]
                                                if (anyPostsSelected) {
                                                  updatedModelFilterData = updatedModelFilterData.filter(
                                                    (post) => !brand.car_posts.includes(post),
                                                  )
                                                } else {
                                                  updatedModelFilterData = updatedModelFilterData.concat(
                                                    brand.car_posts,
                                                  )
                                                }
            
                                                const updatedDisplayText = getUpdatedDisplayText(
                                                  updatedModelFilterData,
                                                  updatedBrandFilter,
                                                  originalCarBrands,
                                                )
                                                dispatch(setDisplayBrandModelData(updatedBrandFilter))
                                                dispatch(setModelFilterData(updatedModelFilterData))
                                                setTempBrandFilterData(updatedBrandFilter)
                                                setTempModelFilterData(updatedModelFilterData)
                                                function getUpdatedDisplayText(
                                                  updatedModelFilter,
                                                  updatedBrandFilter,
                                                  brands,
                                                ) {
                                                  const updatedDisplayText = []
                                                  for (const brandName of updatedBrandFilter) {
                                                    const brand = brands.find(
                                                      (brand) => brand.brand_name === brandName,
                                                    )
                                                    if (brand) {
                                                      const brandPosts = brand.car_posts.filter((post) =>
                                                        updatedModelFilter.includes(post),
                                                      )
                                                      if (brandPosts.length === brand.car_posts.length) {
                                                        updatedDisplayText.push(brandName)
                                                      } else if (brandPosts.length > 0) {
                                                        updatedDisplayText.push(
                                                          ...brandPosts.map((post) => `${brandName} ${post}`),
                                                        )
                                                      }
                                                    }
                                                  }
                                                  return updatedDisplayText
                                                }
                                              }
                                            }}
                                            >
                                            All
                                          </div>
                                          {post === 1 && <ReactTooltip
                                            id={`tooltip-${post}`} // Define unique tooltip IDs
                                            place="top"
                                            effect="solid"
                                            content={post}
                                            className='model-tooltip'
                                          />}
                                        </div>
                                      )
                                    })}
                                    {brand.car_posts.map((post, postIndex) => {
                                      return (
                                        <div key={postIndex}>
                                          <div
                                            key={postIndex}
                                            className={
                                              tempModelFilterData.includes(post)
                                                ? 'model-div --active'
                                                : 'model-div'
                                            }
                                            data-tooltip-id={`tooltip-${post}`}
                                            onClick={(e) => {
                                              const brandName = brand.brand_name

                                              let updatedModelFilterData =
                                                tempModelFilterData.includes(post)
                                                  ? tempModelFilterData.filter(
                                                      (selectedModel) => selectedModel !== post,
                                                    )
                                                  : [...tempModelFilterData, post]

                                              const anyPostsSelected = brand.car_posts.some(
                                                (post) => updatedModelFilterData.includes(post),
                                              )

                                              let updatedBrandFilter = [...tempBrandFilterData]
                                              if (anyPostsSelected) {
                                                updatedBrandFilter = addBrandIfNotExists(
                                                  updatedBrandFilter,
                                                  brandName,
                                                )
                                              } else {
                                                updatedBrandFilter = updatedBrandFilter.filter(
                                                  (selectedBrand) => selectedBrand !== brandName,
                                                )
                                              }
                                              const updatedDisplayText = getUpdatedDisplayText(
                                                updatedModelFilterData,
                                                updatedBrandFilter,
                                                originalCarBrands,
                                              )
                                              dispatch(setDisplayBrandModelData(updatedBrandFilter))
                                              dispatch(setModelFilterData(updatedModelFilterData))
                                              setTempBrandFilterData(updatedBrandFilter)
                                              setTempModelFilterData(updatedModelFilterData)

                                              function addBrandIfNotExists(brandList, targetBrand) {
                                                if (!brandList.includes(targetBrand)) {
                                                  return [...brandList, targetBrand]
                                                }
                                                return brandList
                                              }
                                              function getUpdatedDisplayText(
                                                updatedModelFilter,
                                                updatedBrandFilter,
                                                brands,
                                              ) {
                                                const updatedDisplayText = []
                                                for (const brandName of updatedBrandFilter) {
                                                  const brand = brands.find(
                                                    (brand) => brand.brand_name === brandName,
                                                  )
                                                  if (brand) {
                                                    const brandPosts = brand.car_posts.filter(
                                                      (post) => updatedModelFilter.includes(post),
                                                    )
                                                    if (
                                                      brandPosts.length === brand.car_posts.length
                                                    ) {
                                                      updatedDisplayText.push(brandName)
                                                    } else if (brandPosts.length > 0) {
                                                      updatedDisplayText.push(
                                                        ...brandPosts.map(
                                                          (post) => `${brandName} ${post}`,
                                                        ),
                                                      )
                                                    }
                                                  }
                                                }
                                                return updatedDisplayText
                                              }
                                            }}>
                                            {post}
                                          </div>
                                          <ReactTooltip
                                            id={`tooltip-${post}`} // Define unique tooltip IDs
                                            place="top"
                                            effect="solid"
                                            content={post}
                                            className='model-tooltip'
                                          />
                                        </div>
                                      )
                                    })}
                                  </div>
                                )}
                            </li>
                          )
                        })}
                      </div>
                    )
                  })
                ) : (
                  <>
                    <div
                      className="d-flex justify-content-center"
                      style={{ marginTop: width > 991 ? '10em' : '7em' }}>
                      <p className="no-available-listing">
                        Click all to view <br />
                        more car brands
                      </p>
                    </div>
                  </>
                )}
              </div>
              <div className="brandlist-div-footer">
                <div
                  className="d-flex align-items-center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setCarBrandFilter([]))
                    dispatch(setCarModelFilter([]))
                    dispatch(setBrandFilterData([]))
                    dispatch(setDisplayBrandModelData([]))
                    dispatch(setDisplayBrandModelFilter([]))
                    dispatch(setModelFilterData([]))
                    if (type == 'fixed') {
                      handleShowToggleOverlay()
                    } else {
                      handleToggleOverlay()
                    }
                  }}>
                  Clear
                </div>
                <div
                  className="darkTheme-btn reset-btn --all"
                  onClick={() => {
                    backFirstPage(1)
                    startZero(0)
                    setSearchParams({ page: '1' })
                    dispatch(setCarBrandFilter(tempBrandFilterData))
                    dispatch(setCarModelFilter(tempModelFilterData))
                    dispatch(setModelFilterData(tempModelFilterData))
                    dispatch(setBrandFilterData(tempBrandFilterData))

                    const updatedDisplayText = getUpdatedDisplayText(
                      tempModelFilterData,
                      tempBrandFilterData,
                      originalCarBrands,
                    )
                    dispatch(updateDisplayText(updatedDisplayText))
                    function getUpdatedDisplayText(updatedModelFilter, updatedBrandFilter, brands) {
                      const updatedDisplayText = []
                      for (const brandName of updatedBrandFilter) {
                        const brand = brands.find((brand) => brand.brand_name === brandName)
                        if (brand) {
                          const brandPosts = brand.car_posts.filter((post) =>
                            updatedModelFilter.includes(post),
                          )
                          if (brandPosts.length === brand.car_posts.length) {
                            updatedDisplayText.push(brandName)
                          } else if (brandPosts.length > 0) {
                            updatedDisplayText.push(
                              ...brandPosts.map((post) => `${brandName} ${post}`),
                            )
                          }
                        }
                      }
                      return updatedDisplayText
                    }
                    dispatch(setDisplayBrandModelFilter(displayBrandModelData))
                    if (type == 'fixed') {
                      handleShowToggleOverlay()
                    } else {
                      handleToggleOverlay()
                    }
                  }}>
                  Apply
                </div>
              </div>
            </div>
          </MDBContainer>
        </>
      ) :optionStep !== "checkbox" &&  chooseBrand ? (
        <Modal
          show={showBrandList}
          onHide={() => {
            dispatch(setShowBrandList(false))
          }}
          keyboard={false}
          centered
          className={
            isDarkMode && chooseBrand ? 'brandlist-modal-container-dark --choose-brand' : 'brandlist-modal-container-light'
          }>
          { chooseBrand ? (
            <Modal.Header className="brandlist-modal-header">
              <h2 style={{ margin: '0' }}>Choose Car Brand</h2>
              <div
                className="brandlist-header-closeBtn"
                onClick={() => {
                  dispatch(setShowBrandList(false))
                }}>
                <FontAwesomeIcon icon={solid('times')}></FontAwesomeIcon>
              </div>
            </Modal.Header>
          ) : (
            <Modal.Header className="brandlist-modal-header --filter">
              <div
                className="brandlist-header-closeBtn --filter"
                onClick={() => {
                  dispatch(setShowBrandList(false))
                }}>
                <FontAwesomeIcon icon={solid('times')}></FontAwesomeIcon>
              </div>
            </Modal.Header>
          )}
          <Modal.Body
            className={!chooseBrand ? 'brandlist-modal-body --footer' : 'brandlist-modal-body'}>
            {Object.keys(brandData).map((key, outerBrandIndex) => {
              if (brandData[key].length >= 1 && brandData[key][0].hasPost > 0) {
                return (
                  <ul key={outerBrandIndex}>
                    <h2>{key}</h2>
                    {brandData[key].map((brand, innerBrandIndex) => {
                      if (chooseBrand && brand.hasPost > 0) {
                        return (
                          <li
                            className="brandlist-listing --choose-brand-grid"
                            key={innerBrandIndex}
                            onClick={() => {
                              if (chooseBrand) {
                                navigate(`/brand/${brand.name}`, { state: brand.embedded_link })
                              } else {
                                dispatch(setCarBrandFilter(brand.name))
                                dispatch(setShowBrandList(false))
                                //selectedBrandArray(brand.label)
                              }
                            }}>
                            <div>
                              <LazyLoadImage
                                src={brand.icon.length > 0 ? brand.icon[0].url : ''}
                                width="60"
                                alt="brand-icon"
                              />
                            </div>
                            <div>
                              <p>{brand.name}</p>
                            </div>
                            <div
                              className="selectedIcon"
                              style={{
                                display: carBrandFilter.includes(brand.name) ? 'block' : 'none',
                              }}>
                              <FontAwesomeIcon icon={solid('square-check')} />
                            </div>
                          </li>
                        )
                      } else {
                        return ''
                      }
                    })}
                  </ul>
                )
              }
            })}
          </Modal.Body>
          {!chooseBrand && (
            <Modal.Footer className="brandlist-modal-footer">
              <div
                className="d-flex align-items-center"
                onClick={() => {
                  dispatch(setDisplayBrandModelData([]))
                  dispatch(setDisplayBrandModelFilter([]))
                  dispatch(setCarBrandFilter(''))
                }}>
                Clear
              </div>
              <div className="darkTheme-btn reset-btn --all">Apply</div>
            </Modal.Footer>
          )}
        </Modal>
      ) :optionStep === "checkbox"  ? (
        <>
          {Object.keys(availableCarBrands).length !== 0
            ? Object.keys(availableCarBrands).map((key, index) => {
                return (
                  <div key={index}>
                    {availableCarBrands[key].map((brand, brandIndex) => {
                      const isLastItem = brandIndex === availableCarBrands[key].length - 1
                      return (
                        <li className="brandlist-listing --brand-grid" key={'brand-' + brandIndex}>
                          <div
                            className="mt-1"
                            key={'brand-div-' + brandIndex}
                            onClick={() => {
                              startZero(0)
                              backFirstPage(1)
                              if (brand.car_posts.length > 0) {
                                const brandName = brand.brand_name

                                const updatedBrandFilter = tempBrandFilterData.includes(brandName)
                                  ? tempBrandFilterData.filter(
                                      (selectedBrand) => selectedBrand !== brandName,
                                    )
                                  : [...tempBrandFilterData, brandName]

                                const anyPostsSelected = brand.car_posts.some((post) =>
                                  tempModelFilterData.includes(post),
                                )

                                let updatedModelFilterData = [...tempModelFilterData]
                                if (anyPostsSelected) {
                                  updatedModelFilterData = updatedModelFilterData.filter(
                                    (post) => !brand.car_posts.includes(post),
                                  )
                                } else {
                                  updatedModelFilterData = updatedModelFilterData.concat(
                                    brand.car_posts,
                                  )
                                }

                                const updatedDisplayText = getUpdatedDisplayText(
                                  updatedModelFilterData,
                                  updatedBrandFilter,
                                  originalCarBrands,
                                )
                                dispatch(setDisplayBrandModelData(updatedBrandFilter))
                                dispatch(setModelFilterData(updatedModelFilterData))
                                setTempBrandFilterData(updatedBrandFilter)
                                setTempModelFilterData(updatedModelFilterData)
                                function getUpdatedDisplayText(
                                  updatedModelFilter,
                                  updatedBrandFilter,
                                  brands,
                                ) {
                                  const updatedDisplayText = []
                                  for (const brandName of updatedBrandFilter) {
                                    const brand = brands.find(
                                      (brand) => brand.brand_name === brandName,
                                    )
                                    if (brand) {
                                      const brandPosts = brand.car_posts.filter((post) =>
                                        updatedModelFilter.includes(post),
                                      )
                                      if (brandPosts.length === brand.car_posts.length) {
                                        updatedDisplayText.push(brandName)
                                      } else if (brandPosts.length > 0) {
                                        updatedDisplayText.push(
                                          ...brandPosts.map((post) => `${brandName} ${post}`),
                                        )
                                      }
                                    }
                                  }
                                  return updatedDisplayText
                                }
                              }
                            }}>
                            <Icon
                              key={'brand-icon-' + brandIndex}
                              icon={
                                tempBrandFilterData.includes(brand.brand_name)
                                  ? 'charm:square-tick'
                                  : brand.car_posts == 0
                                  ? 'bi:dash'
                                  : 'charm:square'
                              }
                              color={
                                tempBrandFilterData.includes(brand.brand_name) && isDarkMode
                                  ? '#ffd439' :  tempBrandFilterData.includes(brand.brand_name)  ? "AC3434"
                                  : '#d1d5db'
                              }
                              width={width < 991 ? '16px' : '18px'}
                            />
                          </div>
                          <div
                            onClick={() => {
                              setModelDropdownState((prevState) => ({
                                ...prevState,
                                [brand.brand_name]: !prevState[brand.brand_name],
                              }))
                            }}>
                            <p>{brand.brand_name}</p>
                          </div>
                          <div
                            className="selectedIcon"
                            onClick={() => {
                              setModelDropdownState((prevState) => ({
                                ...prevState,
                                [brand.brand_name]: !prevState[brand.brand_name],
                              }))
                            }}>
                            <Icon
                              icon={
                                brand.car_posts.length > 0
                                  ? modelDropdownState[brand.brand_name]
                                    ? 'mingcute:down-line'
                                    : 'mingcute:up-line'
                                  : ''
                              }
                              color="#d1d5db"
                              width={width < 991 ? '16px' : '18px'}
                            />
                          </div>
                          {modelDropdownState[brand.brand_name] && brand.car_posts.length > 0 && (
                            <div className="model-row">
                              {brand.car_posts.map((post, postIndex) => (
                                <div key={postIndex}>
                                  <div
                                    key={postIndex}
                                    className={
                                      tempModelFilterData.includes(post)
                                        ? 'model-div --active'
                                        : 'model-div'
                                    }
                                    data-tooltip-id={`tooltip-${post}`}
                                    onClick={() => {
                                      const brandName = brand.brand_name

                                      let updatedModelFilterData = tempModelFilterData.includes(
                                        post,
                                      )
                                        ? tempModelFilterData.filter(
                                            (selectedModel) => selectedModel !== post,
                                          )
                                        : [...tempModelFilterData, post]

                                      const anyPostsSelected = brand.car_posts.some((post) =>
                                        updatedModelFilterData.includes(post),
                                      )

                                      let updatedBrandFilter = [...tempBrandFilterData]
                                      if (anyPostsSelected) {
                                        updatedBrandFilter = addBrandIfNotExists(
                                          updatedBrandFilter,
                                          brandName,
                                        )
                                      } else {
                                        updatedBrandFilter = updatedBrandFilter.filter(
                                          (selectedBrand) => selectedBrand !== brandName,
                                        )
                                      }
                                      const updatedDisplayText = getUpdatedDisplayText(
                                        updatedModelFilterData,
                                        updatedBrandFilter,
                                        originalCarBrands,
                                      )
                                      dispatch(setDisplayBrandModelData(updatedBrandFilter))
                                      dispatch(setModelFilterData(updatedModelFilterData))
                                      setTempBrandFilterData(updatedBrandFilter)
                                      setTempModelFilterData(updatedModelFilterData)

                                      function addBrandIfNotExists(brandList, targetBrand) {
                                        if (!brandList.includes(targetBrand)) {
                                          return [...brandList, targetBrand]
                                        }
                                        return brandList
                                      }
                                      function getUpdatedDisplayText(
                                        updatedModelFilter,
                                        updatedBrandFilter,
                                        brands,
                                      ) {
                                        const updatedDisplayText = []
                                        for (const brandName of updatedBrandFilter) {
                                          const brand = brands.find(
                                            (brand) => brand.brand_name === brandName,
                                          )
                                          if (brand) {
                                            const brandPosts = brand.car_posts.filter((post) =>
                                              updatedModelFilter.includes(post),
                                            )
                                            if (brandPosts.length === brand.car_posts.length) {
                                              updatedDisplayText.push(brandName)
                                            } else if (brandPosts.length > 0) {
                                              updatedDisplayText.push(
                                                ...brandPosts.map((post) => `${brandName} ${post}`),
                                              )
                                            }
                                          }
                                        }
                                        return updatedDisplayText
                                      }
                                    }}>
                                    {post}
                                  </div>
                                  <ReactTooltip
                                    id={`tooltip-${post}`} // Define unique tooltip IDs
                                    place="top"
                                    effect="solid"
                                    content={post}
                                    className='model-tooltip'
                                  />
                                </div>
                              ))}
                            </div>
                          )}
                        </li>
                      )
                    })}
                  </div>
                )
              })
            : ''}
        </>
      ) : ""}
    </>
  )
}