//lib
import { MDBBtn, MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setContactAgent } from '../../../redux/reducer/carPostReducer'
import { Skeleton } from 'primereact/skeleton'

export default function CarPanel({ loading }) {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const { quick_look_main } = useSelector((state) => state.carPost)
  const { isDarkMode } = useSelector((state) => state.home)

  const openWhatsapp = () => {
    const whatsappNumber = `+6${quick_look_main.company_mobile_number}`
    const message = `Hello, I${"'"}m interested with this ${
      quick_look_main.car_model_name
    }, how D-Concept can help to import?`
    const url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
      message,
    )}`
    window.open(url, '_blank')
  }

  return (
    <MDBCol className="car-panel-container mt-4">
      <MDBRow className="toolbar-box">
        {width >= 911 && (
          <MDBRow className="contact-section justify-content-center">
            {/* <MDBRow className="col-6 text-center d-flex align-item-center justify-content-end"> */}
            {!loading ? (
              <MDBBtn
                className="button-group --contact-agent"
                onClick={() => dispatch(setContactAgent(true))}>
                CONTACT D-AGENT
              </MDBBtn>
            ) : (
              <Skeleton width="18em" height="35px" className="mt-1 mb-2" />
            )}
            {/* </MDBRow> */}
            {/* <MDBCol className="col-6 text-center d-flex align-item-center justify-content-start">
              {!loading ? (
                <MDBBtn className="button-group --whatsapp" onClick={openWhatsapp}>
                  <Icon icon="logos:whatsapp-icon" width="20" color="#10141A" />
                  <span className="ps-2 pt-2">WhatsApp</span>
                </MDBBtn>
              ) : (
                <Skeleton width="18em" height="35px" className="ms-4 mt-1 mb-2" />
              )}
            </MDBCol> */}
          </MDBRow>
        )}
        <MDBCol className="col-4 d-flex align-items-center p-0">
          {!loading ? (
            <>
              <div>
                <Icon icon="ph:car-simple-bold" color={isDarkMode ?  "#d1d5db" : "#686a71" } width={25} height={25} />
              </div>
              <div className="text-div">
                <label className="_label --options">Body</label>
                <label className="_label --options-label">{quick_look_main.car_bodies_name}</label>
              </div>
            </>
          ) : (
            <>
              <Skeleton width="3em" height="30px" borderRadius="100%" className="mt-3" />
              <Skeleton width="12em" height="15px" className="ms-3 mt-3" />
            </>
          )}
        </MDBCol>
        <MDBCol className="col-4 d-flex align-items-center p-0">
          {!loading ? (
            <>
              <div>
                <Icon
                  icon="material-symbols:event-seat-outline-rounded"
                  color={isDarkMode ?  "#d1d5db" : "#686a71" }
                  width={30}
                  height={28}
                />
              </div>
              <div className="text-div">
                <label className="_label --options">Seat</label>
                <label className="_label --options-label">{quick_look_main.car_seats_num}</label>
              </div>
            </>
          ) : (
            <>
              <Skeleton width="3em" height="30px" borderRadius="100%" className="mt-3" />
              <Skeleton width="12em" height="15px" className="ms-3 mt-3" />
            </>
          )}
        </MDBCol>
        <MDBCol className="col-4 d-flex align-items-center p-0">
          {!loading ? (
            <>
              <div>
                <Icon icon="simple-icons:rainmeter" color={isDarkMode ?  "#d1d5db" : "#686a71" } width={25} height={25} />
              </div>
              <div className="text-div">
                <label className="_label --options">Fuel Type</label>
                <label className="_label --options-label">{quick_look_main.car_fuels_name}</label>
              </div>
            </>
          ) : (
            <>
              <Skeleton width="3em" height="30px" borderRadius="100%" className="mt-3" />
              <Skeleton width="12em" height="15px" className="ms-3 mt-3" />
            </>
          )}
        </MDBCol>
        <MDBCol className="col-4 d-flex align-items-center p-0 mt-4">
          {!loading ? (
            <>
              <div>
                <Icon icon="bx:tachometer" color={isDarkMode ?  "#d1d5db" : "#686a71" } width={25} height={25} />
              </div>
              <div className="text-div">
                <label className="_label --options">Brand</label>
                <label className="_label --options-label">{quick_look_main.brand_name}</label>
              </div>
            </>
          ) : (
            <>
              <Skeleton width="3em" height="30px" borderRadius="100%" className="mt-2" />
              <Skeleton width="12em" height="15px" className="ms-3 mt-2" />
            </>
          )}
        </MDBCol>
        <MDBCol className="col-4 d-flex align-items-center p-0 mt-4">
          {!loading ? (
            <>
              <div>
                <Icon icon="bx:tachometer" color={isDarkMode ?  "#d1d5db" : "#686a71" } width={25} height={25} />
              </div>
              <div className="text-div">
                <label className="_label --options">Model</label>
                <label className="_label --options-label">{quick_look_main.car_model_name}</label>
              </div>
            </>
          ) : (
            <>
              <Skeleton width="3em" height="30px" borderRadius="100%" className="mt-2" />
              <Skeleton width="12em" height="15px" className="ms-3 mt-2" />
            </>
          )}
        </MDBCol>
        <MDBCol className="col-4 d-flex align-items-center p-0 mt-4">
          {!loading ? (
            <>
              <div>
                <Icon icon="tabler:engine" color={isDarkMode ?  "#d1d5db" : "#686a71" } width={30} height={35} />
              </div>
              <div className="text-div">
                <label className="_label --options">Model Year</label>
                <label className="_label --options-label">{quick_look_main.car_model_year}</label>
              </div>
            </>
          ) : (
            <>
              <Skeleton width="3em" height="30px" borderRadius="100%" className="mt-2" />
              <Skeleton width="12em" height="15px" className="ms-3 mt-2" />
            </>
          )}
        </MDBCol>
      </MDBRow>
    </MDBCol>
  )
}
