import _ from 'lodash'

//lib
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import useWindowDimensions from '../../../components/common/window-dimension'

//assets
import { CarPostDesktopBackground, CarPostMobileBackground } from '../../../assets/images'
import CarLoader2 from '../../../assets/json/car-loader.json'

//redux
import { useSelector } from 'react-redux'
import Lottie from 'lottie-react'

export default function ImageHeader({ divRef, loading }) {
  const { width } = useWindowDimensions()
  const { quick_look_main } = useSelector((state) => state.carPost)

  return (
    <MDBContainer className="image-header-container mt-2" ref={divRef}>
      {!loading ? (
        <>
          <LazyLoadImage
            alt="car-post-background"
            className="car-post-background"
            src={width >= 911 ? CarPostDesktopBackground : CarPostMobileBackground}
          />
          <LazyLoadImage
            alt="car-post-display-img"
            className="car-item"
            //src={width >= 911 ? CarDesktop : CarMobile}
            style={width >= 911 ? { width: '45%' } : { width: '80%' }}
            src={_.find(quick_look_main.medias, { image_type: 'display_image' })?.url}
          />
          {quick_look_main.stocks.length > 0 ||
          quick_look_main.directs.length > 0 ||
          quick_look_main.direct_import === 'yes' ? (
            <MDBRow className="post-tag-container">
              <div style={{alignItems : "center" , display : "f"}}>
                <label className={width > 991 ? "_label --order-type_desktop" : "_label --order-type"}>Order via</label>&nbsp;
                {quick_look_main.stocks && quick_look_main.stocks.length > 0 ? (
                  <>
                    <label className="_label --tag-order-type">D-Stock</label>&nbsp;
                  </>
                ) : (
                  ''
                )}
                {quick_look_main.directs && quick_look_main.directs.length > 0 ? (
                  <>
                    <label className="_label --tag-order-type">Used Car</label>&nbsp;
                  </>
                ) : (
                  ''
                )}
                {quick_look_main.direct_import && quick_look_main.direct_import === 'yes' ? (
                  <>
                    <label className="_label --tag-order-type">D-Import</label>&nbsp;
                  </>
                ) : (
                  ''
                )}
              </div>
            </MDBRow>
          ) : (
            ''
          )}
          <MDBRow className="post-brand-container mt-2">
            <div className="post-brand">
              <LazyLoadImage
                src={quick_look_main.icon.length > 0 && quick_look_main.icon[0].url}
                alt="brand-icon"
                width="35"
              />
              <label className="_label --brand">{quick_look_main.brand_name}</label>
            </div>
            {/* <div className="post-brand">
              <label className="_label --type">{quick_look_main.car_model_name}</label>
              <label className="_label --model">{quick_look_main.car_bodies_name}</label>
            </div> */}
          </MDBRow>
        </>
      ) : (
        <>
          <div className="overlay-img --post"></div>
          <div className="overlay-img-div --post d-flex justify-content-center">
            {/* <LazyLoadImage src={CarLoader} width="100%"/> */}
            <Lottie animationData={CarLoader2} style={{ width: '100%' }} />
          </div>
        </>
      )}
    </MDBContainer>
  )
}

{
  /* <div className='d-flex'>
  <Skeleton
    width="8em"
    height="15px"
    className='mt-2'
  />
  <Skeleton
    width="5em"
    height="15px"
    className='mt-2 ms-4'
  />
  <Skeleton
    width="5em"
    height="15px"
    className='mt-2 ms-4'
  />
  </div> */
}

{
  /* <>
    <div className="post-brand">
      <Skeleton
        width="4em"
        height="40px"
        borderRadius="100%"
      />
      <Skeleton
        width="5em"
        height="15px"
        className='mt-4'
      />
    </div>
      <div className="post-brand">
      <Skeleton
        width="15em"
        height="15px"
      />
      <label className="_label --model">
        <Skeleton
          width="10em"
          height="15px"
        />
      </label>
    </div>
  </> */
}
