import { useEffect } from 'react'

//lib
import { MDBContainer } from 'mdb-react-ui-kit'

//component
import Header from '../../components/header/header-main'
import ContactTable from './component/contact.table'

export default function ContactUs() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <MDBContainer className="common-main-container" breakpoint="none">
      <Header />
      <ContactTable />
    </MDBContainer>
  )
}
