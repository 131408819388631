import { useEffect } from 'react'

//lib
import { MDBContainer } from 'mdb-react-ui-kit'

//component
import CompareTable from './component/compare.table'
import CompareCard from './component/compare.card'

export default function CompareModel() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [window.location.pathname])

  return (
    <MDBContainer className="common-main-container" breakpoint="none">
      <CompareTable />
      <CompareCard />
      {/* <FooterOne /> */}
    </MDBContainer>
  )
}
