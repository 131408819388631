import React from 'react'
import { Field ,useFormikContext} from 'formik'
import { MDBRow, MDBInput} from 'mdb-react-ui-kit'

const Input = ({
  name,
  label,
  type,
  autoComplete,
  className,
  readOnly,
  errors,
  priceValue,
  ...rest
}) => {

  const { setFieldValue, values, touched } = useFormikContext();

  function formatNumberInput(inputValue) {
    const numericValue = Number(inputValue.replace(/[^0-9]/g, ""));
    const formattedValue = numericValue.toLocaleString();
    return formattedValue;
  }
  return (
    <>
      <MDBRow className={readOnly ? 'custom-field-code' : 'custom-field'}>
        {/* <label htmlFor={name}> {label} </label> */}
        <Field
          as={MDBInput}
          label={label}
          className={`form-control ${className}`}
          name={name}
          type={type}
          autoComplete={autoComplete}
          readOnly={readOnly}
          setfieldvalue={setFieldValue}
          {...rest}
          onChange={(e) => {
            if(priceValue){
              const newValue = formatNumberInput(e.target.value)
              setFieldValue(name , newValue)
            } else (
              setFieldValue(name , e.target.value)
            )
          }}
        />
      </MDBRow>
      {/* {errors && touched && errors[name] && touched[name] ? (
        <div className="text-danger text-right no-padding error-message">{errors[name]}</div>
      ) : null} */}
    </>
  )
}

export default Input
