import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//lib
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { motion } from 'framer-motion'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getNewBrand } from '../../../redux/reducer/homeReducer'
import {
  setCar1Data,
  setCar2Data,
  setCar3Data,
  setChooseBrand,
} from '../../../redux/reducer/carCompareReducer'

//helper
import LazyImage from '../../../components/common/common.image'

export default function LastSeen() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const { car_brand } = useSelector((state) => state.home)
  const selectedItems = JSON.parse(localStorage.getItem('selectedItems')) || []

  // last search
  function handleItemClick(id) {
    let updatedItems

    if (selectedItems.includes(id)) {
      updatedItems = selectedItems.filter((n) => n !== id)
    } else {
      updatedItems = selectedItems
    }

    updatedItems.unshift(id)

    if (updatedItems.length > 3) {
      updatedItems.pop()
    }

    localStorage.setItem('selectedItems', JSON.stringify(updatedItems))
    //setSelectedItems(updatedItems)
  }

  const handleClick = (itemCar) => {
    handleItemClick(itemCar.id)
    navigate(`/brand/${itemCar.name}`)
    dispatch(setChooseBrand(false))
    dispatch(setCar1Data({}))
    dispatch(setCar2Data({}))
    dispatch(setCar3Data({}))
  }

  useEffect(() => {
    dispatch(getNewBrand())
  }, [])

  return (
    <MDBContainer id={'image'} className="last-seen-container mt-5">
      <MDBRow className="main-title-row">
        <label className="_title-special-text">
          D - <span className="_title">CAR BRANDS</span>
        </label>
      </MDBRow>
      <MDBContainer className="car-brand-container">
        <MDBRow style={{ width: '100%' }}>
          {car_brand.length > 0 &&
            car_brand.map((item, index) => {
              return (
                <motion.div
                  key={index}
                  // animate={{opacity : 0}}
                  // whileInView={{
                  //   opacity:1
                  // }}
                  style={{
                    width: width > 991 ? '12.5%' : '33.33%',
                    maxWidth: width > 991 ? '12.5%' : '33.33%',
                  }}
                  className="brand-col"
                  onClick={() => handleClick(item)}>
                  <div className="carBrand-container">
                    <div className="carBrand-div">
                      <LazyImage
                       item={item.icon ? item.icon[0] && item.icon[0] : null}
                        src={item.icon ? item.icon[0] && item.icon[0].url : ''}
                        alt="item.name"
                        className="carBrand-image"
                      />
                    </div>
                    <MDBRow className="text-center">
                      <label className="_label">{item.name}</label>
                    </MDBRow>
                  </div>
                </motion.div>
              )
            })}
        </MDBRow>
      </MDBContainer>
    </MDBContainer>
  )
}
