import React from 'react'
import { useState, useRef, useEffect } from 'react'
import { Icon } from '@iconify/react'

//redux
import { useSelector } from 'react-redux'

//lib
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//assets
import useWindowDimensions from '../../../components/common/window-dimension'

//component
import TandCScroll from './t_and_c.scroll'
import TandCDropdown from './t_and_c.dropdown'

export default function TermsData() {
  const { width } = useWindowDimensions()

  const { t_and_c_Listing, privacyListing } = useSelector((state) => state.common)

  const section1 = useRef(null)
  const section2 = useRef(null)
  const containerRef = useRef(null)

  return (
    <MDBContainer className="about-us-container p-0">
      <MDBRow style={{marginTop : width > 991 ? "9em" : ""}}>
        <label className="_label">
          <span className="_text --gold">Terms of Use</span>∂
        </label>
      </MDBRow>
      <MDBRow className="d-flex justify-content-center">
        <label className="_label mt-5 mb-5">
          <span className="_text --subtitle">
            We believe service the best, values the customer and honestly always the best policy at
            all time∂
          </span>
        </label>
      </MDBRow>
      {width < 991 && <TandCDropdown section1={section1} section2={section2} />}
      <MDBRow className="">
        {width > 991 && (
          <MDBCol className="col-4 t_c-row">
            <TandCScroll containerRef={containerRef} section1={section1} section2={section2} />
          </MDBCol>
        )}
        {width > 991 && <MDBCol className="col-4"></MDBCol>}
        <MDBCol className={width > 991 ? 'col-8' : 'col-12'}>
          <div ref={containerRef}>
            <div id="section1">
              <MDBRow className="d-flex justify-content-center">
                <label className="tc-title mt-5 mb-5" ref={section1}>
                  Terms and Conditions
                </label>
              </MDBRow>
              {t_and_c_Listing.map((item, index) => {
                return (
                  <div key={index} className="t_and_c-div">
                    <MDBRow>
                      <label className="_text --t_c_title">{item.title}</label>
                    </MDBRow>
                    <MDBRow>
                      <label className="_text --t_c_content">{item.content}</label>
                    </MDBRow>
                    {item.company && (
                      <MDBRow>
                        <label className="_text --t_c_detail">{item.company}</label>
                      </MDBRow>
                    )}
                    {item.address && (
                      <MDBRow>
                        <label className="_text --t_c_detail">{item.address}</label>
                      </MDBRow>
                    )}
                    {item.email && (
                      <MDBRow>
                        <label className="_text --t_c_detail">{item.email}</label>
                      </MDBRow>
                    )}
                  </div>
                )
              })}
            </div>
            <div id="section2">
              <MDBRow className="d-flex justify-content-center">
                <label className="tc-title mt-5 mb-5" ref={section2}>
                  Privacy Policy
                </label>
              </MDBRow>
              {privacyListing.map((item, index) => {
                return (
                  <div key={index} className="t_and_c-div">
                    <MDBRow>
                      <label className="_text --t_c_title">{item.title}</label>
                    </MDBRow>
                    <MDBRow>
                      <label className="_text --t_c_content">{item.content}</label>
                    </MDBRow>
                    {item.company && (
                      <MDBRow>
                        <label className="_text --t_c_detail">{item.company}</label>
                      </MDBRow>
                    )}
                    {item.address && (
                      <MDBRow>
                        <label className="_text --t_c_detail">{item.address}</label>
                      </MDBRow>
                    )}
                    {item.email && (
                      <MDBRow>
                        <label className="_text --t_c_detail">{item.email}</label>
                      </MDBRow>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}
