//lib
import { Icon } from '@iconify/react'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { Skeleton } from 'primereact/skeleton'
import { Galleria } from 'primereact/galleria'
import { Image } from 'primereact/image'
import { useRef } from 'react'
import useWindowDimensions from '../../../components/common/window-dimension'

export default function OptionsBox({ title, type, data, info, index, loading }) {
  // const uniqueKey = Date.now()
  const {width} = useWindowDimensions();
  const galleria = useRef(null)
  const itemTemplate = (item) => {
    return <img src={item} alt={item.alt} style={{ width: '100%', display: 'block' }} />
  }

  const thumbnailTemplate = (item) => {
    return <img src={item} alt={item.alt} style={{ display: 'block' }} />
  }

  let newData = []

  for (var key in data) {
    newData.push(data[key]?.url)
  }
  return (
    <MDBCol className="options-box-container">
      <MDBRow className="toolbar-box">
        {!loading ? (
          <label className="_label --options-title">{title}</label>
        ) : (
          <Skeleton width="20em" height="15px" className="mb-3" />
        )}
        <div className="line-container --options-box"></div>
        {type === 'image' && newData.length > 0 ? (
          !loading ? (
            <div>
              <MDBRow>
                <MDBCol onClick={() => galleria.current.show()}>
                  <Image src={newData[0]} alt="car-img" width="100%" height={width > 991 ? 200 : 100} />
                </MDBCol>
                {newData[1] && (
                  <MDBCol onClick={() => galleria.current.show()}>
                    <Image src={newData[1]} alt="car-img" width="100%" height={width > 991 ? 200 : 100} />
                  </MDBCol>
                )}
              </MDBRow>
              <MDBRow style={{ marginTop: '0.5em' }}>
                <MDBCol onClick={() => galleria.current.show()}>
                  <Image src={newData[2]} alt="car-img" width="100%" height={width > 991 ? 200 : 100} />
                </MDBCol>
                <MDBCol className="last-image-column" onClick={() => galleria.current.show()}>
                  <div className="image">
                    <Image src={newData[3]} alt="car-img" width="100%" height={width > 991 ? 200 : 100} />
                    {newData.length > 4 && (
                      <div className="overlay">
                        <label>+{newData.length - 3}</label>
                      </div>
                    )}
                  </div>
                </MDBCol>
              </MDBRow>
              <Galleria
                ref={galleria}
                value={newData}
                numVisible={9}
                style={width > 850 ? {maxWidth: '50%'} : {maxWidth: '100%'}}
                circular
                fullScreen
                showItemNavigators
                showThumbnails={false}
                item={itemTemplate}
                thumbnail={thumbnailTemplate}
              />
              {/* <ReactPhotoCollage
                key={uniqueKey}
                width="100%"
                height={['100px', '100px']}
                layout={[2, 2]}
                photos={
                  data.length > 0 &&
                  data.map((obj) => {
                    const sourceImage = {}
                    sourceImage['source'] = obj.url
                    return sourceImage
                  })
                }
                showNumOfRemainingPhotos={true}
              /> */}
            </div>
          ) : (
            <>
              <div className="gap-3 d-flex">
                <Skeleton width="100%" height="120px" className="mt-3 ms-2" />
                <Skeleton width="100%" height="120px" className="mt-3 ms-2" />
              </div>
              <div className="gap-3 d-flex">
                <Skeleton width="100%" height="120px" className="mt-3 ms-2" />
                <Skeleton width="100%" height="120px" className="mt-3 ms-2" />
              </div>
            </>
          )
        ) : (
          //data && data.map((item, index) => (
          <div className="p-0">
            {type === 'listing-text' ? (
              !loading ? (
                data &&
                data.map((item, index) => (
                  <div key={index} className="listing-text-container">
                    <Icon icon="mdi:car-sports" color="#ac4343" width={23} />
                    <div className="listing-option">
                      <label className="_label --options">
                        {title === 'Spec Option' ? `Option ${index + 1}` : `Type ${index + 1}`}
                      </label>
                      <label className="_label --options-detail">{item.label}</label>
                    </div>
                  </div>
                ))
              ) : (
                <>
                  <div className="listing-text-container">
                    <Skeleton width="15em" height="15px" className="mb-3" />
                    <ul className="listing-option">
                      <li className="_label --options-detail">
                        <Skeleton width="25em" height="15px" />
                      </li>
                    </ul>
                  </div>
                </>
              )
            ) : type === 'listing-demension' ? (
              <div className="listing-box-container">
                {!loading ? (
                  <label className="_label --options">Measure</label>
                ) : (
                  <Skeleton width="10em" height="15px" className="mb-4" />
                )}
                {data ? (
                  <MDBRow>
                    <MDBCol className="text-center">
                      {!loading ? (
                        <>
                          <label className="_label --options-label">Length</label>
                          <label className="_label --options-detail">{data.length}</label>
                        </>
                      ) : (
                        <Skeleton width="10em" height="15px" className="me-3 mb-2" />
                      )}
                    </MDBCol>
                    <MDBCol className="text-center">
                      {!loading ? (
                        <>
                          <label className="_label --options-label">Width</label>
                          <label className="_label --options-detail">{data.width}</label>
                        </>
                      ) : (
                        <Skeleton width="10em" height="15px" className="me-3 mb-4" />
                      )}
                    </MDBCol>
                    <MDBCol className="text-center">
                      {!loading ? (
                        <>
                          <label className="_label --options-label">Height</label>
                          <label className="_label --options-detail">{data.height}</label>
                        </>
                      ) : (
                        <Skeleton width="10em" height="15px" className="me-3 mb-2" />
                      )}
                    </MDBCol>
                    <MDBCol className="text-center">
                      {!loading ? (
                        <>
                          <label className="_label --options-label">Wheelbase</label>
                          <label className="_label --options-detail">{data.wheelbase}</label>
                        </>
                      ) : (
                        <Skeleton width="10em" height="15px" className="me-3 mb-2" />
                      )}
                    </MDBCol>
                  </MDBRow>
                ) : (
                  ''
                )}
              </div>
            ) : !loading ? (
              data &&
              data.map((item, index) => (
                <div key={index} className="listing-box-container">
                  <label className="_label --options">
                    {title === 'Engine specifications' ||
                    title === 'Transmission' ||
                    title === 'Fuel efficiency'
                      ? `Type ${index + 1}`
                      : `Feature ${index + 1}`}
                  </label>
                  <ul className="listing-option">
                    <li className="_label --options-detail">{item.label}</li>
                  </ul>
                </div>
              ))
            ) : (
              <>
                <div className="listing-box-container">
                  <Skeleton width="15em" height="15px" className="mb-3" />
                  <ul className="listing-option">
                    <li className="_label --options-detail">
                      <Skeleton width="25em" height="15px" />
                    </li>
                  </ul>
                </div>
              </>
            )}
          </div>
        )}
      </MDBRow>
    </MDBCol>
  )
}
