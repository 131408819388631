//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//reducer
import { useDispatch, useSelector } from 'react-redux'
import { setLoginModal } from '../../../redux/reducer/orderReducer'

//helper 
import LazyImage from '../../../components/common/common.image'

export default function HowItWork() {
  const { width , height } = useWindowDimensions()
  const dispatch = useDispatch()

  const token = localStorage.getItem('token')
  const { how_it_work , isDarkMode } = useSelector((state) => state.home)

  return (
    <MDBContainer className="how-it-work-container mt-5">
      <MDBRow className="main-title-row">
        <label className="_title-special-text">
          D - <span style={{color :height > 894 &&  width >= 653 &&  width < 992 && !isDarkMode ? "#D1D5DB" : ""}} className="_title">PURCHASE FLOW</span>
        </label>
      </MDBRow>
      <div
        className="card-carousel-detail"
        style={!token ? { cursor: 'pointer' } : { cursor: 'unset' }}>
        {width < 991 ? (
          <Swiper
            slidesPerView={2.3}
            spaceBetween={20}
            breakpoints={{
              640: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
              1024: {
                slidesPerView: 5,
              },
            }}
            className="mySwiper">
            {how_it_work.map((item, index) => (
              <SwiperSlide
                key={index}
                className="col-4 col-md-3 card-card-container"
                onClick={() => {
                  if (!token) {
                    dispatch(setLoginModal(true))
                  }
                }}>
                <MDBCol>
                  <LazyImage
                    alt="how-it-work-img"
                    src={item.icon}
                    width={width >= 991 ? '100%' : 103}
                    height={width >= 991 ? 100 : 61}
                  />
                  <label className="_label --title">{item.label}</label>
                  <label className="_label --details">{item.desc}</label>
                </MDBCol>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          how_it_work.map((item, index) => (
            <MDBCol
              className="col-2"
              key={index}
              onClick={() => {
                if (!token) {
                  dispatch(setLoginModal(true))
                }
              }}>
              <div className="card-card-container">
                <LazyImage
                  alt="how-it-work-img"
                  src={item.icon}
                  width={width >= 991 ? '100%' : 103}
                  height={width >= 991 ? (width >= 1440 ? 100 : 80) : 61}
                />
                <label className="_label --title">{item.label}</label>
                <label className="_label --details">{item.desc}</label>
              </div>
            </MDBCol>
          ))
        )}
      </div>
    </MDBContainer>
  )
}
