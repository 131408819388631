import { useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import _ from 'lodash'
import { Icon } from '@iconify/react'
//lib
import {
  MDBBtn,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardText,
  MDBCol,
  MDBContainer,
  MDBRipple,
  MDBRow,
} from 'mdb-react-ui-kit'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import { EffectCreative } from 'swiper'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getStockList, setStockType } from '../../../redux/reducer/stockReducer'
import { Skeleton } from 'primereact/skeleton'

//component
import StockImage from './ready.stock.image'

export default function ReadyStock2() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()

  const { homeDStockList, homeDOwnerList, loading } = useSelector((state) => state.stock)
  const { isDarkMode } = useSelector((state) => state.home)

  useEffect(() => {
    dispatch(
      getStockList({
        search: '',
        body_type: '',
        brand_name: '',
      }),
    )
  }, [dispatch])

  const formatCash = (num, digits = 2) => {
    var units = ['k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'],
      decimal

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1)

      if (num <= -decimal || num >= decimal) {
        const number = +(num / decimal).toFixed(digits)
        if (units[i] === 'Y') {
          return number.toLocaleString() + units[i]
        }
        return number.toLocaleString() + units[i]
      }
    }

    return num
  }

  if (homeDStockList.length > 0 && homeDOwnerList.length > 0) {
    return (
      <MDBContainer className="ready-stock-container mt-5">
        <MDBRow className="main-title-row">
          <label className="_title-special-text">
            D - <span className="_title">READY STOCK</span>
          </label>
        </MDBRow>
        {homeDStockList.length > 0 && (
          <MDBContainer className="ready-stock-card-container">
            <MDBRow style={{ padding: width > 911 ? '0px 15px' : '0px 0px' }}>
              <MDBCol>
                <label className="_sub-title">D-Stock</label>
              </MDBCol>
              <MDBCol className="text-end">
                <MDBBtn
                  className={
                    isDarkMode ? 'button-group --view-all' : 'button-group --view-all light'
                  }
                  onClick={() => {
                    navigate('/stock')
                    dispatch(setStockType('D-Stock'))
                  }}>
                  View All
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <div className="ready-stock-card-carousel2 mt-3">
              <Swiper
                slidesPerView={width > 1400 ? 4 : width > 991 ? 3.5 : width > 400 ? 3.2 : 2.2}
                spaceBetween={width > 991 ? 0 : 15}
                grabCursor={true}
                creativeEffect={{
                  prev: {
                    shadow: false,
                    translate: ['-120%', 0, -500],
                  },
                  next: {
                    shadow: false,
                    translate: ['120%', 0, -500],
                  },
                }}
                style={{ borderRadius: '1em' }}
                modules={[EffectCreative]}>
                {!loading
                  ? homeDStockList.map((item, index) => (
                      <SwiperSlide className="card-container" key={index}>
                        <MDBCard style={{ width: width > 1400 ? '287px' : '' }}>
                          <MDBRipple>
                            <Link to={`/carInfo/${item.path_name}/${item.id}`}>
                              {width > 991 ? (
                                <StockImage data={item.medias} />
                              ) : (
                                <div className="section-top">
                                  <MDBCardImage
                                    src={
                                      _.find(item.medias, { image_type: 'ready_stock_image' })?.url
                                    }
                                    position="top"
                                    alt="ready-stock-car-img"
                                    onClick={() => {
                                      navigate(`/carInfo/${item.path_name}/${item.id}`)
                                    }}
                                  />
                                </div>
                              )}
                            </Link>

                            <MDBCardBody
                              className={width > 911 ? 'card-body-desktop' : 'card-body-mobile'}
                              onClick={() => {
                                navigate(`/carInfo/${item.path_name}/${item.id}`)
                              }}>
                              <MDBCardText>
                                {/* <MDBRow>
                                  <label className="_label --text">
                                    Price&nbsp;
                                    <label className='_label --card-price'>
                                        RM&nbsp;
                                      {width > 991
                                        ? parseInt(item.asking_price).toLocaleString()
                                        : parseInt(item.asking_price).toLocaleString()}
                                    </label>
                                  </label>
                                </MDBRow> */}
                                <div className="d-flex">
                                  <div className="carListing-border" />
                                  <div className="carListing-border" />
                                  <label className="_label --car-price2">
                                    <span>From </span>
                                    RM {parseInt(item.asking_price).toLocaleString()}
                                  </label>
                                  <div className="carListing-border2" />
                                </div>
                                {width < 911 ? (
                                  <MDBRow className="border-row mt-2">
                                    <MDBCol className="col-7 border-col">
                                      <label className="_label --car-info">
                                        <Icon icon="mdi:barometer" />{' '}
                                        {/* {item.car_infos &&
                                          item.car_infos[0] &&
                                          item.car_infos[0].mileage.toLocaleString()} */}
                                        {item.car_infos && item.car_infos[0].mileage
                                          ? item.car_infos[0].mileage === '-'
                                            ? '-'
                                            : parseInt(item.car_infos[0].mileage).toLocaleString()
                                          : '-'}
                                        KM
                                      </label>
                                    </MDBCol>
                                    <MDBCol className="border-col">
                                      <label className="_label --car-info">
                                        <Icon icon="ri:building-3-line" />{' '}
                                        {item.car_infos &&
                                          item.car_infos[0] &&
                                          item.car_infos[0].manufactory_year}
                                      </label>
                                    </MDBCol>
                                  </MDBRow>
                                ) : (
                                  <>
                                    <MDBRow className="mt-2 mb-0">
                                      <label className="_label --info-desktop">
                                        {item.car_infos && item.car_infos[0].manufactory_year} /{' '}
                                        {/* {item.car_infos && item.car_infos[0].mileage.toLocaleString()} */}
                                        {item.car_infos && item.car_infos[0].mileage
                                          ? item.car_infos[0].mileage === '-'
                                            ? '-'
                                            : parseInt(item.car_infos[0].mileage).toLocaleString()
                                          : '-'}
                                        KM / {item.grade && item.grade}
                                      </label>
                                    </MDBRow>
                                    <MDBRow className="p-0 mb-0">
                                      <label className="_label --info-desktop">
                                        {item.chassis_number && item.chassis_number
                                          ? item.chassis_number.substring(
                                              item.chassis_number.length - 5,
                                            )
                                          : '-'}{' '}
                                        / {item.username ? item.username : '-'}
                                      </label>
                                    </MDBRow>
                                  </>
                                )}
                                <MDBRow style={{ alignItems: 'center', height: '3.5em' }}>
                                  <label className="_label --car-modal-name">
                                    {/* {capitalizeWords(item.car_model_name ? item.car_model_name : "")}   */}
                                    {item.car_model_name && item.car_model_name}
                                  </label>
                                </MDBRow>
                              </MDBCardText>
                            </MDBCardBody>
                          </MDBRipple>
                        </MDBCard>
                      </SwiperSlide>
                    ))
                  : Array.from({ length: 4 }, (_, index) => (
                      <SwiperSlide className="card-container" key={index}>
                        <Skeleton width="100%" height="220px" />
                        <div className="p-2">
                          <Skeleton width="100%" height="15px" className="mt-3" />
                          <Skeleton width="60%" height="15px" className="mt-4" />
                          <Skeleton width="80%" height="15px" className="mt-3" />
                        </div>
                      </SwiperSlide>
                    ))}
              </Swiper>
            </div>
          </MDBContainer>
        )}
        {homeDOwnerList.length > 0 && (
          <MDBContainer className="ready-stock-card-container">
            <MDBRow style={{ padding: width > 911 ? '0px 15px' : '0px 0px' }}>
              <MDBCol>
                <label className="_sub-title">Direct Owner</label>
              </MDBCol>
              <MDBCol className="text-end">
                <MDBBtn
                  className={
                    isDarkMode ? 'button-group --view-all' : 'button-group --view-all light'
                  }
                  onClick={() => {
                    navigate('/stock')
                    dispatch(setStockType('Direct Owner'))
                  }}>
                  View All
                </MDBBtn>
              </MDBCol>
            </MDBRow>
            <div className="ready-stock-card-carousel2 mt-3">
              <Swiper
                slidesPerView={width > 1400 ? 4 : width > 991 ? 3.5 : width > 400 ? 3.2 : 2.2}
                spaceBetween={width > 991 ? 0 : 15}
                // loop={true}
                grabCursor={true}
                creativeEffect={{
                  prev: {
                    shadow: false,
                    translate: ['-120%', 0, -500],
                  },
                  next: {
                    shadow: false,
                    translate: ['120%', 0, -500],
                  },
                }}
                style={{ borderRadius: '1em' }}
                modules={[EffectCreative]}>
                {!loading
                  ? homeDOwnerList.map((item, index) => (
                      <SwiperSlide className="card-container" key={index}>
                        <MDBCard style={{ width: width > 1400 ? '287px' : '' }}>
                          <MDBRipple>
                            <Link to={`/carInfo/${item.path_name}/${item.id}`}>
                              {width > 991 ? (
                                <StockImage data={item.medias} />
                              ) : (
                                <div className="section-top">
                                  <MDBCardImage
                                    src={
                                      _.find(item.medias, { image_type: 'ready_stock_image' })?.url
                                    }
                                    position="top"
                                    alt="ready-stock-car-img"
                                    onClick={() => {
                                      navigate(`/carInfo/${item.path_name}/${item.id}`)
                                    }}
                                  />
                                </div>
                              )}
                            </Link>
                            <MDBCardBody
                              className={width > 911 ? 'card-body-desktop' : 'card-body-mobile'}
                              onClick={() => {
                                navigate(`/carInfo/${item.path_name}/${item.id}`)
                              }}>
                              <MDBCardText>
                                {/* <MDBRow>
                                  <label className="_label --text">
                                    Price &nbsp;
                                    <label className='_label --card-price'>
                                        RM&nbsp; {parseInt(item.asking_price).toLocaleString()}
                                    </label>
                                  </label>
                                </MDBRow> */}
                                <div className="d-flex">
                                  <div className="carListing-border" />
                                  <div className="carListing-border" />
                                  <label className="_label --car-price2">
                                    <span>From </span>
                                    RM {parseInt(item.asking_price).toLocaleString()}
                                  </label>
                                  <div className="carListing-border2" />
                                </div>
                                {width < 911 ? (
                                  <MDBRow className="border-row mt-2">
                                    <MDBCol className="col-7 border-col">
                                      <label className="_label --car-info">
                                        <Icon icon="mdi:barometer" />{' '}
                                        {/* {item.car_infos && item.car_infos[0] && item.car_infos[0].mileage.toLocaleString()} */}
                                        {item.car_infos && item.car_infos[0].mileage
                                          ? item.car_infos[0].mileage === '-'
                                            ? '-'
                                            : parseInt(item.car_infos[0].mileage).toLocaleString()
                                          : '-'}
                                        KM
                                      </label>
                                    </MDBCol>
                                    <MDBCol className="border-col">
                                      <label className="_label --car-info">
                                        <Icon icon="ri:building-3-line" />{' '}
                                        {item.car_infos &&
                                          item.car_infos[0] &&
                                          item.car_infos[0].manufactory_year}
                                      </label>
                                    </MDBCol>
                                  </MDBRow>
                                ) : (
                                  <>
                                    <MDBRow className="mt-2 mb-0">
                                      <label className="_label --info-desktop">
                                        {item.car_infos && item.car_infos[0].manufactory_year} /{' '}
                                        {/* {item.car_infos && item.car_infos[0].mileage.toLocaleString()} */}
                                        {item.car_infos && item.car_infos[0].mileage
                                          ? item.car_infos[0].mileage === '-'
                                            ? '-'
                                            : parseInt(item.car_infos[0].mileage).toLocaleString()
                                          : '-'}
                                        KM / {item.grade && item.grade}
                                      </label>
                                    </MDBRow>
                                    <MDBRow className="p-0 mb-0">
                                      <label className="_label --info-desktop">
                                        {item.chassis_number && item.chassis_number
                                          ? item.chassis_number.substring(
                                              item.chassis_number.length - 5,
                                            )
                                          : '-'}{' '}
                                        / {item.username ? item.username : '-'}
                                      </label>
                                    </MDBRow>
                                  </>
                                )}
                                <MDBRow style={{ alignItems: 'center', height: '3.5em' }}>
                                  <label className="_label --car-modal-name">
                                    {item.car_model_name && item.car_model_name}
                                  </label>
                                </MDBRow>
                              </MDBCardText>
                            </MDBCardBody>
                          </MDBRipple>
                        </MDBCard>
                      </SwiperSlide>
                    ))
                  : Array.from({ length: 4 }, (_, index) => (
                      <SwiperSlide className="card-container" key={index}>
                        <Skeleton width="100%" height="220px" />
                        <div className="p-2">
                          <Skeleton width="100%" height="15px" className="mt-3" />
                          <Skeleton width="60%" height="15px" className="mt-4" />
                          <Skeleton width="80%" height="15px" className="mt-3" />
                        </div>
                      </SwiperSlide>
                    ))}
              </Swiper>
            </div>
          </MDBContainer>
        )}
      </MDBContainer>
    )
  }
}
