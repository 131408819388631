//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import Lottie from 'lottie-react'

//assets
import DiscoveryNoData from '../../../assets/json/discovery-no-data.json'

export default function AskBotView() {
  const { width } = useWindowDimensions();
  const animationStyle = {
    width: width >= 991 ? '20%' : '60%',
  }
  return (
    <MDBContainer>
      <MDBRow
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          marginTop: '6em',
          marginLeft: '0',
          marginRight: '0',
          color: '#D1D5DB',
          fontFamily: 'cabin-regular',
        }}>
        <label
          style={{
            fontFamily: 'inter-regular',
            fontSize: '22px',
            fontWeight: '600',
            color: '#33363b',
            fontStyle: 'italic',
            textTransform: 'capitalize',
          }}>
            Upcoming Soon
        </label>
        <Lottie animationData={DiscoveryNoData} style={animationStyle} />
      </MDBRow>
    </MDBContainer>
  )
}
