import { useFormikContext } from 'formik'
import { MDBRow } from 'mdb-react-ui-kit'

import AuthCode from 'react-auth-code-input'
//import ReactInputVerificationCode from 'react-input-verification-code'

export default function Code({ name, className, label }) {
  const { errors, touched, setFieldValue } = useFormikContext()

  const handleChange = (e) => {
    setFieldValue(name, e)
  }

  return (
    <div className={`${className}`}>
      {label && (
        <MDBRow>
          <label
            className="form-label"
            style={className ? { color: '#D1D5DB' } : { color: '#24262D' }}>
            {label}
          </label>
        </MDBRow>
      )}
      <MDBRow
        className={`form-group _text ${
          errors && touched && errors[name] && touched[name] ? '--errors' : ''
        }`}>
        <div className="code-container">
          <AuthCode
            allowedCharacters="numeric"
            autoFocus={false}
            length={6}
            isPassword={false}
            inputClassName="code-input"
            // value={''}
            onChange={(e) => handleChange(e)}
            // placeholder={false}
            // type="password"
            // onCompleted={(e) => handleCompleted(e)}
          />
        </div>
      </MDBRow>
      <MDBRow>
        {errors && touched && errors[name] && touched[name] ? (
          <div className="text-right no-padding error-message ps-3">{errors[name]}</div>
        ) : null}
      </MDBRow>
    </div>
  )
}
