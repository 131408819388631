import React, { useEffect } from 'react'
import { useState } from 'react'
import { Icon } from "@iconify/react";
import { motion ,AnimatePresence} from 'framer-motion'
import moment from "moment/moment";

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedVideo } from '../../../redux/reducer/discoverReducer';
import {discoverImage , LogoD} from '../../../assets/images';

//lib
import {
  MDBContainer,
  MDBRow,
  MDBCol
} from 'mdb-react-ui-kit'
import ReactPlayer from "react-player";
import { RemoveScroll } from 'react-remove-scroll'

//assets
import useWindowDimensions from '../../../components/common/window-dimension';

//helper
import LazyImage from '../../../components/common/common.image';

export default function VideoInfo({setSearch}) {
    const {width , height} = useWindowDimensions()
    const { isDarkMode } = useSelector((state) => state.home)
  const dispatch = useDispatch()
  const [close , setClose]=useState(false)
  const [videoStatus , setVideoStatus] =useState(false)
  
  const { selectedVideo} = useSelector((state) => state.discover)

  const tagArray = selectedVideo.tag !== ""  && selectedVideo.tag !== null ? selectedVideo.tag.split(/[\s,#\r\n]+/).filter(tag => tag.trim() !== "") : [];
  const trimmedArray = tagArray.map(str => str.trim());

  const closeModal = () => {
      setClose(true)
    const timeOutId = setTimeout(() => {
          dispatch(setSelectedVideo({}))
      }, 550)
      return () => clearTimeout(timeOutId)
  }

  const closeModal2 = () => {
        dispatch(setSelectedVideo({}))
}

  const openWhatsapp = () => {
    const message = "Hello, I want to know what the car is about the video."
    const url = `https://api.whatsapp.com/send?phone=6${selectedVideo.phone_number}&text=${encodeURIComponent(message,)}`
    window.open(url, '_blank')
  }

  const handleSearch = (item) => {
    setSearch(item)
    dispatch(setSelectedVideo({}))
  }

  return (
    <RemoveScroll>


    {/* //destop ui */}

    {width > 991 &&
    <AnimatePresence>
    <motion.div className="video_info-div no-background" 
    layoutId={"div-"+selectedVideo.id} 
    // layoutId={"card-"+selectedVideo.id}
    // layoutId={"card-"+selectedVideo.id}
    initial={{opacity : 0 ,  transition: {
      duration: 0.4,
      delay: 0.3,
    },}}
    animate={{opacity : 1}}
    >
    <div className='back_btn' onClick={() => closeModal2()}><Icon icon="mingcute:back-line" color={isDarkMode ? "#f6f6f6" : '#393939'} height={"1.3em"}/></div>
    
      <motion.div 
      initial={{opacity : 1}}
      animate={{opacity : 1 }}
      >
   {width > 991 && <div className='video_info-div' style={{position : "fixed" , zIndex : 1000 , cursor : "pointer"}} onClick={() => closeModal2()}></div>}
        <MDBContainer className={'video_info-container p-0'} style={{maxWidth :width > 1200 ?  "1000px" : "800px" , maxHeight :width > 1200 ? "700px" : "400px" , height : "500px" }}>
         <MDBRow style={{height : "100%"}}>
       <MDBCol className="col-7 p-0 info-left">
        <motion.div 
       layoutId={"card-"+selectedVideo.id}
        // layoutId={"div-"+selectedVideo.id} 
        style={{height : "100%"}}>
      <ReactPlayer
          className="desktop-video"
          url={selectedVideo.medias?.[0]?.url}
          id={`video-${selectedVideo.id}`}
          width={"100%"}
          height={"100%"}
          controls={true}
          loop={false}
          playing={true}
          light={selectedVideo.medias?.[1]?.url   ? selectedVideo.medias?.[1]?.url   : false}
          />
        </motion.div>
     </MDBCol>
     <MDBCol className="col-5 p-0 info-rigth">
    <MDBRow style={{margin : "0.9em 0em 0em 0em" , alignItems : "center"}}>
        <MDBCol className='col-2'>
            <div className='avatar-div' onClick={() => openWhatsapp()}>
                <LazyImage src={selectedVideo?.upload_by_img?.[0]?.url ? selectedVideo?.upload_by_img?.[0]?.url  : LogoD} alt={"Dconcept Agent"} height={"100%"} width={"100%"} style={{objectFit : "cover" , borderRadius : "50%"}}/>
            </div>
        </MDBCol>
        <MDBCol>
            <MDBRow>
            <label className='_label --upload_by'>{selectedVideo.upload_by ? selectedVideo.upload_by : "D-concept"}</label>
            </MDBRow>
            <label className='_label --upload_by'> {moment(selectedVideo.created_at , "DD/MM/YYYY hh:mm").format("DD.MM.yyyy")}</label>
    </MDBCol>
    </MDBRow>
    <MDBRow className='row-description' style={{margin : "0.6em 0em 0em 0em" , alignItems : "center" , maxHeight :width > 1200 ?  "420px" : "322px" , overflow : "scroll"}}>
        <motion.div
        className='_p --description'
        style={{fontSize : width > 1200  ? "16px" : "14px"}}
        initial={{opacity : 0 , transform : "translateY(20px)"}}
        animate={{opacity : 1 , transform : "translateY(0)" ,  transition: {
          duration: 0.4,
          delay: 0.3,
        },}}
        >
     {selectedVideo.description}
        </motion.div>
        <motion.div   initial={{opacity : 0 , transform : "translateY(20px)"}}
        animate={{opacity : 1 , transform : "translateY(0)" ,  transition: {
          duration: 0.4,
          delay: 0.3,
        },}} className='mt-1'>
        {trimmedArray.length > 0 && trimmedArray.map((item , index) => {
          return (
            <span key={index} className='span-tag' 
        style={{fontSize : width > 1200  ? "16px" : "14px"}}
            onClick={() => handleSearch(item)}>#{item} </span>
          )
        })}
        </motion.div>
    </MDBRow>
    </MDBCol>
         </MDBRow>
        </MDBContainer>
      </motion.div>
    </motion.div>
    </AnimatePresence>}

    {/* //mobile ui */}
    {width < 991 && 
    <motion.div
    // style={{ opacity: 0, transform: "translateX(390px)" }}
    initial={{ opacity: 0, transform: "translateX(390px)" }}
    animate={
      close === true
        ? {
            opacity: 1,
            transform: "translateX(390px)",
            transition: {
              duration: 0.4,
              delay: 0.3,
            },
          }
        : {
            opacity: 1,
            transform: "translateX(0)",
            transition: {
              duration: 0.4,
              delay: 0.3,
            },
          }
    }
    className='video_info-mobile'
    >
    {/* <div className='back_btn' onClick={() => closeModal()}><Icon icon="ic:baseline-close" color='#f6f6f6' height={"1.5em"}/></div> */}
    <motion.div
    // layoutId={"card-"+selectedVideo.id}
    initial={{opacity : 0}}
    animate={{opacity : 1}}
    className='info-div'
    >
         <MDBRow style={{padding : "1em 0em" , alignItems : "center"}}>
        <MDBCol className='col-3 text-center'>
        <Icon icon="mdi:arrow-back" color={isDarkMode ? "#f6f6f6" : '#393939'} height={"1.5em"} onClick={() => closeModal()}/>
        </MDBCol>
        <MDBCol className='text-center'>
         <label className='_label --title'>Post</label>
    </MDBCol>
    <MDBCol className='col-3 text-center'>
        </MDBCol>
    </MDBRow>
    <motion.div 
    // layoutId={"video-"+selectedVideo.id} 
    style={{height : "270px" ,marginTop : "1em" ,
    backgroundImage: `url(${discoverImage})`,
    backgroundSize: '100% 100%',
    backgroundRepeat : "no-repeat",
    position : "relative"
    }}>
     {!videoStatus && <div className="video-loader">
  <p className="heading">Loading</p>
  <div className="loading">
    <div className="load"></div>
    <div className="load"></div>
    <div className="load"></div>
    <div className="load"></div>
  </div>
</div>}
       <ReactPlayer
          url={selectedVideo.medias?.[0]?.url}
        //   url={Video1}
          id={`video-${selectedVideo.id}`}
          width={"100%"}
          height={"100%"}
          style={{
          }}
          controls={videoStatus}
          playsinline={true}
          // muted={true}
          volume={0.3}
          playing={videoStatus}
          onReady={(e) => {
            const timeOutId = setTimeout(() => {
              setVideoStatus(true)
          }, 2000)
          return () => clearTimeout(timeOutId)
          }}
          loop={false}       
          pip={false} 
          />
        </motion.div>
          <MDBRow style={{margin : "1em 0em 0.5em 0em" , alignItems : "center"}}>
        <MDBCol className='col-2'>
            <div className='avatar-div' onClick={() => openWhatsapp()}>
            <LazyImage src={selectedVideo?.upload_by_img?.[0]?.url ? selectedVideo?.upload_by_img?.[0]?.url  : LogoD} alt={"'Dconcept discover"} height={"100%"} width={"100%"} style={{objectFit : "cover" , borderRadius : "50%"}}/>
            </div>
        </MDBCol>
        <MDBCol style={{marginLeft : "-1em"}}>
            <MDBRow>
            <label className='_label --upload_by'>{selectedVideo.upload_by ? selectedVideo.upload_by : "D-concept"}</label>
            </MDBRow>
            <MDBRow>
            <label className='_label --upload_by'> {moment(selectedVideo.created_at , "DD/MM/YYYY hh:mm").format("DD.MM.yyyy")}</label>
            </MDBRow>
    </MDBCol>
    </MDBRow>
        <MDBRow className='row-description' style={{margin : "0.6em 0em 0em 0em" , alignItems : "center" , maxHeight : height - 403 + "px" , overflow : "scroll" }}>
        <motion.div
         style={{fontSize : width > 1200  ? "16px" : "14px"}}
        className='_p --description'
        initial={{opacity : 0 , transform : "translateY(20px)"}}
        animate={{opacity : 1 , transform : "translateY(0)" , transitionDuration : "0.5s" , transitionDelay : "0.3s"}}
        >
     {selectedVideo.description} 
        </motion.div>
        <div className='mt-1'>
        {trimmedArray.length > 0 && trimmedArray.map((item , index) => {
          return (
            <span key={index} className='span-tag'  style={{fontSize : width > 1200  ? "16px" : "14px"}} onClick={() => handleSearch(item)}>#{item} </span>
          )
        })}
        </div>
    </MDBRow>
    </motion.div>
    </motion.div>
    }
    </RemoveScroll>
  )
}
