import { useState } from 'react'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'

export default function OrderCard({ data }) {
  const { width } = useWindowDimensions()

  const [active, setActive] = useState(false)

  const handleDownload = (event, fileUrl) => {
    event.preventDefault()

    const link = document.createElement('a')
    link.href = fileUrl
    link.setAttribute('target', '_blank')
    link.setAttribute('download', '')
    link.style.display = 'none'

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  // const download = (url) => {
  //   var element = document.createElement("a");
  //   var file = new Blob(
  //     [
  //       url
  //     ],
  //     { type: "image/*" }
  //   );
  //   element.href = URL.createObjectURL(file);
  //   //element.download = "image.jpg";
  //   element.click();
  // };

  //const bol = data.task?.filter((item) => item.description === 'BOL ')

  const containerHeight = width >= 991 ? 4.5 : 5.3
  // const topContainerHeight = 7
  // const dataLength =
  //     data.task?.length !== 1
  //     ? data.task.length * 3 + containerHeight + topContainerHeight
  //     : 5 + containerHeight + topContainerHeight
  return (
    <>
      <MDBContainer
        style={{
          height: active && data.status === 'Pending' ? '14em' : active ? 'auto' : `${containerHeight}em`
        }}
        className={
          active ? `order-card-container --active ` : `order-card-container --${data.status}`
        }
        onClick={() =>
          data.status === '-' ? '' : data.status !== 'complete' ? setActive(!active) : ''
        }>
        <label className="_label --white-text-huge">SALES ORDER : {data.sales_order}</label>
        <label className="_label --white-text-big">
          Status : <span className="_label --gold-text">{data.status}</span>
        </label>
        {data.status !== 'complete' && (
          <MDBRow className="padding_column">
            <MDBCol>
              <label className="_label --white-text-medium">FROM :</label>
              <MDBRow>
                <label className="_label --grey-text-small">
                  ({data.from['registration number']})
                </label>
              </MDBRow>
              <MDBRow className="">
                <label className="_label --white-text-medium">{data.from?.name}</label>
              </MDBRow>
            </MDBCol>
            <MDBCol className="text-left">
              <label className="_label --white-text-medium">TO : </label>
              <MDBRow>
                <label>
                  <span className="_label --grey-text-medium">Name :</span>
                  <span className="_label --white-text-medium">
                    &nbsp;{data.to?.name ? data.to.name : '-'}
                  </span>
                </label>
              </MDBRow>
              <MDBRow>
                <label>
                  <span className="_label --grey-text-medium">Contact :</span>
                  <span className="_label --white-text-medium">&nbsp;{data.to?.contact}</span>
                </label>
              </MDBRow>
            </MDBCol>
            {data.status !== 'Pending' ? <div className="line-container --gold-small"></div> : ''}
          </MDBRow>
        )}
        <div>
          {data.status === 'Pending'
            ? ''
            : data?.task?.map((item, index) => (
                <div 
                  key={index} 
                  className="padding_column_small" 
                >
                  {item?.description === 'BOL ' ? (
                    <MDBRow style={{ height: '3em' }}>
                      <MDBCol className="col-3 col-md-2 date_column">
                        <label>{item.completeDate ? item.completeDate : '-'}</label>
                      </MDBCol>
                      <MDBCol className="col-1 text-center dot_column">
                        <label className="line-container --dot"></label>
                      </MDBCol>
                      <MDBCol>
                        <label className="text-start">Car Success Order!&nbsp;&nbsp;&nbsp;</label>
                      </MDBCol>
                    </MDBRow>
                  ) : (
                    ''
                  )}
                  <MDBRow>
                    <MDBCol className="col-3 col-md-2 date_column">
                      <label>{item.completeDate ? item.completeDate : '-'}</label>
                    </MDBCol>
                    <MDBCol className="col-1 text-center dot_column">
                      <label className="line-container --dot"></label>
                    </MDBCol>
                    <MDBCol>
                      <label className="text-start">
                        {item.description}&nbsp;&nbsp;&nbsp;
                        <span>{item.status === 'Completed' ? item.status : ''}</span>
                      </label>
                    </MDBCol>
                    <MDBCol className="text-center col-2">
                      {item.fileUrl?.length > 0 ? (
                        <a
                          href={`${item?.fileUrl}`}
                          onClick={(event) => {
                            setActive(active)
                            handleDownload(event, item?.fileUrl)
                          }}
                          download>
                          <Icon
                            icon="material-symbols:sim-card-download-sharp"
                            width="16"
                            height="20"
                          />
                        </a>
                      ) : (
                        ''
                      )}
                    </MDBCol>
                  </MDBRow>
                </div>
              ))}
        </div>
        {data.status !== 'complete' ? <div className="line-container --short-line"></div> : ''}
      </MDBContainer>
    </>
  )
}
