// Library Level //
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useSelector, useDispatch } from 'react-redux'

// Component Level //
import FormikForm from '../../../components/element/formik.form'
import Loader from '../../../components/element/loader'
import { sweetAlertHelper } from '../../../helper/sweetalert.helper'
import LoanResult from './loan.result'
import { postLoan } from '../../../redux/reducer/calculatorReducer'
import Input from '../../../components/element/input'

async function onSubmit(data, setFieldError, dispatch) {
  const value = {
    down_payment: data.down_payment.replace(/,/g, ''),
    interest_rate: data.interest_rate,
    loan_period: data.loan_period,
    total_amount: data.total_amount.replace(/,/g, ''),
  }
  try {
    dispatch(postLoan(value))
    // sweetAlertHelper({
    //   title: 'Loan has calculated',
    //   text: 'Please refer to the loan table below',
    //   icon: 'success',
    // })
  } catch (ex) {
    if (ex && ex.response.status === 422) {
      const errors = ex.response.data.errors

      if (errors && Object.keys(errors).length > 0) {
        // eslint-disable-next-line array-callback-return
        Object.keys(errors).map((item, i) => {
          setFieldError(item, errors[item])
        })
      }
    }
  }
}

const LoanForm = () => {
  const dispatch = useDispatch()
  const { loanData, loading, loanResult } = useSelector((state) => state.calculator)

  const loanSchema = Yup.object({
    total_amount: Yup.string().required('Please enter total amount'),
    down_payment: Yup.string().required('Please enter down payment'),
    interest_rate: Yup.number().required('Please enter interest rate'),
    loan_period: Yup.number()
      .integer('Loan period must be an integer')
      .min(1, 'Loan period cannot be 0')
      .required(' '),
  })

  const date = new Date()
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const monthsOfYear = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const formattedDate = `${daysOfWeek[date.getDay()]}, ${date.getFullYear()} ${
    monthsOfYear[date.getMonth()]
  } ${date.getDate()}`

  return (
    <MDBContainer className="calculator-loan-form">
      <Formik
        initialValues={loanData}
        enableReinitialize={true}
        validationSchema={loanSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          onSubmit(values, setFieldError, dispatch)
          setSubmitting(false)
        }}>
        {({ setFieldValue, errors, isSubmitting, isValid }) => (
          <>
            {!loading ? (
              <Form className="formik-form">
                <MDBRow className="roadtax-remark">
                  <MDBRow className="roadtax-remark-content">
                    <label className="white">
                      Find out if you can afford your dream car using our car Loan Calculator
                    </label>
                    <span className="font-highlight no-width">{formattedDate}</span>
                  </MDBRow>
                </MDBRow>
                <Input
                  name="total_amount"
                  label="Total Car Amount"
                  type="text"
                  autoComplete="off"
                  readOnly={false}
                  className="className"
                  priceValue={true}
                />
                <Input
                  name="down_payment"
                  label="Down Payment"
                  type="text"
                  autoComplete="off"
                  readOnly={false}
                  className="className"
                  priceValue={true}
                />
                {/* {FormikForm.renderInput(
                  'total_amount',
                  'Total Car Amount',
                  'number',
                  'off',
                  'className',
                  false,
                  errors,
                )} */}
                {/* {FormikForm.renderInput(
                  'down_payment',
                  'Down Payment',
                  'number',
                  'off',
                  'className',
                  false,
                  errors,
                )} */}
                {FormikForm.renderInput(
                  'interest_rate',
                  'Interest Rate %',
                  'number',
                  'off',
                  'className',
                  false,
                  errors,
                )}
                {FormikForm.renderInput(
                  'loan_period',
                  'Loan Period (In Years)',
                  'number',
                  'off',
                  'className',
                  false,
                  errors,
                )}
                {errors && errors.loan_period && (
                  <div className="text-danger no-padding" style={{ fontSize: '0.8em' }}>
                    {errors.loan_period}
                  </div>
                )}
                <MDBRow className="roadtax-result">
                  <label className="roadtax-result year">Monthly Payment</label>
                  <label className="roadtax-result amount">
                    {' '}
                    RM{' '}
                    {loanResult && loanResult.monthly
                      ? Number(loanResult.monthly).toFixed(2).toLocaleString()
                      : '0'}
                  </label>
                </MDBRow>
                {FormikForm.renderButton('Calculate', isSubmitting, isValid, 'btn-submit')}
              </Form>
            ) : (
              <Loader
                style={{
                  position: 'fixed',
                  left: '44%',
                  top: '44%',
                }}
              />
            )}
          </>
        )}
      </Formik>

      <LoanResult payload={loanResult ? loanResult.population : []} />
    </MDBContainer>
  )
}

export default LoanForm
