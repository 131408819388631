import { Field } from 'formik'
import { MDBRow } from 'mdb-react-ui-kit'

export default function Select({ label, name, options, disabled, placeholder, className }) {
  return (
    <MDBRow className={`custom-field _select ${className}`}>
      {/* {label && (
        <MDBRow>
          <label className="form-label">{label}</label>
        </MDBRow>
      )} */}
      <Field as="select" name={name} disabled={disabled}>
        <option value="">{placeholder ? placeholder : `- Please select ${label} -`}</option>
        {options &&
          Object.keys(options).map((key, index) => (
            <option key={key} value={key}>
              {options[key]}
            </option>
          ))}
      </Field>
      {/* {errors && errors[name] ? (
        <div className="text-danger text-right no-padding error-message">{errors[name]}</div>
      ) : null} */}
    </MDBRow>
  )
}
