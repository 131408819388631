import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'

//lib
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setAllCarData } from '../../../redux/reducer/carCompareReducer'
import { setShowBrandList } from '../../../redux/reducer/stockReducer'
import { setLastView } from '../../../redux/reducer/homeReducer'

export default function CarItem({ item, index }) {
  const { id } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  const formatCash = (num, digits = 2) => {
    var units = ['k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'],
      decimal

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1)

      if (num <= -decimal || num >= decimal) {
        const number = +(num / decimal).toFixed(digits)
        if (units[i] === 'Y') {
          return number.toLocaleString() + units[i]
        }
        return number.toLocaleString() + units[i]
      }
    }

    return num
  }

  function handlePostClick(id) {
    let updatedPosts

    const storedPosts = JSON.parse(localStorage.getItem('selectedPosts')) || []
    if (storedPosts.includes(id)) {
      updatedPosts = storedPosts.filter((n) => n !== id)
    } else {
      updatedPosts = storedPosts
    }

    updatedPosts.unshift(id)

    if (updatedPosts.length > 5) {
      updatedPosts.pop()
    }

    localStorage.setItem('selectedPosts', JSON.stringify(updatedPosts))
  }

  const { chooseBrand } = useSelector((state) => state.car_compare)

  return (
    <>
      {item.status === 'active' ? (
        <MDBCol
          className="car-item-container col-12 col-md-6 col-lg-4"
          breakpoint="none"
          style={{ cursor: 'pointer' }}
          key={index}>
          <MDBRow
            className="car-item-box"
            style={{ cursor: 'pointer' }}
            onClick={() => {
              if (chooseBrand) {
                // navigate(`/calculator?tab=compare`)
                dispatch(setAllCarData(item))
                dispatch(setShowBrandList(false))
              } else {
                handlePostClick(item.id)
                localStorage.setItem('brand', id)
                dispatch(setLastView(false))
                navigate(`/post/${item.id}`)
              }
            }}>
            {item.medias ? (
              <MDBCol className="col-4 d-flex align-items-center">
                <LazyLoadImage
                  src={_.find(item.medias, { image_type: 'display_image' })?.url}
                  width="100%"
                  alt="..."
                />
              </MDBCol>
            ) : (
              ''
            )}
            <MDBCol style={{ lineHeight: '1.4em' }}>
              <label className="_label --title">{item.car_model_name}</label>
              {/* <label className="_label --detail">Estimate price range</label>
              <label className="_label --detail">
                RM&nbsp;
                {width > 991
                  ? parseInt(item.starting_price).toLocaleString()
                  : formatCash(item.starting_price)}
              </label> */}
              <div>
                {item.stock_total > 0 ? (
                  <>
                    <label className="_label --tag">D-Stock</label>&nbsp;
                  </>
                ) : (
                  ''
                )}
                {item.used_total > 0 ? (
                  <>
                    <label className="_label --tag">D-owner</label>&nbsp;
                  </>
                ) : (
                  ''
                )}
                {item.direct_import === 'yes' ? (
                  <>
                    <label className="_label --tag">Import</label>&nbsp;
                  </>
                ) : (
                  ''
                )}
                {item.preorder_total > 0 ? (
                  <>
                    <label className="_label --tag">Preorder</label>&nbsp;
                  </>
                ) : (
                  ''
                )}
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCol>
      ) : (
        ''
      )}
    </>
  )
}
