import { useEffect, useState } from 'react'
import { getFilterOption, setData, setEmptyData, setHasMore, setOwnerData, setOwnerLoading, setOwnerTotalRecord, setStart, setStockData, setStockLoading, setStockTotalRecord, setTotalRecord, stockDetailListing } from '../../../redux/reducer/stockReducer'
import { useDispatch, useSelector } from 'react-redux'
import InfiniteSrcollListing2 from './infinite.scoll.listing2'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import InfiniteScroll from 'react-infinite-scroll-component'
import { Skeleton } from 'primereact/skeleton'
import { useLocation, useSearchParams } from 'react-router-dom'
import queryString from 'query-string'

//helper
import useWindowDimensions from '../../../components/common/window-dimension'

export default function StockListing({ search, searchLoading, startData, selectedPage }) {
  const dispatch = useDispatch()
  const location = useLocation()
  const { width } = useWindowDimensions()
  const [searchParams, setSearchParams] = useSearchParams()

  const parsedQuery = queryString.parse(location.search)

  // const [loading, setLoading] = us)eState(true)
  const [fecthMoreLoading, setFetchMoreLoading] = useState(false)
  const [current, setCurrent] = useState(0)
  // const [hasMore, setHasMore] = useState(true)
  // const [data, setData] = useState([])
  // const [emptyData, setEmptyData] = useState(false)
  const [catchError, setCatchError] = useState(false)

  const {
    stockLoading,
    ownerLoading,
    hasMore,
    emptyData,
    stockData,
    ownerData,
    searchStock,
    stockType,
    length,
    start,
    carBodyTypeFilter,
    carBrandFilter,
    carYearFilter,
    carPriceFilter,
    carModelFilter,
    sortByFilter,
    sortBySequence,
  } = useSelector((state) => state.stock)

  const currentYear = new Date().getFullYear()

  const getFirstEntries = () => {
    // setCurrent(0)
    dispatch(setOwnerData([]))
    dispatch(setStockData([]))

    if(stockType === 'D-Stock') {
      dispatch(setStockLoading(true))
    } else {
      dispatch(setOwnerLoading(true))
    }

    dispatch(setHasMore(true))
    dispatch(setEmptyData(false))

    setCatchError(false)
    dispatch(
      stockDetailListing({
        search: (parsedQuery.search && parsedQuery.search.toString().toLowerCase()) 
        || searchStock?.toString().toLowerCase(),
        start: 9 * (parsedQuery.page - 1) || startData,
        length: 9,
        brand_name: carBrandFilter,
        car_post: carModelFilter,
        start_price: carPriceFilter[0] !== 50000 || carPriceFilter[1] !== 5000000 ? carPriceFilter[0] : '',
        end_price: carPriceFilter[0] !== 50000 || carPriceFilter[1] !== 5000000 ? carPriceFilter[1] : '',
        start_year: carYearFilter[0] !== 1990 || carYearFilter[1] !== currentYear ? carYearFilter[0] : '',
        end_year: carYearFilter[0] !== 1990 || carYearFilter[1] !== currentYear ? carYearFilter[1] : '',
        sort_by: sortByFilter,
        sort_order: sortBySequence,
        body_type: carBodyTypeFilter,
        ready_stock_type: stockType === 'D-Stock' ? 'd-stock' : 'direct owner',
      }),
    )
    .unwrap()
    .then((res) => {
      if (res.data.iFilterTotalRecords === 0) {
        dispatch(setHasMore(false))
        dispatch(setEmptyData(true))
      }
      dispatch(setHasMore(false))
      if(stockType === 'D-Stock') {
        dispatch(setStockLoading(false))
        dispatch(setStockData(res.data.aaData))
        dispatch(setStockTotalRecord(res.data.iFilterTotalRecords))
      } else {
        dispatch(setOwnerLoading(false))
        dispatch(setOwnerData(res.data.aaData))
        dispatch(setOwnerTotalRecord(res.data.iFilterTotalRecords))
      }

      // setCurrent((prevState) => {
      //   const newState = prevState + res.data.aaData.length
      //   if (newState >= res.data.iFilterTotalRecords) {
      //     dispatch(setHasMore(false))
      //   }
      //   return newState
      // })
    })
    .catch((ex) => {})
  }

  useEffect(() => {
    getFirstEntries()
  }, [
    // searchStock,
    parsedQuery.search,
    carBodyTypeFilter,
    carYearFilter,
    parsedQuery.page,
    carBrandFilter,
    carModelFilter,
    carPriceFilter,
    sortByFilter,
    sortBySequence,
  ])

  useEffect(() => {
    dispatch(getFilterOption({
      ready_stock_type: stockType === 'D-Stock' ? 'd-stock' : 'direct owner',
    }))
  }, [stockType])

  if (width < 1200 && (stockType === 'D-Stock' ? stockLoading : ownerLoading)) {
    return (
      <MDBRow className="carList-container-grid m-0">
        {[1, 2, 3].map((item, index) => (
          <MDBCol
            key={index}
            className={'col-12 col-md-6 col-lg-6 col-xl-4 carListing-container mt-2'}>
            <MDBRow className="carListing">
              <MDBCol className="col-5 col-md-6 d-flex justify-content-center align-items-center carListing-img-container">
                <div className="carListing-img">
                  <Skeleton width="100%" height="100%" />
                </div>
              </MDBCol>
              <MDBCol className="col-6 col-md-6 p-2 mt-4">
                <Skeleton width="100%" height="15px" />
                <Skeleton width="50%" height="15px" className="mt-5" />
                <Skeleton width="80%" height="15px" className="mt-4" />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        ))}
      </MDBRow>
    )
  }

  if (width > 1200 && (stockType === 'D-Stock' ? stockLoading : ownerLoading)) {
    return (
      <MDBContainer className="">
        <MDBRow className="carList-container-grid m-0">
          {[1, 2, 3].map((item, index) => (
            <MDBCol key={index} className={'col-4 carListing-container mt-2'}>
              <MDBRow className="carListing">
                <div className="carListing-img" style={{ maxHeight: '144px', width: '300px' }}>
                  <Skeleton width="100%" height="100%" />
                </div>
              </MDBRow>
              <MDBRow className="carListing">
                <div className="carListing-img" style={{ maxHeight: '38px', width: '300px' }}>
                  <Skeleton width="100%" height="100%" />
                </div>
              </MDBRow>
              <MDBRow className="carListing">
                <div className="carListing-img" style={{ maxHeight: '19px', width: '300px' }}>
                  <Skeleton width="100%" height="100%" />
                </div>
              </MDBRow>
            </MDBCol>
          ))}
        </MDBRow>
      </MDBContainer>
    )
  }

  if (emptyData && !(stockType === 'D-Stock' ? stockLoading : ownerLoading) && (stockType === 'D-Stock' ? stockData.length === 0 : ownerData.length === 0)) {
    return (
      <MDBRow className="carList-container-grid m-0">
        <MDBRow
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            marginTop: '6em',
            marginLeft: '0',
            marginRight: '0',
            color: '#D1D5DB',
            fontFamily: 'cabin-regular',
          }}>
          <label
            style={{
              fontFamily: 'inter-regular',
              fontSize: '22px',
              fontWeight: '600',
              color: '#33363b',
              fontStyle: 'italic',
              textTransform: 'capitalize',
            }}>
            No data available
          </label>
        </MDBRow>
      </MDBRow>
    )
  }

  if (catchError) {
    return (
      <MDBRow className="carList-container-grid m-0">
        <MDBRow
          style={{
            textAlign: 'center',
            justifyContent: 'center',
            marginTop: '6em',
            marginLeft: '0',
            marginRight: '0',
            color: '#D1D5DB',
            fontFamily: 'cabin-regular',
          }}>
          <label
            style={{
              fontFamily: 'inter-regular',
              fontSize: '22px',
              fontWeight: '600',
              color: '#33363b',
              fontStyle: 'italic',
              textTransform: 'capitalize',
            }}>
            Too Many Attempts.
          </label>
        </MDBRow>
      </MDBRow>
    )
  }

  return (
    <InfiniteScroll
      className=""
      dataLength={stockType === 'D-Stock' ? stockData.length : ownerData.length}
      next={() => {
        //fetchMoreData()
      }}
      hasMore={hasMore}
      loader={<h4 className="text-center">Loading...</h4>}>
      
      <>
        <MDBContainer>
          {!(stockType === 'D-Stock' ? stockLoading : ownerLoading) && (stockType === 'D-Stock' ? stockData.length > 0 : ownerData.length > 0) && <MDBRow
            style={{
              marginTop: '2em',
              marginLeft: 0,
              marginRight: 0,
              marginBottom: '1em',
              position: 'relative',
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <div className="carList-title">
              <div className="decorativeLine lineOne">
                <div className="rhombusOne rhombus">&#9670;</div>
              </div>
              <div className="decorativeLine lineTwo">
                <div className="rhombusTwo rhombus">&#9670;</div>
              </div>
            </div>
            <label className="title-text text-uppercase">All</label>
          </MDBRow>}
          <InfiniteSrcollListing2
            loading={fecthMoreLoading}
            data={stockType === 'D-Stock' ? stockData : ownerData}
            type={stockType === 'D-Stock' ? 'Recon Car' : 'Used Car'}
          />
        </MDBContainer>
      </>
    </InfiniteScroll>
  )
}

// export const carInfoRow = ({ item, index, fecthMoreLoading, newData, stockType, bodyType }) => {
//   return (
//     <div key={index}>
//       {bodyType && (
//         <MDBRow
//           style={{
//             marginTop: '2em',
//             marginLeft: 0,
//             marginRight: 0,
//             marginBottom: '1em',
//             position: 'relative',
//             alignItems: 'center',
//             display: 'flex',
//             justifyContent: 'center',
//           }}>
//           <div className="carList-title">
//             <div className="decorativeLine lineOne">
//               <div className="rhombusOne rhombus">&#9670;</div>
//             </div>
//             <div className="decorativeLine lineTwo">
//               <div className="rhombusTwo rhombus">&#9670;</div>
//             </div>
//           </div>
//           <label className="title-text text-uppercase">{item}</label>
//         </MDBRow>
//       )}
//       <MDBContainer>
//         <InfiniteSrcollListing2
//           loading={fecthMoreLoading}
//           data={newData.filter((array) => array.car_bodies_name === item)}
//           type={stockType === 'D-Stock' ? 'Recon Car' : 'Used Car'}
//         />
//       </MDBContainer>
//     </div>
//   )
// }

  // const fetchMoreData = () => {
  //   dispatch(
  //     stockDetailListing({
  //       search: search,
  //       start: start + 10,
  //       length: length,
  //       manufactoryYear: carYearFilter,
  //       bodyType: carBodyTypeFilter,
  //       brandName: carBrandFilter,
  //       stockType: stockType === 'D-Stock' ? 'd-stock' : 'direct owner',
  //     }),
  //   )
  //     .unwrap()
  //     .then((res) => {
  //       if (res.data.iFilterTotalRecords === 0) {
  //         setHasMore(false)
  //         setEmptyData(true)
  //       }
  //       dispatch(setLoading(false))
  //       setData((oldState) => [...oldState, ...res.data.aaData])
  //       setCurrent((prevState) => {
  //         const newState = prevState + res.data.aaData.length
  //         if (newState >= res.data.iFilterTotalRecords) {
  //           setHasMore(false)
  //         }
  //         return newState
  //       })
  //       dispatch(setStart(start + 10))
  //     })
  //     .catch((ex) => {})
  // }

    // useEffect(() => {
  //   if (searchLoading) {
  //     dispatch(setLoading(true))
  //   }
  // }, [searchLoading])

  // const getListing = ({
  //   total_record = 0,
  //   body_type = '',
  //   start = '',
  //   length = '',
  //   ready_stock_type = stockType === 'D-Stock' ? 'd-stock' : 'direct owner',
  // }) => {
  //   setEmptyData(false)
  //   dispatch(
  //     getStockList({
  //       search: search,
  //       body_type: body_type,
  //       start: start,
  //       length: length,
  //       brand_name: carBrandFilter,
  //       ready_stock_type: ready_stock_type,
  //     }),
  //   )
  //     .unwrap()
  //     .then((res) => {
  //       if (res.iFilterTotalRecords === 0) {
  //         dispatch(setLoading(false))
  //         setHasMore(false)
  //         setEmptyData(true)
  //       }
  //       // if (length >= 999999) {
  //       //   setBodyType({
  //       //     Convertible: 0,
  //       //     Coupe: 0,
  //       //     Hatchback: 0,
  //       //     MPV: 0,
  //       //     Sedan: 0,
  //       //     SUV: 0,
  //       //     Wagon: 0,
  //       //   })
  //       //   setData([...res.payload.aaData])
  //       //   setHasMore(false)
  //       // } else {
  //       setData((oldState) => [...oldState, ...res.aaData])
  //       // }
  //       setCurrent((prevState) => {
  //         const newState = prevState + res.aaData.length
  //         if (newState >= total_record) {
  //           setHasMore(false)
  //         }
  //         return newState
  //       })
  //       currentLength = currentLength + res.aaData.length
  //       if (currentLength >= 10) {
  //         dispatch(setLoading(false))
  //       } else if (currentLength >= total_record) {
  //         dispatch(setLoading(false))
  //       }
  //     })
  //     .catch((ex) => {
  //       if (ex.response.status === 429) {
  //         toast.error('Too Many Attempts. Please try agian later.', {
  //           autoClose: 3000,
  //           theme: 'colored',
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           closeButton: false,
  //           autoClose: false,
  //         })
  //       }
  //     })
  // }

  // let currentSum = 0
  // const calculateObjectBodyType = (obj) => {
  //   const targetSum = 10

  //   const selectedObject = {}
  //   const remainingObject = {}

  //   for (const [key, value] of Object.entries(obj)) {
  //     if (currentSum < targetSum) {
  //       const { count, skip } = value
  //       const remaining = targetSum - currentSum
  //       const selectedCount = Math.min(count, remaining)

  //       currentSum += selectedCount
  //       selectedObject[key] = { ...value, count: selectedCount }
  //       if (selectedCount < count) {
  //         remainingObject[key] = {
  //           count: count - remaining,
  //           skip: skip === undefined ? selectedCount : skip + selectedCount,
  //           separate: true,
  //         }
  //       }
  //     } else {
  //       remainingObject[key] = value
  //     }
  //   }
  //   // setSelectedBodyType(selectedObject)
  //   setNextBodyType(remainingObject)
  //   return { selectedObject, remainingObject }
  // }

  // const getFirstEntries = () => {
  //   setData([])
  //   setCurrent(0)
  //   dispatch(setLoading(true))
  //   setHasMore(true)
  //   setEmptyData(false)
  //   setCatchError(false)
  //   dispatch(
  //     stockDetailListing({
  //       search: search,
  //       start: start,
  //       length: 999999,
  //       bodyType: carBodyTypeFilter,
  //       brandName: carBrandFilter,
  //       stockType: stockType === 'D-Stock' ? 'd-stock' : 'direct owner',
  //     }),
  //   )
  //     .unwrap()
  //     .then((res) => {
  //       if (res.data.iFilterTotalRecords === 0) {
  //         dispatch(setLoading(false))
  //         setHasMore(false)
  //         setEmptyData(true)
  //       }
  //       setTotalRecord(res.data.iFilterTotalRecords)
  //       const updatedObject = Object.keys({
  //         Convertible: res.data.filterCarBody.Convertible,
  //         Coupe: res.data.filterCarBody.Coupe,
  //         Hatchback: res.data.filterCarBody.Hatchback,
  //         MPV: res.data.filterCarBody.MPV,
  //         Sedan: res.data.filterCarBody.Sedan,
  //         SUV: res.data.filterCarBody.SUV,
  //         Wagon: res.data.filterCarBody.Wagon,
  //       }).reduce((result, key) => {
  //         result[key] = { count: res.data.filterCarBody[key], separate: false }
  //         return result
  //       }, {})
  //       setBodyType({
  //         Convertible: res.data.filterCarBody.Convertible,
  //         Coupe: res.data.filterCarBody.Coupe,
  //         Hatchback: res.data.filterCarBody.Hatchback,
  //         MPV: res.data.filterCarBody.MPV,
  //         Sedan: res.data.filterCarBody.Sedan,
  //         SUV: res.data.filterCarBody.SUV,
  //         Wagon: res.data.filterCarBody.Wagon,
  //       })
  //       const { selectedObject } = calculateObjectBodyType(updatedObject)
  //       for (var key in selectedObject) {
  //         if (key && selectedObject[key].count > 0) {
  //           getListing({
  //             start: start,
  //             length: selectedObject[key].count,
  //             body_type: key,
  //             total_record: res.data.iFilterTotalRecords,
  //           })
  //         }
  //       }
  //     })
  //     .catch((ex) => {
  //       if (ex.response.status === 429) {
  //         toast.error('Too Many Attempts. Please try agian later.', {
  //           autoClose: 3000,
  //           theme: 'colored',
  //           hideProgressBar: true,
  //           closeOnClick: true,
  //           closeButton: false,
  //           autoClose: false,
  //         })
  //       }
  //     })
  // }

  // const fetchMoreData = () => {
  //   setFetchMoreLoading(true)
  //   const { selectedObject } = calculateObjectBodyType(nextBodyType)
  //   for (var key in selectedObject) {
  //     if (key && selectedObject[key].count > 0) {
  //       getListing({
  //         start: selectedObject[key].separate ? selectedObject[key].skip : start,
  //         length: selectedObject[key].separate ? length : selectedObject[key].count,
  //         body_type: key,
  //         total_record: totalRecord,
  //       })
  //     }
  //   }
  // }

  // useEffect(() => {
  //   if (search.length > 0 || carBrandFilter || carBodyTypeFilter) {
  //     getListing({
  //       start: start,
  //       length: 999999,
  //       body_type: carBodyTypeFilter,
  //     })
  //   }
  // }, [search, carBrandFilter, carBodyTypeFilter])

  {/* {carBodyTypeFilter ? (
        Object.keys(bodyType).map((item, index) => {
          const newData = data.filter((array) => array.car_bodies_name === item)
          switch (item) {
            case 'Convertible':
              if (newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  fecthMoreLoading: fecthMoreLoading,
                  newData: newData,
                  stockType: stockType,
                  bodyType: carBodyTypeFilter,
                })
              }
              break
            case 'Coupe':
              const coupe = data.filter((array) => array.car_bodies_name === 'Convertible')

              if (coupe.length >= bodyType['Convertible'] && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  fecthMoreLoading: fecthMoreLoading,
                  newData: newData,
                  stockType: stockType,
                  bodyType: carBodyTypeFilter,
                })
              }
              break
            case 'Hatchback':
              const hatchback = data.filter((array) => array.car_bodies_name === 'Coupe')

              if (hatchback.length >= bodyType['Coupe'] && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  fecthMoreLoading: fecthMoreLoading,
                  newData: newData,
                  stockType: stockType,
                  bodyType: carBodyTypeFilter,
                })
              }
              break
            case 'MPV':
              const mpv = data.filter((array) => array.car_bodies_name === 'Hatchback')

              if (mpv.length >= bodyType['Hatchback'] && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  fecthMoreLoading: fecthMoreLoading,
                  newData: newData,
                  stockType: stockType,
                  bodyType: carBodyTypeFilter,
                })
              }
              break
            case 'Sedan':
              const sedan = data.filter((array) => array.car_bodies_name === 'MPV')

              if (sedan.length >= bodyType['MPV'] && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  fecthMoreLoading: fecthMoreLoading,
                  newData: newData,
                  stockType: stockType,
                  bodyType: carBodyTypeFilter,
                })
              }
              break
            case 'SUV':
              const suv = data.filter((array) => array.car_bodies_name === 'Sedan')

              if (suv.length >= bodyType['Sedan'] && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  fecthMoreLoading: fecthMoreLoading,
                  newData: newData,
                  stockType: stockType,
                  bodyType: carBodyTypeFilter,
                })
              }
              break
            case 'Wagon':
              const wagon = data.filter((array) => array.car_bodies_name === 'SUV')

              if (wagon.length >= bodyType['SUV'] && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  fecthMoreLoading: fecthMoreLoading,
                  newData: newData,
                  stockType: stockType,
                  bodyType: carBodyTypeFilter,
                })
              }
              break
            default:
              if (search.length > 0 && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  fecthMoreLoading: fecthMoreLoading,
                  newData: newData,
                  stockType: stockType,
                  bodyType: carBodyTypeFilter,
                })
              }
              break
          }
        })
      ) : ( */}
