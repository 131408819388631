import { useEffect } from 'react'

//lib
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'

//redux
import { useSelector, useDispatch } from 'react-redux'

//component
import FormikForm from '../../../components/element/formik.form'
import Loader from '../../../components/element/loader'
import Input from '../../../components/element/input'

//redux
import { getInsurance, postInsurance } from '../../../redux/reducer/calculatorReducer'

//helper
import { sweetAlertHelper } from '../../../helper/sweetalert.helper'

async function onSubmit(data, setFieldError, dispatch) {
  const value = {
    market_price: data.market_price.replace(/,/g, ''),
    ncd: data.ncd,
    engine_capacity: data.engine_capacity,
    coverage_type: data.coverage_type,
    location: data.location,
  }
  try {
    dispatch(postInsurance(value))
    // sweetAlertHelper({
    //   title: 'Insurance has calculated',
    //   text: 'Please refer to the result',
    //   icon: 'success',
    // })
  } catch (ex) {
    if (ex && ex.response.status === 422) {
      const errors = ex.response.data.errors

      if (errors && Object.keys(errors).length > 0) {
        Object.keys(errors).map((item, i) => {
          setFieldError(item, errors[item])
        })
      }
    }
  }
}

const InsuranceForm = () => {
  const dispatch = useDispatch()
  const { insuranceData, loading, insuranceParam, insuranceResult } = useSelector(
    (state) => state.calculator,
  )

  const date = new Date()
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const monthsOfYear = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const formattedDate = `${daysOfWeek[date.getDay()]}, ${date.getFullYear()} ${
    monthsOfYear[date.getMonth()]
  } ${date.getDate()}`

  useEffect(() => {
    dispatch(getInsurance())
  }, [])

  const insuranceSchema = Yup.object({
    market_price: Yup.string().required('Please enter your market price'),
    coverage_type: Yup.string().required('Please select coverage type'),
    location: Yup.string().required('Please select location'),
    engine_capacity: Yup.string().required('Please select car engine capacity'),
    ncd: Yup.string().required('Please select your NCD'),
  })

  return (
    <MDBContainer className="calculator-loan-form">
      <Formik
        initialValues={insuranceData}
        enableReinitialize={true}
        validationSchema={insuranceSchema}
        onSubmit={(values, { setSubmitting, setFieldError }) => {
          onSubmit(values, setFieldError, dispatch)
          setSubmitting(false)
        }}>
        {({ setFieldValue, errors, isSubmitting, isValid }) => (
          <>
            {!loading ? (
              <Form className="formik-form">
                <MDBRow className="roadtax-remark">
                  <MDBRow className="roadtax-remark-content">
                    <label className="white">
                      Calculator how much you will have to spend on your car insurance
                    </label>
                    <span className="font-highlight no-width">{formattedDate}</span>
                  </MDBRow>
                </MDBRow>
                <Input
                  name="market_price"
                  label="Market Price"
                  type="text"
                  autoComplete="off"
                  readOnly={false}
                  className="className"
                  priceValue={true}
                />
                {/* {FormikForm.renderInput(
                  'market_price',
                  'Market Price',
                  'number',
                  'off',
                  'className',
                  false,
                  errors,
                )} */}
                {FormikForm.renderSelect(
                  'coverage_type',
                  'coverage',
                  insuranceParam.coverage_type,
                  errors,
                )}
                {FormikForm.renderSelect('location', 'location', insuranceParam.location, errors)}
                {FormikForm.renderSelect(
                  'engine_capacity',
                  'engine capacity',
                  insuranceParam.engine_capacity,
                  errors,
                )}
                {FormikForm.renderSelect('ncd', 'non claim discount', insuranceParam.ncd, errors)}

                <MDBRow className="roadtax-result">
                  <label className="roadtax-result year">Yearly Insurance Payment</label>
                  <label className="roadtax-result amount">
                    {' '}
                    RM{' '}
                    {insuranceResult && insuranceResult.yearly
                      ? Number(insuranceResult.yearly).toFixed(2).toLocaleString()
                      : '0'}
                  </label>
                </MDBRow>
                {FormikForm.renderButton('Calculate', isSubmitting, isValid, 'btn-submit')}
              </Form>
            ) : (
              <Loader
                style={{
                  position: 'fixed',
                  left: '44%',
                  top: '44%',
                }}
              />
            )}
          </>
        )}
      </Formik>
    </MDBContainer>
  )
}

export default InsuranceForm
