import React from 'react'
import { useState } from 'react'
import _ from 'lodash'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import {
  MDBCard,
  MDBCardBody,
  MDBCardText,
  MDBRipple,
  MDBCol,
  MDBContainer,
  MDBRow,
} from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//icon
import { cancleIcon } from '../../../assets/images'

//redux
import { useSelector, useDispatch } from 'react-redux'
import {
  setCar1Data,
  setChooseBrand,
  setSelectedId,
} from '../../../redux/reducer/carCompareReducer'
import { setShowBrandList } from '../../../redux/reducer/stockReducer'

//components
import { BrandList } from '../../../components/element/brandList'

export default function CompareCardTable() {
  const { width } = useWindowDimensions()
  const dispatch = useDispatch()

  const [isCarSelected, setIsCarSelected] = useState([false, false, false])
  const [isSelectTwoClicked, setIsSelectTwoClicked] = useState(false)
  const [isSelectThreeClicked, setIsSelectThreeClicked] = useState(false)

  const { car1Data, car2Data, car3Data } = useSelector((state) => state.car_compare)

  const handleClearOne = (e) => {
    dispatch(setCar1Data({}))
  }

  const handleClearTwo = (e) => {
    setIsCarSelected([isCarSelected[0], false, isCarSelected[2]])
    setIsSelectTwoClicked(false)
  }

  const handleClearThree = (e) => {
    setIsCarSelected([isCarSelected[0], isCarSelected[1], false])
    setIsSelectThreeClicked(false)
  }

  const car1 = car1Data || {}
  const car2 = car2Data || {}
  const car3 = car3Data || {}

  const renderinfo = () => {
    if (car1.id) {
      return true
    } else if (car2.id) {
      return true
    } else if (car3.id) {
      return true
    } else {
      return false
    }
  }

  return (
    <MDBContainer className="compare-model-card-container">
      <div className="_padding --all">
        <div>
          <MDBRow>
            <label className="_text --first">Compare Cars</label>
          </MDBRow>

          <MDBRow>
            <MDBCol className="col-6 col-lg-4">
              <MDBCard
                onClick={() => {
                  dispatch(setShowBrandList(true))
                  dispatch(setChooseBrand(true))
                  dispatch(setSelectedId('1'))
                }}
                className="_radius">
                <MDBRipple className="_ripple --grey _radius">
                  <MDBCardBody className="_padding --none ">
                    <div style={{ position: 'relative' }}>
                      {car1.id ? (
                        <>
                          <div className="_card --size">
                            <LazyLoadImage
                              alt="car-1-img"
                              className="_card --img-size"
                              src={_.find(car1.medias, { image_type: 'display_image' })?.url}
                              position="top"
                            />
                          </div>

                          <div
                            style={{
                              position: 'absolute',
                              top: 4,
                              right: 4,
                              zIndex: 1,
                              pointerEvents: 'auto',
                              display: 'none',
                            }}>
                            <LazyLoadImage
                              alt="cancel-icon"
                              src={cancleIcon}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleClearOne()
                              }}
                            />
                          </div>

                          <div
                            style={{
                              position: 'absolute',
                              bottom: 0,
                              left: '50%',
                              transform: 'translateX(-50%)',
                              width: '100%',
                            }}>
                            <div className="_text --card-name">
                              <label>
                                <span>{car1.brand_name} </span>
                                <span>{car1.car_model_name}</span>
                              </label>
                            </div>

                            <div className="_text --card-small">
                              <MDBCardText>
                                <Icon
                                  icon="material-symbols:local-fire-department"
                                  color="#943d3f"
                                  width="18"
                                />
                                &nbsp;<label className="mt-1">Car 1</label>
                              </MDBCardText>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="">
                            <div className="_ripple --normal">
                              <MDBRow>
                                <svg
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 1.5A1.5 1.5 0 011.5 0H4v1H1.5a.5.5 0 00-.5.5V4H0V1.5zM9 1H6V0h3v1zm4.5 0H11V0h2.5A1.5 1.5 0 0115 1.5V4h-1V1.5a.5.5 0 00-.5-.5zM7 7V4h1v3h3v1H8v3H7V8H4V7h3zM0 9V6h1v3H0zm14 0V6h1v3h-1zM0 13.5V11h1v2.5a.5.5 0 00.5.5H4v1H1.5A1.5 1.5 0 010 13.5zm14 0V11h1v2.5a1.5 1.5 0 01-1.5 1.5H11v-1h2.5a.5.5 0 00.5-.5zM9 15H6v-1h3v1z"
                                    fill="currentColor"></path>
                                </svg>
                                <div>
                                  <label>Selected Vehicle</label>
                                </div>
                              </MDBRow>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                    <MDBCardText></MDBCardText>
                  </MDBCardBody>
                </MDBRipple>
              </MDBCard>
            </MDBCol>

            <MDBCol className="col-6 col-lg-4">
              <MDBCard
                onClick={() => {
                  dispatch(setShowBrandList(true))
                  dispatch(setChooseBrand(true))
                  dispatch(setSelectedId('2'))
                }}
                className="_radius">
                <MDBRipple className="_ripple --grey _radius">
                  <MDBCardBody className="_padding --none">
                    <div style={{ position: 'relative' }}>
                      {car2.id ? (
                        <>
                          <div className="_card --size">
                            <LazyLoadImage
                              alt="car-2-img"
                              className="_card --img-size"
                              src={_.find(car2.medias, { image_type: 'display_image' })?.url}
                              position="top"
                            />
                          </div>

                          <div
                            style={{
                              position: 'absolute',
                              top: 4,
                              right: 4,
                              zIndex: 1,
                              pointerEvents: 'auto',
                              display: isSelectTwoClicked ? 'block' : 'none',
                            }}>
                            <LazyLoadImage
                              alt="cancel-icon"
                              src={cancleIcon}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleClearTwo()
                              }}
                            />
                          </div>

                          <div
                            style={{
                              position: 'absolute',
                              bottom: 0,
                              left: '50%',
                              transform: 'translateX(-50%)',
                              width: '100%',
                            }}>
                            <div className="_text --card-name">
                              <label>
                                <span>{car2.brand_name} </span>
                                <span>{car2.car_model_name}</span>
                              </label>
                            </div>

                            <div className="_text --card-small">
                              <MDBCardText>
                                <Icon
                                  icon="material-symbols:local-fire-department"
                                  color="#943d3f"
                                  width="18"
                                />
                                &nbsp;<label className="mt-1">Car 2</label>
                              </MDBCardText>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="_ripple --normal">
                            <MDBRow>
                              <svg
                                viewBox="0 0 15 15"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                width="30"
                                height="30">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M0 1.5A1.5 1.5 0 011.5 0H4v1H1.5a.5.5 0 00-.5.5V4H0V1.5zM9 1H6V0h3v1zm4.5 0H11V0h2.5A1.5 1.5 0 0115 1.5V4h-1V1.5a.5.5 0 00-.5-.5zM7 7V4h1v3h3v1H8v3H7V8H4V7h3zM0 9V6h1v3H0zm14 0V6h1v3h-1zM0 13.5V11h1v2.5a.5.5 0 00.5.5H4v1H1.5A1.5 1.5 0 010 13.5zm14 0V11h1v2.5a1.5 1.5 0 01-1.5 1.5H11v-1h2.5a.5.5 0 00.5-.5zM9 15H6v-1h3v1z"
                                  fill="currentColor"></path>
                              </svg>
                              <div>
                                <label>Selected Vehicle</label>
                              </div>
                            </MDBRow>
                          </div>
                        </>
                      )}
                    </div>
                    <MDBCardText></MDBCardText>
                  </MDBCardBody>
                </MDBRipple>
              </MDBCard>
            </MDBCol>

            {width > 991 && (
              <MDBCol className="col-6 col-lg-4">
                <MDBCard
                  onClick={() => {
                    dispatch(setShowBrandList(true))
                    dispatch(setChooseBrand(true))
                    dispatch(setSelectedId('3'))
                  }}
                  className="_radius">
                  <MDBRipple className="_ripple --grey _radius">
                    <MDBCardBody className="_padding --none ">
                      <div style={{ position: 'relative' }}>
                        {car3.id ? (
                          <>
                            <div className="_card --size">
                              <LazyLoadImage
                                alt="car-3-img"
                                className="_card --img-size"
                                src={_.find(car3.medias, { image_type: 'display_image' })?.url}
                                position="top"
                              />
                            </div>

                            <div
                              style={{
                                position: 'absolute',
                                top: 4,
                                right: 4,
                                zIndex: 1,
                                pointerEvents: 'auto',
                                display: isSelectThreeClicked ? 'block' : 'none',
                              }}>
                              <LazyLoadImage
                                alt="cancel-icon"
                                src={cancleIcon}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  handleClearThree()
                                }}
                              />
                            </div>

                            <div
                              style={{
                                position: 'absolute',
                                bottom: 0,
                                left: '50%',
                                transform: 'translateX(-50%)',
                                width: '100%',
                                height: '3em',
                              }}>
                              <div className="_text --card-name">
                                <label>
                                  <span>{car3.brand_name} </span>
                                  <span>{car3.car_model_name}</span>
                                </label>
                              </div>

                              <div className="_text --card-small">
                                <MDBCardText>
                                  <Icon
                                    icon="material-symbols:local-fire-department"
                                    color="#943d3f"
                                    width="18"
                                  />
                                  &nbsp;<label className="mt-1">Car 3</label>
                                </MDBCardText>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="_ripple --normal">
                              <MDBRow>
                                <svg
                                  viewBox="0 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30">
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M0 1.5A1.5 1.5 0 011.5 0H4v1H1.5a.5.5 0 00-.5.5V4H0V1.5zM9 1H6V0h3v1zm4.5 0H11V0h2.5A1.5 1.5 0 0115 1.5V4h-1V1.5a.5.5 0 00-.5-.5zM7 7V4h1v3h3v1H8v3H7V8H4V7h3zM0 9V6h1v3H0zm14 0V6h1v3h-1zM0 13.5V11h1v2.5a.5.5 0 00.5.5H4v1H1.5A1.5 1.5 0 010 13.5zm14 0V11h1v2.5a1.5 1.5 0 01-1.5 1.5H11v-1h2.5a.5.5 0 00.5-.5zM9 15H6v-1h3v1z"
                                    fill="currentColor"></path>
                                </svg>
                                <div>
                                  <label>Selected Vehicle</label>
                                </div>
                              </MDBRow>
                            </div>
                          </>
                        )}
                      </div>
                      <MDBCardText></MDBCardText>
                    </MDBCardBody>
                  </MDBRipple>
                </MDBCard>
              </MDBCol>
            )}
          </MDBRow>
        </div>
        {renderinfo() && (
          <div>
            <div>
              <MDBRow>
                <label className="_text --sec">Overview</label>
                <div className="line-container --gold-thin-compare"></div>
              </MDBRow>
              <MDBRow>
                <MDBCol className="col-6 col-lg-4">
                  {car1.id ? (
                    <>
                      <div className="_boder">
                        <div>
                          <h1 className="_text --third">Details</h1>
                          <p className="_text --forth-big">Brand</p>
                          <p className="_text --forth-small">{car1.brand_name}</p>

                          <p className="_text --forth-big">Model</p>
                          <p className="_text --forth-small">{car1.car_model_name}</p>

                          <p className="_text --forth-big">Model Year</p>
                          <p className="_text --forth-small">{car1.car_model_year}</p>

                          <p className="_text --forth-big">Body</p>
                          <p className="_text --forth-small">{car1.car_bodies_name}</p>

                          <p className="_text --forth-big">Seat</p>
                          <p className="_text --forth-small">{car1.car_seats_num}</p>

                          <p className="_text --forth-big">Fuel</p>
                          <p className="_text --forth-small">{car1.car_fuels_name}</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <label className="text-center w-100">No information</label>
                  )}
                </MDBCol>

                <MDBCol className="col-6 col-lg-4">
                  {car2.id ? (
                    <>
                      <div className="_boder">
                        <div>
                          <h1 className="_text --third">Details</h1>
                          <p className="_text --forth-big">Brand</p>
                          <p className="_text --forth-small">{car2.brand_name}</p>

                          <p className="_text --forth-big">Model</p>
                          <p className="_text --forth-small">{car2.car_model_name}</p>

                          <p className="_text --forth-big">Model Year</p>
                          <p className="_text --forth-small">{car2.car_model_year}</p>

                          <p className="_text --forth-big">Body</p>
                          <p className="_text --forth-small">{car2.car_bodies_name}</p>

                          <p className="_text --forth-big">Seat</p>
                          <p className="_text --forth-small">{car2.car_seats_num}</p>

                          <p className="_text --forth-big">Fuel</p>
                          <p className="_text --forth-small">{car2.car_fuels_name}</p>
                        </div>
                      </div>
                    </>
                  ) : (
                    <label className="text-center w-100">No information</label>
                  )}
                </MDBCol>

                {width > 991 && (
                  <MDBCol className="col-6 col-lg-4">
                    {car3.id ? (
                      <>
                        <div className="_boder">
                          <div>
                            <h1 className="_text --third">Details</h1>
                            <p className="_text --forth-big">Brand</p>
                            <p className="_text --forth-small">{car3?.brand_name}</p>

                            <p className="_text --forth-big">Model</p>
                            <p className="_text --forth-small">{car3?.car_model_name}</p>

                            <p className="_text --forth-big">Model Year</p>
                            <p className="_text --forth-small">{car3?.car_model_year}</p>

                            <p className="_text --forth-big">Body</p>
                            <p className="_text --forth-small">{car3?.car_bodies_name}</p>

                            <p className="_text --forth-big">Seat</p>
                            <p className="_text --forth-small">{car3?.car_seats_num}</p>

                            <p className="_text --forth-big">Fuel</p>
                            <p className="_text --forth-small">{car3?.car_fuels_name}</p>
                          </div>
                        </div>
                      </>
                    ) : (
                      <label className="text-center w-100">No information</label>
                    )}
                  </MDBCol>
                )}
              </MDBRow>
            </div>

            <div className="_padding --top">
              <MDBRow>
                <label className="_text --sec">Car Specifications</label>
                <div className="line-container --gold-thin-compare"></div>
              </MDBRow>
            </div>

            <MDBRow>
              <MDBCol className="col-6 col-lg-4">
                {car1.id ? (
                  <>
                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Specs Options</h1>
                        {car1?.spec_option?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Optional ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Variations</h1>
                        {car1?.variations?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Type ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Engine Specifications</h1>
                        {car1?.engine_specification?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Type ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Transmissions</h1>
                        {car1?.transmission?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Type ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Fuel Efficiency</h1>
                        {car1?.fuel_efficiency?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Type ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Safety Features</h1>
                        {car1?.safety_features?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Feature ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Interior Features</h1>
                        {car1?.interior_features?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Feature ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Exterior Features</h1>
                        {car1?.exterior_features?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Feature ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Dimensions</h1>
                        <div>
                          <p className="_text --forth-big">Length</p>
                          <p className="_text --forth-small">{car1?.dimension[0]?.length}</p>
                        </div>
                        <div>
                          <p className="_text --forth-big">Width</p>
                          <p className="_text --forth-small">{car1?.dimension[0]?.width}</p>
                        </div>
                        <div>
                          <p className="_text --forth-big">Height</p>
                          <p className="_text --forth-small">{car1?.dimension[0]?.height}</p>
                        </div>
                        <div>
                          <p className="_text --forth-big">Wheelbase</p>
                          <p className="_text --forth-small">{car1?.dimension[0]?.wheelbase}</p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <label className="text-center w-100">No specifications</label>
                )}
              </MDBCol>

              <MDBCol className="col-6 col-lg-4">
                {car2.id ? (
                  <>
                    <div className="_boder">
                      <h1 className="_text --third">Specs Options</h1>
                      <div>
                        {car2?.spec_option?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Optional ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Variations</h1>
                        {car2?.variations?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Type ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Engine Specifications</h1>
                        {car2?.engine_specification?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Type ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Transmissions</h1>
                        {car2?.transmission?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Type ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Fuel Efficiency</h1>
                        {car2?.fuel_efficiency?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Type ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Safety Feature</h1>
                        {car2?.safety_features?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Feature ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Interior Features</h1>
                        {car2?.interior_features?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Feature ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Exterior Features</h1>
                        {car2?.exterior_features?.map((item, index) => (
                          <div key={index}>
                            <p className="_text --forth-big">Feature ({index + 1})</p>
                            <p className="_text --forth-small">{item.label}</p>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="_boder">
                      <div>
                        <h1 className="_text --third">Dimensions</h1>
                        <div>
                          <p className="_text --forth-big">Length</p>
                          <p className="_text --forth-small">{car2?.dimension[0]?.length}</p>
                        </div>
                        <div>
                          <p className="_text --forth-big">Width</p>
                          <p className="_text --forth-small">{car2?.dimension[0]?.width}</p>
                        </div>
                        <div>
                          <p className="_text --forth-big">Height</p>
                          <p className="_text --forth-small">{car2?.dimension[0]?.height}</p>
                        </div>
                        <div>
                          <p className="_text --forth-big">Wheelbase</p>
                          <p className="_text --forth-small">{car2?.dimension[0]?.wheelbase}</p>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <label className="text-center w-100">No specifications</label>
                )}
              </MDBCol>

              {width > 991 && (
                <MDBCol className="col-6 col-lg-4">
                  {car3?.id ? (
                    <>
                      <div className="_boder">
                        <h1 className="_text --third">Specs Options</h1>
                        <div>
                          {car3?.spec_option?.map((item, index) => (
                            <div key={index}>
                              <p className="_text --forth-big">Optional ({index + 1})</p>
                              <p className="_text --forth-small">{item.label}</p>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="_boder">
                        <div>
                          <h1 className="_text --third">Variations</h1>
                          {car3?.variations?.map((item, index) => (
                            <div key={index}>
                              <p className="_text --forth-big">Type ({index + 1})</p>
                              <p className="_text --forth-small">{item.label}</p>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="_boder">
                        <div>
                          <h1 className="_text --third">Engine Specifications</h1>
                          {car3?.engine_specification?.map((item, index) => (
                            <div key={index}>
                              <p className="_text --forth-big">Type ({index + 1})</p>
                              <p className="_text --forth-small">{item.label}</p>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="_boder">
                        <div>
                          <h1 className="_text --third">Transmissions</h1>
                          {car3?.transmission?.map((item, index) => (
                            <div key={index}>
                              <p className="_text --forth-big">Type ({index + 1})</p>
                              <p className="_text --forth-small">{item.label}</p>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="_boder">
                        <div>
                          <h1 className="_text --third">Fuel Efficiency</h1>
                          {car3?.fuel_efficiency?.map((item, index) => (
                            <div key={index}>
                              <p className="_text --forth-big">Type ({index + 1})</p>
                              <p className="_text --forth-small">{item.label}</p>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="_boder">
                        <div>
                          <h1 className="_text --third">Safety Feature</h1>
                          {car3?.safety_features?.map((item, index) => (
                            <div key={index}>
                              <p className="_text --forth-big">Feature ({index + 1})</p>
                              <p className="_text --forth-small">{item.label}</p>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="_boder">
                        <div>
                          <h1 className="_text --third">Interior Features</h1>
                          {car3?.interior_features?.map((item, index) => (
                            <div key={index}>
                              <p className="_text --forth-big">Feature ({index + 1})</p>
                              <p className="_text --forth-small">{item.label}</p>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="_boder">
                        <div>
                          <h1 className="_text --third">Exterior Features</h1>
                          {car3?.exterior_features?.map((item, index) => (
                            <div key={index}>
                              <p className="_text --forth-big">Feature ({index + 1})</p>
                              <p className="_text --forth-small">{item.label}</p>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="_boder">
                        <div>
                          <h1 className="_text --third">Dimensions</h1>
                          <div>
                            <p className="_text --forth-big">Length</p>
                            <p className="_text --forth-small">{car3?.dimension[0]?.length}</p>
                          </div>
                          <div>
                            <p className="_text --forth-big">Width</p>
                            <p className="_text --forth-small">{car3?.dimension[0]?.width}</p>
                          </div>
                          <div>
                            <p className="_text --forth-big">Height</p>
                            <p className="_text --forth-small">{car3?.dimension[0].height}</p>
                          </div>
                          <div>
                            <p className="_text --forth-big">Wheelbase</p>
                            <p className="_text --forth-small">{car3?.dimension[0]?.wheelbase}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <label className="text-center w-100">No specifications</label>
                  )}
                </MDBCol>
              )}
            </MDBRow>
          </div>
        )}
      </div>
      <BrandList />
    </MDBContainer>
  )
}
