//framework & plugin
import { BottomSheet } from 'react-spring-bottom-sheet'
import 'react-spring-bottom-sheet/dist/style.css'
import { MDBModal, MDBModalDialog, MDBModalContent } from 'mdb-react-ui-kit'
import { RemoveScroll } from 'react-remove-scroll'

export default function ModalBox({
  show,
  header,
  footer,
  content,
  backDrop,
  className,
  onDismiss,
  setShow,
  staticBackdrop,
  screenSize,
  scrollable,
  desktopModal,
}) {
  if (desktopModal) {
    return (
      <RemoveScroll>
        <MDBModal
          staticBackdrop={staticBackdrop}
          className={`full-modal-box-container ${className}`}
          tabIndex="-1"
          show={show}
          setShow={setShow}>
          <MDBModalDialog size={`${screenSize}`} scrollable={scrollable ? true : false}>
            <MDBModalContent>{content}</MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </RemoveScroll>
    )
  } else {
    return (
      <BottomSheet
        open={show}
        onDismiss={onDismiss}
        snapPoints={({ maxHeight }) => [maxHeight / 1.5]}
        blocking={backDrop}
        header={header}
        footer={footer}
        className={`${className}`}>
        <MDBModalDialog size={`${screenSize}`} scrollable={scrollable ? true : false}>
          <MDBModalContent>{content}</MDBModalContent>
        </MDBModalDialog>
      </BottomSheet>
    )
  }
}
