import { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

//lib
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit'

//component
import Header from '../../components/header/header-main'
import Panoroma from './component/panoroma'

export default function ViewPanaroma({ state }) {
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return (
    <MDBContainer className="common-main-container view-360-container" breakpoint="none">
      <Header />
      <MDBContainer className="header-360-container">
        <MDBBtn
          className="back-btn"
          onClick={() => {
            navigate(-1)
          }}>
          BACK
        </MDBBtn>
      </MDBContainer>
      <Panoroma state={location.state} />
    </MDBContainer>
  )
}
