import _ from 'lodash'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

//lib
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCreative } from 'swiper'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import queryString from 'query-string'

//assets
import { IconBank, IconInsurance, IconCar, IconReceipt } from '../../assets/images'
import IconVerifyDAgent from '../../assets/images/icon/icon-verifydgent.webp'

//redux
import { useDispatch } from 'react-redux'
import {
  setCar1Data,
  setCar2Data,
  setCar3Data,
  setChooseBrand,
} from '../../redux/reducer/carCompareReducer'

//hook
import useWindowDimensions from '../../components/common/window-dimension'

//component
import Header from '../../components/header/header-main'
import LoanForm from './component/loan.form'
import RoadtaxForm from './component/roadtax.form'
import InsuranceForm from './component/insurance.form'
import CompareCardTable from '../CompareModel/component/compare.card'
import HeaderSecondary from '../../components/header/header-secondary'
import VerifyAgent from '../VerifyAgent'

export default function Calculator() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { width } = useWindowDimensions()
  const { tab } = queryString.parse(location.search)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [window.location.pathname])

  return (
    <MDBContainer className="common-main-container" breakpoint="none">
      {width > 991 && <Header />}
      {/* <MDBContainer className="calculator-header">
        <MDBRow className="content">
          <MDBCol
            className="pointer"
            onClick={() => {
              navigate('/')
              dispatch(setChooseBrand(false))
              dispatch(setCar1Data({}))
              dispatch(setCar2Data({}))
              dispatch(setCar3Data({}))
            }}>
            <img alt="" src={IconLeft} className="content-icon"></img>BACK
          </MDBCol>
          <MDBCol className="right">
            <label>Car Loan</label>
          </MDBCol>
        </MDBRow>
      </MDBContainer> */}
      <HeaderSecondary
        className={'secondary-header'}
        handleClick={() => {
          navigate('/')
          dispatch(setChooseBrand(false))
          dispatch(setCar1Data({}))
          dispatch(setCar2Data({}))
          dispatch(setCar3Data({}))
        }}
        title={
          <div className="text">
            <span className="secondaryText" style={{ color: '#FBB24B' }}>
              Car Loan
            </span>
          </div>
        }
      />
      {width > 991 ? (
        <MDBContainer className="p-0">
          <MDBRow className="calculator-icon">
            <MDBCol
              onClick={() => {
                navigate('/calculator?tab=agent')
              }}
              className={`calculator-icon-col ${tab == 'agent' ? '--active-icon' : ''}`}>
              <MDBRow className="calculator-icon-title">
                <label className={width < 991 ? '_label' : '_label --bold'}>
                  Verify <br />
                  D-Agent
                </label>
              </MDBRow>

              <LazyLoadImage
                src={IconVerifyDAgent}
                width={width < 991 ? '35' : '45'}
                height={width < 991 ? '35' : '45'}
                alt="car-icon"
              />
            </MDBCol>
            <MDBCol
              onClick={() => {
                navigate('/calculator?tab=loan')
              }}
              className={`calculator-icon-col ${tab == 'loan' ? '--active-icon' : ''}`}>
              <MDBRow className="calculator-icon-title">
                <label className={width < 991 ? '_label' : '_label --bold'}>
                  Loan <br />
                  Calculator
                </label>
              </MDBRow>
              <LazyLoadImage
                src={IconBank}
                width={width < 991 ? '35' : '45'}
                height={width < 991 ? '35' : '45'}
                alt="bank-icon"
              />
            </MDBCol>
            <MDBCol
              onClick={() => {
                navigate('/calculator?tab=insurance')
              }}
              className={`calculator-icon-col ${tab == 'insurance' ? '--active-icon' : ''}`}>
              <MDBRow className="calculator-icon-title">
                <label className={width < 991 ? '_label' : '_label --bold'}>
                  Insurance <br />
                  Calculator
                </label>
              </MDBRow>

              <LazyLoadImage
                src={IconInsurance}
                width={width < 991 ? '35' : '45'}
                height={width < 991 ? '35' : '45'}
                alt="insurance-icon"
              />
            </MDBCol>
            <MDBCol
              onClick={() => {
                navigate('/calculator?tab=roadtax')
              }}
              className={`calculator-icon-col ${tab == 'roadtax' ? '--active-icon' : ''}`}>
              <MDBRow className="calculator-icon-title">
                <label className={width < 991 ? '_label' : '_label --bold'}>
                  Road Tax <br />
                  Calculator
                </label>
              </MDBRow>

              <LazyLoadImage
                src={IconReceipt}
                width={width < 991 ? '35' : '45'}
                height={width < 991 ? '35' : '45'}
                alt="receipt-icon"
              />
            </MDBCol>
            {/* <MDBCol
              onClick={() => {
                navigate('/calculator?tab=compare')
              }}
              className={`calculator-icon-col ${tab == 'compare' ? '--active-icon' : ''}`}>
              <MDBRow className="calculator-icon-title">
                <label className={width < 991 ? '_label' : '_label --bold'}>
                  Compare <br />
                  Model
                </label>
              </MDBRow>

              <LazyLoadImage
                src={IconCar}
                width={width < 991 ? '35' : '45'}
                height={width < 991 ? '35' : '45'}
                alt="car-icon"
              />
            </MDBCol> */}
          </MDBRow>
        </MDBContainer>
      ) : (
        <div className="calculator-icon">
          <Swiper
            slidesPerView={3}
            spaceBetween={15}
            grabCursor={true}
            creativeEffect={{
              prev: {
                shadow: false,
                translate: ['-120%', 0, -500],
              },
              next: {
                shadow: false,
                translate: ['120%', 0, -500],
              },
            }}
            modules={[EffectCreative]}
            breakpoints={{
              500: {
                slidesPerView: 4,
              },
            }}>
            <SwiperSlide>
              <div
                onClick={() => {
                  navigate('/calculator?tab=agent')
                }}
                className={`calculator-icon-col ${tab == 'agent' ? '--active-icon' : ''}`}>
                <MDBRow className="calculator-icon-title">
                  <label className={width < 991 ? '_label' : '_label --bold'}>
                    Verify <br /> D-Agent
                  </label>
                </MDBRow>

                <LazyLoadImage
                  src={IconVerifyDAgent}
                  width={width < 991 ? '35' : '45'}
                  height={width < 991 ? '35' : '45'}
                  alt="car-icon"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                onClick={() => {
                  navigate('/calculator?tab=loan')
                }}
                className={`calculator-icon-col ${tab == 'loan' ? '--active-icon' : ''} `}>
                <MDBRow className="calculator-icon-title">
                  <label className={width < 991 ? '_label' : '_label --bold'}>
                    Loan <br />
                    Calculator
                  </label>
                </MDBRow>
                <LazyLoadImage
                  src={IconBank}
                  width={width < 991 ? '35' : '45'}
                  height={width < 991 ? '35' : '45'}
                  alt="bank-icon"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                onClick={() => {
                  navigate('/calculator?tab=insurance')
                }}
                className={`calculator-icon-col ${tab == 'insurance' ? '--active-icon' : ''}`}>
                <MDBRow className="calculator-icon-title">
                  <label className={width < 991 ? '_label' : '_label --bold'}>
                    Insurance <br />
                    Calculator
                  </label>
                </MDBRow>
                <LazyLoadImage
                  src={IconInsurance}
                  width={width < 991 ? '35' : '45'}
                  height={width < 991 ? '35' : '45'}
                  alt="insurance-icon"
                />
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div
                onClick={() => {
                  navigate('/calculator?tab=roadtax')
                }}
                className={`calculator-icon-col ${tab == 'roadtax' ? '--active-icon' : ''}`}>
                <MDBRow className="calculator-icon-title">
                  <label className={width < 991 ? '_label' : '_label --bold'}>
                    Road Tax <br />
                    Calculator
                  </label>
                </MDBRow>

                <LazyLoadImage
                  src={IconReceipt}
                  width={width < 991 ? '35' : '45'}
                  height={width < 991 ? '35' : '45'}
                  alt="receipt-icon"
                />
              </div>
            </SwiperSlide>
            {/* <SwiperSlide>
              <div
                onClick={() => {
                  navigate('/calculator?tab=compare')
                }}
                className={`calculator-icon-col ${tab == 'compare' ? '--active-icon' : ''}`}>
                <MDBRow className="calculator-icon-title">
                  <label className={width < 991 ? '_label' : '_label --bold'}>
                    Compare <br />
                    Model
                  </label>
                </MDBRow>

                <LazyLoadImage
                  src={IconCar}
                  width={width < 991 ? '35' : '45'}
                  height={width < 991 ? '35' : '45'}
                  alt="car-icon"
                />
              </div>
            </SwiperSlide> */}
          </Swiper>
        </div>
      )}

      {tab == 'loan' ? (
        <LoanForm />
      ) : tab == 'insurance' ? (
        <InsuranceForm />
      ) : tab == 'roadtax' ? (
        <RoadtaxForm />
      ) : tab == 'compare' ? (
        <CompareCardTable />
      ) : tab == 'agent' ? (
        <VerifyAgent />
      ) : (
        ''
      )}
      {/* <FooterOne /> */}
    </MDBContainer>
  )
}
