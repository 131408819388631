import { useEffect } from 'react'

//lib
import { MDBContainer } from 'mdb-react-ui-kit'
import { Helmet } from 'react-helmet';

//redux
import { useSelector } from 'react-redux'

//component
import Login from '../Login'
import Account from './component/account'
import OrderHistory from './component/order.history'
import Recommend from './component/recommend'
import Event from './component/event'

export default function Order() {
  const { login_modal } = useSelector((state) => state.order)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <MDBContainer className="common-main-container" breakpoint="none">
       <Helmet>
        <title>Welcome to Dconcept</title>
        <meta name="Dconcept" content="Welcome to Dconcept" />
    </Helmet>
      <div className="content-container order-page-container">
        <Account />
        {/* <Event /> */}
        {localStorage.getItem('token') && <OrderHistory />}
        <Recommend />
      </div>
      {login_modal && <Login show={login_modal} />}
    </MDBContainer>
  )
}
