//lib
import { MDBContainer, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Icon } from '@iconify/react'

//assest
import { LogoMain } from '../../assets/images'
import JPAuction from '../../assets/images/japan-link/jp_auc.webp'
import Goonet from '../../assets/images/japan-link/goonet.webp'
import Carsensor from '../../assets/images/japan-link/carsensor.webp'

//reducer
import { useDispatch } from 'react-redux'

//component
import ModalBox from './full.modal.box'

//helper
import LazyImage from '../common/common.image'

export default function JapanLinkModal({ show, setShow }) {
  const dispatch = useDispatch()
  return (
    <ModalBox
      className={'japan-link-modal-container'}
      show={show}
      setShow={setShow}
      staticBackdrop={true}
      desktopModal={false}
      screenSize="s"
      content={
        <MDBModalBody>
          <MDBContainer className="card">
            <MDBRow className="m-0 header-login">
              <div className="text-center">
                <LazyImage src={LogoMain} width={200} height={50} alt="dc-logo" />
              </div>
              <div className="button-close">
                <Icon
                  icon="clarity:window-close-line"
                  onClick={() => {
                    dispatch(setShow(false))
                    //dispatch(setSelectedLoginTab('account'))
                  }}
                />
              </div>
            </MDBRow>
            <MDBRow className="mt-5 d-flex justify-content-center" style={{ flexDirection: 'row' }}>
              <div
                className="_action-card"
                onClick={() => {
                  window.open('https://jpauc.com/', '_blank')
                }}>
                <div className="_action-img">
                  <LazyImage src={JPAuction} alt="jpauction-logo" />
                </div>
                <label>JP Auction</label>
              </div>
            </MDBRow>
            <MDBRow className="mt-4 d-flex justify-content-center" style={{ flexDirection: 'row' }}>
              <div
                className="_action-card"
                onClick={() => {
                  window.open('https://www.goo-net-exchange.com/', '_blank')
                }}>
                <div className="_action-img">
                  <LazyImage src={Goonet} alt="goonet-logo" />
                </div>
                <label>Goonet Exchange</label>
              </div>
            </MDBRow>
            <MDBRow className="mt-4 d-flex justify-content-center" style={{ flexDirection: 'row' }}>
              <div
                className="_action-card"
                onClick={() => {
                  window.open('https://www.carsensor.net/', '_blank')
                }}>
                <div className="_action-img">
                  <LazyImage src={Carsensor} alt="carsensor-logo" />
                </div>
                <label>Carsensor</label>
              </div>
            </MDBRow>
          </MDBContainer>
        </MDBModalBody>
      }
    />
  )
}
