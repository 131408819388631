//lib
import * as Yup from 'yup'
import { Icon } from '@iconify/react'
import { Formik, Form } from 'formik'
import {
  MDBBtn,
  MDBModalBody,
  MDBModalFooter,
  MDBModalHeader,
  MDBRow,
  MDBRadio,
  MDBCol,
} from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//reducer
import { useDispatch, useSelector } from 'react-redux'
import { setContactAgent } from '../../redux/reducer/carPostReducer'

//hook
import useWindowDimensions from '../common/window-dimension'

//component
import ModalBox from './half.modal.box'
import Text from '../element/text'
import apiServices from '../../services/api.services'
import { toast } from 'react-toastify'

//assets
import Profile from '../../assets/images/logo/profile-icon.webp'

export default function ContactAgent({ show, data }) {
  const { width } = useWindowDimensions()

  const dispatch = useDispatch()
  const { isDarkMode } = useSelector((state) => state.home)

  const initialValues = {
    post_id: data.id,
    model_year: '',
    budget: '',
    percentage: '',
    requirement_input: '',
    name: '',
    mobile_number: '',
  }

  const useSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    mobile_number: Yup.string()
      .required('Phone number is required')
      .min(10, 'Invalid phone number format'),
  })

  // const openWhatsapp = () => {
  //   const whatsappNumber = `+6${data.company_mobile_number ? data.company_mobile_number : ''}`
  //   const message = `Hello, I${"'"}m interested with this ${
  //     data.car_model_name
  //   }, how D-Concept can help to import?`
  //   const url = `https://api.whatsapp.com/send?phone=${whatsappNumber}&text=${encodeURIComponent(
  //     message,
  //   )}`
  //   window.open(url, '_blank')
  // }

  // const openTelegram = () => {
  //   const telegramNumber = `+6${data.company_mobile_number ? data.company_mobile_number : ''}`
  //   const message = encodeURIComponent(
  //     `Hello, I${"'"}m interested with this ${
  //       data.car_model_name
  //     }, how D-Concept can help to import?`,
  //   )
  //   const telegramUrl = `https://telegram.me/${telegramNumber}?text=${message}`
  //   window.open(telegramUrl, '_blank')
  // }

  // const openPhone = () => {
  //   const phoneNumber = data.company_mobile_number ? data.company_mobile_number : ''
  //   window.location.href = `tel:${phoneNumber}`
  // }

  const handleSubmit = async ({ values, setFieldError, resetForm }) => {
    try {
      const response = await apiServices.postEnquiry({
        ...values,
        // recommend_car_id: recommendId,
        // mobile_number: values.mobile_number,
      })
      if (response) {
        //reset form
        toast.success('enquiry send successful.', {
          autoClose: 3000,
          theme: 'dark',
          hideProgressBar: true,
          closeOnClick: true,
          closeButton: false,
        })
        resetForm()
        dispatch(setContactAgent(false))
      }
    } catch (ex) {
      if (ex && ex.response.status === 422) {
        const errors = ex.response.data.errors
        if (errors && Object.keys(errors).length > 0) {
          Object.keys(errors).map((item, i) => setFieldError(item, errors[item]))
        }
      }
    }
  }

  return (
    <ModalBox
      className={isDarkMode ? 'contact-agent-container-dark' : 'contact-agent-container-light'}
      show={show}
      scrollable={true}
      staticBackdrop={true}
      desktopModal={width >= 991 ? true : false}
      screenSize="lg"
      onDismiss={() => dispatch(setContactAgent(false))}
      content={
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={useSchema}
            enableReinitialize={true}
            validateOnChange={true}
            onSubmit={(values, { resetForm, setFieldError }) => {
              handleSubmit({ values, setFieldError, resetForm })
            }}>
            {({
              setFieldValue,
              isSubmitting,
              handleReset,
              isValid,
              errors,
              values,
              handleSubmit,
            }) => (
              <Form>
                {/* <MDBModalHeader>
                  <MDBRow className="align-items-center">
                    {width >= 991 ? (
                      <div style={{ width: 'auto' }}>
                        <Icon
                          onClick={() => dispatch(setContactAgent(false))}
                          className="icon-close-btn"
                          icon="clarity:window-close-line"
                          width={30}
                          height={30}
                        />
                      </div>
                    ) : (
                      ''
                    )}
                    {width >= 991 ? (
                      <div className="content-desktop d-flex align-items-center justify-content-center">
                        <label className="_label --title">{data.car_model_name}</label>
                      </div>
                    ) : (
                      <label className="_label --title">{data.car_model_name}</label>
                    )}
                  </MDBRow>
                </MDBModalHeader> */}
                <MDBModalBody className="content-desktop">
                  {/* <MDBRow className="m-0">
                    <div>
                      <LazyLoadImage src={Profile} width={45} alt="contact-avatar" /> */}
                  {/* <label className="_label --name">
                        {data.company_mobile_number
                          ? `Hi, I${"'"}m +6${data.company_mobile_number}`
                          : `Hi, I${"'"}m Anonymous`}
                      </label> */}
                  {/* <label className="_label --name">we will contact you 30 min</label>
                    </div>
                  </MDBRow> */}
                  {/* <MDBRow className="m-0 justify-content-around mt-3 mb-3">
                    <label
                      className="_label --text-social"
                      onClick={() => {
                        openTelegram()
                      }}>
                      <i className="pi pi-telegram"></i>Telegram
                    </label>
                    <label
                      className="_label --text-social"
                      onClick={() => {
                        openWhatsapp()
                      }}>
                      {' '}
                      <i className="pi pi-whatsapp"></i>Whatsapp
                    </label>
                    <label
                      className="_label --text-social"
                      onClick={() => {
                        openPhone()
                      }}>
                      <i className="pi pi-phone"></i>Call
                    </label>
                  </MDBRow> */}

                  <MDBRow className="m-0 mt-3">
                    <MDBCol>
                      <label className="_label --sub-title">Message for agent</label>
                    </MDBCol>
                    <MDBCol>
                      <div style={{ textAlign: 'end' }}>
                        <Icon
                          onClick={() => dispatch(setContactAgent(false))}
                          className="icon-close-btn"
                          icon="clarity:window-close-line"
                          width={30}
                          height={30}
                        />
                      </div>
                    </MDBCol>
                  </MDBRow>
                  <MDBRow className="m-0">
                    <Text name="model_year" placeholder="Model with year" className="_input" />
                    <Text name="budget" placeholder="Budget" className="_input" />
                    <Text
                      name="requirement_input"
                      placeholder="Any requirement"
                      className="_input"
                    />
                    {/* <Text name="percentage" placeholder="percentage" className="_input" /> */}

                    <MDBRow className="m-0 mt-1">
                      <label className="_label --sub-title">Purchase Type</label>
                      <div style={{ fontSize: '1.8rem' }}>
                        <div className="form-check form-check-inline mt-2">
                          <MDBRadio
                            className="mt-2"
                            id="percentage3"
                            name="percentage"
                            value="3"
                            checked={values.percentage === '3'}
                            onChange={() => setFieldValue('percentage', '3')}
                            label="3% ( Cash buy )"
                          />
                        </div>
                      </div>
                      <div style={{ fontSize: '1.8rem' }}>
                        <div className="form-check form-check-inline mt-2">
                          <MDBRadio
                            className="mt-2"
                            id="percentage8"
                            name="percentage"
                            value="8"
                            checked={values.percentage === '8'}
                            onChange={() => setFieldValue('percentage', '8')}
                            label="8% ( Loan buy )"
                          />
                        </div>
                      </div>
                    </MDBRow>

                    <MDBRow className="m-0  mt-4">
                      <label className="_label --sub-title">Your Details</label>
                    </MDBRow>
                    <MDBRow className="m-0">
                      <Text name="name" placeholder="eg. John" className="_input" />
                      <Text name="mobile_number" placeholder="eg. 0123456789" className="_input" />
                    </MDBRow>

                    {/* <input
                      className="_input"
                      name="message"
                      placeholder="i am interest with this car , how D-concept can help to import ? "
                    /> */}
                  </MDBRow>

                  <MDBRow className="m-0">
                    <label className="_label --details">
                      D-concept once receive your enquire instant will arrange suitable agent to
                      contact you in 30min. your privacy is our high level concern.{' '}
                      <span>Term Of Use</span> & <span>Privacy</span>
                    </label>
                  </MDBRow>
                </MDBModalBody>
                <MDBModalFooter className="content-desktop">
                  <MDBBtn
                    type="submit"
                    className="button-group --contact-agent"
                    style={{ fontFamily: 'montserrat' }}>
                    SEND ENQUIRY
                  </MDBBtn>
                </MDBModalFooter>
              </Form>
            )}
          </Formik>
        </div>
      }
    />
  )
}
