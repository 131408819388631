import http from './http.services'

http.setToken(getToken())
// process.env.REACT_APP_API_URL

async function login(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + '/login', payload)
  return data
}

async function verificationCode(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + `/login/request`, payload)
  return data
}

async function verificationLogin(payload) {
  const { data } = await http.post(process.env.REACT_APP_API_URL + '/user_login', payload)
  return data
}

function getToken() {
  return localStorage.getItem('token')
}

async function logout() {
  localStorage.clear()
}

function getCurrentUser() {
  const token = localStorage.getItem('token')
  if (token) return { token }
  else return ''
}

export default {
  login,
  logout,
  getToken,
  getCurrentUser,
  verificationCode,
  verificationLogin
}
