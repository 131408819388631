import { useEffect } from 'react'

//lib
import { MDBContainer } from 'mdb-react-ui-kit'

//componnent
import Header from '../../components/header/header-main'
import AuctionGuideImage from './component/auction.guide'
import FirstTable from './component/auction.table'
import SecondTable from './component/auction.second.table'
import ThirdTable from './component/auction.third.table'

export default function AuctionGuide() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <MDBContainer className="common-main-container" breakpoint="none">
      <Header />
      <AuctionGuideImage />
      <FirstTable />
      <SecondTable />
      <ThirdTable />
    </MDBContainer>
  )
}
