import React from 'react'
import Input from './input'
// import TextArea from './textarea'
import Select from './select'
// import RadioButton from './radioButton'
// import Checkboxes from './checkboxes'
import Button from './button'
// import MobileInput from './mobile.input'
// import Selectv2 from './Selectv2'

function renderInput(
  name,
  label,
  type = 'text',
  autoComplete = 'off',
  className,
  readOnly,
  errors,
  ...rest
) {
  return (
    <>
      <Input
        name={name}
        label={label}
        type={type}
        autoComplete={autoComplete}
        className={className}
        readOnly={readOnly}
        errors={errors}
        {...rest}
      />
    </>
  )
}
// function renderTextArea(name, label, ...rest) {
//   return <TextArea name={name} label={label} {...rest} />
// }
function renderSelect(name, label, options, ...rest) {
  return <Select name={name} label={label} options={options} {...rest} />
}
// function renderSelectv2(name, label, options, ...rest) {
//   return <Selectv2 name={name} label={label} options={options} {...rest} />
// }
// function renderRadioButton(name, label, options, ...rest) {
//   return <RadioButton name={name} label={label} options={options} {...rest} />
// }
// function renderCheckboxes(name, label, options, ...rest) {
//   return <Checkboxes name={name} label={label} options={options} {...rest} />
// }

function renderButton(label, isSubmitting, isValid, className) {
  return (
    <Button label={label} isSubmitting={isSubmitting} isValid={isValid} className={className} />
  )
}
// function renderMobileInput(name, placeholder, setFieldValue, errors, ...rest) {
//   return (
//     <MobileInput
//       name={name}
//       placeholder={placeholder}
//       setFieldValue={setFieldValue}
//       errors={errors}
//       {...rest}
//     />
//   )
// }

export default {
  renderInput,
  // renderTextArea,
  renderSelect,
  // renderSelectv2,
  // renderRadioButton,
  // renderMobileInput,
  renderButton,
  // renderCheckboxes,
}
