import http from './http.services'

async function postLoan(payload) {
  const response = await http.post(process.env.REACT_APP_API_URL + `/loan`, payload)
  return response.data
}

async function getRoadtax() {
  const response = await http.get(process.env.REACT_APP_API_URL + `/roadtax`)
  return response.data
}

async function postRoadtax(payload) {
  const response = await http.post(process.env.REACT_APP_API_URL + `/roadtax`, payload)
  return response.data
}

async function getInsurance() {
  const response = await http.get(process.env.REACT_APP_API_URL + `/insurance`)
  return response.data
}

async function postInsurance(payload) {
  const response = await http.post(process.env.REACT_APP_API_URL + `/insurance`, payload)
  return response.data
}

async function getBrand() {
  const response = await http.get(process.env.REACT_APP_API_URL + `/brand`)
  return response.data
}
async function getNewBrand() {
  const response = await http.get(process.env.REACT_APP_API_URL + `/brand/new`)
  return response.data
}
async function getDStock() {
  const response = await http.get(process.env.REACT_APP_API_URL + `/dstock`)
  return response.data
}
async function getCarPostApi(data) {
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/car_post_brand?search=${data.search ?? ''}&start=${data.start ? data.start : ''}&length=${
        data.length ? data.length : ''
      }&body_type=${data.body_type ? data.body_type : ''}`,
  )
  return response.data
}
async function getCarPostInfo(payload) {
  const response = await http.get(process.env.REACT_APP_API_URL + `/car_post?id=${payload.id}`)
  return response.data
}
async function getStockList(payload) {
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/stock/all?search=${payload.search}&body_type=${payload.body_type}&brand_name=${
        payload.brand_name
      }&length=${payload.length ? payload.length : ''}&start=${
        payload.start ? payload.start : ''
      }&ready_stock_type=${payload.ready_stock_type ? payload.ready_stock_type : ''}`,
  )
  return response.data
}
async function getStockInfo(payload) {
  const response = await http.get(process.env.REACT_APP_API_URL + `/stock_info?id=${payload.id}`)
  return response.data
}
async function getHomeSearch(payload) {
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/stock/search?search=${payload.search}&search_car_bodies=${payload.search_car_bodies}&search_ready_stock=${payload.search_ready_stock}&start=${payload.start}&length=${payload.length}`,
  )
  return response.data
}
async function postLastView(payload) {
  const response = await http.post(process.env.REACT_APP_API_URL + `/last_view`, payload)
  return response.data
}
async function getAnnouncement(payload) {
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/announcement_info?search=${payload.search}&start=${payload.start}&length=${payload.length}`,
  )
  return response.data
}
async function getStockDetailListing(payload) {
  const response = await http.post(process.env.REACT_APP_API_URL + `/stock/all_for_user`, payload)
  return response.data
}

async function getSamePriceRange(payload) {
  const response = await http.get(process.env.REACT_APP_API_URL + `/stock_same_price_range?id=${payload.id}`, payload)
  return response.data
}

async function getOrderHistory() {
  http.setToken(localStorage.getItem('token'))
  const response = await http.get(process.env.REACT_APP_API_URL + `/autoform/self`)
  return response.data
}
async function getRecommended(payload) {
  const response = await http.get(
    process.env.REACT_APP_API_URL +
      `/recommend_car_info?search=${payload.search}&start=${payload.start}&length=${payload.length}`,
  )
  return response.data
}
async function postRecommendContact(payload) {
  const response = await http.post(process.env.REACT_APP_API_URL + `/recommend_contact`, payload)
  return response.data
}
async function getDAgent(payload) {
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/check_agent?search=${payload.search}`,
  )
  return response.data
}
async function postEnquiry(payload) {
  const response = await http.post(process.env.REACT_APP_API_URL + `/enquiry`, payload)
  return response.data
}

async function getVideos2(payload) {
  const response = await http.get(
    process.env.REACT_APP_API_URL + `/video_user?search=${payload.search}&start=${payload.start}&length=${payload.length}`,
  )
  return response.data
}

async function getFilterOption(payload) {
  const response = await http.post(
    process.env.REACT_APP_API_URL +
      `/stock/filter_option_listing?ready_stock_type=${payload.ready_stock_type}`,
  )
  return response.data
}

export default {
  getStockDetailListing,
  getSamePriceRange,
  postLoan,
  getRoadtax,
  getInsurance,
  postInsurance,
  postRoadtax,
  getBrand,
  getDStock,
  getCarPostApi,
  getCarPostInfo,
  getStockList,
  getStockInfo,
  getHomeSearch,
  postLastView,
  getAnnouncement,
  getOrderHistory,
  getRecommended,
  postRecommendContact,
  getDAgent,
  postEnquiry,
  getNewBrand,
  getVideos2,
  getFilterOption
}
