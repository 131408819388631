import { useEffect, useState } from 'react'

//lib
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import Lottie from 'lottie-react'
import NoSearchData from './../../assets/json/no-search-data-found.json'
import { debounce } from 'lodash'

//redux
import { useSelector, useDispatch } from 'react-redux'
import {
  setCarYearFilter,
  setCarBrandFilter,
  setYearFilterData,
} from '../../redux/reducer/stockReducer'
import DatePicker from 'react-datepicker'

import { setShowCarYearList } from '../../redux/reducer/stockReducer'
import { MDBBtn, MDBContainer } from 'mdb-react-ui-kit'
import useWindowDimensions from '../common/window-dimension'
import { Icon } from '@iconify/react'

// range slider
import Slider from '@mui/material/Slider'
import { useSearchParams } from 'react-router-dom'

export default function YearPickerList({
  selectedYear,
  handleYearPickerChange,
  setSearch,
  show,
  setShow,
  type,
  top,
  startZero,
  backFirstPage,
}) {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const [isYearSelected, setIsYearSelected] = useState(false)
  const { showCarYearList, carYearFilter, availableCarYears } = useSelector((state) => state.stock)
  
  const [ searchParams, setSearchParams ] = useSearchParams()

  const handleToggleOverlay = () => {
    dispatch(setShowCarYearList(!showCarYearList))
    document.body.style.overflow = 'auto'
  }
  const handleShowToggleOverlay = () => {
    setShow(!show)
    document.body.style.overflow = 'auto'
  }
  const handleOverflowChange = debounce((newShowValue) => {
    document.body.style.overflow = newShowValue ? 'hidden' : 'auto'
  }, 100)

  useEffect(() => {
    if (type === 'non-fixed') {
      handleOverflowChange(showCarYearList)
    } else if (type === 'fixed') {
      handleOverflowChange(show)
    } else {
      handleOverflowChange(showCarYearList)
    }
  }, [showCarYearList, show])

  // range slider
  const [fromValue, setFromValue] = useState(carYearFilter[0])
  const [toValue, setToValue] = useState(carYearFilter[1])
  const [value, setValue] = useState([carYearFilter[0], carYearFilter[1]])

  const handleRange = (event, newValue) => {
    setFromValue(newValue[0])
    setToValue(newValue[1])
    dispatch(setYearFilterData([fromValue, toValue]))
  }

  function valueText(value) {
    return `${value}`
  }

  const currentYear = new Date().getFullYear()
  const handleTooltipValue = (event, value) => {
    // setHoveredValue(value);
  }
  return (
    <>
      {type ? (
        <>
          <div
            className="brandlist-div-overlay"
            onClick={() => {
              type == 'fixed' ? handleShowToggleOverlay() : handleToggleOverlay()
            }}
          />
          <MDBContainer
            className={
              type == 'non-fixed' ? 'brandlist-div-container' : 'brandlist-div-container --fixed'
            }
            style={{ top: top }}>
            <div className="brandlist-div-body --slider">
              <div className="mt-5">
                <div className="mui-slider-div">
                  <Slider
                    min={1990}
                    max={currentYear}
                    getAriaLabel={() => 'Year List'}
                    defaultValue={value}
                    value={[fromValue, toValue]}
                    onChange={handleRange}
                    valueLabelDisplay="auto"
                    getAriaValueText={valueText}
                    onMouseOver={handleTooltipValue}
                    aria-labelledby="range-slider"
                  />
                </div>
                <div className="d-flex justify-content-center mt-4 range-slider-input">
                  <input
                    defaultValue={fromValue}
                    value={
                      Number(fromValue) >= 0
                        ? Number(fromValue)
                        : carYearFilter[0] != undefined
                        ? carYearFilter[0]
                        : 0
                    } // Use the value from the state directly
                    onChange={(e) => {
                      if (isNaN(parseInt(e.target.value))) {
                        setFromValue(0)
                      } else {
                        setFromValue(parseInt(e.target.value))
                      }
                    }}
                  />
                  <label className="ms-3 me-3"> - </label>
                  <input
                    defaultValue={toValue}
                    value={
                      Number(toValue) >= 0
                        ? Number(toValue)
                        : carYearFilter[1] != undefined
                        ? carYearFilter[1]
                        : 0
                    } // Use the value from the state directly
                    onChange={(e) => {
                      if (isNaN(parseInt(e.target.value))) {
                        setToValue(0)
                      } else {
                        setToValue(parseInt(e.target.value))
                      }
                    }}
                  />
                </div>
                {/* {availableCarYears && availableCarYears.length > 0 ?
              availableCarYears.map((year, index) => {
                return (
                  <ul key={`carbody-${index}`} className='brandlist-ul'>
                    <li
                      className="brandlist-listing --grid"
                      onClick={() => {
                        backFirstPage(1)
                        startZero(0)
                        if (carYearFilter === year) {
                          dispatch(setCarYearFilter(''))
                          if (type == 'fixed') {
                            handleShowToggleOverlay()
                          } else {
                            handleToggleOverlay()
                          }
                        } else {
                          dispatch(setCarYearFilter(year))
                          if (type == 'fixed') {
                            handleShowToggleOverlay()
                          } else {
                            handleToggleOverlay()
                          }
                        }
                      }}>
                      <p>{year}</p>
                      <div
                        className="selectedIcon"
                        style={{ display: carYearFilter === year ? 'block' : 'none' }}
                      >
                        <Icon
                          icon="charm:circle-tick"
                          width={width < 991 ? '16px' : '22px'}
                          />
                      </div>
                    </li>
                  </ul>
                )
              })
              :
              <>
                <div className="d-flex justify-content-center" style={{ marginTop: width > 991 ? '10em' : '7em' }}>
                  <p className='no-available-listing'>Click all to view<br/>more manufacture years</p>
                </div>
              </>
            } */}
              </div>
              {/* <FontAwesomeIcon icon={solid('square-check')} /> */}
              <div className="brandlist-div-footer">
                <div
                  className="d-flex align-items-center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setCarYearFilter([1990, currentYear]))
                    if (type == 'fixed') {
                      handleShowToggleOverlay()
                    } else {
                      handleToggleOverlay()
                    }
                  }}>
                  Clear
                </div>
                <div
                  className="darkTheme-btn reset-btn --all"
                  onClick={() => {
                    startZero(0)
                    backFirstPage(1)
                    setSearchParams({ page: '1' })

                    if (
                      (fromValue < 1990 || fromValue > currentYear) &&
                      (toValue < 1990 || toValue > currentYear)
                    ) {
                      dispatch(setCarYearFilter([1990, currentYear]))
                    } else if (fromValue < 1990 || fromValue > currentYear) {
                      dispatch(setCarYearFilter([1990, toValue]))
                    } else if (toValue < 1990 || toValue > currentYear) {
                      dispatch(setCarYearFilter([fromValue, currentYear]))
                    } else {
                      dispatch(setCarYearFilter([fromValue, toValue]))
                    }
                    if (type == 'fixed') {
                      handleShowToggleOverlay()
                    } else {
                      handleToggleOverlay()
                    }
                  }}>
                  Apply
                </div>
              </div>
            </div>
          </MDBContainer>
        </>
      ) : (
        <div className="brandlist-div-body" style={{ width: '100%' }}>
          <div className="mt-5">
            <div className="mui-slider-div">
              <Slider
                min={1990}
                max={currentYear}
                getAriaLabel={() => 'Year List'}
                defaultValue={value}
                value={[fromValue, toValue]}
                onChange={handleRange}
                valueLabelDisplay="auto"
                getAriaValueText={valueText}
              />
            </div>
            <div className="d-flex justify-content-center mt-4 range-slider-input">
              <input
                defaultValue={fromValue}
                value={
                  Number(fromValue) >= 0
                    ? Number(fromValue)
                    : carYearFilter[0] != undefined
                    ? carYearFilter[0]
                    : 0
                } // Use the value from the state directly
                onChange={(e) => {
                  if (isNaN(parseInt(e.target.value))) {
                    setFromValue(0)
                  } else {
                    setFromValue(parseInt(e.target.value))
                    dispatch(setYearFilterData([parseInt(e.target.value), toValue]))
                  }
                }}
              />
              <label className="ms-3 me-3"> - </label>
              <input
                defaultValue={toValue}
                value={
                  Number(toValue) >= 0
                    ? Number(toValue)
                    : carYearFilter[1] != undefined
                    ? carYearFilter[1]
                    : 0
                } // Use the value from the state directly
                onChange={(e) => {
                  if (isNaN(parseInt(e.target.value))) {
                    setToValue(0)
                  } else {
                    setToValue(parseInt(e.target.value))
                    dispatch(setYearFilterData([fromValue, parseInt(e.target.value)]))
                  }
                }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
