import React, { useState } from 'react';

//lib
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBTableBody,
  MDBTable,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane
} from 'mdb-react-ui-kit';

const AuctionGuideTable = () => {
  const [activeTab, setActiveTab] = useState('auction-grade');

  const handleTabClick = (event) => {
    setActiveTab(event.target.getAttribute('href').substring(1));
  };

  return (
    <MDBContainer className="auction-guide-table-container">
      <MDBRow className='' >
        <MDBCol>
          <div className='dark-theme-tabs'>
          <MDBTabs justify className='_tab'>
            <MDBTabsItem className='_padding'>
              <MDBTabsLink className='_text --grey'
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick(e);
                }}
                active={activeTab === 'auction-grade'}
                href='#auction-grade'
              >
                Auction Grade
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem className='_padding'>
              <MDBTabsLink className='_text --grey'
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick(e);
                }}
                active={activeTab === 'inside-grade'}
                href='#inside-grade'
              >
                Inside Grade
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem className='_padding'>
              <MDBTabsLink className='_text --grey'
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick(e);
                }}
                active={activeTab === 'detailed-body-condition'}
                href='#detailed-body-condition'
              >
                Detailed Body Condition
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem className='_padding'>
              <MDBTabsLink className='_text --grey'
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick(e);
                }}
                active={activeTab === 'vehicle-options'}
                href='#vehicle-options'
              >
                Vehicle Options
              </MDBTabsLink>
            </MDBTabsItem>
            <MDBTabsItem className='_padding'>
              <MDBTabsLink className='_text --grey'
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick(e);
                }}
                active={activeTab === 'transmission'}
                href='#transmission'
              >
                Transmission
              </MDBTabsLink>
            </MDBTabsItem>
          </MDBTabs>
          </div>
        </MDBCol>
      </MDBRow>
        <MDBRow>
          <MDBCol>
            <MDBTabsContent className='_tab --height'>
            <MDBTabsPane  show={activeTab === 'auction-grade'}>
              <MDBTable hover borderless striped >
                <MDBTableBody className='_text --body '>
                    <tr>
                    <th className='table-dark'>S</th>
                    <td className='table-warning'>New Vehicle. Registration date is no longer than 1 year, speedometer shows less than 10,000km</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>5</th>
                    <td className='table-warning'>Excellent condition vehicle.Speedometer shows up to 50,000km</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>4.5</th>
                    <td className='table-warning'>Excellent grade with almost no issue. Speedometer shows up to 100,000km</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>4</th>
                    <td className='table-warning'>Great grade with minor issues only. Speedometer could show up to 150,000km</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>3.5</th>
                    <td className='table-warning'>Good grade with some issues that may need repair</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>3</th>
                    <td className='table-warning'>Average grade with issues to fix</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>2</th>
                    <td className='table-warning'>Bad grade</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>1</th>
                    <td className='table-warning'>Engine swap or has been under water</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>*</th>
                    <td className='table-warning'>Non running car, needs a forklift to be moved. But also mentioned for any motorbike or machinery</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>R or O</th>
                    <td className='table-warning'>NonAccident history repaired. Repaired parts are mostly informed on auction sheet</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>RA</th>
                    <td className='table-warning'>Minor accident repaired (core support or back panel only)</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>RB</th>
                    <td className='table-warning'>Heavy repair (till inner panel or floor)</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>RC</th>
                    <td className='table-warning'>Pillar or frame has been changed/repaired</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>R2</th>
                    <td className='table-warning'>Accident history and mostly rust or corrosion issue</td>
                    </tr>
                    
                </MDBTableBody>
              </MDBTable>
            </MDBTabsPane>
            <MDBTabsPane show={activeTab === 'inside-grade'}>
                <MDBTable borderless striped>
                    <MDBTableBody className='_text --body'>
                        <tr>
                        <th className='table-dark'>A</th>
                        <td className='table-warning'>Excellent interior condition</td>
                        <th className='table-dark'>B</th>
                        <td className='table-warning'>Good condition with some imperfections</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>C</th>
                        <td className='table-warning'>Good or average condition with imperfections</td>
                        <th className='table-dark'>D</th>
                        <td className='table-warning'>Bad interior condition</td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
              </MDBTabsPane>
            <MDBTabsPane show={activeTab === 'detailed-body-condition'}>
                <MDBTable borderless striped>
                    <MDBTableBody className='_text --body'>
                        <tr>
                        <th className='table-dark'>1</th>
                        <td className='table-warning'>Minor, almost not noticeable (eg A1, U1)</td>
                        <th className='table-dark'>2</th>
                        <td className='table-warning'>Small but noticeable (eg A2, U2)</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>3</th>
                        <td className='table-warning'>Average or big (eg A3, U3)</td>
                        <th className='table-dark'>大</th>
                        <td className='table-warning'>Big (Japanese kanji for "big")</td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </MDBTabsPane>
            <MDBTabsPane show={activeTab === 'vehicle-options'}>
                <MDBTable borderless striped>
                        <MDBTableBody className='_text --body'>
                            <tr>
                            <th className='table-dark'>SR</th>
                            <td className='table-warning'>Sunroof</td>
                            <th className='table-dark'>カワor革</th>
                            <td className='table-warning'>Leather Seat</td>
                            </tr>
                            <tr>
                            <th className='table-dark'>AW</th>
                            <td className='table-warning'>Alloy Wheel</td>
                            <th className='table-dark'>TV</th>
                            <td className='table-warning'>TV</td>
                            </tr>
                            <tr>
                            <th className='table-dark'>PS</th>
                            <td className='table-warning'>Power Steering</td>
                            <th className='table-dark'>ナビ</th>
                            <td className='table-warning'>Navigation System</td>
                            </tr>
                            <tr>
                            <th className='table-dark'>PW</th>
                            <td className='table-warning'>Power Window</td>
                            <th className='table-dark'>エアB</th>
                            <td className='table-warning'>Airbag</td>
                            </tr>
                        </MDBTableBody>
                    </MDBTable>
              </MDBTabsPane>
            <MDBTabsPane show={activeTab === 'transmission'}>
                <MDBTable borderless striped>
                    <MDBTableBody className='_text --body'>
                        <tr>
                        <th className='table-dark'>Manual</th>
                        <td className='table-warning'> F5,  F6,  MT,  5MT,  6MT </td>
                        <th className='table-dark'>Auto</th>
                        <td className='table-warning'> AT,  FA,  FAT,  CVT</td>
                        </tr>
                    </MDBTableBody>
                </MDBTable>
            </MDBTabsPane>
            </MDBTabsContent>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  };
// }
  export default AuctionGuideTable;