import _ from 'lodash'
import { Icon } from '@iconify/react'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Link, useNavigate } from 'react-router-dom'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//component
import SwiperCarImage from './swiper.car.image'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'
import React from 'react'

//helper
import LazyImage from '../../../components/common/common.image'

//redux
import { useSelector } from 'react-redux'

export default function InfiniteSrcollListing2({ data, type }) {
  const navigateTo = useNavigate()
  const { width } = useWindowDimensions()
  const { isDarkMode } = useSelector((state) => state.home)

  const formatCash = (num, digits = 2) => {
    var units = ['k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'],
      decimal

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1)

      if (num <= -decimal || num >= decimal) {
        const number = +(num / decimal).toFixed(digits)
        if (units[i] === 'Y') {
          return number.toLocaleString() + units[i]
        }
        return number.toLocaleString() + units[i]
      }
    }

    return num
  }

  const { searchKeywords } = useSelector((state) => state.stock)
  const charactersToColor = searchKeywords ? searchKeywords : []

  return (
    <>
      {width < 991 ? (
        <MDBRow className="carList-container-grid2">
          {data.map((car, index) => (
            <MDBCol
              key={index}
              className={'col-12 col-md-6 col-lg-6 col-xl-4 carListing-container'}
              onClick={() => {
                // navigateTo(`/carInfo/${car.path_name}/${car.id}`)
              }}>
              <Link to={`/carInfo/${car.path_name}/${car.id}`}>
                <MDBRow className="carListing mt-4">
                  <MDBCol
                    className={
                      'col-5 col-md-6 d-flex justify-content-end align-items-center carListing-img-container'
                    }>
                    <div className={'carListing-img'}>
                      {/* {car.stock_status === "reserved" &&   <div className='booking-icon'>
                        <p className='_p'>BOOKED</p>
                      </div>} */}
                      <LazyImage
                        src={_.find(car.medias, { image_type: 'ready_stock_image' })?.url}
                        alt="stock-car-img"
                        className="stock-image"
                      />
                      <div
                        className={
                          car.stock_status === 'reserved'
                            ? 'carListing-condition2 active'
                            : 'carListing-condition2'
                        }>
                        <label>{car.stock_status}</label>
                      </div>
                    </div>
                  </MDBCol>
                  <MDBCol className="col-6 col-md-6">
                    <MDBRow className="modal-row">
                      <label className="_label --modal-name">{car.car_model_name}</label>
                    </MDBRow>
                    <div className="d-flex mt-2">
                      <div className="carListing-border" />
                      <div className="carListing-border" />
                      <label className="_label --car-price">
                        <span>From </span>
                        RM {parseInt(car.asking_price).toLocaleString()}
                      </label>
                      <div className="carListing-border2" />
                    </div>
                    <MDBRow className="car-info-row">
                      <MDBCol className="col-5 info-col">
                        <Icon icon="mdi:barometer" className="icon" />
                        &nbsp;
                        <label className="_label --info2">
                          {' '}
                          {car.car_infos && car.car_infos[0].mileage
                            ? car.car_infos[0].mileage === '-'
                              ? '-'
                              : parseInt(car.car_infos[0].mileage).toLocaleString()
                            : '-'}
                          KM
                        </label>
                      </MDBCol>
                      <MDBCol className=" info-col">
                        <Icon icon="ri:building-3-line" className="icon" /> &nbsp;
                        <label className="_label --info">
                          {car.car_infos && car.car_infos[0].manufactory_year}
                        </label>
                      </MDBCol>
                      <MDBCol className=" info-col">
                        <Icon icon="mdi:car-search-outline" className="icon" />
                        &nbsp;<label className="_label --info">{car.grade && car.grade}</label>
                      </MDBCol>
                      <MDBCol className="col-5 info-col">
                        <Icon icon="radix-icons:id-card" className="icon" /> &nbsp;{' '}
                        <label className="_label --info">
                          {car.chassis_number && car.chassis_number
                            ? car.chassis_number.substring(car.chassis_number.length - 5)
                            : '-'}
                        </label>
                      </MDBCol>
                      <MDBCol className="info-col">
                        <Icon icon="fluent:people-call-20-regular" className="icon" />
                        &nbsp;{' '}
                        <label className="_label --info">{car.username && car.username} </label>
                      </MDBCol>
                    </MDBRow>
                  </MDBCol>
                </MDBRow>
              </Link>
            </MDBCol>
          ))}
        </MDBRow>
      ) : (
        <MDBContainer style={{ maxWidth: '1200px' }}>
          <MDBRow className="carList-container-grid2">
            {data.map((car, index) => {
              let text = car.car_model_name ? car.car_model_name : ''
              const regex = new RegExp(`(${charactersToColor.join('|')})`, 'g')
              const newRegex = new RegExp(regex, 'gi')
              const modifiedText = text.split(newRegex).map((part, index) => {
                let carName = part.toString().toLowerCase()
                if (charactersToColor.includes(carName)) {
                  return (
                    <span key={index} style={{ color: isDarkMode ? '#FBB24B' : '#DC6969' }}>
                      {part}
                    </span>
                  )
                }
                return part
              })

              return (
                <MDBCol
                  key={index}
                  className={'col-12 col-md-6 col-lg-6 col-xl-4 carListing-container2'}>
                  <MDBRow className="d-grid justify-content-center align-items-center carListing mt-4">
                    <div className="card-stock">
                      <Link to={`/carInfo/${car.path_name}/${car.id}`}>
                        <SwiperCarImage data={car.medias} status={car.stock_status} />
                        <MDBContainer className="mt-3">
                          <div className="d-flex mt-2">
                            <div className="carListing-border" />
                            <div className="carListing-border" />
                            <label className="_label --car-price2">
                              <span>From </span>
                              RM {parseInt(car.asking_price).toLocaleString()}
                            </label>
                            <div className="carListing-border2" />
                          </div>
                          <MDBRow className="mt-2 mb-0">
                            <label className="_label --info-desktop">
                              {car.car_infos && car.car_infos[0].manufactory_year} /{' '}
                              {car.car_infos && car.car_infos[0].mileage
                                ? car.car_infos[0].mileage === '-'
                                  ? '-'
                                  : parseInt(car.car_infos[0].mileage).toLocaleString()
                                : '-'}
                              KM / {car.grade && car.grade}
                            </label>
                          </MDBRow>
                          <MDBRow className="p-0 mb-0">
                            <label className="_label --info-desktop">
                              {car.chassis_number && car.chassis_number
                                ? car.chassis_number.substring(car.chassis_number.length - 5)
                                : '-'}{' '}
                              / {car.username && car.username}
                            </label>
                          </MDBRow>
                          <MDBRow
                            className="modal-row-desktop mb-1"
                            style={{ alignContent: 'center', height: '50px' }}>
                            <label className="_label --modal-name-desktop">{modifiedText}</label>
                          </MDBRow>
                        </MDBContainer>
                      </Link>
                    </div>
                  </MDBRow>
                </MDBCol>
              )
            })}
          </MDBRow>
        </MDBContainer>
      )}
    </>
  )
}
