import { useNavigate } from 'react-router-dom'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Icon } from '@iconify/react'
//assets
import { LogoMain } from '../../assets/images'

//redux
import { useDispatch, useSelector } from 'react-redux'

//hook
import useWindowDimensions from '../common/window-dimension.jsx'
import {
  setBodyTypeFilterData,
  setBrandFilterData,
  setCarBodyTypeFilter,
  setCarBrandFilter,
  setCarModelFilter,
  setCarPriceFilter,
  setCarYearFilter,
  setDisplayBrandModelData,
  setDisplayBrandModelFilter,
  setModelFilterData,
  setPriceFilterData,
  setSearchData,
  setSearchStock,
  setStockType,
  setYearFilterData,
  updateDisplayText,
} from '../../redux/reducer/stockReducer'
import { setChooseBrand } from '../../redux/reducer/carCompareReducer'
import { setOpenEventModal, setIsDarkMode } from '../../redux/reducer/homeReducer'
import { useEffect } from 'react'
import { useState } from 'react'
import ThemeSwitcher from '../element/themeSwitch'
import { IconStock, IconStockActive } from '../../assets/images'

import VersionUpdatePage from '../../pages/VersionUpdate'

export default function Header() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { isDarkMode, openVersionModal } = useSelector((state) => state.home)
  const { headerListing } = useSelector((state) => state.common)
  const { width } = useWindowDimensions()
  const [runEffect, setRunEffect] = useState(false)
  const storedPreference = localStorage.getItem('dontShowEventModal')

  const currentYear = new Date().getFullYear()

  const handelChangePlace = () => {
    dispatch(setSearchStock(''))
    dispatch(setCarBrandFilter([]))
    dispatch(setCarModelFilter([]))
    dispatch(setCarPriceFilter([50000, 5000000]))
    dispatch(setPriceFilterData([50000, 5000000]))
    dispatch(setCarYearFilter([1990, currentYear]))
    dispatch(setYearFilterData([1990, currentYear]))
    dispatch(setCarBodyTypeFilter([]))
    dispatch(setBodyTypeFilterData([]))

    dispatch(setModelFilterData([]))
    dispatch(setDisplayBrandModelData([]))
    dispatch(setDisplayBrandModelFilter([]))
    dispatch(setBrandFilterData([]))

    dispatch(updateDisplayText([]))
    dispatch(setStockType('D-Stock'))
    // setRunEffect(!runEffect)
  }
  // const location = useLocation()

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [location])

  // useEffect(() => {
  //   if (storedPreference === 'true') {
  //     dispatch(setOpenEventModal(false))
  //   } else {
  //     dispatch(setOpenEventModal(true))
  //   }
  // }, [runEffect])

  const toggleTheme = () => {
    const newIsDarkMode = !isDarkMode
    // Save the new theme to local storage
    localStorage.setItem('isDark', newIsDarkMode ? 'true' : 'false')
    dispatch(setIsDarkMode(newIsDarkMode))
  }

  return (
    <div>
      {openVersionModal && <VersionUpdatePage />}
      <header
        className={width < 991 ? 'header-main-container' : 'header-main-container fixed-top'}
        style={{ background: width < 991 ? null : '#0A0A0A' }}>
        <div className="section-top">
          <MDBContainer
            className="d-flex align-items-center"
            style={{
              padding: width >= 991 && width < 1200 ? '0em' : '',
            }}>
            <LazyLoadImage
              src={LogoMain}
              onClick={() => {
                navigate('/')
              }}
              width={width < 991 ? 200 : width < 1200 ? 230 : 280}
              height={width < 991 ? '43' : width < 1200 ? '50' : '60'}
              alt="dc-logo"
              style={{ cursor: 'pointer' }}
            />
            {width <= 991 && (
              <MDBCol className="theme-switch-container">
                <ThemeSwitcher toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
              </MDBCol>
            )}
            {width >= 991 && (
              <>
                {headerListing.map((item, index) => (
                  <MDBCol
                    className="header-text text-center"
                    key={index}
                    onClick={() => {
                      // dispatch(setChooseBrand(false))
                      // dispatch(setCarBodyTypeFilter(''))
                      // dispatch(setCarBrandFilter(''))
                      // dispatch(setCarYearFilter(''))
                      // dispatch(setStockType('D-Stock'))
                      handelChangePlace()
                    }}>
                    <label
                      style={{
                        color:
                          window.location.pathname === item.path ? item.color_active : item.color,
                        cursor: 'pointer',
                        fontSize: width >= 991 && width < 1200 ? '17.5px' : '',
                      }}
                      onClick={() => navigate(item.path)}>
                      {item.label}
                    </label>
                  </MDBCol>
                ))}
                {width >= 991 && (
                  <MDBCol
                    className="theme-switch-container col-1"
                    style={{ paddingLeft: width >= 991 && width < 1200 ? '1em' : '' }}>
                    <ThemeSwitcher toggleTheme={toggleTheme} isDarkMode={isDarkMode} />
                  </MDBCol>
                )}
              </>
            )}
          </MDBContainer>
        </div>

        {width < 991 && (
          <MDBContainer>
            <MDBRow className="section-bottom">
              {headerListing.map((item, index) => (
                <MDBCol
                  className="text-center"
                  key={index}
                  onClick={() => {
                    // dispatch(setChooseBrand(false))
                    // dispatch(setCarBodyTypeFilter(''))
                    // dispatch(setCarBrandFilter(''))
                    // dispatch(setCarYearFilter(''))
                    // dispatch(setStockType('D-Stock'))
                    handelChangePlace()
                  }}
                  style={{
                    cursor: 'pointer',
                    paddingTop: '1rem',
                  }}>
                  <div
                    style={{
                      maxHeight: '30px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      margin: 'auto',
                      height: '24px',
                      width: '30px',
                    }}>
                    {item.path !== '/stock' ? (
                      <Icon
                        icon={item.icon}
                        height={30}
                        width={30}
                        color={
                          window.location.pathname === item.path ? item.color_active : item.color
                        }
                        onClick={() => navigate(item.path)}
                      />
                    ) : (
                      <img
                        src={window.location.pathname === item.path ? IconStockActive : IconStock}
                        alt="dconcept"
                        style={{ height: '100%', width: '100%' }}
                        onClick={() => navigate(item.path)}
                      />
                    )}
                  </div>
                  <MDBRow>
                    <label
                      className="_label --header_page"
                      style={{
                        color:
                          window.location.pathname === item.path ? item.color_active : item.color,
                      }}
                      onClick={() => navigate(item.path)}>
                      {item.label}
                    </label>
                  </MDBRow>
                </MDBCol>
              ))}
            </MDBRow>
          </MDBContainer>
        )}
      </header>
    </div>
  )
}
