import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//lib
import { MDBCol, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { getHomeSearch, setLastView } from '../../../redux/reducer/homeReducer'

//component
import ModalBox from '../../../components/modal/half.modal.box'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'
import { Skeleton } from 'primereact/skeleton'

export default function SearchModalBox({ show, setShow }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const storedTheme = localStorage.getItem('isDark')

  const { width } = useWindowDimensions()

  const ref = useRef()

  const [selectedType, setSelectedType] = useState('All')

  const type = ['All', 'Sedan', 'Hatchback', 'Coupe', 'Convertible', 'SUV', 'MVP', 'Wagon']

  const [search, setSearch] = useState('')
  const [searchCarBodies, setSearchCarBodies] = useState('')

  const { searchData, lastViewData, loading } = useSelector((state) => state.home)

  const formatCash = (num, digits = 2) => {
    var units = ['k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'],
      decimal

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1)

      if (num <= -decimal || num >= decimal) {
        const number = +(num / decimal).toFixed(digits)
        if (units[i] === 'Y') {
          return number.toLocaleString() + units[i]
        }
        return number.toLocaleString() + units[i]
      }
    }

    return num
  }

  const charactersToColor = searchData.searchkeywords ? searchData.searchkeywords : []

  //last view
  function handlePostClick(id) {
    let updatedPosts

    const storedPosts = JSON.parse(localStorage.getItem('selectedPosts')) || []
    if (storedPosts.includes(id)) {
      updatedPosts = storedPosts.filter((n) => n !== id)
    } else {
      updatedPosts = storedPosts
    }

    updatedPosts.unshift(id)

    if (updatedPosts.length > 5) {
      updatedPosts.pop()
    }

    localStorage.setItem('selectedPosts', JSON.stringify(updatedPosts))
  }

  useEffect(() => {
    dispatch(
      getHomeSearch({
        search: search,
        search_car_bodies: searchCarBodies === 'All' ? '' : searchCarBodies,
        search_ready_stock: '',
        start: '',
        length: '',
      }),
    )
  }, [search, searchCarBodies])

  // useEffect(() => {
  //   ref.current.focus();
  // }, []);
  const { isDarkMode } = useSelector((state) => state.home)
  return (
    <ModalBox
      show={width < 991 && show ? show : false}
      setShow={setShow}
      scrollable={true}
      onDismiss={() => setShow(false)}
      className={isDarkMode ? 'search-half-modal-dark' : "search-half-modal-light"}
      content={
        <MDBModalBody>
          <MDBRow className="">
            <Icon
              onClick={() => setShow(false)}
              icon="clarity:window-close-line"
              color="#707070"
              width="25"
              height="30"
            />
          </MDBRow>
          <MDBRow
            className="search-bar-row align-items-center"
            onClick={() => {
              setShow(true)
            }}>
            <MDBCol className="col-10 text-start">
              <input
                placeholder={'type your car name...'}
                value={search}
                ref={ref}
                onChange={(e) => {
                  setSearch(e.target.value)
                  setSearchCarBodies(selectedType)
                }}
              />
            </MDBCol>
            <MDBCol
              className="col-2 text-end"
              onClick={() => {
                if (search) {
                  setSearch('')
                } else {
                  setSearch(search)
                  setSearchCarBodies(selectedType)
                }
              }}>
              {search ? (
                <Icon icon="material-symbols:close" color="#9091A0" width="25" height="30" />
              ) : (
                <Icon icon="eva:search-outline" color="#D6A055" width="25" height="30" />
              )}
            </MDBCol>
          </MDBRow>

          {search === '' ? (
            <>
              <div className="line-container --gold"></div>
              <MDBRow>
                <label className="_title">Type</label>
              </MDBRow>
              <MDBRow>
                {type.map((item, index) => (
                  <label
                    className={selectedType === item ? 'search-tab-box --active' : 'search-tab-box'}
                    key={index}
                    onClick={() => {
                      setSelectedType(item)
                    }}>
                    {item}
                  </label>
                ))}
              </MDBRow>
              {width < 991 && lastViewData && lastViewData.length > 0 ? (
                <MDBRow className="mt-5">
                  <label className="_title">Recent Search</label>
                </MDBRow>
              ) : (
                ''
              )}
              {width < 991 &&
                lastViewData &&
                lastViewData.length > 0 &&
                lastViewData.map((item, index) => (
                  <div key={index}>
                    <MDBRow
                      className="car-item-box --search"
                      onClick={() => {
                        navigate(`/post/${item.id}`)
                        dispatch(setLastView(true))
                        handlePostClick(item.postId)
                      }}>
                      <MDBCol className="col-4 d-flex align-items-center">
                        {item.image[0]?.url ? (
                          <LazyLoadImage src={item.image[0].url} width="100%" alt="last-view-img" />
                        ) : (
                          ''
                        )}
                      </MDBCol>
                      <MDBCol style={{ lineHeight: '1.4em', paddingLeft: '2em' }}>
                        <label className="_label --title">{item.car_model_name}</label>
                        <label className="_label --detail">Estimate price range</label>
                        <label className="_label --detail">
                          RM{' '}
                          {width > 991
                            ? parseInt(
                                item.starting_price ? item.starting_price : '',
                              ).toLocaleString()
                            : formatCash(item.starting_price ? item.starting_price : '')}
                        </label>
                        <div>
                          {item.stock_total > 0 ? (
                            <>
                              <label className="_label --tag">Recon</label>&nbsp;
                            </>
                          ) : (
                            ''
                          )}
                          {item.used_total > 0 ? (
                            <>
                              <label className="_label --tag">Used Car</label>&nbsp;
                            </>
                          ) : (
                            ''
                          )}
                          {item.direct_import > 0 ? (
                            <>
                              <label className="_label --tag">Import</label>&nbsp;
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                      </MDBCol>
                    </MDBRow>
                  </div>
                ))}
            </>
          ) : (
            ''
          )}

          {search !== '' ? (
            <>
              <div className="line-container --gold"></div>
              <MDBRow className="mb-1">
                <label className="_title">Suggestion</label>
              </MDBRow>
              {!loading ? (
                <>
                  {searchData &&
                    searchData.brandData &&
                    searchData.brandData.length > 0 &&
                    searchData.brandData.map((item, index) => {
                      let text = item.name ? item.name : "";
                      const regex = new RegExp(`(${charactersToColor.join('|')})`, 'g');
                      const newRegex = new RegExp(regex , "gi")
                      const modifiedText = text.split(newRegex).map((part, index) => {
                        let carName = part.toString().toLowerCase()
                        if (charactersToColor.includes(carName)) {
                          return <span key={index} style={{ color: '#FBB24B' }}>{part}</span>;
                        }
                        return part;
                      });
                      return (
                        <div key={index}>
                          <MDBRow
                            className="brandlist-listing"
                            onClick={() => {
                              navigate(`/brand/${item.name}`)
                            }}>
                            <div className="mobile-icon-div" style={{ paddingLeft: '1rem' }}>
                              <LazyLoadImage
                                alt="brand-icon"
                                src={item.icon.length > 0 ? item.icon[0].url : ''}
                                width="50"
                                height="50"
                              />
                            </div>
                            <p className="text-white" style={{ paddingLeft: '5.3rem' }}>
                              {modifiedText}
                            </p>
                          </MDBRow>
                        </div>
                      )
                    })}

                  {searchData &&
                    searchData.postData &&
                    searchData.postData.length > 0 &&
                    searchData.postData.map((item, index) => {
                      let text = item.car_model_name ? item.car_model_name : "";
                      const regex = new RegExp(`(${charactersToColor.join('|')})`, 'g');
                      const newRegex = new RegExp(regex , "gi")
                      const modifiedText = text.split(newRegex).map((part, index) => {
                        let carName = part.toString().toLowerCase()
                        if (charactersToColor.includes(carName)) {
                          return <span key={index} style={{ color: '#FBB24B' }}>{part}</span>;
                        }
                        return part;
                      });
                      return (
                        <div key={index}>
                          <MDBRow
                            className="brandlist-listing"
                            onClick={() => {
                              navigate(`/post/${item.id}`)
                              dispatch(setLastView(true))
                              handlePostClick(item.id)
                            }}>
                            <div
                              className="mobile-icon-div"
                              style={{ paddingLeft: '2.2rem' }}
                              onClick={() => {
                                if (search) {
                                  setSearch('')
                                } else {
                                  setSearch(search)
                                  setSearchCarBodies(selectedType)
                                }
                              }}>
                              <Icon
                                icon="ic:baseline-search"
                                color="#ababab"
                                width="26"
                                height="26"
                              />
                            </div>
                            <p className="text-white" style={{ paddingLeft: '6.5rem' }}>
                              {modifiedText}
                            </p>
                          </MDBRow>
                        </div>
                      )
                    })}
                </>
              ) : (
                Array.from({ length: 3 }, (_, index) => (
                  <MDBRow className="brandlist-listing mt-3">
                    <div className="mobile-icon-div" style={{ paddingLeft: '2.2rem' }}>
                      <Skeleton width="4em" height="40px" borderRadius="100%" className="mt-3" />
                    </div>
                    <Skeleton width="70%" height="15px" className="mt-3 ms-3" />
                  </MDBRow>
                ))
              )}
            </>
          ) : (
            ''
          )}
        </MDBModalBody>
      }
    />
  )
}
