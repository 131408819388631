import React, { useEffect, useRef } from 'react'

//lib
import { MDBContainer } from 'mdb-react-ui-kit'

export default function Banner({ embedId }) {
  const playerRef = useRef(null)

  useEffect(() => {
    const tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    window.onYouTubeIframeAPIReady = () => {
      playerRef.current = new window.YT.Player('video-responsive', {
        height: '360',
        width: '640',
        videoId: `${embedId}`,
        playerVars: {
          autoplay: 1,
          mute: 1,
        },
      })
    }

    return () => {
      window.onYouTubeIframeAPIReady = null
    }
  }, [])

  return (
    <MDBContainer className="video-banner-container">
      <div className="video-responsive">
        <iframe
          src={`https://www.youtube.com/embed/${embedId}?autoplay=1&mute=1&mute=1&enablejsapi=1`}
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
          title="video"
        />
      </div>
    </MDBContainer>
  )
}
