import _ from 'lodash'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'

//redux
import { useSelector } from 'react-redux'

//components
import CarPanel from '../component/car.panel'
import OptionsBox from '../component/options.box'

export default function QuickLookView({ item, index, loading }) {
  const { width } = useWindowDimensions()
  const { quick_look } = useSelector((state) => state.carPost)

  return (
    <div>
      {width < 991 ? (
        <MDBContainer>
          <CarPanel />
          {quick_look.spec_option && quick_look.spec_option[0].label !== '-' && (
            <OptionsBox title="Spec Option" type="listing-text" data={quick_look.spec_option} />
          )}
          {quick_look.variarion && quick_look.variarion[0].label !== '-' && (
            <OptionsBox title="Variations" type="listing-text" data={quick_look.variarion} />
          )}
          {quick_look.dimension &&
            quick_look.dimension.length !== '-' &&
            quick_look.dimension.width !== '-' &&
            quick_look.dimension.height !== '-' &&
            quick_look.dimension.wheelbase !== '-' && (
              <OptionsBox title="Dimension" type="listing-demension" data={quick_look.dimension} />
            )}
          {quick_look && quick_look.medias && (
            <>
              {_.filter(quick_look.medias, (obj) => {
                return obj.image_type === 'exterior_image'
              }).length > 0 ? (
                <OptionsBox
                  title="Exterior Look"
                  type="image"
                  data={_.filter(quick_look.medias, (obj) => {
                    return obj.image_type === 'exterior_image'
                  })}
                  loading={loading}
                />
              ) : (
                ''
              )}

              {_.filter(quick_look.medias, (obj) => {
                return obj.image_type === 'interior_image'
              }).length > 0 ? (
                <OptionsBox
                  title="Interior Look"
                  type="image"
                  data={_.filter(quick_look.medias, (obj) => {
                    return obj.image_type === 'interior_image'
                  })}
                  loading={loading}
                />
              ) : (
                ''
              )}
            </>
          )}
          {quick_look.engine_specifications &&
            quick_look.engine_specifications[0].label !== '-' && (
              <OptionsBox
                title="Engine Specifications"
                type="listing-box"
                data={quick_look.engine_specifications}
                loading={loading}
              />
            )}
          {quick_look.transmission && quick_look.transmission[0].label !== '-' && (
            <OptionsBox title="Transmission" type="listing-box" data={quick_look.transmission} />
          )}
          {quick_look.fuel_efficiency && quick_look.fuel_efficiency[0].label !== '-' && (
            <OptionsBox
              title="Fuel Efficiency"
              type="listing-box"
              data={quick_look.fuel_efficiency}
              loading={loading}
            />
          )}
          {quick_look.safety_features && quick_look.safety_features[0].label !== '-' && (
            <OptionsBox
              title="Safety Features"
              type="listing-box"
              data={quick_look.safety_features}
              loading={loading}
            />
          )}
          {quick_look.interior_features && quick_look.interior_features[0].label !== '-' && (
            <OptionsBox
              title="Interior Features"
              type="listing-box"
              data={quick_look.interior_features}
              loading={loading}
            />
          )}
          {quick_look.exterior_features && quick_look.exterior_features[0].label !== '-' && (
            <OptionsBox
              title="Exterior Features"
              type="listing-box"
              data={quick_look.exterior_features}
              loading={loading}
            />
          )}
        </MDBContainer>
      ) : (
        <MDBContainer className="p-0">
          <MDBRow className="m-0 gap-4">
            <MDBCol>
              {quick_look.spec_option && quick_look.spec_option[0].label !== '-' && (
                <OptionsBox
                  title="Spec Option"
                  type="listing-text"
                  data={quick_look.spec_option}
                  loading={loading}
                />
              )}
              {quick_look.variarion && quick_look.variarion[0].label !== '-' && (
                <OptionsBox
                  title="Variations"
                  type="listing-text"
                  data={quick_look.variarion}
                  loading={loading}
                />
              )}
              {quick_look.dimension &&
                quick_look.dimension.length !== '-' &&
                quick_look.dimension.width !== '-' &&
                quick_look.dimension.height !== '-' &&
                quick_look.dimension.wheelbase !== '-' && (
                  <OptionsBox
                    title="Dimension"
                    type="listing-demension"
                    data={quick_look.dimension}
                    loading={loading}
                  />
                )}
              {quick_look.engine_specifications &&
                quick_look.engine_specifications[0].label !== '-' && (
                  <OptionsBox
                    title="Engine Specifications"
                    type="listing-box"
                    data={quick_look.engine_specifications}
                    loading={loading}
                  />
                )}
              {quick_look.transmission && quick_look.transmission[0].label !== '-' && (
                <OptionsBox
                  title="Transmission"
                  type="listing-box"
                  data={quick_look.transmission}
                  loading={loading}
                />
              )}
              {quick_look.fuel_efficiency && quick_look.fuel_efficiency[0].label !== '-' && (
                <OptionsBox
                  title="Fuel Efficiency"
                  type="listing-box"
                  data={quick_look.fuel_efficiency}
                  loading={loading}
                />
              )}
              {quick_look.safety_features && quick_look.safety_features[0].label !== '-' && (
                <OptionsBox
                  title="Safety Features"
                  type="listing-box"
                  data={quick_look.safety_features}
                  loading={loading}
                />
              )}
              {quick_look.interior_features && quick_look.interior_features[0].label !== '-' && (
                <OptionsBox
                  title="Interior Features"
                  type="listing-box"
                  data={quick_look.interior_features}
                  loading={loading}
                />
              )}
              {quick_look.exterior_features && quick_look.exterior_features[0].label !== '-' && (
                <OptionsBox
                  title="Exterior Features"
                  type="listing-box"
                  data={quick_look.exterior_features}
                  loading={loading}
                />
              )}
            </MDBCol>
            <MDBCol>
              <CarPanel />
              {quick_look && quick_look.medias && (
                <>
                  {_.filter(quick_look.medias, (obj) => {
                    return obj.image_type === 'exterior_image'
                  }).length > 0 ? (
                    <OptionsBox
                      title="Exterior Look"
                      type="image"
                      data={_.filter(quick_look.medias, (obj) => {
                        return obj.image_type === 'exterior_image'
                      })}
                      loading={loading}
                    />
                  ) : (
                    ''
                  )}

                  {_.filter(quick_look.medias, (obj) => {
                    return obj.image_type === 'interior_image'
                  }).length > 0 ? (
                    <OptionsBox
                      title="Interior Look"
                      type="image"
                      data={_.filter(quick_look.medias, (obj) => {
                        return obj.image_type === 'interior_image'
                      })}
                      loading={loading}
                    />
                  ) : (
                    ''
                  )}
                </>
              )}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      )}
    </div>
  )
}
