import { configureStore } from '@reduxjs/toolkit'

//reducer
import commonReducer from './reducer/commonReducer'
import homeReducer from './reducer/homeReducer'
import orderReducer from './reducer/orderReducer'
import carInfoReducer from './reducer/carInfoReducer'
import stockReducer from './reducer/stockReducer'
import brandReducer from './reducer/brandReducer'
import carPostReducer from './reducer/carPostReducer'
import calculatorReducer from './reducer/calculatorReducer'
import carCompareReducer from './reducer/carCompareReducer'
import discoverReduser from './reducer/discoverReducer'

const reducer = {
  common: commonReducer,
  home: homeReducer,
  order: orderReducer,
  carInfo: carInfoReducer,
  stock: stockReducer,
  brand: brandReducer,
  carPost: carPostReducer,
  calculator: calculatorReducer,
  car_compare: carCompareReducer,
  discover : discoverReduser
}

const store = configureStore({
  reducer: reducer,
  devTools: true,
})

export default store
