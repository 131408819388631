import { LazyLoadImage } from 'react-lazy-load-image-component'

export default function LazyImage({
  item,
  alt,
  title,
  src,
  width,
  height,
  className,
  style,
  onClick,
}) {
  const image = src ? src : ''
  const imageSet = item
    ? item
    : {
        image_xl: null,
        image_l: null,
        image_m: null,
        image_s: null,
      }
  return (
    <LazyLoadImage
      src={image}
      srcSet={`
    ${imageSet.image_s ? imageSet.image_s : image} 430w,
    ${imageSet.image_m ? imageSet.image_m : image} 1024w,
    ${imageSet.image_l ? imageSet.image_l : image} 1800w,
    ${imageSet.image_xl ? imageSet.image_xl : image} 2200w
  `}
      sizes={`
      (max-width: 429px) 430px,
      (max-width: 1023px) 1024px, 
      (max-width: 1799px) 1800px,
    (max-width: 2199px) 2200px,
    2600px
    25vw 
    `}
      alt={alt ? alt : 'Dconcept Car Import'}
      title={title ? title : 'Dconcept Car Import'}
      width={width ? width : ''}
      height={height ? height : ''}
      className={className ? className : ''}
      // style={style ? style : { display: image === '' ? 'none' : ""  , pointerEvents : "none" }}
      style={
        style
          ? { ...style, minWidth: '1px', minHeight: '1px' }
          : {
              display: image === '' ? 'none' : 'block',
              pointerEvents: 'none',
              minWidth: '1px',
              minHeight: '1px',
            }
      }
      onClick={onClick ? onClick : null}
    />
  )
}
