//lib
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { debounce } from 'lodash'

//redux
import { useSelector, useDispatch } from 'react-redux'
import {
  setShowSortByList,
  setSortByFilter,
  setSortBySequence,
} from '../../redux/reducer/stockReducer'
import { Icon } from '@iconify/react'
import { MDBContainer } from 'mdb-react-ui-kit'
import { useEffect, useState } from 'react'
import useWindowDimensions from '../common/window-dimension'
import { useSearchParams } from 'react-router-dom'

export const SortByList = ({ show, setShow, type, display, top, backFirstPage, startZero }) => {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const { isDarkMode } = useSelector((state) => state.home)
  const { showSortByList, sortByFilter, sortBySequence } = useSelector((state) => state.stock)

  const [ searchParams, setSearchParams ] = useSearchParams()

  const [sortByFilterData, setSortByFilterData] = useState(sortByFilter ? sortByFilter : [])
  const [sortBySequenceData, setSortBySequenceData] = useState(sortBySequence ? sortBySequence : [])

  const sortByList = [
    {
      label: 'Latest',
      subLabel: [
        { title: 'Default', value: [] },
      ],
    },
    {
      label: 'Price',
      subLabel: [
        { title: 'Low to High', value: 'asc' },
        { title: 'High to Low', value: 'desc' },
      ],
      value: 'asking_price',
    },
    {
      label: 'Mfg. Year',
      subLabel: [
        { title: 'Old to New', value: 'asc' },
        { title: 'New to Old', value: 'desc' },
      ],
      value: 'year',
    },
  ]

  const handleToggleOverlay = () => {
    dispatch(setShowSortByList(!showSortByList))
    document.body.style.overflow = 'auto'
  }

  const handleShowToggleOverlay = () => {
    setShow(!show)
    document.body.style.overflow = 'auto'
  }

  const handleOverflowChange = debounce((newShowValue) => {
    document.body.style.overflow = newShowValue ? 'hidden' : 'auto'
  }, 100)

  // useEffect(() => {
  //   if (type === 'non-fixed') {
  //     handleOverflowChange(showSortByList)
  //   } else if (type === 'fixed') {
  //     handleOverflowChange(show)
  //   } else {
  //     handleOverflowChange(showSortByList)
  //   }
  // }, [showSortByList, show])

  return (
    <>
      {type ? (
        <>
          <div
            className="brandlist-div-overlay"
            onClick={() => {
              type == 'fixed' ? handleShowToggleOverlay() : handleToggleOverlay()
            }}
          />
          <MDBContainer
            className={
              type == 'non-fixed' ? 'brandlist-div-container' : 'brandlist-div-container --fixed'
            }
            style={{ top: top }}>
            <div className="brandlist-div-body --sort">
              {sortByList.map((menu, index) => (
                <li className="brandlist-listing --sort-grid">
                  <p>{menu.label}</p>
                  <div style={{ padding: '1em' }}>
                    {menu.subLabel.map((subMenu, subIndex) => {
                      const isSelected = sortByFilterData.some(
                        (item) => item.label === menu.value && item.value === subMenu.value,
                      )

                      return (
                        <div
                          className="d-flex justify-content-end mt-2"
                          style={{ color: isDarkMode ? isSelected || (sortByFilterData.length === 0 && menu.label === 'Latest') ? '#ffd439' : '#d1d5db' : isSelected || (sortByFilterData.length === 0 && menu.label === 'Latest') ? '#Ac3434' : '#24252D', cursor: 'pointer' }}
                          onClick={() => {
                            if(subMenu.title === 'Default') {
                              setSortByFilterData([])
                            } else {
                              const updatedSortOptions = sortByFilterData.filter(
                                (item) => item.label !== menu.value,
                              )
  
                              if (!isSelected) {
                                updatedSortOptions.push({ label: menu.value, value: subMenu.value })
                              }
  
                              setSortByFilterData(updatedSortOptions)
                            }
                          }}
                          key={subIndex}>
                          {subMenu.title}
                          <div className="ms-3">
                            <Icon
                              icon={isSelected || (sortByFilterData.length === 0 && menu.label === 'Latest') ? "prime:check-square" : "ion:square-outline"}
                              width={width < 991 ? '18px' : isSelected || (sortByFilterData.length === 0 && menu.label === 'Latest') ? '20px' : '19px'}
                              style={{ color: isDarkMode && isSelected || (sortByFilterData.length === 0 && menu.label === 'Latest') ? '#fbb24b' : isSelected || (sortByFilterData.length === 0 && menu.label === 'Latest') ? '#ac3434' : '#d1d5db', marginBottom: '0.2em' }}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </li>
              ))}
              <div className="brandlist-div-footer">
                <div
                  className="d-flex align-items-center"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    setSortByFilterData([])
                    setSortBySequenceData([])
                    dispatch(setSortByFilter([]))
                    dispatch(setSortBySequence([]))
                    if (type == 'fixed') {
                      handleShowToggleOverlay()
                    } else {
                      handleToggleOverlay()
                    }
                  }}>
                  Clear
                </div>
                <div
                  className="darkTheme-btn reset-btn --all"
                  onClick={() => {
                    startZero(0)
                    backFirstPage(1) 
                    setSearchParams({ page: '1' })

                    dispatch(setSortByFilter(sortByFilterData))
                    dispatch(setSortBySequence(sortBySequenceData))
                    if (type == 'fixed') {
                      handleShowToggleOverlay()
                    } else {
                      handleToggleOverlay()
                    }
                  }}>
                  Apply
                </div>
              </div>
            </div>
          </MDBContainer>
        </>
      ) : (
        <Modal
          show={showSortByList}
          onHide={() => {
            dispatch(setShowSortByList(false))
          }}
          keyboard={false}
          centered
          fullscreen
          className={isDarkMode ? "brandlist-modal-container-dark" :  "brandlist-modal-container-light"}>
          <Modal.Header className="brandlist-modal-header --filter">
            <div className="d-flex" style={{ justifyContent: 'space-between' }}>
              <div></div>
              <div className="_label --filter">Sort By</div>
              <div
                className="brandlist-header-closeBtn --filter"
                onClick={() => {
                  dispatch(setShowSortByList(false))
                }}>
                <FontAwesomeIcon icon={solid('times')}></FontAwesomeIcon>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="brandlist-modal-body --footer">
            {sortByList.map((menu, index) => (
              <li className="brandlist-listing --sort-grid">
                <p>{menu.label}</p>
                <div style={{ padding: '1em' }}>
                  {menu.subLabel.map((subMenu, subIndex) => {
                    const isSelected = sortByFilterData.some(
                      (item) => item.label === menu.value && item.value === subMenu.value,
                    )

                    return (
                      <div
                        className="d-flex justify-content-end align-items-center mt-2"
                        style={{ color: isDarkMode ? isSelected || (sortByFilterData.length === 0 && menu.label === 'Latest') ? '#ffd439' : '#d1d5db' : isSelected || (sortByFilterData.length === 0 && menu.label === 'Latest') ? '#Ac3434' : '#24252D', cursor: 'pointer' }}
                        onClick={() => {
                          if(subMenu.title === 'Default') {
                            setSortByFilterData([])
                          } else {
                            const updatedSortOptions = sortByFilterData.filter(
                              (item) => item.label !== menu.value,
                            )

                            if (!isSelected) {
                              updatedSortOptions.push({ label: menu.value, value: subMenu.value })
                            }

                            setSortByFilterData(updatedSortOptions)
                          }
                        }}
                        key={subIndex}>
                        {subMenu.title}
                        <div className="ms-3">
                          <Icon
                            icon={isSelected || (sortByFilterData.length === 0 && menu.label === 'Latest') ? "prime:check-square" : "ion:square-outline"}
                            width={width < 991 ? isSelected || (sortByFilterData.length === 0 && menu.label === 'Latest') ? '18px' : '17px' : '20px'}
                            style={{ color: isDarkMode && isSelected || (sortByFilterData.length === 0 && menu.label === 'Latest') ? '#fbb24b' : isSelected || (sortByFilterData.length === 0 && menu.label === 'Latest') ? '#ac3434' : '#d1d5db', marginBottom: '0.2em' }}
                          />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </li>
            ))}
          </Modal.Body>
          <Modal.Footer className="brandlist-modal-footer">
            <div
              className="darkTheme-btn reset-btn --all"
              onClick={() => {
                startZero(0)
                backFirstPage(1)
                setSearchParams({ page: '1' })
                
                dispatch(setSortByFilter(sortByFilterData))
                dispatch(setSortBySequence(sortBySequenceData))
                dispatch(setShowSortByList(false))
              }}>
              Apply
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  )
}
