import React from 'react'
import { useNavigate } from 'react-router-dom'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//lib
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { Swiper, SwiperSlide } from 'swiper/react'

import LazyImage from '../../../components/common/common.image'

export default function SamePriceRange({ title, data }) {
  const navigate = useNavigate()
  const { width } = useWindowDimensions()

  const formatCash = (num, digits = 2) => {
    var units = ['k', 'M', 'B', 'T', 'P', 'E', 'Z', 'Y'],
      decimal

    for (var i = units.length - 1; i >= 0; i--) {
      decimal = Math.pow(1000, i + 1)

      if (num <= -decimal || num >= decimal) {
        const number = +(num / decimal).toFixed(digits)
        if (units[i] === 'Y') {
          return number.toLocaleString() + units[i]
        }
        return number.toLocaleString() + units[i]
      }
    }

    return num
  }

  return (
    <MDBContainer className="samePriceRange-image-container">
      <div className="carInfo-samePriceRange-title">
        <p>{title}</p>
      </div>
      <MDBContainer className="samePriceRange-swiper-container">
        <Swiper spaceBetween={5} slidesPerView="auto">
          {data.map((item, index) => {
            return (
              <SwiperSlide
                className="samePriceRange-swiper"
                onClick={() => {
                  navigate(`/carInfo/${item.path_name}/${item.id}`)
                }}
                key={index}>
                <div>
                  <MDBRow>
                    <div className="samePriceRange-img">
                      <LazyImage
                        item={item.medias.length > 0 ? item.medias[0] : null}
                        src={item.medias.length > 0 ? item.medias[0].url : ''}
                        alt="same-price-range-img"
                      />
                    </div>
                  </MDBRow>
                  <MDBRow>
                    <div className="samePriceRange-title">
                      <p>{item.car_model_name}</p>
                    </div>
                    <div className="samePriceRange-content">
                      {item.car_infos[0]?.manufactory_year
                        ? item.car_infos[0].manufactory_year
                        : '-'}
                      /
                      {item.car_infos[0]?.mileage
                        ? item.car_infos[0].mileage === '-'
                          ? 0
                          : parseInt(item.car_infos[0].mileage).toLocaleString()
                        : 0}
                      KM
                    </div>
                    <div className="samePriceRange-content">
                      From RM{' '}
                      {width > 991
                        ? parseInt(item.asking_price).toLocaleString()
                        : parseInt(item.asking_price).toLocaleString()}
                    </div>
                  </MDBRow>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </MDBContainer>
    </MDBContainer>
  )
}
