import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

//lib
import {  MDBContainer, MDBRow , MDBCardImage, MDBCard ,MDBCol} from 'mdb-react-ui-kit'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import "swiper/css/navigation";
import 'swiper/css/thumbs';
import 'swiper/css/effect-coverflow';
import { Navigation ,EffectCoverflow , Thumbs , Autoplay} from "swiper";
import { Rating } from 'primereact/rating';
import { Icon } from '@iconify/react';
//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//redux
import { useDispatch, useSelector } from 'react-redux'

//helper
import LazyImage from '../../../components/common/common.image'

export default function CustomerSay2() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { width } = useWindowDimensions()
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [active, setActive] = useState(null);
  const { customersListing} = useSelector((state) => state.common)
  const { isDarkMode } = useSelector((state) => state.home)
  

  return (
    <MDBContainer id={'image'} className="last-seen-container" style={{marginTop : "10em" , marginBottom : width > 991 ? "" : "-3em"}}>
       <MDBRow className='new_costomer_row'>
        <MDBCol className={width > 991 ? 'col-7' : "col-12 p-0 m-0"}>
        <MDBRow>
           <label className={width > 991 ?'_label --title' : "_label --title_mobile text-center"}>
                What our <span className={width > 991 ? 'background-image' : "background-image mobile"}>clients</span>
            </label>
            </MDBRow>
            <MDBRow>
            <label className={width > 991 ?'_label --title' : "_label --title_mobile text-center"}>
                say about <span className='colored-text'>Dconcept</span>
            </label>
            </MDBRow>
        </MDBCol>
       </MDBRow>
        <MDBContainer className='mt-4 p-0'>
        <Swiper
    spaceBetween={15}
        slidesPerView={width > 991 ? 3 : 1.1}
        loop={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        >
          {customersListing.map((item , index) => {
            return (
              <SwiperSlide key={index}>
                <MDBContainer className='p-2'>
                  <MDBCard style={{background : isDarkMode ? "rgb(23 27 42)" : "#F8F8F8" , boxShadow : isDarkMode ? "" : "none"}} className={width > 991 ? 'new_customer_container' : "new_customer_container mobile"}> 
                 <MDBRow className='customer-info mb-5'>
                    <MDBCol className='col-5' style={{justifyContent : "center" ,display : "flex"}}>
                        <div className={width > 991 ? 'new_avatar' : "new_avatar mobile"}>
                            <LazyImage src={item.avatar} alt={item.content} height={"100%"} width={"100%"}/>
                        </div>
                    </MDBCol>
                    <MDBCol>
                    <MDBRow>
                    <Rating value={item.star} readOnly cancel={false} />
                      </MDBRow>
                        <MDBRow className='mt-3'>
                       <label className={width > 991 ?  '_label --name' : "_label --mobile_name"}>
                          <span style={{color : isDarkMode ?  '#cac7cf' : "#616469"}}>By </span>{item.name}
                         </label>
                      </MDBRow>
                        </MDBCol>
                 </MDBRow>
                 <MDBRow className={width > 991 ? 'content-row' : "content-row mobile"}> 
                    <p>{item.content}</p>
                 </MDBRow>
                 <MDBRow className={width > 991 ? 'icon-row' : "icon-row mobile"}> 
                 <Icon icon="logos:google" height={width > 991 ? "2.6rem" : "1.8em"} />
                 </MDBRow>
                  </MDBCard>
                </MDBContainer>
              </SwiperSlide>
            )
          })}
        </Swiper>
        </MDBContainer>
    </MDBContainer>
  )
}

