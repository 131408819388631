import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    car1Data: {},
    car2Data: {},
    car3Data: {},
    allCarData: {},
    chooseBrand: false,
    selectedId: "",
}

const carSlice = createSlice({
    name: 'car_compare',
    initialState,
    reducers: {
        setSection: (state, action) => {
            state.section = action.payload
        },
        setSelectedId: (state, action) => {
            state.selectedId = action.payload
        },
        setAllCarData: (state, action) => {

            if(state.selectedId === "1") {
                state.car1Data = action.payload

            } else if (state.selectedId === "2") {
                state.car2Data = action.payload
                
            } else {
                state.car3Data = action.payload
            }
        },
        setCar1Data: (state, action) => {
            state.car1Data = action.payload
        },
        setCar2Data: (state, action) => {
            state.car2Data = action.payload
        },
        setCar3Data: (state, action) => {
            state.car3Data = action.payload
        },
        setChooseBrand: (state, action) => {
            state.chooseBrand = action.payload
        },
    },
    extraReducers: {
    },
  })
  
  export const {
    setSelectedId,
    setAllCarData,
    setCar1Data,
    setCar2Data,
    setCar3Data,
    setChooseBrand,
  } = carSlice.actions
  const { reducer } = carSlice
  export default reducer

