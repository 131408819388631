import { createSlice } from '@reduxjs/toolkit'

//assets
import {
  IconInfo,
  IconInfoActive,
  IconDiscover,
  IconStock,
  IconOrder,
  IconDiscoverActive,
  IconStockActive,
  IconOrderActive,
} from '../../assets/images'

const initialState = {
  headerListing: [
    {
      label: 'Info',
      image_active: IconInfoActive,
      image: IconInfo,
      color_active: '#FBB24B',
      color: '#9091A0',
      path: '/',
      icon: 'ph:car',
    },
    {
      label: 'Discover',
      image_active: IconDiscoverActive,
      image: IconDiscover,
      color_active: '#FBB24B',
      color: '#9091A0',
      path: '/discover',
      icon: 'ph:video',
    },
    {
      label: 'Stock',
      image_active: IconStockActive,
      image: IconStock,
      color_active: '#FBB24B',
      color: '#9091A0',
      path: '/stock',
      icon: 'mdi:train-car-autorack',
    },
    // {
    //   label: 'preorder',
    //   image_active: IconStockActive,
    //   image: IconStock,
    //   color_active: '#FBB24B',
    //   color: '#9091A0',
    //   path: '/preorder',
    //   icon: 'map:car-dealer',
    // },
    {
      label: 'Profile',
      image_active: IconOrderActive,
      image: IconOrder,
      color_active: '#FBB24B',
      color: '#9091A0',
      path: '/order',
      icon: 'iconamoon:profile-circle-fill',
    },
  ],
  footerListing: [
    {
      id: 1,
      title: 'Service',
      active: false,
      icon: 'ic:outline-keyboard-arrow-down',
      item: [
        {
          label: 'Auction Guide',
          to: '/auction_guide',
        },
        // {
        //     label: "About Us",
        //     to: "/about_us"
        // },
        // {
        //     label: "Contact Us",
        //     to: "/contact_us"
        // }
      ],
    },
    ,
    // {
    //     id: 2,
    //     title: "Support",
    //     active: false,
    //     icon: 'ic:outline-keyboard-arrow-down',
    //     item: [{
    //         label: "Insurance",
    //         //to: "/direct_import"
    //     },{
    //         label: "FAQ",
    //         //to: "/direct_import"
    //     },{
    //         label: "Contact Us",
    //         to: "/direct_import"
    //     },{
    //         label: "Locate Us",
    //         to: "/direct_import"
    //     }]
    // }
    {
      id: 2,
      title: 'About D-Concept',
      active: false,
      icon: 'ic:outline-keyboard-arrow-down',
      item: [
        {
          label: 'About Us',
          to: '/about_us',
        },
        {
          label: 'Privacy Policy',
          to: '/terms-of-use',
        },
        {
          label: 'Term of Use',
          to: '/terms-of-use',
        },
      ],
    },
    {
      id: 3,
      title: 'Partner Website',
      active: false,
      icon: 'ic:outline-keyboard-arrow-down',
      item: [
        {
          label: 'Import Japan Car',
          //to: "/direct_import"
        },
        {
          label: 'Import UK Car',
          to: 'https://www.vehiclecheck.co.uk/',
        },
        // {
        //     label: "England Car",
        //     //to: "/direct_import"
        // }
      ],
    },
  ],
  customersListing: [
    {
      name: 'Tan sin rong',
      content: 'The staff very nice & friendly !',
      star: 5,
      avatar:
        'https://lh3.googleusercontent.com/a/AAcHTtc9OgKEopivjrqFkJV_9XsNvHWiXTcEN5IkB7T_0W6K=w60-h60-p-rp-mo-br100',
    },
    {
      name: 'eug P',
      content:
        'Great purchase experience and transparent purchasing process. Constant follow up by Mr Chia on car delivery status and the dealing was hassle free. The refurbishment of interior exceeded my expectations.',
      star: 5,
      avatar:
        'https://lh3.googleusercontent.com/a/AAcHTtfKs58XndWBYXwItjGNHnTWfHQVG2VQFidfnOYDHFTe=w60-h60-p-rp-mo-br100',
    },
    {
      name: 'Melvin Teo',
      content:
        'Superb! Staff is very friendly & polite. Kate was there to explain to me the car import process & walkabout. She welcomed me to take photos & look around although we are just browsing. Definitely recommended if you are looking for car dealership with a positive attitude towards their customers & patron!',
      star: 5,
      avatar:
        'https://lh3.googleusercontent.com/a-/AD_cMMT_NKF3QI4601DRUmuQakBqzqpGcs_6uMReoJXND4cku6w=w60-h60-p-rp-mo-ba3-br100',
    },
    {
      name: 'Tan Catherine',
      content:
        'Direct Import Your Own Car with Cost2Cost, Genuine Mileage & Genuine Report. Peace of Mind😌You Get What You Pay',
      star: 5,
      avatar:
        'https://lh3.googleusercontent.com/a-/AD_cMMSi6_GxlfBxhAFDaWztoDxGRRaq8MCxbW6FJlJ8Bw0lM6k=w60-h60-p-rp-mo-br100',
    },
    {
      name: 'Geline Loo',
      content:
        'This is my first time ever purchased car from overseas directly. Im glad that i listen what Elden advice.At first, I am doubt with what they doing and i have alot of questions about it because i never heard about import the car myself from overseas. He explained to me the whole process patiently from how to choose car, how’s the calculation works, and every step by step from A to Z. The things that surprised me was he will show me the car condition analysis report from UK which local seller don’t.I would say if you are looking someone trusted to purchase a car with a reasonable price and good condition, i will definitely say look for Elden wont goes wrong.',
      star: 5,
      avatar:
        'https://lh3.googleusercontent.com/a/AAcHTteUXj79Uw-HiGwrEuqNPk50VSxCU-r4iIhn_JdiBhzQ=w60-h60-p-rp-mo-br100',
    },
    {
      name: 'Trista Teoh',
      content: '就是五星级的车行',
      star: 5,
      avatar:
        'https://lh3.googleusercontent.com/a-/AD_cMMRT0-jCZZcXYkqMu1-8bmFtdFSd7Fk4PzBiR3JyiMgvfjM=w60-h60-p-rp-mo-ba2-br100',
    },
    {
      name: 'Kelvin Puah',
      content: 'brilliant, service damn good....',
      star: 5,
      avatar:
        'https://lh3.googleusercontent.com/a/AAcHTtdVpXHvMpznm3S8pFoD-UvMfddTELc5_kU_0yDe3GnQWg=w60-h60-p-rp-mo-br100',
    },
  ],
  t_and_c_Listing: [
    {
      title: '1. Introduction',
      content:
        "Welcome to D-Concept, Malaysia's premier imported car platform. These Terms of Use ('Terms') govern your access and use of dconcept.my ('we', 'our', or 'us'). By accessing and using our website, you signify your agreement to these Terms.",
    },
    {
      title: '2. Acceptance of Terms',
      content:
        'Your use of our website constitutes your acceptance of these Terms and our Privacy Notice. If you do not agree with any part of these Terms, please refrain from using our website.',
    },
    {
      title: '3. Changes to Terms',
      content:
        'We reserve the right to amend or update these Terms at any given time. It is your responsibility to review these Terms periodically. Your continued use of dconcept.my following any changes signifies your acceptance of the revised Terms.',
    },
    {
      title: '4. Use of the Website',
      content:
        'You are expected to use our website in a lawful and ethical manner. All vehicle listings and offerings are subject to change without prior notice.',
    },
    {
      title: '5. Intellectual Property',
      content:
        'All content, including but not limited to text, graphics, logos, and images, on dconcept.my is our proprietary property or that of our licensors. Unauthorized use, reproduction, or distribution is strictly prohibited.',
    },
    {
      title: '6. Limitation of Liability',
      content:
        'We shall not be held liable for any indirect, incidental, or consequential damages arising out of or related to your use of our website.',
    },
    {
      title: '7. Indemnification',
      content:
        'You agree to indemnify and hold harmless D-Concept, its officers, directors, employees, and agents from any claims, damages, liabilities, or expenses related to your violation of these Terms or misuse of our website.',
    },
    {
      title: '8. Termination',
      content:
        'We reserve the right to restrict or terminate your access to our website if we believe you have violated these Terms or acted inconsistently with the spirit of these Terms.',
    },
    {
      title: '9. Governing Law',
      content:
        'These Terms shall be governed by and construed in accordance with the laws of Malaysia.',
    },
    {
      title: '10. Contact Us',
      content: 'For any inquiries or concerns regarding these Terms, please contact:',
      company: 'DIRECT IMPORT CONCEPT Sdn Bhd',
      address: 'Address: No.1, Jalan Astaka 4/KU2, Bandar Bukit Raja, 41050, Klang, Selangor',
      email: 'Email: Dconcept.danny@gmail.com',
    },
  ],
  privacyListing: [
    {
      title: '1. Introduction',
      content:
        'At D-Concept, we are committed to safeguarding your privacy. This Privacy Notice outlines our practices concerning the collection, use, and sharing of your personal data.',
    },
    {
      title: '2. Data We Collect',
      content:
        'We may collect, use, store, and transfer various types of personal data about you, including but not limited to Identity Data, Contact Data, and Technical Data.',
    },
    {
      title: '3. How We Use Your Data',
      content:
        'We use your data for purposes such as customer registration, order processing, relationship management, and ensuring the security and optimal performance of our website.',
    },
    {
      title: '4. Data Security',
      content:
        'We have implemented stringent measures to ensure the security of your personal data and to prevent unauthorized access, alteration, or disclosure.',
    },
    {
      title: '5. Data Retention',
      content:
        'We will retain your personal data only for as long as is necessary for the purposes for which it was collected.',
    },
    {
      title: '6. Your Legal Rights',
      content:
        'You have the right to request access to, correction of, or deletion of your personal data under certain circumstances.',
    },
    {
      title: '7. Third-Party Links',
      content:
        'Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of such websites.',
    },
    {
      title: '8. Cookies',
      content:
        'We use cookies to enhance user experience and analyze website traffic. By using our website, you consent to our use of cookies.',
    },
    {
      title: '9. Changes to This Notice',
      content:
        'We may periodically update this Privacy Notice. We encourage you to review this notice regularly to stay informed about how we are protecting your data.',
    },
    {
      title: '10. Contact Us',
      content:
        'For any inquiries regarding this Privacy Notice or our data practices, please contact:',
      company: 'DIRECT IMPORT CONCEPT Sdn Bhd',
      address: 'Address: No.1, Jalan Astaka 4/KU2, Bandar Bukit Raja, 41050, Klang, Selangor',
      email: 'Email: Dconcept.danny@gmail.com',
    },
  ],
}

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setFooterItemActive: (state, action) => {
      void (state.footerListing[action.payload].active =
        !state.footerListing[action.payload].active)
    },
  },
})

export const { setFooterItemActive } = commonSlice.actions
const { reducer } = commonSlice
export default reducer
