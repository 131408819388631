import { Icon } from '@iconify/react'
import { MDBCol, MDBRow } from 'mdb-react-ui-kit'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import useWindowDimensions from '../../../components/common/window-dimension'

//store
import { motion ,AnimatePresence} from 'framer-motion'
import { Helmet } from 'react-helmet';

//image
import { LogoMain } from '../../../assets/images'

export default function HomeTitle() {
  const dispatch = useDispatch()
  const scrollRef = useRef(null)

  const { width } = useWindowDimensions()

  const gradientAnimation = {
    animate: {
      background: [
        "linear-gradient(135deg, #FF2800 0%, #000000 90%)",
        "linear-gradient(135deg, #000000 0%, #FF2800 90%)",
        "linear-gradient(135deg, #FF8700 0%, #545454 90%)",
        "linear-gradient(135deg, #545454 0%, #FF8700 90%)",
        // "linear-gradient(135deg, #008148 0%, #FFD700 100%)",
        // "linear-gradient(135deg, #FFD700 0%, #008148 100%)"
      ],
      transition: {
        duration: 4,
        ease: "linear",
        repeat: Infinity
      }
    }
  };

  return (
    //     <div className={width > 991 ? "home-title-container" : "home-title-container mobile"}>
    //    {width > 991 &&  <div>
    // <Helmet>
    //     <title>Welcome to Dconcept</title>
    //     <meta name="Dconcept" content="Welcome to Dconcept" />
    // </Helmet>
    //   <MDBRow className="title-details">
    
    //     <motion.h1 className='_h1 --title'
    //        initial={{opacity : 0 , transform : "translateY(50px)"}}
    //        animate={{opacity : 1 , transform : "translateY(0)" ,  transition: {
    //          duration: 0.5,
    //          delay: 0.2,
    //          type: "spring", 
    //        },}}
    //     >
    //       {/* Your Dream Car */}
    //       YOUR DREAM CAR
    //       {/* DIRECT IMPORT0 */}

    //     <br/>
    //     <motion.span
    //     className='color-span'
    //           variants={gradientAnimation}
    //           animate="animate"        
    //           style={{fontSize : width < 1360 ? "71px" : ""}}
    //     >
    //        {/* Import Specialists */}
    //        IMPORT SPECIALISTS
    //        {/* DIRECT IMPORT */}

    //     </motion.span>
    //     </motion.h1>
    //     <motion.p className='_p --description'
    //      initial={{opacity : 0 , transform : "translateY(100px)"}}
    //      animate={{opacity : 1 , transform : "translateY(0)" ,  transition: {
    //        duration: 0.5,
    //        delay: 0.5,
    //        type: "spring", 
    //      },}}
    //     >
    //       Just any model u like; we will import based on your requirements and budget to source it
    //     <br/>
    //     from overseas,  you are getting cost to cost without Mark-up. We're never compromise
    //     <br/>
    //     on delivering good service and customer satisfaction is always in mind.
    //     </motion.p>
    //     <div className='d-flex' style={{justifyContent : "center"}}>
    //     <motion.button 
    //      id='were'
    //      initial={{opacity : 0 , transform : "translateY(100px)"}}
    //      animate={{opacity : 1 , transform : "translateY(0)" ,  transition: {
    //        duration: 0.5,
    //        delay: 0.9,
    //        type: "spring", 
    //      },}}
    //     className='find_btn'>
    //     <a href="#were" style={{color : "#FFFFFF"}}>View More</a>
    //       </motion.button>
    //     </div>
    //   </MDBRow>
    //       </div>}
    //     {width < 992 &&  <div style={{color : "#D1D5DB" , fontFamily : "inter-bold" , fontStyle : "" , fontSize : "30px" , lineHeight : "33px", textAlign : "center" ,marginTop : "0em" , marginBottom : "0.6em"}}>
    //       YOUR DREAM CAR 
    //       <br />
    //       <motion.span 
    //        className='color-span-2'
    //        variants={gradientAnimation}
    //        animate="animate"   
    //       >
    //       IMPORT SPECIALISTS
    //       </motion.span>
    //       </div>}
    // </div>
    <motion.div className='home-title-div' style={{
      paddingTop : width < 992 ? "0.5em" : "",
      marginBottom : width < 992 ? "2.5em" : "",
    }}
    // initial={{opacity : 0 , transform : "translateY(20px)"}}
    //        animate={{opacity : 1 , transform : "translateY(0)" ,  transition: {
    //          duration: 0.5,
    //          delay: 0.2,
    //          type: "spring", 
    //        },}}
    >
      <h1 style={{fontSize : width < 451 ? "28px" : width < 653 ? "36px" : width < 992 ? "46px" : width < 1200 ? "68px" : "80px"}}>
      Discover. Import. Drive
        </h1>
      <h4 style={{fontSize : width < 451 ? "16px" :width < 653 ? "20px" : width < 992 ? "22px" : width < 1200 ? "26px" : "32px"}}>
        Malaysia's No1. Import Specialists</h4>
    </motion.div>
  )
              }
