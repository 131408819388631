//lib
import { MDBContainer } from 'mdb-react-ui-kit'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedTab } from '../../../redux/reducer/carPostReducer'

export default function TabBar({ loading }) {
  const dispatch = useDispatch()
  const { selected_tab } = useSelector((state) => state.carPost)
  const tabOptions = [
    {
      label: 'Quick Look',
      value: 'quick_look',
    },
    {
      label: 'Video',
      value: 'video',
    },
    {
      label: 'D-Stock',
      value: 'd-stock',
    },
    {
      label: 'Direct Owner',
      value: 'direct_owner',
    },
    // {
    //   label: 'Ask D-Bot',
    //   value: 'ask_bot',
    // },
  ]

  return (
    <MDBContainer className="tabbar-container">
      <div className="tabbar-section-row mt-3">
        {tabOptions.map((item, index) => (
          <label
            key={index}
            className={
              selected_tab === item.value ? '_label --tab-active-label' : '_label --tab-label'
            }
            onClick={() => dispatch(setSelectedTab(item.value))}>
            {item.label}
          </label>
        ))}
      </div>
    </MDBContainer>
  )
}
