//lib
import { MDBContainer, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'
import { Icon } from '@iconify/react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setLoginModal, setSelectedLoginTab } from '../../redux/reducer/orderReducer'

//assets
import { LogoMain } from '../../assets/images'

//component
import ModalBox from '../../components/modal/full.modal.box'
import VerificationLogin from './component/verification.login'

export default function Login({ show }) {
  const dispatch = useDispatch()
  const { selectedLoginTab } = useSelector((state) => state.order)

  return (
    <ModalBox
      className={'login-modal-container'}
      show={show}
      staticBackdrop={true}
      desktopModal={false}
      screenSize="fullscreen-md-down"
      content={
        <MDBModalBody>
          <MDBRow className="m-0 header-login">
            <div className="text-center">
              <LazyLoadImage src={LogoMain} alt="dc-logo" width={200} height={50} />
            </div>
            {selectedLoginTab === 'password' ? (
              <div className="button-back">
                <Icon
                  icon="material-symbols:arrow-back-rounded"
                  onClick={() => dispatch(setSelectedLoginTab('verification'))}
                />
              </div>
            ) : (
              <div className="button-close">
                <Icon
                  icon="clarity:window-close-line"
                  onClick={() => {
                    dispatch(setLoginModal(false))
                    dispatch(setSelectedLoginTab('account'))
                  }}
                />
              </div>
            )}
          </MDBRow>
          <MDBContainer style={{ maxWidth: '23em' }}>
            <VerificationLogin />
          </MDBContainer>
        </MDBModalBody>
      }
    />
  )
}
