import { useNavigate } from 'react-router-dom'

//lib
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCreative } from 'swiper'
import IconVerifyDAgent from '../../../assets/images/icon/icon-verify-dagent.png'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { setSection } from '../../../redux/reducer/calculatorReducer'
import {
  setCar1Data,
  setCar2Data,
  setCar3Data,
  setChooseBrand,
} from '../../../redux/reducer/carCompareReducer'
import { setOpenJapanModal } from '../../../redux/reducer/homeReducer'

//helper
import LazyImage from '../../../components/common/common.image'

export default function DirectImport() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { direct_import_listing, direct_import_action , isDarkMode} = useSelector((state) => state.home)
  const { width , height} = useWindowDimensions()

  return (
    <MDBContainer className="direct-import-container">
      <MDBRow className="main-title-row">
        <label className="_title-special-text">
          D - <span className="_titlesec" style={{color :height > 600 &&  width >= 653 && width < 992 && !isDarkMode || height > 850 &&  width >= 991 && !isDarkMode ? "#D1D5DB" : ""}}>DIRECT IMPORT</span>
        </label>
      </MDBRow>
      <div className="box-container mt-3">
        <MDBRow>
          {direct_import_listing.map((item, index) => (
            <MDBCol
              className="col-4"
              key={index}
              onClick={
                item.action === 'openJapanLink'
                  ? () => {
                      dispatch(setOpenJapanModal(true))
                    }
                  : () => {
                      window.open(item.action, '_blank')
                    }
              }
              style={{ cursor: 'pointer' }}>
              <LazyImage
                src={item.image}
                width="100%"
                height="100%"
                alt="Direct-Import-Images"
              />
            </MDBCol>
          ))}
        </MDBRow>
        {width > 991 ? (
          <MDBRow className="action-row mt-4">
            <MDBCol
              className={
                width < 991
                  ? 'text-center'
                  : `col-3 d-grid align-items-center justify-content-center`
              }
              style={{ cursor: 'pointer' }}
              onClick={() => {
                dispatch(setSection('agent'))
                dispatch(setCar1Data({}))
                dispatch(setCar2Data({}))
                dispatch(setCar3Data({}))
                navigate('/calculator?tab=agent')
              }}>
              <LazyImage
                alt="icon-img"
                src={IconVerifyDAgent}
                width={width < 991 ? '35' : '45'}
                height={width < 991 ? '35' : '45'}
              />
              <label className={width < 991 ? '_label' : '_label --bold'}>
                {' '}
                Verify <br /> D-Agent
              </label>
            </MDBCol>
            {direct_import_action.map((item, index) => (
              <MDBCol
                className={
                  width < 991
                    ? 'text-center'
                    : `${item.colums} d-grid align-items-center justify-content-center`
                }
                key={index}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  if (item.action) {
                    window.open(item.action, '_blank')
                  }
                  if (item.navigate) {
                    dispatch(setSection(item.value))
                    dispatch(setChooseBrand(true))
                    dispatch(setCar1Data({}))
                    dispatch(setCar2Data({}))
                    dispatch(setCar3Data({}))
                    navigate(item.navigate)
                  }
                }}>
                <LazyImage
                  alt="icon-img"
                  src={item.image}
                  width={width < 991 ? '35' : '45'}
                  height={width < 991 ? '35' : '45'}
                />
                <label className={width < 991 ? '_label' : '_label --bold'}>{item.label}</label>
              </MDBCol>
            ))}
          </MDBRow>
        ) : (
          <div className=" mt-4">
            <Swiper
              slidesPerView={4}
              spaceBetween={15}
              grabCursor={true}
              creativeEffect={{
                prev: {
                  shadow: false,
                  translate: ['-120%', 0, -500],
                },
                next: {
                  shadow: false,
                  translate: ['120%', 0, -500],
                },
              }}
              modules={[EffectCreative]}>
              <SwiperSlide>
                <div
                  className={
                    width < 991
                      ? 'text-center d-grid justify-content-center'
                      : 'd-grid align-items-center justify-content-center'
                  }
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setSection('agent'))
                    dispatch(setCar1Data({}))
                    dispatch(setCar2Data({}))
                    dispatch(setCar3Data({}))
                    navigate('/calculator?tab=agent')
                  }}>
                  <LazyImage
                    alt="icon-img"
                    src={IconVerifyDAgent}
                    width={width < 991 ? '35' : '45'}
                    height={width < 991 ? '35' : '45'}
                  />
                  <label style={{color :height > 820 &&  width >= 653 && width < 992 && !isDarkMode ? "#D1D5DB" : ""}} className={width < 991 ? '_label' : '_label --bold'}>
                    Verify <br /> D-Agent
                  </label>
                </div>
              </SwiperSlide>
              {direct_import_action.map((item, index) => (
                <SwiperSlide key={index}>
                  <div
                    className={
                      width < 991
                        ? 'text-center d-grid justify-content-center'
                        : 'd-grid align-items-center justify-content-center'
                    }
                    key={index}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      if (item.action) {
                        window.open(item.action, '_blank')
                      }
                      if (item.navigate) {
                        dispatch(setSection(item.value))
                        dispatch(setChooseBrand(true))
                        dispatch(setCar1Data({}))
                        dispatch(setCar2Data({}))
                        dispatch(setCar3Data({}))
                        navigate(item.navigate)
                      }
                    }}>
                    <LazyImage
                      alt="icon-img"
                      src={item.image}
                      width={width < 991 ? '35' : '45'}
                      height={width < 991 ? '35' : '45'}
                    />
                    <label  style={{color :height > 820 &&  width >= 653 && width < 992 && !isDarkMode ? "#D1D5DB" : ""}} className={width < 991 ? '_label' : '_label --bold'}>{item.label}</label>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}
      </div>
    </MDBContainer>
  )
}
