import { MDBCol, MDBContainer, MDBModalBody, MDBRow } from 'mdb-react-ui-kit'
import ModalBox from './full.modal.box'
import { Icon } from '@iconify/react'
import { FacebookShareButton, TwitterShareButton, TelegramShareButton } from 'react-share'
import { toast } from 'react-toastify'
import { CopyToClipboard } from 'react-copy-to-clipboard'

export default function ShareCardBox({ show, setShow, generateImage }) {
  return (
    <ModalBox
      className={'share-modal-container'}
      show={show}
      setShow={setShow}
      staticBackdrop={false}
      desktopModal={false}
      screenSize="s"
      content={
        <MDBModalBody>
          <MDBContainer className="card">
            <MDBRow>
              <label className="mb-3">Share via</label>
            </MDBRow>
            <MDBRow className="m-0 gap-3">
              <div className="_action-card --social">
                <Icon icon="logos:twitter" width="2em" height="2em" />
                <TwitterShareButton url={window.location.href}>Twitter</TwitterShareButton>
              </div>
              <div className="_action-card --social">
                <Icon icon="logos:facebook" width="2em" height="2em" />
                <FacebookShareButton url={window.location.href}>Facebook</FacebookShareButton>
              </div>
              <div className="_action-card --social">
                <Icon icon="logos:telegram" width="2em" height="2em" />
                <TelegramShareButton url={window.location.href}>Telegram</TelegramShareButton>
              </div>
              {'canShare' in navigator && (
                <div onClick={() => generateImage()} className="_action-card --social">
                  Other
                </div>
              )}
              <div className="_action-card --save">
                <CopyToClipboard
                  onCopy={() => {
                    toast(
                      <div>
                        <MDBRow className="m-0">
                          <MDBCol className="text-start">
                            <label className="_label">Copy link</label>
                          </MDBCol>
                        </MDBRow>
                      </div>,
                      {
                        position: 'top-left',
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        theme: 'dark',
                      },
                    )
                  }}
                  text={window.location.href}>
                  <label>
                    <Icon icon="mingcute:copy-line" /> Copy Link
                  </label>
                </CopyToClipboard>
              </div>
            </MDBRow>
          </MDBContainer>
        </MDBModalBody>
      }
    />
  )
}
