import React, { useEffect, useState } from 'react'
import DCMobileLogo from '../../assets/images/first_load_img.webp'
import { LogoMain } from '../../assets/images'

export default function FirstLoadScreen() {
  const [addClass, setAddClass] = useState(false)

  useEffect(() => {
    setTimeout(() => setAddClass(true), 1500)
  }, [])

  return (
    <div className="first-load-page">
      <div className={addClass ? 'first-load-overlay --end' : 'first-load-overlay'}>
        <div className="animated-logo d-flex justify-content-center">
          <img src={LogoMain} width="100%" height="100%" alt="..." />
        </div>
      </div>
    </div>
  )
}
