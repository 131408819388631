import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    car_info: {},
    openGalleria: null,
    car_Image_Modal: {
        show: false,
        index: 0,
    },
    car_exterior_images : [],
    totalSlides : 0,
    currentSlides : 1
};

const orderSlice = createSlice({
  name: "carInfo",
  initialState,
  reducers: {
    setOpenGalleria: (state, action) => {
        state.openGalleria = action.payload
    },
    clearCarInfoData: (state,action) => {
        state.car_info = {}
    },
    setCarInfoData: (state,action) => {
        state.car_info = action.payload
    },
    setImageModalShow:(state,action) => {
        void(state.car_Image_Modal.index = action.payload.index)
        void (state.car_Image_Modal.show = action.payload.show)
    },
    setCarExteriorImages: (state, action) =>{
        void(state.car_exterior_images = action.payload)

    },
    setTotalSlides : (state, action) =>{
        void(state.totalSlides = action.payload)

    },
    setCurrentSlides : (state, action) =>{
        void(state.currentSlides = action.payload)

    },
  },
  extraReducers: {
  }
});

export const {
    setOpenGalleria,
    clearCarInfoData,
    setCarInfoData,
    setImageModalShow,
    setCarExteriorImages,
    setTotalSlides,
    setCurrentSlides,
} = orderSlice.actions
const { reducer } = orderSlice;
export default reducer;
