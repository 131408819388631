//lib
import { Pannellum } from 'pannellum-react'
import { MDBBtn, MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'

export default function Panoroma({ state }) {
  return (
    <MDBContainer className="panoroma-container" breakpoint="none">
      <Pannellum
        width="100%"
        height="52em"
        image={state}
        pitch={-10}
        yaw={360}
        hfov={100}
        autoLoad
        mouseZoom={false}
        showFullscreenCtrl={false}
        showZoomCtrl={false}
        onLoad={() => {}}></Pannellum>
      <MDBRow className="panoroma-button-row">
        <MDBCol className="text-center col-12">
          <MDBBtn
            className="--active"
            onClick={() => {
              //   navigate('/view/bmw', { state: '' })
            }}>
            Interior
          </MDBBtn>
        </MDBCol>
        {/* <MDBCol className="col-6">
          <MDBBtn>Exterior</MDBBtn>
        </MDBCol> */}
      </MDBRow>
    </MDBContainer>
  )
}
