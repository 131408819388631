import { useEffect, useRef, useState } from 'react'

//lib
import ReactPlayer from 'react-player'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'

//redux
import { useSelector } from 'react-redux'

export default function VideCard({ video, index }) {
  const playerRef = useRef(null)
  const [ready, setReady] = useState(false)
  const [isFullscreen, setIsFullscreen] = useState(false)
  const { video_playing } = useSelector((state) => state.home)
  const { width } = useWindowDimensions()

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!(document.fullscreenElement || document.webkitFullscreenElement))
    }

    document.addEventListener('fullscreenchange', handleFullscreenChange)
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange)

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange)
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange)
    }
  }, [])

  useEffect(() => {
    if (!isFullscreen && ready) {
      const player = playerRef.current.getInternalPlayer()
      player.pause()
    } else if (isFullscreen) {
      const player = playerRef.current.getInternalPlayer()
      player.play()
    }
  }, [isFullscreen])

  const handlePlayerEnterFullscreen = () => {
    const player = playerRef.current.getInternalPlayer()
    if (player.requestFullscreen) {
      player.requestFullscreen()
    } else if (player.webkitRequestFullscreen) {
      player.webkitRequestFullscreen()
    } else if (player.msRequestFullscreen) {
      player.msRequestFullscreen()
    }
    player.play()
  }

  return (
    <div>
      <ReactPlayer
        ref={playerRef}
        // onStart={() => onStart()}
        style={{
          height: width >= 991 ? '' : '13em',
          width: width < 991 ? '100% !important' : '',
          zIndex: 2,
          position: 'relative',
        }}
        controls={true}
        id={`video-${index}`}
        url={video}
        width="100%"
        height="100%"
        onReady={setReady}
        onStart={() => handlePlayerEnterFullscreen()}
        onPlay={() => handlePlayerEnterFullscreen()}
        className="react-player"
        playing={video_playing === `video-${index}` ? true : false}
      />
    </div>
  )
}
