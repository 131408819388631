import { useEffect, useState, useRef } from 'react'

//assets
import CarLoader2 from './../../assets/json/car-loader.json'

//lib
import { MDBContainer, MDBRow, MDBCol, MDBInput } from 'mdb-react-ui-kit'
import AgentCard from './component/agent.card'
import { clearAgentData, getDAgent } from '../../redux/reducer/calculatorReducer'
import { useDispatch, useSelector } from 'react-redux'
import Lottie from 'lottie-react'
import { Icon } from '@iconify/react'
import Masonry from 'react-responsive-masonry'

//component
import AgentVideoCard from './component/agent.video.card'
import AgentVideoInfo from './component/agent.video.info'

//helper
import useWindowDimensions from '../../components/common/window-dimension'

export default function VerifyAgent() {
  const dispatch = useDispatch()
  const { width } = useWindowDimensions()
  const ref = useRef(null)
  const wrapperRef = useRef(null)
  const [inputFocus, setInputFocus] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [dataValid, setDataValid] = useState(false)

  const { agentLoading, agentData, agentVideoData } = useSelector((state) => state.calculator)
  const { isDarkMode } = useSelector((state) => state.home)
  const { selectedVideo } = useSelector((state) => state.discover)

  const date = new Date()
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const monthsOfYear = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]

  const formattedDate = `${daysOfWeek[date.getDay()]}, ${date.getFullYear()} ${
    monthsOfYear[date.getMonth()]
  } ${date.getDate()}`

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [window.location.pathname])

  useEffect(() => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    const isValid = emailRegex.test(searchValue)
    const isValidNumber = !Number.isNaN(Number(searchValue))
    setDataValid(isValid || isValidNumber)
    if (isValid) {
      const timeOutId = setTimeout(() => {
        dispatch(
          getDAgent({
            search: searchValue,
          }),
        )
      }, 500)
      return () => clearTimeout(timeOutId)
    } else if (searchValue.length >= 10) {
      const timeOutId = setTimeout(() => {
        dispatch(
          getDAgent({
            search: searchValue,
          }),
        )
      }, 500)
      return () => clearTimeout(timeOutId)
    }
  }, [dispatch, searchValue])

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setInputFocus(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [wrapperRef])

  const openWhatsapp = () => {
    const url = 'https://wa.me/60125733527'
    window.open(url)
  }

  return (
    <MDBContainer className="calculator-loan-form">
      <div className="formik-form">
        {/* <MDBRow className="roadtax-remark">
          <MDBRow className="roadtax-remark-content">
            <label className="white">
              Search agent's full name or contact number or email address to verify our D-Agent.
            </label>
            <span className="font-highlight no-width">{formattedDate}</span>
          </MDBRow>
        </MDBRow> */}

        <MDBRow className="roadtax-remark">
          <MDBRow className="roadtax-remark-content">
            <label className="white" style={{ fontSize: '1.5em' }}>
              <span style={{ color: '#ff4b4b' }}>Notice: </span>
              Kindly contact or WhatsApp management to confirm the account details at
              <br />
              <span style={{ cursor: 'pointer', paddingRight: '0.5rem' }}>
                {' '}
                <Icon icon="logos:whatsapp-icon" width="2em" height="2em" color="#24262D" />
              </span>
              <span
                className="font-highlight no-width"
                onClick={() => openWhatsapp()}
                style={{ cursor: 'pointer' }}>
                {' '}
                +6012 5733 527
              </span>
              .
            </label>
            {/* <label className="white" style={{ textAlign: 'justify', paddingRight: '1em' }}>
              <span style={{ color: '#ff4b4b' }}>Notice:</span> The agent is verified by Dconcept
              Dealer. Always Confirm our bank account by calling{' '}
              <span className="font-highlight no-width">03-33599147</span> before making any
              payments to any agent.
            </label> */}
          </MDBRow>
        </MDBRow>

        {/* 
        <div className="custom-field">
          <MDBRow className="form-outline" ref={wrapperRef}>
            <MDBCol className="col-lg-11 col-md-11 col-10 d-flex align-items-start">
              <MDBInput
                ref={ref}
                onFocus={() => setInputFocus(true)}
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value)
                  if (e.target.value.length == 0) {
                    dispatch(clearAgentData(''))
                  }
                }}
                label="Agent Contact / Email "
              />
            </MDBCol>
            {searchValue ? (
              <MDBCol
                className="col-lg-1 col-md-1 col-2 d-flex align-items-center justify-content-start"
                onClick={() => {
                  setSearchValue('')
                  dispatch(clearAgentData(''))
                }}>
                <Icon icon="material-symbols:close" color={'#9091A0'} width="25" height="30" />
              </MDBCol>
            ) : (
              <MDBCol className="col-lg-1 col-md-1 col-2 d-flex align-items-center justify-content-start">
                <Icon
                  icon="eva:search-outline"
                  color={isDarkMode ? '#D6A055' : '#AC4343'}
                  width="25"
                  height="30"
                />
              </MDBCol>
            )}
          </MDBRow>
        </div>
        {searchValue.length > 0 && searchValue.length < 10 ? (
          <MDBRow>
            <label className="text-danger" style={{ fontSize: '0.8em' }}>
              Fill up agent contact or email
            </label>
          </MDBRow>
        ) : (
          ''
        )}
        {!agentLoading ? (
          dataValid && <AgentCard data={agentData} />
        ) : (
          <>
            <div className="overlay-img"></div>
            <div className="overlay-img-div --agent d-flex justify-content-center">
              <Lottie animationData={CarLoader2} style={{ width: '70%' }} />
            </div>
          </>
        )}
         */}
      </div>

      {/*       
      {!agentLoading && agentData.length > 0 && dataValid && (
        <MDBContainer className="text-center" style={{ marginTop: '-5em' }}>
          <label className="_label --video-title">
            Videos <Icon icon="ion:car-sport-sharp" height={25} />
          </label>
        </MDBContainer>
      )}
      {!agentLoading && agentData.length > 0 && dataValid && (
        <Masonry
          columnsCount={width > 1200 ? 5 : width > 990 ? 4 : width > 767 ? 3 : 2}
          gutter="4px"
          style={{ justifyContent: 'center' }}>
          {agentVideoData.length > 0 &&
            agentVideoData.map((item, index) => {
              return (
                <MDBRow key={index} style={{ justifyContent: 'center' }}>
                  <AgentVideoCard item={item} />
                </MDBRow>
              )
            })}
        </Masonry>
      )}
      {selectedVideo?.description !== undefined && <AgentVideoInfo />} 
      */}
    </MDBContainer>
  )
}
