//lib
import { MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import { LazyLoadImage } from 'react-lazy-load-image-component'

//component
import CarItem from './car.item'

//hook
import useWindowDimensions from '../../../components/common/window-dimension'
import InfiniteScroll from 'react-infinite-scroll-component'

export default function CarListing({ data, bodyType, fetchMoreData, hasMore, brandData }) {
  const { width } = useWindowDimensions()

  return (
    <MDBContainer className="car-listing-container" breakpoint="sm">
      <div className="icon-container">
        <div>
          <LazyLoadImage
            src={brandData?.icon[0]?.url}
            width={width < 991 ? '55' : '55'}
            alt="brand-icon"
          />
        </div>
        <label>{brandData?.brand_name}</label>
      </div>
      <InfiniteScroll
        dataLength={data.length}
        next={() => {
          fetchMoreData()
        }}
        hasMore={hasMore}
        loader={<h4 className="text-center"></h4>}>
        {Object.keys(bodyType).map((item, index) => {
          const newData = data.filter((array) => array.car_bodies_name === item)
          switch (item) {
            case 'Convertible':
              if (newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  data: newData,
                })
              }
              break
            case 'Coupe':
              const coupe = data.filter((array) => array.car_bodies_name === 'Convertible')

              if (coupe.length >= bodyType['Convertible'] && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  data: newData,
                })
              }
              break
            case 'Hatchback':
              const hatchback = data.filter((array) => array.car_bodies_name === 'Coupe')

              if (hatchback.length >= bodyType['Coupe'] && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  data: newData,
                })
              }
              break
            case 'MPV':
              const mpv = data.filter((array) => array.car_bodies_name === 'Hatchback')

              if (mpv.length >= bodyType['Hatchback'] && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  data: newData,
                })
              }
              break
            case 'Sedan':
              const sedan = data.filter((array) => array.car_bodies_name === 'MPV')

              if (sedan.length >= bodyType['MPV'] && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  data: newData,
                })
              }
              break
            case 'SUV':
              const suv = data.filter((array) => array.car_bodies_name === 'Sedan')

              if (suv.length >= bodyType['Sedan'] && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  data: newData,
                })
              }
              break
            case 'Wagon':
              const wagon = data.filter((array) => array.car_bodies_name === 'SUV')

              if (wagon.length >= bodyType['SUV'] && newData.length > 0) {
                return carInfoRow({
                  item: item,
                  index: index,
                  data: newData,
                })
              }
              break
            default:
              break
          }
        })}
      </InfiniteScroll>
    </MDBContainer>
  )
}

export const carInfoRow = ({ item, index, data, loading }) => {
  return (
    <div key={index}>
      <MDBRow className="m-0 justify-content-center" style={{ position: 'relative' }}>
        <div className="line-container --brand-info">
          <div>
            <div
              className="rhombusOne rhombus"
              style={{ fontSize: '0.7em', marginTop: '0.1em', marginLeft: '-0.4em' }}>
              &#9670;
            </div>
          </div>
          <div>
            <div
              className="rhombusTwo rhombus"
              style={{ fontSize: '0.7em', marginTop: '0.1em', marginLeft: '0.4em' }}>
              &#9670;
            </div>
          </div>
        </div>
        <label className="_label --brand-title">{item}</label>
      </MDBRow>
      <MDBRow className="m-0">
        {data.map((listing, index) => {
          return <CarItem item={listing} key={index} loading={loading} />
        })}
      </MDBRow>
    </div>
  )
}
