import { useState } from "react";

//lib
import { MDBCol,
  MDBContainer,
  MDBRow,
  MDBTableBody,
  MDBTable,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsContent,
  MDBTabsPane } from "mdb-react-ui-kit";

const AuctionGuidesecondTable = () => {
    const [activeTab, setActiveTab] = useState("breakdown-level");
  
    const handleTabClick = (event) => {
      setActiveTab(event.target.getAttribute("href").substring(1));
    };
  
    return (
      <MDBContainer className="auction-guide-table-container">
        <MDBRow >
          <MDBCol>
          <div className='dark-theme-tabs'>
            <MDBTabs justify className='_tab'>
              <MDBTabsItem className='_padding'>
                <MDBTabsLink className='_text --grey ' 
                  onClick={(e) => {
                    e.preventDefault();
                    handleTabClick(e);
                  }}
                  active={activeTab === 'breakdown-level'}
                  href='#breakdown-level'
                >
                  Breakdown level of vehicle
                </MDBTabsLink>
              </MDBTabsItem>

              <MDBTabsItem className='_padding'>
                <MDBTabsLink className='_text --grey'
                  onClick={(e) => {
                    e.preventDefault();
                    handleTabClick(e);
                  }}
                  active={activeTab === 'abbreviation-auction-list'}
                  href='#abbreviation-auction-list'
                >
                Abbreviation in the Auction List
              </MDBTabsLink>
            </MDBTabsItem>

            <MDBTabsItem className='_padding'>
              <MDBTabsLink className='_text --grey'
                onClick={(e) => {
                  e.preventDefault();
                  handleTabClick(e);
                }}
                active={activeTab === 'exterior-grading'}
                href='#exterior-grading'
              >
                Exterior grading
              </MDBTabsLink>
            </MDBTabsItem>
            </MDBTabs>
            </div>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol>
          <MDBTabsContent className='_tab --height'>
          <MDBTabsPane  show={activeTab === 'breakdown-level'}>
                <MDBTable hover borderless striped >
                  <MDBTableBody className='_text --body '>

                        {/* old table */}
                        <tr>
                        <th className='table-dark'>A</th>
                        <td className='table-warning'>Scratch</td>
                        <th className='table-dark'>E</th>
                        <td className='table-warning'>Dimple</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>U</th>
                        <td className='table-warning'>Dent</td>
                        <th className='table-dark'>W</th>
                        <td className='table-warning'>Repair Mark</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>S</th>
                        <td className='table-warning'>Place with Rust</td>
                        <th className='table-dark'>C</th>
                        <td className='table-warning'>Place with Corrosion</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>P</th>
                        <td className='table-warning'>Paint blemish</td>
                        <th className='table-dark'>H</th>
                        <td className='table-warning'>Paint dissolved</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>X</th>
                        <td className='table-warning'>Body part required to be replaced (has no number, located on the chassis)</td>
                        <th className='table-dark'>X</th>
                        <td className='table-warning'>Cleavage on the Window (Usually with the number, located on the windows)</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>XX</th>
                        <td className='table-warning'>Body part has been replaced</td>
                        <th className='table-dark'>B</th>
                        <td className='table-warning'>Dent and scratch</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>Y</th>
                        <td className='table-warning'>Crack or Hole</td>
                        <th className='table-dark'>R</th>
                        <td className='table-warning'>Repaired Cleavage on the Window</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>RX</th>
                        <td className='table-warning'>Repaired Cleavage on the Window and needs to be replaced</td>
                        <th className='table-dark'>G</th>
                        <td className='table-warning'>Stone chip in the glass</td>
                        </tr>


                    {/* new table (need to modified for better layout)*/}
                    {/* <tr>
                    <td>
                    <tr>
                      <th className='table-dark'>A</th>
                      <td className='table-warning'>Scratch</td>
                    </tr>
                    <tr>
                      <th className='table-dark'>U</th>
                      <td className='table-warning'>Dent</td>
                    </tr>
                    </td>

                    <td>

                    </td>
                    </tr> */}
                    {/*new table end here */}

                    </MDBTableBody>
                </MDBTable>
              </MDBTabsPane>

              <MDBTabsPane  show={activeTab === 'abbreviation-auction-list'}>
                <MDBTable hover borderless striped >
                  <MDBTableBody className='_text --body--special '>
                        <tr>
                        <th className='table-dark'>AAC</th>
                        <td className='table-warning'>Climate Control Air Conditioner</td>
                        <th className='table-dark'>AC</th>
                        <td className='table-warning'>Air Conditioner</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>AIRBAGU</th>
                        <td className='table-warning'>Airbag</td>
                        <th className='table-dark'>AW</th>
                        <td className='table-warning'>Original Alloy Wheels</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>CA</th>
                        <td className='table-warning'>Column Automatic Transmission</td>
                        <th className='table-dark'>F5</th>
                        <td className='table-warning'>5 Speed Manual Transmission</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>FA / AT</th>
                        <td className='table-warning'>Automatic Transmission</td>
                        <th className='table-dark'>F6</th>
                        <td className='table-warning'>6 Speed Manual Transmission</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>KAWA</th>
                        <td className='table-warning'>Leather seats</td>
                        <th className='table-dark'>NAVI</th>
                        <td className='table-warning'>Navigator</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>PS</th>
                        <td className='table-warning'>Power Steering</td>
                        <th className='table-dark'>PW</th>
                        <td className='table-warning'>Power Steering</td>
                        </tr>
                        <tr>
                        <th className='table-dark'>SR</th>
                        <td className='table-warning'>Sunroof</td>
                        <th className='table-dark'>TV</th>
                        <td className='table-warning'>Television</td>
                        </tr>
                    </MDBTableBody>
                  </MDBTable>
              </MDBTabsPane>

              <MDBTabsPane  show={activeTab === 'exterior-grading'}>
              <MDBTable hover borderless striped >
                <MDBTableBody className='_text --body '>
                    <tr>
                    <th className='table-dark'>A</th>
                    <td className='table-warning'>Awesome exterior condition</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>B</th>
                    <td className='table-warning'>Could be found scratches up to 15 cm but usually smaller.</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>C</th>
                    <td className='table-warning'>Signify vehicle with scratches up to 30 cm or dents.</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>D</th>
                    <td className='table-warning'>Define visible scratches, rust or even corrosion.</td>
                    </tr>
                    <tr>
                    <th className='table-dark'>E</th>
                    <td className='table-warning'>An awful car condition. Its all about rust, corrosion and all variety of exterior breakdowns.</td>
                    </tr>   
                </MDBTableBody>
              </MDBTable>
            </MDBTabsPane>

            </MDBTabsContent>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  };
  export default AuctionGuidesecondTable;