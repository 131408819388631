import Header from '../header/header-main';
import FooterOne from '../footer/footer-one';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      {children}
    </>
  );
};

export default Layout;